import React from "react";
import login3 from "../../images/LandingPage/login3.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import checkUser from "../../Helpers/cloaker";

function GoogleButtonRed({ loginViaGoogle, languageData }) {

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      loginViaGoogle(userInfo.data.email);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      <div
        onClick={async () => {
          await checkUser()
          googleLogin()
        }}
        className="border-[#FF0000] border-2 bg-transparent rounded-[32px] max-w-[320px] w-full m-auto flex justify-center items-center py-3 cursor-pointer mt-4"
      >

        <svg className="w-[24px]" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_17_89)">
            <path d="M13.854 1.30804C9.88035 2.68652 6.45352 5.30292 4.07681 8.77294C1.7001 12.2429 0.498785 16.3837 0.649322 20.5869C0.799859 24.7901 2.29431 28.8343 4.91317 32.1254C7.53203 35.4165 11.1373 37.781 15.1993 38.8717C18.4925 39.7215 21.9428 39.7588 25.2536 38.9805C28.2528 38.3068 31.0257 36.8657 33.3007 34.7984C35.6685 32.5811 37.3871 29.7604 38.2719 26.6394C39.2333 23.2455 39.4044 19.6765 38.7721 16.2061H20.2792V23.8773H30.9891C30.775 25.1008 30.3164 26.2686 29.6405 27.3107C28.9646 28.3528 28.0855 29.2478 27.0556 29.9422C25.748 30.8076 24.2737 31.3897 22.7276 31.651C21.177 31.9394 19.5865 31.9394 18.036 31.651C16.4643 31.3265 14.9776 30.6778 13.6706 29.7464C11.5708 28.26 9.99405 26.1483 9.16549 23.7126C8.32315 21.2313 8.32315 18.5414 9.16549 16.0601C9.75528 14.3208 10.7303 12.7373 12.0177 11.4276C13.491 9.90123 15.3563 8.81021 17.4089 8.27418C19.4614 7.73816 21.622 7.77785 23.6534 8.38891C25.2405 8.87585 26.6918 9.72702 27.8914 10.8745C29.099 9.67313 30.3045 8.46865 31.508 7.26106C32.1294 6.6117 32.8067 5.99341 33.4188 5.32851C31.5873 3.62437 29.4376 2.29826 27.0929 1.42611C22.823 -0.124316 18.1509 -0.165982 13.854 1.30804Z" fill="white" />
            <path d="M13.8541 1.30806C18.1506 -0.166969 22.8227 -0.1264 27.093 1.42302C29.4382 2.30109 31.5868 3.63359 33.4158 5.34406C32.7944 6.00896 32.1388 6.63036 31.505 7.27661C30.2994 8.48006 29.095 9.67936 27.8915 10.8745C26.6919 9.72704 25.2406 8.87586 23.6536 8.38892C21.6227 7.77572 19.4623 7.73374 17.4092 8.26757C15.3561 8.8014 13.4897 9.89042 12.0147 11.4151C10.7273 12.7249 9.75229 14.3084 9.1625 16.0477L2.72168 11.0609C5.0271 6.48918 9.0188 2.99213 13.8541 1.30806Z" fill="white" />
            <path d="M1.01319 16.0011C1.35912 14.2853 1.93387 12.6237 2.72204 11.0609L9.16286 16.0601C8.32052 18.5414 8.32052 21.2314 9.16286 23.7127C7.01696 25.3697 4.87002 27.0351 2.72204 28.7087C0.749562 24.7825 0.14799 20.3089 1.01319 16.0011Z" fill="white" />
            <path d="M20.2792 16.203H38.7721C39.4043 19.6734 39.2332 23.2424 38.2719 26.6363C37.3871 29.7573 35.6685 32.578 33.3007 34.7953C31.2221 33.1735 29.1342 31.564 27.0556 29.9422C28.0861 29.2471 28.9657 28.3511 29.6416 27.3079C30.3175 26.2646 30.7758 25.0957 30.9891 23.8711H20.2792C20.2761 21.3171 20.2792 18.7601 20.2792 16.203Z" fill="white" />
            <path d="M2.71875 28.7087C4.86673 27.0516 7.01366 25.3863 9.15957 23.7126C9.98978 26.1492 11.5688 28.261 13.6709 29.7464C14.9819 30.6735 16.4719 31.3169 18.0456 31.6355C19.5962 31.9239 21.1866 31.9239 22.7372 31.6355C24.2833 31.3742 25.7576 30.7921 27.0652 29.9267C29.1438 31.5485 31.2317 33.1579 33.3103 34.7798C31.0356 36.8482 28.2627 38.2903 25.2632 38.9649C21.9524 39.7433 18.5021 39.7059 15.2089 38.8562C12.6043 38.1607 10.1714 36.9348 8.0628 35.2552C5.83112 33.483 4.00826 31.25 2.71875 28.7087Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_17_89">
              <rect width="39.7696" height="39.7696" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="text-[14px] poppins font-semibold ml-3">
          {languageData?.newRegisterContinueBtn}{" "}
          <span className="text-[14px] poppins font-bold redGradient">
            Google
          </span>
        </p>
      </div>

      {/*
                 <button  type="button" className='w-[50%] lg:w-full gap-2 lg:gap-4  flex bg-[#1E1E1E] px-2 lg:px-3 py-2  rounded-[8px] lg:rounded-[12px] items-center justify-center'>
                <img className='w-4 lg:w-6 h-4 lg:h-6' src={google} alt="Google" />
                <p className='text-[12px] lg:text-[14px] text-[#888888] font-semibold text-left saira' dangerouslySetInnerHTML={{ __html: languageData?.authGoogle + ' <span class="saira" style="color:#FFFFFF">Google</span>' }} />
            </button>

                <LoginSocialGoogle  className='w-full' scope='https://www.googleapis.com/auth/userinfo.email' client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID} onResolve={(response) => {
                    console.log(response)
                    loginViaGoogle(response.data.email)
                }}
                
                   
                    onReject={(response) => {
                        console.log(response)
                    }}
                >
                    <button type="button" className='w-full flex gap-4 bg-white py-3 rounded-[12px] items-center justify-center'>
                        <img className='w-6 h-6' src={google} alt="Google" />
                        <p className='text-[14px] lg:text-[16px] text-[#8E8E8E] font-semibold text-left saira' dangerouslySetInnerHTML={{ __html: 'Continue with <span class="saira" style="color:#282B54">Google</span>' }} />
                    </button>
                </LoginSocialGoogle>
                */}
    </>
  );
}

export default GoogleButtonRed;
