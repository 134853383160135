import React, { useEffect } from "react";
import { discordUserAuth } from "../../Requests/auth";
import { getUserCountry } from "../../Requests/utills";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";
import { jwtDecode } from "jwt-decode";

import moengage from "@moengage/web-sdk";
import mixpanelTrack from "../../Helpers/mixpanel";

function DiscordAuth() {
  const { language } = useLanguage();

  const queryParams = new URLSearchParams(window.location.search);
  const fragment = new URLSearchParams(window.location.hash.slice(1));
  const [accessToken, tokenType] = [
    fragment.get("access_token"),
    fragment.get("token_type"),
  ];

  let refferalCode =
    queryParams.get("ref") || window.localStorage.getItem("ref");
  if (refferalCode === undefined || refferalCode === null) refferalCode = null;

  let special = queryParams.get("uid");

  useEffect(() => {
    const auth = async () => {


      let utms = {
        utm_source: localStorage.getItem("utm_source"),
        utm_medium: localStorage.getItem("utm_medium"),
        utm_campaign: localStorage.getItem("utm_campaign"),
        utm_term: localStorage.getItem("utm_term"),
        utm_content: localStorage.getItem("utm_content"),
      }

      try {
        let userCountry = await getUserData();
        const res = await discordUserAuth(
          accessToken,
          tokenType,
          userCountry.country,
          refferalCode,
          language,
          special,
          utms
        );

        let decoded = jwtDecode(res.data.token);
        await handleMixpanelEvent(true, "discord", decoded.id);

        window.location.replace(
          `https://cybersaysm-redirect.vercel.app?${"redirectUrl=" + window.location.protocol + "//" + window.location.host}&token=` +
            res.data.token
        );
        /*
                localStorage.setItem('token', res.data.token);
                navigate('/')
                */
      } catch (error) {
        console.log(error);
      }
    };
    auth();
  }, []);

  const getUserData = async () => {
    try {
      const res = await getUserCountry();
      return res.data.Data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMixpanelEvent = async (success, provider, userID) => {
    mixpanelTrack("login", {
      distinct_id: userID,
      registered_with: provider,
      success: success,
    });
    moengage.track_event("login", {
      id: userID,
      registered_with: provider,
      success: success,
    });
  };

  return <div className="saira text-black">Loading...</div>;
}

export default DiscordAuth;
