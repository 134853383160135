import React from 'react'
import avatar1 from "../../Components/SliderHomepage/avatar1.png";

function Members({img, name, role}) {
  return (
    <div className='bg-[#181232] sm:max-w-[250px] h-[180px] w-full rounded-[35px] membersLED relative m-auto mt-[150px]'>
     <img className='w-[195px] m-auto mt-[-50px] absolute top-[-50px] left-1/2 transform -translate-x-1/2' src={img} alt="avatar" />
     <p className='satoshi text-[24px] font-bold text-center pt-[100px]'>{name}</p>
     <p className='satoshi text-[16px] font-medium text-center'>{role}</p>
    </div>
  )
}

export default Members
