import React, { useState, useEffect } from "react";
import TimeCounter from "./TimeCounter";
import link5 from "../images/NewDesign2/MenuBottom/link5.svg";
import link5Active from "../images/NewDesign2/MenuBottom/link5active.svg";
import bgDesk from "../images/NewDesign2/MenuBottom/bg-desk.svg";
import bgMob from "../images/NewDesign2/MenuBottom/bg-mob.svg";
import { CSSTransition } from "react-transition-group";
import HoverBtn from "./Buttons/HoverBtn";
import TimeCounterDay from "./TimeCounterDay";
import cn from "classnames";
import { competitionStore } from "../stores/competitionStore";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
import LinkDaily from "../images/icons/LinkDaily";
import LinkHome from "../images/icons/LinkHome";
import LinkVegas from "../images/icons/LinkVegas";
import LinkRefer from "../images/icons/LinkRefer";
import mixpanelTrack from "../Helpers/mixpanel";
import moengage from "@moengage/web-sdk";
import moment from "moment";
import { withdrawPartner } from "../Requests/withdraw";

function MainMenu({
  scrollToPage,
  setMenuOpen,
  languageData,
  activePageIndex,
  setActivePageIndex,
  setWinModal,
  PartnerURL,
  user,
  setGamePoint,
  setSpinNoTimes,
  setTourModal,
  setWithdrawModal,
  setWithdrawComplete,
  setWitdrawError,
}) {
  const [activeLink, setActiveLink] = useState("link1");

  const { language } = useLanguage();

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const competitionMode = competitionStore((state) => state.mode);
  const competition = competitionStore.getState();

  const doubleStarted = competitionStore((state) => state.doubleStarted);
  const wheelStarted = competitionStore((state) => state.wheelStarted);

  const [lastTask, setLastTask] = useState(null);

  useEffect(() => {
    if (user === null) return setLastTask(0);

    let tasks = JSON.parse(user?.completed_tasks);
    //let tasks = [1, 2, 3];
    if (tasks === null) return setLastTask(0);
    let lastTask = tasks[tasks.length - 1];
    setLastTask(lastTask);
  }, [user]);

  const [checkBox, setCheckBox] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleEvent = (e) => {
    mixpanelTrack("Homepage_button_click", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });

    moengage.track_event("Homepage_button_click", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });
  };

  const [timeLeft, setTimeLeft] = useState(
    moment.duration().add({ minutes: 7, seconds: 0 })
  );

  useEffect(() => {
    const storedTimeLeft = window.localStorage.getItem("timeLeft");
    if (storedTimeLeft) {
      setTimeLeft(moment.duration(parseFloat(storedTimeLeft), "seconds"));
    } else {
      setTimeLeft(moment.duration().add({ minutes: 7, seconds: 0 }));
    }

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = moment.duration(prevTime.asSeconds() - 1, "seconds");
        window.localStorage.setItem("timeLeft", newTime.asSeconds());
        return newTime.asSeconds() >= 0 ? newTime : moment.duration(0);
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const handleWithdraw = async (e) => {
    try {
      const res = await withdrawPartner();

      if (res.data.status === "success") {
        setWithdrawComplete(true);
        setWitdrawError(false);
      } else {
        setWitdrawError(true);
        alert(res.data.message);
      }
    } catch (error) {
      setWitdrawError(true);
    }
  };

  return (
    <div
      className={`w-full fixed bottom-0 z-30 ${activePageIndex === 0 && "hidden"}`}
    >
      <img
        className="w-full object-cover sm:block hidden pointer-events-none select-none scale-[1.02] mb-[-2px]"
        src={bgDesk}
        alt="bgDesk"
      />
      <img
        className="w-full object-cover block sm:hidden pointer-events-none select-none scale-[1.02]"
        src={bgMob}
        alt="bgDesk"
      />
      <div
        className="bg-[#8B007D] w-full justify-center flex pb-2 2xl:pb-4"
        style={{ transition: "all 0.2s ease-in-out" }}
      >
        <div className=" max-w-[1000px] w-full px-2">
          {activePageIndex === 0 &&
            (lastTask !== 3 ? (
              <div className="mb-6 sm:my-6">
                <p className="text-center text-[15px] sm:text-[20px] font-bold saira mb-5 sm:mt-0">
                  <span className="text-center text-[20px] sm:text-[32px] font-bold saira">
                    {language === "es" && "¡"}
                    {timeLeft.minutes().toString().padStart(2, "0")}:
                    {timeLeft.seconds().toString().padStart(2, "0")}
                  </span>{" "}
                  {languageData?.homepageLiveMenuTitle1}
                </p>
                <HoverBtn
                  disabled={
                    moment
                      .duration(timeLeft.asSeconds(), "seconds")
                      .asSeconds() < 1
                  }
                  onClick={(e) => {
                    handleEvent(e);
                    window.open(PartnerURL);
                  }}
                  text={languageData?.homepageLiveMenuBtn1}
                  mAuto={"m-auto"}
                />
              </div>
            ) : (
              <div className="mb-4 mt-6">
                <HoverBtn
                  onClick={(e) => handleWithdraw()}
                  text={languageData?.homepageLiveMenuBtn2}
                  mAuto={"m-auto"}
                />
                <p
                  onClick={() => setWithdrawModal(true)}
                  className="cursor-pointer text-center text-[16px] sm:text-[28px] font-bold saira mt-2 underline"
                >
                  {languageData?.homepageLiveMenuTitle2}
                </p>
              </div>
            ))}
          <CSSTransition
            in={activePageIndex === 2}
            timeout={200}
            classNames="time-counter-animation"
            unmountOnExit
          >
            <div className="max-w-[750px] w-full m-auto scaleFrom125Bot">
              <div className="time-counter-container flex justify-between mx-2 sm:mx-0 leading-4 sm:leading-[unset] m-auto sm:my-3  mb-0 ">
                <p
                  onClick={(e) => setTourModal(true)}
                  className={`saira ${language !== "en" ? "sm:text-[14px] sm:max-w-[200px]" : "sm:text-[16px] sm:max-w-[200px]"} text-[13px]  font-semibold text-[#00FFFF] border-[2px] border-[#00FFFF] rounded-[10px] w-[50%]  text-center cursor-pointer m-auto max-w-[160px]  py-[2px] px-8 sm:px-0 sm:m-0`}
                >
                  {languageData?.newMenuBottom3Link1}
                </p>
                <p
                  onClick={(e) => setWinModal(true)}
                  className={`saira ${language !== "en" ? "sm:text-[14px] " : "sm:text-[16px]"} text-[13px] font-semibold text-[#00FFFF] border-[2px] border-[#00FFFF] rounded-[10px] w-[50%]  text-center cursor-pointer m-auto max-w-[160px] sm:max-w-[250px] py-[2px] sm:m-0`}
                >
                  {languageData?.newMenuBottom3Link2}
                </p>
              </div>
              <div className="flex justify-between ml-3 sm:ml-6 sm:hidden ">
                <HoverBtn
                  onClick={(e) => window.open(PartnerURL)}
                  text={languageData?.newMenuBottom3Btn1}
                  width={"!w-[90%]"}
                />
                <HoverBtn
                  text={languageData?.newMenuBottom3Btn2}
                  onClick={(e) => {
                    setActivePageIndex(4);
                  }}
                  width={"!w-[90%]"}
                  bgColor={"!bg-[#0FF]"}
                />
              </div>
              <div className="flex justify-between w-full m-auto sm:hidden">
                <p
                  className={`text-center saira w-[50%] font-semibold text-[12px] ${language !== "en" ? "px-0" : "px-[40px]"} `}
                >
                  {languageData?.newMenuBottom3UnderBtn1}
                </p>
                <p
                  className={`text-center saira w-[50%] text-[12px] font-semibold ${language !== "en" ? "px-0" : "px-[10px] iphone:px-[22px] sm:px-[42px]"}`}
                >
                  {languageData?.newMenuBottom3UnderBtn2}
                </p>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            in={activePageIndex === 1}
            timeout={50}
            classNames="time-counter-animation"
            unmountOnExit
          >
            <div className="time-counter-container ">
              <CSSTransition
                in={activePageIndex === 1 && competitionMode === "default"}
                timeout={50}
                classNames="time-counter-animation "
                unmountOnExit
              >
                <div className="scaleFrom125">
                  <div className="flex justify-between max-w-[200px] sm:max-w-[300px] m-auto mt-0 sm:mt-2 pl-3">
                    <p
                      className={`text-[16px] saira font-black ${checkBox === true && "opacity-[0.5]"}`}
                    >
                      {languageData?.newMenuToggle1}
                    </p>
                    <label className="relative mx-1 cursor-pointer items-center h-2 flex my-2">
                      <input
                        onClick={(e) => setCheckBox(!checkBox)}
                        type="checkbox"
                        checked={checkBox}
                        className="peer sr-only"
                      />
                      <div
                        className={`peer h-6 w-[40px] rounded-full border-[2px] after:absolute after:start-[4px] after:top-[50%] after:translate-y-[-50%] after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full ${checkBox ? "border-[#00FFFF] after:bg-[#00FFFF]" : "border-[#FF00E5] after:bg-[#FF00E5]"} after:h-4 after:w-4 after:rounded-full after:transition-all`}
                      ></div>
                    </label>
                    <p
                      className={`text-[16px] saira font-black ${checkBox === false && "opacity-[0.5]"}`}
                    >
                      {languageData?.newMenuToggle2}
                    </p>
                  </div>
                  {checkBox === true ? (
                    <div className="flex sm:ml-4 ">
                      <HoverBtn
                        onClick={(e) =>
                          competitionStore.getState().changeMode("spin")
                        }
                        text={languageData?.newMenuBottom2Btn1}
                        width={"!w-[96%]"}
                      />
                      <HoverBtn
                        onClick={(e) =>
                          competitionStore.getState().changeMode("double")
                        }
                        text={languageData?.newMenuBottom2Btn2}
                        width={"!w-[96%]"}
                        bgColor={"!bg-[#0FF]"}
                      />
                    </div>
                  ) : (
                    <div className="flex sm:ml-4 ">
                      <HoverBtn
                        text={languageData?.newMenuBottom3Btn1}
                        width={"!w-[96%]"}
                        onClick={(e) => window.open(PartnerURL)}
                      />
                      <HoverBtn
                        onClick={(e) => {
                          setActivePageIndex(4);
                        }}
                        text={
                          isLinkCopied
                            ? languageData?.newVegasBtn2Copy
                            : languageData?.newVegasBtn2
                        }
                        width={"!w-[96%]"}
                        bgColor={"!bg-[#0FF]"}
                      />
                    </div>
                  )}
                  {checkBox === true ? (
                    <div className="flex justify-between w-full m-auto ">
                      <p
                        className={`text-center saira w-[50%] font-semibold text-[12px] sm:text-[16px] ${language !== "en" ? "px-0" : "px-[42px]"} `}
                      >
                        {languageData?.newMenuBottom4UnderBtn1}
                      </p>
                      <p
                        className={`text-center saira w-[50%] text-[12px] sm:text-[16px] font-semibold ${language !== "en" ? "px-0" : "px-[22px] sm:px-[42px]"}`}
                      >
                        {languageData?.newMenuBottom4UnderBtn2}
                      </p>
                    </div>
                  ) : (
                    <div className="flex justify-between w-full m-auto ">
                      <p
                        className={`text-center saira w-[50%] font-semibold text-[12px] sm:text-[16px] ${language !== "en" ? "px-0" : "px-[42px]"} `}
                      >
                        {languageData?.newMenuBottom3UnderBtn1}
                      </p>
                      <p
                        className={`text-center saira w-[50%] text-[12px] sm:text-[16px] font-semibold ${language !== "en" ? "px-0" : "px-[22px] sm:px-[42px]"}`}
                      >
                        {languageData?.newMenuBottom3UnderBtn2}
                      </p>
                    </div>
                  )}
                </div>
              </CSSTransition>
              <CSSTransition
                in={activePageIndex === 1 && competitionMode === "double"}
                timeout={50}
                classNames="time-counter-animation"
                unmountOnExit
              >
                <div className="flex max-w-[570px] m-auto scaleFrom125Bot">
                  <HoverBtn
                    disabled={doubleStarted === true}
                    onClick={(e) => {
                      if (user?.points < 1) setGamePoint(true);
                      else competition.setDoubleWin();
                    }}
                    text={languageData?.newMenuBottom2Btn2}
                    bgColor={"!bg-[#0FF]"}
                  />
                </div>
              </CSSTransition>

              <CSSTransition
                in={activePageIndex === 1 && competitionMode === "spin"}
                timeout={50}
                classNames="time-counter-animation"
                unmountOnExit
              >
                <div className="flex max-w-[570px] m-auto scaleFrom125Bot">
                  <HoverBtn
                    disabled={wheelStarted === true}
                    onClick={(e) => {
                      if (user?.points < 1) setSpinNoTimes(true);
                      else competition.setWheelWin();
                    }}
                    text={languageData?.newMenuBottom2Btn1}
                  />
                </div>
              </CSSTransition>

              <TimeCounterDay languageData={languageData} />
            </div>
          </CSSTransition>
          {activePageIndex !== 0 && (
            <CSSTransition
              in={activePageIndex !== 1}
              timeout={200}
              classNames="time-counter-animation"
              unmountOnExit
            >
              <div className="time-counter-container">
                <TimeCounter languageData={languageData} />
              </div>
            </CSSTransition>
          )}
          {activePageIndex !== 0 && (
            <div className=" bg-[#EAEAEA] bg-opacity-20 backdrop-blur-lg pt-2 sm:pt-3 pb-1 px-4 sm:px-6 rounded-t-[15px] rounded-b-[35px] mt-2 max-w-[570px] w-full m-auto scaleFrom125">
              <div
                className={`md:max-w-[570px] w-full bg-[#EAEAEA] bg-opacity-10 backdrop-blur-lg rounded-[20px] flex justify-between items-center ${language !== "en" ? "px-5" : "px-3"}  iphone:px-6 sm:px-4 py-2 sm:py-1`}
              >
                <div
                  onClick={() => {
                    handleLinkClick("link1");
                    scrollToPage(0);
                  }}
                >
                  <LinkHome
                    color={activePageIndex === 0 ? "#00FFFF" : "white"}
                    className="w-[26px] h-[16px] sm:w-[45px] sm:h-[28px] cursor-pointer"
                  />
                </div>

                <div
                  onClick={() => {
                    handleLinkClick("link2");
                    scrollToPage(1);
                  }}
                >
                  <LinkDaily
                    color={activePageIndex === 1 ? "#00FFFF" : "white"}
                    className="w-[26px] h-[16px] sm:w-[45px] sm:h-[28px] cursor-pointer"
                  />
                </div>

                <div
                  onClick={() => {
                    handleLinkClick("link3");
                    scrollToPage(2);
                  }}
                >
                  <LinkVegas
                    color={activePageIndex === 2 ? "#00FFFF" : "white"}
                    className="w-[26px] h-[16px] sm:w-[45px] sm:h-[28px] cursor-pointer"
                  />
                </div>

                <div
                  onClick={() => {
                    handleLinkClick("link4");
                    scrollToPage(3);
                  }}
                >
                  <LinkRefer
                    color={activePageIndex === 3 ? "#00FFFF" : "white"}
                    className="w-[26px] h-[16px] sm:w-[45px] sm:h-[28px] cursor-pointer"
                  />
                </div>

                <img
                  className="w-[26px]  sm:w-[45px] sm:h-[28px] cursor-pointer"
                  src={activePageIndex === 4 ? link5Active : link5}
                  alt="link5"
                  onClick={() => {
                    setMenuOpen(true);
                  }}
                />
              </div>
              <div
                className={`max-w-[590px] w-full rounded-[20px] flex justify-between items-center ${language !== "en" ? "iphone:px-0" : "iphone:px-2"}  sm:px-0`}
              >
                <p
                  onClick={() => {
                    handleLinkClick("link1");
                    scrollToPage(0);
                  }}
                  className={cn(
                    `text-[10px] ${language !== "en" ? "sm:text-[14px] w-[20%] sm:w-[15%]" : " sm:text-[16px] w-[15%]"}  saira font-medium text-center w-[15%] select-none cursor-pointer`,
                    {
                      "text-[#00FFFF]": activePageIndex === 0,
                      "text-white": activePageIndex !== 0,
                    }
                  )}
                >
                  {languageData?.newMenuLink1}
                </p>
                <p
                  onClick={() => {
                    handleLinkClick("link2");
                    scrollToPage(1);
                  }}
                  className={cn(
                    `text-[10px] ${language !== "en" ? "sm:text-[14px] w-[20%] sm:w-[19%] tracking-[-1px] " : " sm:text-[16px] w-[15%]"} saira font-medium text-center  select-none cursor-pointer`,
                    {
                      "text-[#00FFFF]": activePageIndex === 1,
                      "text-white": activePageIndex !== 1,
                    }
                  )}
                >
                  {languageData?.newMenuLink2}
                </p>
                <p
                  onClick={() => {
                    handleLinkClick("link3");
                    scrollToPage(2);
                  }}
                  className={cn(
                    `text-[10px] ${language !== "en" ? "sm:text-[14px] w-[20%] sm:w-[15%] pr-3" : " sm:text-[16px] w-[15%]"}  saira font-medium text-center w-[15%] select-none cursor-pointer`,
                    {
                      "text-[#00FFFF]": activePageIndex === 2,
                      "text-white": activePageIndex !== 2,
                    }
                  )}
                >
                  {languageData?.newMenuLink3}
                </p>
                <p
                  onClick={() => {
                    handleLinkClick("link4");
                    scrollToPage(3);
                  }}
                  className={cn(
                    `text-[10px] ${language !== "en" ? "sm:text-[14px] w-[15%] sm:w-[15%] pr-3 sm:pr-3" : " sm:text-[16px] w-[15%]"}  saira font-medium text-center w-[15%] select-none cursor-pointer`,
                    {
                      "text-[#00FFFF]": activePageIndex === 3,
                      "text-white": activePageIndex !== 3,
                    }
                  )}
                >
                  {languageData?.newMenuLink4}
                </p>
                <p
                  onClick={() => {
                    setMenuOpen(true);
                  }}
                  className={cn(
                    `text-[10px] ${language !== "en" ? "sm:text-[14px] w-[20%] sm:w-[15%]" : " sm:text-[16px] w-[15%]"}   saira font-medium text-center w-[15%] select-none cursor-pointer`,
                    {
                      "text-[#00FFFF]": activePageIndex === 4,
                      "text-white": activePageIndex !== 4,
                    }
                  )}
                >
                  {languageData?.newMenuLink5}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
