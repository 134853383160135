import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthError from "./Auth/AuthError";
import { processUserRegistration } from "../Requests/auth";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
import { jwtDecode } from "jwt-decode";
import moengage from "@moengage/web-sdk";
import mixpanelTrack from "../Helpers/mixpanel";

function RegisterToken() {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [error, setError] = useState(false);
  let urlToken = window.location.href.split("/")[4];

  urlToken = urlToken.split("?")[0];

  let params = new URLSearchParams(window.location.search);

  let fbcMatch = document.cookie.match(/_fbc=([^;]+)/);
  let fbpMatch = document.cookie.match(/_fbp=([^;]+)/);
  
  let fbc = fbcMatch ? fbcMatch[1] : null;
  let fbp = fbpMatch ? fbpMatch[1] : null;

  useEffect(() => {
    async function checkToken() {
      if (!urlToken) return setError(true);
      navigate("/welcome");
      try {
        let decodedUrlToken = jwtDecode(urlToken);
        console.log("decodedUrlToken", decodedUrlToken);

        mixpanelTrack("click_email_verification", {
          distinct_id: decodedUrlToken.data.email,
          email: decodedUrlToken.data.email,
          _fbc: fbc,
          _fbp: fbp,
          fbclid: decodedUrlToken.data.fbclid,
        });

        let utms = {
          utm_source: localStorage.getItem("utm_source"),
          utm_medium: localStorage.getItem("utm_medium"),
          utm_campaign: localStorage.getItem("utm_campaign"),
          utm_term: localStorage.getItem("utm_term"),
          utm_content: localStorage.getItem("utm_content"),
        }

        const res = await processUserRegistration(urlToken, language, utms);
        const decoded = jwtDecode(res.data.token);
          await moengage.add_unique_user_id(decoded?.userId);
        if (res.status === 200) {
          window.localStorage.setItem("token", res.data.token);

          if (decodedUrlToken.fbclid) {
            let _fbc = `fb.1.${new Date().getTime()}.${decodedUrlToken.fbclid}`
            document.cookie = `_fbc=${_fbc}; path=/; max-age=31536000; SameSite=None; Secure`;
          }

          navigate("/landing" + decodedUrlToken.fbclid ? `?fbclid=${decodedUrlToken.fbclid}` : '');
        }
      } catch (error) {
        setError(true);
      }
    }
    checkToken();
  }, [urlToken]);

  return <AuthError error={error} />;
}

export default RegisterToken;
