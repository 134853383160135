import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Papum({ userData }) {

    const navigate = useNavigate()

    useEffect(() => {
        navigate('/qokee4dgwr')
    }, [])

    return (
        <div className='h-screen w-screen'>

        </div>
    )
}

export default Papum