import React, { useState } from "react";
import close from "../images/CyberSaysPage/closeMenu.png";
import { useDesign } from "../Helpers/Design/DesignContext";
import congrats from "../images/CyberSaysPage/congrats.png";

function DoubleComplete({ setOpen, languageData, user, PartnerURL, userCountry, scrollToPage }) {
  const { design } = useDesign();
  
  const [linkCopied, setLinkCopied] = useState(false);


  return (
    <div className="fixed top-0 z-[99999] flex h-screen w-screen bg-[#1E1E1E] bg-opacity-60 p-4 backdrop-blur-md">
      <div className="relative m-auto w-full max-w-[600px] ">
        <div className=" right-0 mb-[20px] flex justify-end md:my-4  lg:mb-10">
          <img onClick={(e) => setOpen(false)} className="h-[24px] w-[24px] cursor-pointer" src={design === "0" ? close : require("../images/NewDesign/closeBtn.png")} alt="close" />
        </div>
        <div className="  m-auto max-w-[600px] ">
          <p className="mx-2 text-center text-[24px] font-semibold leading-8 md:text-[32px] lg:leading-9 text-[#00FFFF]">
            {languageData?.doubleCompleteTitle1}
            {userCountry === "BR" || userCountry === "UA" ? "R$25" : "$5"}
            {userCountry === "BR" || userCountry === "UA" ? languageData?.doubleCompleteTitle2.replace("dollars", "") : languageData?.doubleCompleteTitle2}
            {/* <span className=' truncate text-[24px] md:text-[32px] font-semibold ml-1 block lg:inline'>
                        {user?.email}
                    </span> {languageData?.doubleCompleteTitleSpan}*/}
          </p>
          <img className="m-auto my-4 mt-2 w-full max-w-[345px] md:max-w-[400px]" src={congrats} alt="congrats" />
          <p className="saira  text-center text-[20px] font-medium leading-6 lg:leading-[unset]">{languageData?.doubleCompleteSubTitle}</p>
          <div className="mx-2 flex justify-center">
            <ul
              className={` max-w-[600px] w-full btnNewHover mt-4 `}>
              <li onClick={(e) => {
                scrollToPage(3);
                setOpen(false);
              }}
                className={`h-[45px]`}>
                
                  <span></span>
                  <span className={`fab fa-css3-alt text-[18px] font-semibold saira`}>
                    {linkCopied === true ? languageData?.CopyLinkDone : languageData?.doubleCompleteBtn}
                    <svg className="ml-2" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 12.5L14 5.5V9.5C7 10.5 4 15.5 3 20.5C5.5 17 9 15.4 14 15.4V19.5L21 12.5Z" fill="#1E1E1E" />
                    </svg>
                  </span>

                
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
}

export default DoubleComplete;
