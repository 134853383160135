import React, { useEffect, useState } from "react";
import cn from "classnames";
import { competitionStore } from "../../stores/competitionStore";
import gifs from "../../Helpers/gifs.json";
import { useQueryClient } from "@tanstack/react-query";
import double from "../../images/NewDesign2/Games/double.png";

function Double({ languageData, user }) {
  const queryCLient = useQueryClient();
  const isStarted = competitionStore((state) => state.doubleStarted);
  const isWin = competitionStore((state) => state.doubleWin);

  const [winMessage, setWinMessage] = useState(false);
  const [loseMessage, setLoseMessage] = useState(false);
  const [userResult, setUserResult] = useState(null);

  useEffect(() => {
    console.log(queryCLient);
  }, [queryCLient]);

  useEffect(() => {
    if (isStarted === true) {
      setLoseMessage(false);
      setWinMessage(false);
      setUserResult(isWin);
      setTimeout(() => {
        if (isWin === true) {
          setTimeout(() => {
            setWinMessage(true);
          }, 500);
        } else {
          setLoseMessage(true);
        }
        const inv = queryCLient.invalidateQueries("userData");
        inv.then((res) => {
          console.log(res);
        });
        competitionStore.getState().clearDouble();
      }, 4500);
    }
  }, [isStarted, isWin]);

  const renderGif = (index) => {
    switch (index) {
      case "win":
        return (
          <img
            className="w-[150px] sm:w-[200px] 2xl:w-[270px] m-auto rotate-[10deg]"
            src={gifs.double_win}
            alt="gif"
          />
        );
      case "lose":
        return (
          <img
            className="w-[150px] sm:w-[200px] 2xl:w-[270px] m-auto rotate-[10deg]"
            src={gifs.double_lose}
            alt="gif"
          />
        );
    }
  };
  return (
    <div className="relative">
      <svg
        className="cursor-pointer absolute top-[45px] sm:top-[25px] 2xl:top-[60px]"
        onClick={(e) => competitionStore.getState().changeMode("default")}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clip-path="url(#clip0_325_26259)">
          <path
            d="M11.3333 14.6667L4.66666 8L11.3333 1.33334"
            stroke="white"
            stroke-width="1.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_325_26259">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(16) rotate(90)"
            />
          </clipPath>
        </defs>
      </svg>
      <div className="m-auto max-w-[1000px] flex items-center sm:h-[unset] sm:block">
        <div className="flex items-center justify-center">
          <div>
            <p className="text-[16px] sm:text-[28px] 2xl:text-[32px] font-bold saira text-center sm:mt-0 2xl:mt-0 ">
              {languageData?.newDoubleTitle}{" "}
              <span className="text-[16px] sm:text-[28px] 2xl:text-[32px] font-bold saira text-[#00FFFF]">
                {user?.points}
              </span>{" "}
              {languageData?.newDoubleTitleSpan}{" "}
            </p>
            <p className="saira text-[14px] sm:text-[18px] font-normal text-center px-6 sm:px-0 mb-1 mt-1">
              {languageData?.newDoubleSubTitle2}
            </p>
            <div className={cn(` sm:h-[unset]`, {})}>
              {isStarted === false && userResult === null ? (
                <img
                  className="w-[240px] sm:w-[200px] 3xl:w-[240px] m-auto rotate-[10deg]"
                  src={double}
                  alt="gif"
                />
              ) : (
                renderGif(userResult ? "win" : "lose")
              )}
            </div>

            <p className="text-[14px] sm:text-[28px] 2xl:text-[32px] font-bold saira text-center mb-[50px] mt-5 sm:mb-4 sm:mt-0 2xl:mb-4 2xl:mt-0 ">
              {winMessage === true
                ? `${languageData?.newDoubleSubtitle} ${user.points / 2}${" "}
              ${languageData?.newDoubleSubtitleSpan}`
                : null}
              {loseMessage && languageData?.newDoubleLose}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Double;
