import React, { useEffect } from 'react'
import { useDesign } from '../Helpers/Design/DesignContext'
import what1 from '../images/NewDesign/toolnfo/what1.png'
import what2 from '../images/NewDesign/toolnfo/what2.png'
import what3 from '../images/NewDesign/toolnfo/what3.png'
import what4 from '../images/NewDesign/toolnfo/what4.png'
import moengage from '@moengage/web-sdk'
import mixpanelTrack from '../Helpers/mixpanel'

function ToolTipInfo({ languageData, setToolInfo, user }) {
    const { design } = useDesign()

    useEffect(() => {
        mixpanelTrack('info_icon', {
            distinct_id: user?.id,
            is_referred: user?.referral_id ? 'Yes' : 'No',

            vegas_tickets: user?.raffle_tickets,
            points: user?.points,
            user_id: user?.id,
            USD_earned: user?.allTimeEarned,

            number_referrals: user?.referral_id ? user?.referral_id.length : 0,
        })
        moengage.track_event('info_icon', {
            distinct_id: user?.id,
            is_referred: user?.referral_id ? 'Yes' : 'No',
            vegas_tickets: user?.raffle_tickets,
            points: user?.points,
            user_id: user?.id,
            USD_earned: user?.allTimeEarned,
            number_referrals: user?.referral_id ? user?.referral_id.length : 0,
        })

    }, [])



    return (
        <div className='w-screen sm:w-[97.5%] sm:max-w-[375px] absolute sm:right-[37px]  sm:top-[70px] z-[999999999999] '>
            <div onClick={e => setToolInfo(false)} className=' cursor-pointer bg-[#000000] bg-opacity-60 sm:bg-opacity-30 backdrop-blur-sm sm:mt-[20px] sm:rounded-[12px] lg:rounded-[10x] p-2 h-screen sm:h-[unset]  sm:border-2 border-[#8B007D] pt-8 sm:pt-0'>
                <div className='flex justify-end '>

                    <svg onClick={(e) => setToolInfo(false)} className="h-[24px] w-[24px] cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20 20L4 4M20 4L4 20" stroke="white" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </div>
                <p className='text-[16px] md:text-[18px] font-semibold text-center text-[#00FFFF] max-w-[280px] lg:max-w-[380px] m-auto saira'>{languageData?.toolInfoTitle}</p>
                <div>
                    <div className='flex justify-around mt-2'>
                        <p className='text-[14px] text-center font-semibold saira'>{languageData?.toolInfoWhat}</p>
                        <p className='text-[14px] text-center font-semibold saira'>{languageData?.toolInfoHow}</p>
                    </div>
                    <div className='flex justify-around mt-2'>
                        <div className='bg-[#FFED63] w-full p-1 rounded-l-[24px] flex items-center'>
                            <img className='w-[40px]' src={what1} alt="what1" />
                            <p className='w-[115px] lg:w-[unset] saira text-[14px] text-[#1E1E1E] font-semibold leading-[16px] ml-1'>{languageData?.toolInfolef1}</p>
                        </div>
                        <div className='bg-white w-full p-1 flex items-center justify-center'>

                            <p className=' saira text-[12px] text-[#1E1E1E] font-semibold leading-[16px] ml-1 text-center'>{languageData?.toolInfoRight1}</p>
                        </div>
                    </div>
                    <div className='flex justify-around mt-2'>
                        <div className='bg-[#FF00E5] w-full p-1 rounded-l-[24px] flex items-center'>
                            <img className='w-[40px]' src={what2} alt="what1" />
                            <p className='w-[115px] lg:w-[unset] saira text-[14px] text-[#1E1E1E] font-semibold leading-[16px] ml-1'>{languageData?.toolInfolef2}</p>
                        </div>
                        <div className='bg-white w-full p-1 flex items-center justify-center'>

                            <p className=' saira text-[11px] text-[#1E1E1E] font-semibold leading-[14px] ml-1 text-center'>{languageData?.toolInfoRight2}</p>
                        </div>
                    </div>
                    <div className='flex justify-around mt-2'>
                        <div className='bg-[#00FFFF] w-full p-1 rounded-l-[24px] flex items-center'>
                            <img className='w-[40px]' src={what3} alt="what1" />
                            <p className='w-[115px] lg:w-[unset] saira text-[14px] text-[#1E1E1E] font-semibold leading-[16px] ml-1'>{languageData?.toolInfolef3}</p>
                        </div>
                        <div className='bg-white w-full p-1 flex items-center justify-center'>

                            <p className=' saira text-[11px] text-[#1E1E1E] font-semibold leading-[14px] ml-1 text-center'>{languageData?.toolInfoRight3}</p>
                        </div>
                    </div>
                    <div className='flex justify-around mt-2'>
                        <div className='bg-[#C7FF4D] w-full p-1 rounded-l-[34px] lg:rounded-l-[34px] flex items-center'>
                            <img className='w-[40px]' src={what4} alt="what4" />
                            <p className='w-[165px] lg:w-[unset] saira text-[14px] text-[#1E1E1E] font-semibold leading-[16px] ml-1'>{languageData?.toolInfolef4}</p>
                        </div>
                        <div className='bg-white w-full p-1 flex items-center justify-center'>
                            <p className=' saira text-[9px] text-[#1E1E1E] font-semibold leading-[12px] ml-1 text-center'>{languageData?.toolInfoRight4}</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ToolTipInfo