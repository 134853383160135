import React, { useEffect } from "react";
import bgDesk from "../../images/NewDesign2/MenuBottom/bg-desk2.svg";
import bgMob from "../../images/NewDesign2/MenuBottom/bg-mob2.svg";

function BottomMenu({
  languageData,
  setLandingLogin,
  setLoading,
  handleMixpanelEvent,
  refferalCode,
  special,
  setEmailEnabled,
  emailEnabled,
  mode,
  setMode,
  setEmailModal,
  scrollToDown,
}) {
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params.get("email") === null) {
      setMode("popup");
    } else if (params.get("email") === "1") {
      setMode("email");
      setEmailEnabled(true);
    } else {
      setMode("email");
    }
  }, [params]);

  return (
    <div className="w-full absolute bottom-0 z-30">
      <img
        className="w-full object-cover sm:block hidden pointer-events-none select-none mb-[-2px]"
        src={bgDesk}
        alt="bgDesk"
      />
      <img
        className="w-full object-cover block sm:hidden pointer-events-none select-none scale-[1.02]"
        src={bgMob}
        alt="bgDesk"
      />
      <div className="h-[30px] w-full absolute bottom-0 bg-[#15172A]"></div>
      <div
        className="bg-bottomMenu bg-no-repeat bg-cover w-full justify-center flex rounded-b-[22px] sm:rounded-none relative z-10 "
        style={{ transition: "all 0.2s ease-in-out" }}
      >
        <div>
          <div className="mt-8  max-w-[300px] sm:max-w-[500px] m-auto w-full">
            <ul
              onClick={(e) => {
                mode === "popup" ? setLandingLogin(true) : setEmailModal(true);
              }}
              className={`w-[300px]  btnNewHover mt-3 sm:mt-4 cursor-pointer m-auto`}
            >
              <li className={`!h-[60px]`}>
                <span className='!bg-[#A30023]'></span>
                <span
                  className={`fab fa-css3-alt text-[18px]  sm:text-[20px] font-semibold saira`}
                >
                  {languageData?.LPmenuJoinNow}
                </span>
              </li>
            </ul>
          </div>
          <svg
            onClick={scrollToDown}
            className="m-auto animate-bounce sm:mt-1 h-[50px] sm:h-[40px] cursor-pointer"
            width="18"
            viewBox="0 0 18 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33594 10.4219L0.71875 2.84375C0.367188 2.45312 0.367188 1.86719 0.71875 1.51562L1.61719 0.617188C1.96875 0.265625 2.55469 0.265625 2.94531 0.617188L9 6.63281L15.0156 0.617188C15.4062 0.265625 15.9922 0.265625 16.3438 0.617188L17.2422 1.51562C17.5938 1.86719 17.5938 2.45312 17.2422 2.84375L9.625 10.4219C9.27344 10.7734 8.6875 10.7734 8.33594 10.4219Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default BottomMenu;
