import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";
import { getDomain } from "../../Helpers/domainHelper";
import { getLogotype } from "../../Helpers/domainHelper";
import cn from "classnames";


function Footer({ languageData, setMissionOpen }) {
  const { language } = useLanguage();
  return (
    <div className="py-6 sm:py-14 mb-28 sm:mb-0 bg-[#1E1E1E] px-4 sm:px-6 ">
      <div className="max-w-[1440px] w-full m-auto">
      <div
        className={` flex flex-wrap justify-between sm:justify-around items-start mr-12 sm:mr-0 sm:max-w-[1020px]  sm:w-full `}
      >

        <img
          className={cn(
            "w-[55px] sm:w-[130px] 3xl:w-[160px]  pt-2 2xl:pt-4 duration-200",
            {
              "opacity-0": !getLogotype(),
            }
          )}
          src={getLogotype()}
          alt="logo"
        />
        <div className="">
          <p className="saira text-[14px] sm:text-[24px] font-semibold">{languageData?.LPFooterRow1}</p>
          <p className="saira text-[12px] sm:text-[18px] font-normal cursor-pointer pt-2" onClick={e => setMissionOpen(true)}>{languageData?.LPFooterRow2}</p>
          <Link to="https://www.cyber-says.club/terms" target="_blank">
            <p className="saira text-[12px] sm:text-[18px] font-normal pt-2">{languageData?.LPfooterLink2}</p>
          </Link>
        </div>
        <div className="">
          <p className="saira text-[14px] sm:text-[24px] font-semibold">{languageData?.LPFooterRow3}</p>
          <Link
            to="https://www.cyber-says.club/affiliation-terms"
            target="_blank"
          >
            <p className="saira text-[12px] sm:text-[18px] font-normal pt-2">{languageData?.LPfooterLink1}</p>
          </Link>
          <Link to="https://www.cyber-says.club/privacy" target="_blank">
            <p className="saira text-[12px] sm:text-[18px] font-normal pt-2">{languageData?.LPfooterLink3}</p>
          </Link>
          <Link to="https://www.cyber-says.club/policy" target="_blank">
            <p className="saira text-[12px] sm:text-[18px] font-normal pt-2">{languageData?.LPfooterLink4}</p>
          </Link>
        </div>
        
      </div>
      </div>
    </div>
  );
}

export default Footer;
