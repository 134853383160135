import React from "react";
import LandingLoginBtn from "./LandingLoginBtn";
import login2 from "../../images/LandingPage/login2.svg";
import { Link } from "react-router-dom";
import DiscordButton from "../Buttons/DiscordButton";
import GoogleButton from "../Buttons/GoogleButton";
import { socialUserAuth } from "../../Requests/auth";
import { getUserCountry } from "../../Requests/utills";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import checkUser from "../../Helpers/cloaker";
import logo from "../../Pages/WelcomeRed/Image/SignIn.png";

function LandingLogin({
  setOpen,
  setLoading,
  handleMixpanelEvent,
  refferalCode,
  special,
  languageData,
  setEmailModal,
}) {
  const recaptchaRef = useRef();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const loginViaGoogle = async (email) => {
    setLoading(true);

    const token = await recaptchaRef.current.executeAsync();
    socialAuth(email, token);

  };

  async function socialAuth(email, token) {

    const result = await checkUser();
    if (result !== true) return

    try {
      let userCountry = await getUserCountry();

      let utms = {
        utm_source: localStorage.getItem("utm_source"),
        utm_medium: localStorage.getItem("utm_medium"),
        utm_campaign: localStorage.getItem("utm_campaign"),
        utm_term: localStorage.getItem("utm_term"),
        utm_content: localStorage.getItem("utm_content")
      }


      const res = await socialUserAuth(
        email,
        token,
        refferalCode,
        special,
        userCountry.data.Data.country,
        language,
        utms
      );

      let decoded = jwtDecode(res.token);

      await handleMixpanelEvent(true, "social", decoded.id);

      localStorage.setItem("token", res.token);
      navigate("/");

    } catch (error) {
      await handleMixpanelEvent(false, "social");
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div className="bg-[#131313] bg-opacity-60 m-auto fixed w-screen h-screen flex justify-center items-center top-0 px-2 z-50">
      <div className="bg-[#212121] border-2 bg-opacity-95 max-w-[400px] p-3 rounded-[12px] border-[#FF00E5]">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
          >
            <div className="flex justify-end relative z-10">
              <svg
                onClick={(e) => setOpen(false)}
                className="h-[24px] w-[24px] cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 20L4 4M20 4L4 20"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            
            <p className="text-center text-[30px] poppins font-semibold text-[#00FFFF]">
              {languageData?.newRegisterJoinWith}
            </p>
            <DiscordButton languageData={languageData} />
            <div
              onClick={(e) => {
                setEmailModal(true);
                setOpen(false);
              }}
            >
              <LandingLoginBtn
                icon={login2}
                text={languageData?.newRegisterContinueBtn}
                span={languageData?.newRegisterContinueBtn2}
              />
            </div>
            <GoogleButton
              loginViaGoogle={loginViaGoogle}
              languageData={languageData}
            />

            <p className="text-center poppins text-[10px] font-extralight opacity-[0.6] my-5">
              {languageData?.newRegisterUnderTerms}{" "}
              <Link to="https://www.cyber-says.club/terms" target="_blank">
                <span className="poppins text-[10px] font-extralight underline cursor-pointer">
                  {languageData?.newRegisterUnderLink1}
                </span>
              </Link>
              ,{" "}
              <Link to="https://www.cyber-says.club/privacy" target="_blank">
                <span className="poppins text-[10px] font-extralight underline cursor-pointer">
                  {languageData?.newRegisterUnderLink2}
                </span>
              </Link>
              ,{" "}
              <Link to="https://www.cyber-says.club/policy" target="_blank">
                <span className="poppins text-[10px] font-extralight underline cursor-pointer">
                  {languageData?.newRegisterUnderLink3}
                </span>
              </Link>
            </p>
          </ReCAPTCHA>
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default LandingLogin;
