import React from "react";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";

import logoIM from "../../images/NewDesign/Homepage/logoIm2.png";
import { getLogotype } from "../../Helpers/domainHelper";
import cn from "classnames";
import link5 from "../../images/NewDesign2/MenuBottom/link5.svg";
import Slider from "../../Components/SliderHomepage/Slider.jsx";
import MainMenuForHomepage from "../../Components/MainMenuForHomepage.jsx";
import HomepagerUnder from "./HomepageUnder.jsx";
import ReferBtn from "../../Components/HomePage/ReferBtn.jsx";

function Homepage({
  user,
  languageData,
  scrollToPage,
  setMenuOpen,
  setTourModal,
  setWinTicketModal,
  setWinModal,
  setVisible,
  activePageIndex,
  setActivePageIndex,
  userCountry,
  PartnerURL,
  setGamePoint,
  setSpinNoTimes,
  setWithdrawComplete,
  setWitdrawError,
  setWithdrawModal,
}) {
  const { language } = useLanguage();

  return (
    <div className=" overflow-y-scroll overflow-x-hidden  h-full">
      <div
        className={` h-[100vh] w-screen bg-[#0A0A0A] sm:bg-landMainDesk2 relative z-10 bg-cover bg-center bg-no-repeat`}
      >
        <div className="m-auto  max-w-[1000px] px-2 pt-2 sm:pt-[20px] 3xl:pt-[40px] relative z-20">
          <img
            onClick={(e) => {
              scrollToPage(0);
            }}
            className={cn(
              "w-[150px] sm:w-[200px] cursor-pointer duration-200 m-auto",
              {
                "opacity-0": !getLogotype(),
              }
            )}
            src={getLogotype()}
            alt="logo"
          />
          <img
            className="w-[42px] sm:w-[55px]  cursor-pointer absolute top-3 sm:top-6 left-0 "
            src={link5}
            alt="menu"
            onClick={() => {
              setMenuOpen(true);
            }}
          />
          <div className="flex items-center sm:h-[52vh]">
            <div className="m-auto">
              <div className="lg:flex">
                <div className="3xl:mb-2 sm:mt-[10px] 3xl:mt-0">

                  <p className="saira font-extrabold text-[20px] sm:text-[28px] my-3 sm:my-1 3xl:mt-[20px] text-center leading-6 pr-4 ">
                    {languageData?.NewLPmenuTitleOther}{" "}
                    <span className="saira font-extrabold text-[22px] sm:text-[28px] text-[#A30023] ">
                      {languageData?.NewLPmenuTitle}
                    </span>{" "}
                    {languageData?.NewLPmenuTitleSpan}{" "}
                  </p>


                  <Slider languageData={languageData} />
                  {/*
                  <p className="saira text-[20px] sm:text-[28px] font-bold mt-3 justify-center items-center text-center pr-4">
                    {languageData?.NewLPmenuTitle2}{" "}
                    <span className="text-[#A30023] saira font-extrabold">{languageData?.NewLPmenuTitle2Span}</span>
                  </p>
                  */}
                  <p className="saira text-[20px] sm:text-[28px] font-bold mt-1 sm:mt-[-50px] 2xl:mt-1 justify-center items-center text-center pr-4">
                    {languageData?.homepageLiveOverTitle}{" "}

                  </p>
                  <p className="saira text-[16px] sm:text-[20px] font-bold text-center">
                    {languageData?.homepageLiveUnderSlideChoose}
                  </p>

                  <div className="m-auto flex justify-center items-center mt-2 sm:mt-2 3xl:mt-12">
                    <div className=" max-w-[150px] sm:max-w-[280px] w-full px-2 py-1 h-[50px] rounded-[16px]  flex items-center justify-center sm:mr-[-30px]">
                      <p className="text-center saira text-[12px] md:text-[18px] font-bold leading-5 sm:leading-[unset] uppercase px-4 welcomeTextLED2 max-w-[170px]">
                        {languageData?.LPUnderOrLeft}<br />
                        {languageData?.LPUnderOrLeft2}
                      </p>
                    </div>
                    <hr className="bg-[#AD3A92] w-[30px] h-[1px] border-none" />
                    <div className="w-[50px] h-[50px] rounded-full flex justify-center items-center saira text-[12px] md:text-[18px] uppercase font-bold relative z-10 mx-2 sm:mx-4">
                      {languageData?.homepageLiveUnderOr}
                    </div>
                    <hr className="bg-[#AD3A92] w-[30px] h-[1px] border-none" />
                    <div className=" max-w-[150px] sm:max-w-[280px] w-full px-3 py-1 h-[50px] rounded-[16px] flex items-center justify-center sm:ml-[-30px]">
                      <p className="text-center saira text-[12px] md:text-[18px] font-bold leading-5 sm:leading-[unset] uppercase px-4">
                        {languageData?.LPUnderOrRight}<br />
                        {languageData?.LPUnderOrRight2}
                      </p>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ zIndex: 0 }}>
          <MainMenuForHomepage
            setTourModal={setTourModal}
            setWinTicketModal={setWinTicketModal}
            setWinModal={setWinModal}
            setVisible={setVisible}
            languageData={languageData}
            activePageIndex={activePageIndex}
            setActivePageIndex={setActivePageIndex}
            user={user}
            scrollToPage={scrollToPage}
            setMenuOpen={setMenuOpen}
            userCountry={userCountry}
            PartnerURL={PartnerURL}
            setGamePoint={setGamePoint}
            setSpinNoTimes={setSpinNoTimes}
            setWithdrawComplete={setWithdrawComplete}
            setWitdrawError={setWitdrawError}
            setWithdrawModal={setWithdrawModal}
          />
        </div>
      </div>
      <HomepagerUnder
        scrollToPage={scrollToPage}
        user={user}
        languageData={languageData}
        setMenuOpen={setMenuOpen}
        setWithdrawComplete={setWithdrawComplete}
        setWitdrawError={setWitdrawError}
        setWithdrawModal={setWithdrawModal}
        PartnerURL={PartnerURL}
      />
    </div>
  );
}

export default Homepage;
