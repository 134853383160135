const { getDomain, getAmount } = require("../../domainHelper");
module.exports = {
  siteTitle: "" + getDomain() + "",
  metaDescription:
    "Aproveite e ganhe na TreatCoin através da " + getDomain() + "!",
  HeaderTitle:
    "" +
    getDomain() +
    "... <span class='text-gradient'>Dobre seu dinheiro!</span>",
  HeaderSubTitle: "Compre, aproveite, e X2 seu dinheiro!",
  HeaderButton: "Seja pago para experimentar o ImLive",
  LatestOffers: "Últimas Ofertas",
  OfferTitle: "Nosso parceiro - Experiências de Webcam para Adultos",
  OfferDescription:
    "Registre-se e deposite R$25 no nosso parceiro! Nosso parceiro dobrará seus créditos, lhe dando R$50 ao invés de R$25. Após usar seus créditos do nosso parceiro, " +
    getDomain() +
    " dobrará seu dinheiro, te devolvendo R$50 na TreatCoin!",
  listTexts: [
    "Registre-se no site do nosso parceiro",
    "Compre 5 créditos e ganhe outros 5 de graça",
    "Aproveite usando todos os 10 créditos no nosso parceiro",
    "Ganhe $10 da " + getDomain() + " - dobrando os $5 que gastou!",
  ],
  OfferButton: "Ganhe X2 Agora!",
  CopyLink: "Copiar Link",
  TreatCoins: "Treat Coins",
  LinkCopied: "Link Copiado!",
  tasks: {
    task1: {
      taskTitle: "Registre-se no site do nosso parceiro 🎉",
      taskTitleOpen: "Registre-se no site do nosso parceiro 🎉",
      taskBlocks: [
        {
          taskImage: "images/bg-mobile-Task1.png",
          newTaskImage: "images/newTask/bg_task1.png",
          taskImageDesk: "images/BgTaskDesk1.png",
          newTaskImageDesk: "images/BgNewTaskDesk1.png",
          taskText:
            "Curta o parceiro, seja tratado como um REI 👑 e realize suas fantasias!",
        },
      ],
      taskButton: "1/3 Comece agora 🎉",
    },
    task2: {
      taskTitle: "Depositar",
      taskTitleOpen:
        "Boas-vindas reais do nosso parceiro: deposite R$25 e sinta o prazer",
      taskBlocks: [
        {
          taskImage: "images/bg-mobile-Task2.png",
          newTaskImage: "images/newTask/bg_task2.png",
          taskImageDesk: "images/BgTaskDesk2.png",
          newTaskImageDesk: "images/BgNewTaskDesk2.png",
          taskText:
            "Deposite R$25 no nosso parceiro, e a " +
            getDomain() +
            " dobrará o dinheiro!",
        },
      ],
      taskButton: "2/3: Deposite R$25 e ganhe 5 créditos 💸",
    },
    task3: {
      taskTitle: "Gastar",
      taskTitleOpen:
        "Use todos os créditos para obter R$50 de CashBack da " +
        getDomain() +
        "",
      taskBlocks: [
        {
          taskImage: "images/bg-mobile-Task3.png",
          newTaskImage: "images/newTask/bg_task3.png",
          taskImageDesk: "images/BgTaskDesk3.png",
          newTaskImageDesk: "images/BgNewTaskDesk3.png",
          taskText:
            "Gaste seus 5 créditos no parceiro, seja tratado como um rei e ganhe R$50 na " +
            getDomain() +
            ".",
        },
      ],
      taskButton: "3/3: Gaste todos os créditos e receba R$50 de volta!",
    },
    task4: {
      taskTitle: "Sacar seu dinheiro 🤑",
      taskTitleOpen: "Saque seu dinheiro 🤑",
      taskBlocks: [
        {
          taskImage: "images/bg-mobile-Task4.png",
          newTaskImage: "images/newTask/bg_task4.png",
          taskImageDesk: "images/BgTaskDesk1.png",
          newTaskImageDesk: "images/BgTaskDesk1.png",
          taskText:
            "o nosso parceiro dobra seu crédito, nós dobramos seu dinheiro - é uma situação vantajosa para você, com mais R$50 na sua carteira!",
        },
      ],
      taskButton: "Receber R$50 da " + getDomain() + "",
    },
  },
  homepageTitle1:
    "1. Gaste R$25 no tratamento real do nosso parceiro e receba R$50 de volta da " +
    getDomain() +
    "",
  homepageTitle2:
    "2. Adquira o maior número possível de bilhetes para ter a melhor chance de ganhar a promoção de Las Vegas!",
  homepageTitle3:
    "3. Termine entre os 10 primeiros e ganhe a competição diária de 6.9$!",
  homepageTitle4:
    "Dobre seu dinheiro e tenha a chance de ganhar o fim de semana em Las Vegas!",
  homepageTitle5:
    "Receba o tratamento de rei no nosso parceiro e ganhe o Fim de Semana em Las Vegas",
  homepageBtn1: "Dobre seu dinheiro ",
  homepageBtn2: "2/3: Depósito para 200% de Cashback 🤑",
  homepageBtn3: "3/3: Gaste para 200% de Cashback 🤑",
  homepageBtn4: "Ganhe o fim de semana em Las Vegas 💃",
  timeCounterTitle: "Countdown para a Promoção de Vegas:",
  timeCounterTitle2: "Countdown para o fim do dia:",
  timeCounterDays: "Dias",
  timeCounterHours: "Horas",
  timeCounterMinutes: "Minutos",
  timeCounterSeconds: "Segundos",
  raffleInfoTitle1:
    "A elegibilidade para Vegas indica que você, seus amigos indicados ou o amigo que o indicou estão qualificados para participar no sorteio de Vegas por um dos dois métodos:",
  raffleInfoTitle2: "Seleção para uma Viagem a Vegas:",
  raffleInfoTitle2Span:
    "Se seu amigo ou seu indicante ganhou, você pode ser escolhido para ir a Vegas.",
  raffleInfoTitle3: "Participação no Sorteio:",
  raffleInfoTitle3Span:
    "Alternativamente, você pode participar do sorteio possuindo bilhetes de sorteio.",
  raffleInfoTitle4: "Para informações adicionais, consulte os",
  raffleInfoTitle4Span: "detalhes da competição.",
  raffleTitle1:
    "Adquira o máximo de bilhetes para Las Vegas e aumente suas chances de ganhar!",
  raffleSubTitle1:
    "Se você aceitou a oferta de dobrar seu dinheiro do nosso parceiro, você pode ser escolhido pelo seu indicado ou indicador para ir a Vegas se eles ganharem.",
  raffleLink1: "Como ganho o fim de semana em Las Vegas?",
  raffleTitle2: "Ganhe o fim de semana em Vegas!",
  raffleSubTitle2: "Elegível para ir a Las Vegas",
  raffleLink2: "Como ganho o sorteio do fim de semana em Vegas?",
  raffleUnderBtn:
    "Colete o máximo de bilhetes de Las Vegas que puder e aumente sua chance de ganhar:",
  raffleUnderBtn2:
    "Ganhe mais bilhetes de Las Vegas para aumentar suas chances de ganhar o sorteio:",
  raffleLeftBtn: "Desfrute de Experiências Ao Vivo 💃",
  raffleLeftSection1: "1 crédito comprado =",
  raffleLeftSection2: "1 bilhete para Las Vegas",
  raffleRightBtn: "Indique amigos 🤑",
  raffleRightBtn2: "Link copiado!",
  raffleRightSection1: "Amigo que dobrou o dinheiro =",
  raffleRightSection2: "30 bilhetes de sorteio + R$5",
  raffleRulesDesc:
    "*Não é necessário fazer uma compra para participar do sorteio.",
  raffleRulesLink: "Regras do sorteio",
  doubleTitle: "Dobre Seu Dinheiro - Se Divirta!",
  doubleSubtitleSpan: "Um",
  doubleSubtitle: "aparecerá após a conclusão da tarefa",
  doublePaid: "Saque",
  refferalsTitle:
    "Ganhe R$5 + 30 ingressos para Vegas e 10 pontos de competição para cada amigo indicado que completar a oferta!",
  refferalsLink1: "Leia mais sobre seus benefícios de indicação",
  refferalsSubtitle: "Selecione um texto e uma imagem e compartilhe seu link",
  refferalsImage: "Escolha uma imagem",
  refferalsLink2: "Escolha uma experiência personalizada",
  refferalsSome: "Adicione algumas fotos",
  refferalsInfoTitle: "Seja o rei",
  refferalsInfo1:
    "Você será a pessoa que permite aos seus amigos cobrando seu dinheiro e se divertirem 👬",
  refferalsInfo2:
    "Para cada amigo que dobrar o dinheiro, você receberá 30 bilhetes de sorteio e 1$ 🃏",
  refferalsInfo3:
    "Se seus amigos dobrarem o dinheiro, você pode levá-los para Las Vegas se ganhar o sorteio, ou eles podem te levar se ganharem, e se você tiver concluído a oferta de dobrar o dinheiro",
  competitionTitleSpan: "competição diária",
  competitionPoints: "Pontos",
  competitionSubtitle:
    "Os 2 primeiros lugares recebem US$ 6.9 cada, 3-10 US$ 6,90 cada.",
  competitionLeftBtn: "Desfrute de Experiências Ao Vivo 💃",
  competitionLeftSection1: "1 crédito comprado =",
  competitionLeftSection2: "0,5 pontos de competição",
  competitionRightBtn: "Indique amigos 🤑",
  competitionRightBtn2: "Link copiado!",
  competitionRightSection1: "Amigo que dobrou o dinheiro =",
  competitionRightSection2: "10 pontos de competição + R$5",
  competitionRightLink1: "Classificações",
  competitionRightRules: "Regras da competição",
  header1: "Ganho:",
  header2: "Pontos de competição:",
  header3: "Bilhetes para Vegas:",
  modalMenuLink1: "Página inicial",
  modalMenuLink2: "Dobre Seu Dinheiro",
  modalMenuLink3: "O fim de semana em Las Vegas",
  modalMenuLink4: "Indique para ganhar bilhetes",
  modalMenuLink5: "Minhas Indicações Elegíveis para Vegas",
  modalMenuLink6: "A",
  modalMenuLink6Span: "Competição diária de US$6.9",
  modalMenuLink7: "Sacar seu dinheiro",
  modalMenuLink8: "Sala de bate-papo",
  modalMenuLink9: "Histórico de transações",
  modalMenuLink10: "Meus Bilhetes e Pontos",
  modalMenuLink11: "Perguntas Frequentes",
  modalMenuLink12: "Nossa missão",
  modalMenuLink13: "Por que se juntar a nós?",
  modalMenuBottom1: "Sobre a " + getDomain() + "",
  modalMenuBottom2: "Legal",
  modalMenuBottom3: "Configurações",
  modalMenuBottom4: "Contate-nos",
  modalMenuBottom5: "Tour em Las Vegas",
  chatOnline: "online",
  chatTitle: "Sala de bate-papo",
  chatNotMessages: "Ainda não há mensagens",
  chatGif: "Escolha um GIF",
  chatNotGif: "Nenhum resultado encontrado",
  withdrawTitle1: "Saque",
  withdrawSubtitle: "É uma retirada total ou nada.",
  withdrawPrice: "Disponível",
  withdrawTitle2: "Escolha sua maneira de receber o pagamento:",
  withdrawTitle3:
    "Você receberá um voucher em dinheiro em um endereço de e-mail de sua escolha:",
  withdrawLink:
    "Clique para ver quais métodos de pagamento estão disponíveis no seu país",
  withdrawBtn: "Sacar",
  noTransactions: "Você ainda não fez nenhuma transação",
  ransactionsTitle: "Histórico de Transações",
  transactionsSection1Left:
    "Dobrando seu dinheiro e aproveitando o nosso parceiro!",
  transactionsSection1Right: "Adicionado ao saldo",
  transactionsSection2Left: "Seu amigo dobrou o dinheiro",
  transactionsSection2Right: "Adicionado ao Saldo",
  transactionsSection3Left: "Saque de dinheiro",
  transactionsSection3Left2: "para Pix",
  transactionsSection3Right: "Adicionado ao Saldo",
  transactionsSection4Left: "Sacar dinheiro",
  transactionsSection4Left2: "/ Pix",
  transactionsSection4Right: "Saque",
  ticketsTitle: "Meus Bilhetes e Pontos",
  ticketsSection1Left: "Seu amigo dobrou o dinheiro deles!",
  ticketsSection1Right: "Bilhetes para Las Vegas",
  ticketsSection2Left: "Email enviado",
  ticketsSection2Right: "Bilhetes para Las Vegas",
  ticketsSection3Left: "Por",
  ticketsSection3Left2: "crédito comprado no nosso parceiro!",
  ticketsSection3Right: "Bilhetes para Vegas",
  ticketsSection4Left: "Amigo dobrou com sucesso seu dinheiro e recebeu pontos",
  ticketsSection4Right: "Pontos",
  ticketsSection5Left: "Email enviado",
  ticketsSection5Right: "Pontos",
  ticketsSection6Left: "Por",
  ticketsSection6Left2: "créditos comprados no nosso parceiro!",
  ticketsSection6Right: "Pontos",
  aboutModalTitle: "Sobre nós",
  aboutModalDesc1:
    "Bem-vindo à " +
    getDomain() +
    ", sua porta de entrada para transformar o engajamento on-line em recompensas tangíveis e emocionantes!",
  aboutModalDesc2:
    "Em um mundo em que o valor de suas ações digitais muitas vezes parece intangível, nós oferecemos uma plataforma que não apenas valoriza sua participação, mas também o recompensa de forma generosa.",
  aboutModalDesc3:
    "Desde dobrar seu dinheiro com uma simples transação até ganhar aventuras com todas as despesas pagas, a " +
    getDomain() +
    " está aqui para fazer cada clique valer a pena.",
  aboutModalDesc4:
    "" +
    getDomain() +
    " - onde cada clique traz uma recompensa, e cada recompensa traz um (lucrativo) sorriso ",
  contactModalTitle: "Contate-nos",
  contactModalSubTitle: "Envie-nos seu e-mail e entraremos em contato com você",
  contactModalBtn1: "Enviado!",
  contactModalBtn2: "Enviar mensagem",
  legalModalTitle: "Legal",
  legalModalDesc:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  messageBtn: " Copiar mensagem",
  settingsTitle: "Configurações da conta",
  settingsUserName: "Nome de usuário",
  settingsUserEmail: "E-mail",
  settingsCheckbox: "Receber notificações e lembretes por e-mail",
  settingsDeleteStep: "Excluir conta",
  settingsDeleteSure:
    "Se você excluir sua conta, todas as suas conquistas serão perdidas",
  settingsDeleteSure2: "Você tem certeza de que deseja excluir sua conta?",
  settingsDeleteNo: "Não",
  settingsDeleteYes: "Sim",
  settingsBtn: "Aplicar alterações",
  settingsBtnDone: "Salvo!",
  leaderboardsTitle: "Classificações",
  leaderboardsPoints: "Pontos",
  leaderboardsPrize: "Prêmio",
  tourShortTitle: "Lorem ipsum",
  tourLongTitle: "Lorem ipsum",
  tourShortToggle: "Versão curta",
  tourLongToggle: "Versão longa",
  tourShortBtn: "Compartilhe com seus amigos",
  tourLongBtn: "Compartilhe o tour",
  authSlide1: "“Meu desejo é sua ordem - deixe a emoção invadir você.”",
  authSlide1li1: "1. Dobre seu dinheiro - valorizamos seu tempo",
  authSlide1li2: "2. Tenha a chance de ganhar a promoção de Las Vegas",
  authSlide1li3: "3. Conquiste a competição de US$6.9",
  authSlide2: "1. Deposite e Dobre!",
  authSlide2li1:
    "Deposite 5$ no site do nosso parceiro - aproveite para usá-los e dobre para receber imediatamente um retorno de 10$ em dinheiro da " +
    getDomain() +
    "... porque valorizamos o seu tempo!",
  authSlide3: "2. Las Vegas com Amigos!",
  authSlide3li1:
    "Adquira bilhetes para Las Vegas e tenha a chance de ganhar a nossa incrível promoção de Las Vegas - um fim de semana dos seus sonhos que você nunca esquecerá!",
  authSlide4: "3. Ganhe a competição diária de US$6.9",
  authSlide4li1:
    "Ganhe pontos ao engajar mais na " +
    getDomain() +
    ". Termine em primeiro lugar em nosso top 10 para ganhar US6.9, diariamente! ",
  authEnterEmail: "Digite seu e-mail",
  authContinue: "Continuar",
  authDiscord: "Continuar com",
  authGoogle: "Continuar com",
  authFacebook: "Continuar com",
  verifyCodeTitle: "Verificar sua conta",
  verifyCodePlaceholder: "Digite o código de verificação enviado por e-mail",
  verifyCodeError: "O código está errado",
  verifyCodeBtn: "Enviar",
  verifyCodeLink: "Já tem uma conta?",
  verifyCodeLinkSpan: "Fazer login",
  verifyCodeEmailTitle:
    "Enviamos um email de verificação para",
    "openEmailApp": "POR FAVOR ABRA O SEU EMAILl",
  logoutConfirmTitle: "Tem certeza que deseja sair?",
  logoutConfirmNo: "Não",
  logoutConfirmYes: "Sim",
  winTitle: "Como posso obter bilhetes para ganhar a Promoção de Las Vegas?",
  winSubTitle: "Como posso obter bilhetes para ganhar a Promoção de LasVegas?",
  winPoint1Title: "Compre mais créditos no nosso parceiro",
  winPoint1li1:
    "Cada crédito extra que você comprar, além dos 5 primeiros que comprar na oferta Dobre seu Dinheiro, lhe dará 1 bilhete para Las Vegas (sem créditos bônus incluídos).",
  winPoint2Title: "Indique amigos que completem a oferta Dobre seu Dinheiro",
  winPoint2li1:
    "Indique seus amigos e ganhe 30 bilhetes para Las Vegas para cada amigo que completar a oferta Dobre seu Dinheiro",
  winPoint3Title:
    "Envie um formulário postal gratuito por correio para o nosso escritório",
  winPoint3li1: "Faça o download do formulário",
  winPoint3li2:
    "Preencha seus dados e envie-os para nosso escritório no Chipre (endereço detalhado no formulário para download)",
  winPoint3li3:
    "Cada carta que você enviar poderá incluir apenas um formulário e lhe dará como prêmio um bilhete",
  winPointEnd: "Boa sorte!",
  winPointEndLink: "Para os termos completos da Promoção de Las Vegas",
  rulesTitle: "🎉 Bem-vindo à Competição da " + getDomain() + "! 🎉",
  rulesSubTitle:
    "A partir de 15 de Março de 2024, e continuando diariamente, junte-se a nós na Competição da " +
    getDomain() +
    ".",
  rulesPoint1Title: "Para participar:",
  rulesPoint1li1: "Faça login no nosso parceiro",
  rulesPoint1li2: "Compre 5 créditos e receba um bônus de mais 5",
  rulesPoint2Title: "Maneiras de ganhar prêmios:",
  rulesPoint2li1: `Gaste todos os 10 créditos no nosso parceiro e receba R$50 na ${getDomain()}`,
  rulesPoint2li2:
    "US$ 6.9 para o primeiro lugar e US$ 6,90 para o 2º ao 10º - diariamente!",
  rulesPoint2li3:
    "US$ 1 + 30 bilhetes para Las Vegas + 10 pontos para cada indicação bem-sucedida que completar a oferta",
  rulesPoint2li4:
    "1 Bilhete para Las Vegas + 0,5 ponto para cada crédito comprado após a primeira compra no nosso parceiro",
  rulesEnd: `Boa sorte a todos os participantes da Competição da ${getDomain()}!`,
  toolInfoTitle: `O que posso ganhar na ${getDomain()} e como?`,
  toolInfoWhat: "O que?",
  toolInfoHow: "Como?",
  toolInfolef1: "R$50,00 no seu bolso",
  toolInfolef2: "Ganhe a promoção de Las Vegas",
  toolInfolef3: "Ganhe a competição diária de US$6.9",
  toolInfoRight1: 'Complete a oferta "Dobre seu dinheiro"',
  toolInfoRight2:
    "Consiga bilhetes comprando créditos no nosso parceiro e indique amigos que completem a oferta",
  toolInfoRight3:
    "Ganhe pontos comprando créditos no nosso parceiro e indique amigos que completem a oferta",
  ourMissionTitle: "Nossa Missão",
  ourMissionSubTitle1: `A ${getDomain()} surgiu da simples ideia de que as plataformas on-line deveriam oferecer um valor mais direto aos seus usuários.`,
  ourMissionSubTitle2:
    "Estamos empenhados em criar um ambiente em que cada ação - seja um depósito, uma indicação ou uma compra - tenha um resultado gratificante.",
  ourMissionSubTitle3:
    "Nossa plataforma foi projetada para celebrar e recompensar a comunidade por seu engajamento e fidelidade, transformando as atividades cotidianas em oportunidades de prazer e entusiasmo.",
  whyJoinTitle: "Por que se juntar a nós?",
  whyJoinSubTitle1: `A ${getDomain()} está na interseção entre inovação, recompensa e comunidade. Somos mais do que uma plataforma; somos um movimento para criar um ecossistema on-line mais recompensador. Nossa dedicação em oferecer recompensas transparentes, imediatas e substanciais nos diferencia, garantindo que seu tempo gasto conosco não seja apenas agradável, mas também proveitoso.`,
  whyJoinSubTitle2: `Nossa comunidade é o núcleo do nosso sucesso e estamos comprometidos em promover um espaço onde cada membro possa experimentar a alegria de ganhar. De recompensas em dinheiro a férias dos sonhos, a ${getDomain()} se dedica a transformar seu engajamento digital em prazeres do mundo real.`,
  FAQ_Questions: [
    {
      question: "O que estou fazendo aqui?",
      answers: [
        "Você está aqui para garantir 100% do dobro do seu depósito inicial, concorrer a uma chance de ganhar um fim de semana emocionante em Las Vegas e participar da nossa competição diária de US$6.9",
      ],
    },
    {
      question:
        "Quais são os requisitos para se qualificar para o cashback instantâneo de US$ 5?",
      answers: [
        "Basta se cadastrar em nosso parceiro, depositar $5 para receber 5 créditos, gastar esses créditos e você receberá 100% de cashback instantaneamente aqui.",
      ],
    },
    {
      question:
        "Posso sacar meu cashback de US$ 5 imediatamente após recebê-lo?",
      answers: [
        "Sim, seu cashback de US$ 5 estará disponível para saque imediato",
      ],
    },
    {
      question: `Como posso convidar amigos para participar da ${getDomain()} e quais são o nosso benefícios?`,
      answers: [
        'Use a página "Indique seus amigos". Para cada amigo que completar a oferta, você receberá US$ 1 em dinheiro, 30 ingressos para concorrer à viagem a Las Vegas e 10 pontos para a competição diária. Além disso, se você ganhar a promoção de Las Vegas e eles completarem a oferta Dobre seu dinheiro, você poderá levar dois deles para Las Vegas com você!',
      ],
    },
    {
      question:
        "Quais etapas meus amigos devem concluir para se qualificarem para a viagem a Las Vegas?",
      answers: [
        "Seus amigos precisam se registrar por meio do seu link de indicação e completar a oferta Dobre Seu Dinheiro para que você tenha direito a convidar até dois deles como seus companheiros de viagem para a viagem a Las Vegas.",
      ],
    },
    {
      question: "Quais são as regras da promoção de Vegas?",
      answers: ["Lorem ipsum"],
    },
    {
      question: `Há um limite para o número de amigos que posso indicar para a ${getDomain()}?`,
      answers: [
        "Não, não há limite. Indique quantos amigos você quiser e ganhe mais!",
      ],
    },
    {
      question:
        "Como são escolhidos os vencedores do fim de semana em Las Vegas?",
      answers: [
        "Os ganhadores são selecionados por meio de um sorteio ao vivo na presença de um advogado e de um contador, o que garante a imparcialidade e a transparência. Link para as regras do fim de semana em Las Vegas",
      ],
    },
    {
      question:
        "Quais são as despesas cobertas pelo prêmio do fim de semana em Las Vegas?",
      answers: [
        "O prêmio do fim de semana em Las Vegas cobre sua viagem e entretenimento de forma extravagante. Ele inclui passagens de ida e volta para Las Vegas para você e dois amigos que tenham completado a oferta Dobre Seu Dinheiro, se você optar por levá-los, duas noites de hospedagem em um hotel 5 estrelas e US$ 1.000 em crédito nosso parceiro para o vencedor. Além disso, você receberá US$ 1.000 em dinheiro e, se for o caso, cada um dos seus dois amigos receberá US$ 500 para aproveitar. O pacote é coroado com um jantar especial e uma experiência de dirigir um Cadillac com 2 estrelas pornôs, avaliando o prêmio total em aproximadamente US$ 5.000.",
      ],
    },
    {
      question: "Como faço para comprar créditos adicionais no parceiro?",
      answers: [
        "Clique aqui para comprar mais créditos e aumentar suas chances de ganhar a promoção de Las Vegas e $6.9 em pontos da competição diária",
      ],
    },
    {
      question:
        "Há algum limite para a quantidade de créditos que posso comprar?",
      answers: [
        "Não, não há limites para a quantidade de créditos que você pode comprar. Desde que compre mais, você terá mais chances de ganhar a promoção de Las Vegas ou terminar entre os 10 primeiros na competição diária de US$6.9.",
      ],
    },
    {
      question:
        "Como funciona a tabela de classificação diária de US$ 6.9 e como posso ganhar pontos?",
      answers: [
        "Ganhe pontos indicando amigos e comprando créditos de nosso parceiro. Quanto mais você se envolver, maior será sua posição na tabela de classificação. Para cada amigo que você indicar e concluir a oferta, você ganha 10 pontos. Para cada crédito extra em nosso parceiro que você comprar, você ganha 0,5 ponto. ",
      ],
    },
    {
      question:
        "Quais são os prêmios para as 10 primeiras posições na tabela de classificação diária?",
      answers: [
        "Os dois primeiros lugares ganham US$ 6.9 cada, e os lugares de 3 a 10 ganham US$ 6,90 cada, todos os dias.",
      ],
    },
    {
      question:
        "Com que frequência a tabela de classificação e os prêmios são atualizados?",
      answers: [
        "A tabela de classificação e os prêmios são atualizados imediatamente, em tempo real.",
      ],
    },
    {
      question: "Posso ganhar recompensas diárias várias vezes?",
      answers: [
        "Sim, você pode ganhar recompensas diárias sempre que estiver entre os 10 primeiros da tabela de classificação.",
      ],
    },
    {
      question:
        "O que acontece se houver um empate na tabela de classificação diária?",
      answers: [
        "Em caso de empate, será escolhido o usuário com o maior número de amigos indicados, seguido pelo usuário com o maior número de créditos comprados e, por fim, pelo que concluir a oferta mais cedo.",
      ],
    },
    {
      question: "Há alguma taxa ou encargo para depositar ou sacar dinheiro?",
      answers: [
        "Não, não há taxas ou encargos para nenhuma transação financeira em nossa plataforma.",
      ],
    },
    {
      question:
        "Como posso monitorar minhas indicações e recompensas em tempo real?",
      answers: [
        "para monitorar suas indicações e recompensas à medida que elas acontecem.",
      ],
      link: "#",
      linkName: "Clique aqui",
    },
    {
      question:
        "Por que não vejo meu depósito no parceiro mesmo depois de depositar?",
      answers: [
        `Os depósitos normalmente são refletidos em sua conta ${getDomain()} em 3 minutos, creditando a você os fundos correspondentes. Se você não vir seu depósito após 30 minutos, recomendamos entrar em contato com nosso suporte ao cliente para obter assistência.`,
      ],
    },
    {
      question:
        "Com quem posso entrar em contato para obter suporte se tiver problemas com a minha conta ou com as recompensas?",
      answers: [
        "para acessar nosso chat para qualquer assistência que você precisar.",
      ],
      link: "[contactForm]",
      linkName: "Clique aqui",
    },
    {
      question: "O que são os termos de serviço?",
      answers: [
        "Para todos os detalhes sobre nossas políticas:",
        "- Termos de Serviço",
        "- Termos da Competição Diária",
        "- Termos da Promoção",
        "- Política de Cookies",
        "- Política de Privacidade",
        "- Termos de Afiliação",
      ],
    },
  ],
  dataMessageDesc1:
    "Imagine a gente em Las Vegas, se nós ganharmos. Está a fim de uma aventura?",
  dataMessageDesc2:
    "Estou distribuindo dinheiro grátis ao indicar você. Interessado em um impulso?",
  dataMessageDesc3:
    "As competições diárias estão no ar. Você acha que pode me superar? Vamos ver.",
  dataMessageDesc4:
    "Las Vegas comigo? Ganhe e estaremos prontos pra voar. Vamos apostar em mim.",
  dataMessageDesc5:
    "E aí, estou brincando de ser banqueiro da realeza aqui. Junte-se a mim e ganhe dinheiro de graça. É uma dinastia de dinheiro!",
  dataMessageDesc6: "Vir comigo = diversão sem parar. Sério, é uma explosão.",
  dataMessageDesc7:
    "Junte-se à minha comitiva real e vamos transformar sua assinatura no meu tesouro. Ganha-ganha, certo?",
  dataMessageDesc8:
    "Suba a bordo e vamos transformar sua assinatura em minhas joias da coroa. Parece um acordo de reis, não é?",
  tourSlide1Title: "A Promoção de Las Vegas o aguarda",
  tourSlide1SubTitle:
    'Passagens aéreas para Las Vegas por nossa conta para você e 2 amigos que você escolheu* que completaram a oferta "Dobre Seu Dinheiro". 2 noites em um hotel 5 estrelas, 1.000 dólares para seus gastos e 500 dólares para cada um de seus amigos 🎉',
  tourSlide2Title: "Um glamuroso passeio num Cadillac",
  tourSlide2SubTitle:
    "Passeie por Las Vegas em um Cadillac com duas Amigas VIP. Experimente o brilho e o glamour de perto com dois de seus melhores amigos. Seja o centro das atenções nas ruas de Las Vegas por algumas horas 🔥.",
  tourSlide3Title: "Jantar + diversão em uma boate com suas novas Amigas VIP",
  tourSlide3SubTitle:
    "Um jantar conosco com suas novas Amigas VIP, seguido de uma noitada em um clube de elite de Las Vegas. Crie lembranças inesquecíveis em Vegas que vão te marcar para sempre 🏆.",
  withdrawInputEmail: "Digite seu e-mail",
  withdrawContinue: "Continuar",
  verificationTitle: "Verificação de OTP",
  verificationSubTitle: "Nós lhe enviamos uma senha de uso único para ",
  PIXTitle: "Por favor insira os dados do seu Pix:",
  PIXInpu1: "Chave Pix:",
  PIXInpu2: "CPF",
  withdrawConfirm: "Sua solicitação de saque foi recebida...",
  withdrawError:
    "Ocorreu um erro. Por favor tente novamente ou entre em contato com o Suporte ao Cliente.",
  withdrawErrorTry: "Fechar e tentar novamente",
  withdrawLi1: "1. Envie seu e-mail para receber seu cupom exclusivo.",
  withdrawLi2:
    '2. Verifique se há um e-mail nosso em sua caixa de entrada e clique na opção "Resgatar" junto ao cupom.',
  withdrawLi3:
    "3. Você será direcionado à nossa loja de cupons, hospedada pela XOXOday.",
  withdrawLi4:
    "4. Uma vez lá, escolha uma maneira de resgatar seus cupons, como por meio do PayPal ou com cartões Visa pré-pagos.",
  withdrawLi5: "5. Eles processarão sua solicitação e te enviarão os fundos.",
  withdrawLi6:
    "6. Caso precise de qualquer assistência, entre em contato com o nosso",
  withdrawLi6Span: "Suporte ao cliente.",
  myReferralsTitle: "Minhas indicações para Las Vegas",
  myReferralsSubTitle:
    "Veja se seus amigos indicados aproveitaram a oferta do nosso parceiro de dobrar o dinheiro - somente então você poderá levá-los a Las Vegas se ganhar!",
  myReferralsDoneTitle: "Você foi indicado por: ",
  myReferralsDoneSubTitle:
    "Eles completaram a oferta e estão elegíveis para serem escolhidos para Las Vegas se você ganhar o sorteio!",
  myReferralsUnDoneTitle: "Você foi indicado por:",
  myReferralsUnDoneSubTitle:
    "Eles ainda não completaram a oferta e não são elegíveis para serem escolhidos para Las Vegas se você ganhar o sorteio :(",
  myReferralsSheet1: "Nome",
  myReferralsSheet2: "Aceitou a oferta",
  myReferralsSheet3: "Enviar e-mail",
  myReferralsSend: "Enviar",
  myReferralsSubTitle2: "Você ainda não indicou amigos",
  winTicketTitle:
    "👬Ainda posso ir para Las Vegas se meu ingresso não for o vencedor? 👬",
  winTicketSubTitle:
    "Sim! Para que você, quem te indicou ou suas indicações sejam escolhidos pelo outro se ganharem a promoção de Las Vegas, você precisará:",
  winTicketLiTitle:
    "Participe da Promoção da " + getDomain() + " no nosso parceiro:",
  winTicketLi1: "Crie uma nova conta ou use uma já existente.",
  winTicketLi2:
    "Faça uma compra mínima de cinco créditos para ganhar mais cinco.",
  winTicketLi3: "Gaste os cinco (5) créditos recebidos no nosso parceiro.",
  winTicketBtn: "Vá para a página Dobre Seu Dinheiro",
  winTicketEnd: "Boa sorte!",
  winTicketLink: "Para os termos completos da Promoção de Las Vegas",
  leaderSheetYou: "Seu lugar hoje:",
  leaderSheetPoints: "Pontos de Competição",
  leaderSheetFriends: "Amigos Indicados",
  raffleRulesDescSpan: "Leia Mais.",
  competitionOverBtn:
    "Obtenha o máximo de pontos que puder para ganhar a competição diária de US$6.9:",
  infoOfferTitle:
    'O que eu recebo quando indico um amigo que completa a oferta "Dobre seu dinheiro"?',
  infoOfferPoint1:
    "Um amigo que ficará extremamente grato por você ajudá-lo de forma generosa",
  infoOfferPoint2: "US$1 pode ser sacado em dinheiro imediatamente",
  infoOfferPoint3:
    "30 bilhetes para Las Vegas que aumentam sua chance de ganhar a promoção de Las Vegas",
  infoOfferPoint4:
    "10 pontos de competição diários de US$6.9 que o aproximam dos 10 melhores",
  infoOfferPoint5:
    "Um amigo que você pode escolher para ir a Las Vegas se ganhar a promoção",
  infoOfferPoint6:
    'Seu amigo poderá escolher você para ir a Las Vegas se ele ganhou a promoção e você completou a oferta "Dobre seu dinheiro"',
  earnedModalTitle: "Últimos ganhos em dólar",
  TicketModalTitle: "Últimos ganhos com bilhetes para Las Vegas",
  PointsModalTitle: "Últimos pontos da competição ganhos",
  earnedModalLink: "Exibir informações completas",
  toolInfoLeft1: "Ação",
  toolInfoPoint1: "Dólares ganhos",
  toolInfoPoint2: "Pontos",
  toolInfoPoint3: "Bilhetes",
  contactQuestionTitle: "Qual problema você está enfrentando?",
  contactQuestion1: "Não recebi meu dinheiro",
  contactQuestion2: "Não recebi meus pontos da competição",
  contactQuestion3: "Não recebi meus bilhetes para Las Vegas",
  contactQuestion4: "Tenho problemas com minha conta",
  contactQuestion5: "Esqueci minha senha",
  contactQuestion6: "Reclamações",
  contactQuestion7: "Outros - por favor, escreva sobre isso",
  contactQuestionEmail: "Seu endereço de email",
  contactQuestionDesc: "Descrição",
  contactQuestionAttach: "Anexar arquivos",
  contactConfirmTitle:
    "Sua mensagem foi enviada! Entraremos em contato em breve",
  contactConfirmBtn: "Continuar",
  timeCounterLink: "*Sujeito aos Termos e condições da competição diária",
  raffleBotLink1:
    "👬Ainda posso ir para Las Vegas se meu ingresso não for o vencedor? 👬",
  raffleBotLink2: "Você precisará dobrar seu dinheiro",
  doubleBtnTitle: "" + getDomain() + " ordena que...",
  doubleBtnAway: "minutos de distância",
  doubleBtnFrom: "de sacar",
  doubleCompleteTitle:
    "Parabéns, você tem R$50 esperando por você, vá buscar e retire!",
  doubleCompleteTitleSpan: "caixa de entrada!",
  doubleCompleteSubTitle:
    "Seja o rei do camarote indicando seus amigos à " +
    getDomain() +
    " e eles lhe agradecerão para sempre... 👑",
  doubleCompleteBtn: "Compartilhar",
  doubleTaskBtn1: "1/3 Comece agora 🎉",
  doubleTaskBtn2: "2/3: Deposite $5, ganhe 5 créditos 💸",
  doubleTaskBtn3: "3/3: Gaste todos os créditos e ganhe $10 de volta 🤑",
  referralShareBtn1: "Compartilhar Link de Indicação",
  referralShareBtn2: "Link copiado!",
  ModalMenuToggle: "Escolha o seu design",
  leaderSheetToday: "Hoje",
  leaderSheetYesterday: "Ontem",
  promoModalTitle: "Sacar com",
  promoModalMessage:
    "Não seja tolo, aceite esta oferta e ganhe +30% 65 reais em vez de 50",
  promoModalLink: "Não, obrigado",
  promoImLiveDesc:
    "Não seja tolo, aceite esta oferta e ganhe +30% 65 reais em vez de 50",
  confirmLiveDesc: "Tem certeza de que deseja sacar com o nosso parceiro?",
  earnedModalTitleBR: "Ultimos R$ ganhos",
  modalMenuLink14: "Leia Mais",
  raffleSubTitleLink: "Como posso ganhar bilhetes para Las Vegas?",
  competitionTitle: "Ganhe a",
  competitionTitle2: " Competição Diária!",
  leaderboardsNotUser: "Não há mais usuários",
  NamePage1: "Página inicial",
  NamePage2: "Dobre seu dinheiro",
  NamePage3: "Final de Semana em Vegas",
  NamePage4: "Competição",
  NamePage5: "Meus indicados",
  withdrawGiftTitle: "Gift Card / Dinheiro",
  doubleBtnEndTask: "Ganhe a Competição Diária de $6.9!",
  paxumFlowTitle:
    "Você receberá um voucher em dinheiro no e-mail de sua escolha:",
  paxumFlowInput: "Digite sua ID da Paxum",
  paxumFlowPlaceholder: "Digite sua ID da Paxum",
  paxumFlowBtn: "Continuar",
  paxumFlowLink1: "Não tem conta da Paxum?",
  paxumFlowLink2: "Crie-o aqui",
  visaFlowTitle:
    "Você receberá um voucher em dinheiro em um e-mail de sua escolha:",
  visaFlowSubTitle: "O primeiro saque tem um mínimo de ",
  visaFlowSelectName1: "Transferência para",
  visaFlowSelect1: "Cartão de débito Virtual pré-pago",
  visaFlowInput: "Digite sua ID da Paxum",
  visaFlowPlaceholder: "Digite sua ID da Paxum",
  visaFlowSelectName2: "Tipo de cartão",
  visaFlowSelect2: "Visa US$",
  visaFlowSelectName3: "Valor",
  visaFlowSelect3: "Cartão pré-pago Visa",
  visaFlowBtn: "Continuar",
  aboutModalDesc5:
    "A " +
    getDomain() +
    " é uma plataforma de referência social global de ponta que motiva sua comunidade com recompensas como concursos, prêmios e cashback por interagir com parceiro de alta qualidade em vários setores.",
  aboutModalDesc6:
    'Inspirado no jogo infantil "Simon Says", o ' +
    getDomain() +
    " também inclui elementos de gamificação, resultando em recompensas incríveis e diversão ilimitada para seus usuários.",
  loginTermsTitle:
    "Ao se registrar na " +
    getDomain() +
    ", você declara que tem +18 anos e que concorda com o nosso",
  loginTermsLink: "Termos e Serviços",
  homepageMessageTitle: "Diga a ela o que fazer e ela fará... AO VIVO",
  SPEITitle:
    "Você receberá um voucher em dinheiro em um e-mail de sua escolha:",
  SPEIInput1: "Nome",
  SPEIInput2: "Sobrenome",
  SPEISelect1: "Tipo de Documento",
  SPEIInput3: "Documento",
  SPEIInput4: "Email",
  PSETitle: "Você receberá um voucher em dinheiro em um e-mail de sua escolha:",
  PSEInput1: "Email",
  PSEInput2: "Nome",
  PSEInput3: "Carteira de identidade nacional (RG)",
  ServiPagTitle:
    "Você receberá um voucher em dinheiro em um e-mail de sua escolha:",
  ServiPagInput1: "Email",
  ServiPagInput2: "Nome",
  ServiPagInput3: "National identity card (RUT)",
  TransactionCompetitionWinner: "Terminando #",
  TransactionCompetitionWinner2: "na competição diária de US$6.9!",
  modalMenuLink15: "Junte-se como influenciador",
  modalMenuLink16: "Programa de parceiro",
  InfluencerTitle: "Registre-se como influenciador",
  InfluencerSubTitle:
    "Estamos procurando os principais influenciadores locais e mundiais. Junte-se a nós para se associar a marcas líderes mundiais e ter acesso a oportunidades incríveis. ",
  InfluencerInput1: "Email",
  InfluencerInput2: "Nome",
  InfluencerSelect1: "Who you are?",
  InfluencerOptoin1: "Escolha quem você é",
  InfluencerInput3: "Sua mensagem",
  InfluencerBtn: "Continuar",
  collaborateTitle: "Programa de parceiro",
  collaborateSubTitle:
    "A " +
    getDomain() +
    " está buscando colaborar com empresas internacionais que tenham alcance global, com o objetivo de interagir com nosso tráfego de usuários de alto volume.",
  collaborateInput1: "E-mail",
  collaborateInput2: "Nome",
  collaborateSelect1: "Quem é você?",
  collaborateOptoin1: "Escolha quem você é",
  collaborateInput3: "Sua mensagem",
  collaborateBtn: "Continuar",
  paxumFlowCheckbox:
    "Só serei pago através da Paxum se eu tiver uma conta válida.",
  toolInfolef4:
    "Seu bilhete não ganhou a viagem para Las Vegas? Você ainda pode ir!",
  toolInfoRight4:
    "Se um amigo que você indicou ou seu indicado ganhou a promoção e você completou a oferta, ele poderá escolher você para ir a Las Vegas!",
  withdrawConfirmPaxum:
    "Recebemos sua solicitação. Nossa equipe responderá assim que o saque for bem-sucedido",
  withdrawBanner: "Ganhe +40%",
  withdrawBannerSpan: "do seu dinheiro com o nosso parceiro.",
  imLivePromoDesc:
    "o nosso parceiro oferecem o melhor valor pelo seu dinheiro: receba 1 crédito para cada US$5 + 20% de crédito extra",
  imLivePromoVtn: "Aproveite esta oferta",
  InfluencerInputPhone: "Telefone",
  InfluencerWho: "Quem é você?",
  InfluencerWhoSelect: "Escolha quem você é",
  InfluencerWhoOption1: "Influenciador cripto",
  InfluencerWhoOption2: "Top Model",
  InfluencerWhoOption3: "Criador de Conteúdo no Instagram",
  InfluencerWhoOption4: "Criador de Conteúdo no YouTube",
  InfluencerWhoOption5: "TikToker",
  InfluencerWhoOption6: "Outro",
  InfluencerNickname: "Nome das redes sociais",
  InfluencerFollowers: "Rede social e seguidores",
  InfluencerThanks:
    "Obrigado por sua solicitação. Nossa equipe entrará em contato",
  collaboratePhone: "Telefone",
  collaborateURL: "URL do site",
  collaborateJob: "Vaga aberta",
  collaborateWho: "Quem é você?",
  collaborateWhoSelect: "Escolha quem você é",
  collaborateWhoOption1: "Negócios locais",
  collaborateWhoOption2: "Agente",
  collaborateWhoOption3: "Empresa multinacional",
  collaborateWhoOption4: "Outro",
  withdrawBannerTitle:
    "Ou obtenha +38% do valor como créditos no nosso parceiro:",
  withdrawBannerMessage: "Não deixe a",
  withdrawBannerSpan1: "Obter ",
  withdrawBannerSpan2: "créditos",
  withdrawBannerSpan3: "para gastar agora!",
  withdrawBannerBtn: "Obter agora",
  withdrawBannerSubtitle:
    "* A taxa padrão de R$6,90 para 1 crédito do nosso parceiro é usada como base para calcular os benefícios. As porcentagens dos benefícios podem diminuir ao comprar pacotes de créditos mais altos no nosso parceiro, mas esta opção sempre oferecerá um benefício.",
  modalMenuLink17: "Meus ganhos",
  modalMenuCopyLinkTitle:
    "Seja o rei de seus amigos indicando-os ao " +
    getDomain() +
    " e eles lhe agradecerão para sempre... 👑",
  modalMenuCopyLinkSubTitle: "Para cada amigo que completou a oferta:",
  modalMenuLink18: "Suporte ao cliente",
  transactionApprovedTitle: "Sua solicitação de saque através do",
  transactionApprovedSpan: "foi aprovado",
  transactionApprovedRight: "Saque aprovado",
  transactionPendingTitle: "Sua solicitação de saque através do",
  transactionPendingSpan: "está pendente",
  transactionPendingRight: "Pendente",
  transactionRejectedTitle: "Sua solicitação de saque através do",
  transactionRejectedSpan: "foi rejeitada",
  transactionRejectedRight: "Saque rejeitado",
  transactionID: "ID da transação:",
  raffleSubTitleLink2: "Faça o tour em Vegas",
  CopyLinkDone: "Link Copiado!",
  aboutModalDesc: "",
  leaderSheetDayAgo: "2 dias atrás",
  verifyCodeSpam:
    "Não consegue encontrar o e-mail?",
    verifyCodeSpam2:
    "Verifique sua pasta de spam",
    
  timeCounterLink2: "Regras da Promoção de Las Vegas",
  pixType: "Tipo de Pix",
  pixTypeOption1: "CPF / CNPJ",
  pixTypeOption2: "Celular",
  pixTypeOption3: "E-mail",
  pixTypeOption4: "Aleatório",
  pixTypeSelectMain: "Selecione o tipo de Pix",
  pixTypeSelectName1: "Inserir CPF / CNPJ",
  pixTypeSelectName2: "Digite o número do seu celular",
  pixTypeSelectName3: "Digite o e-mail do destinatário",
  pixTypeSelectName4: "Aleatório",
  tourUnderSlide:
    "*Somente os amigos que foram indicados por você ou por quem o indicou para a " +
    getDomain() +
    " podem ser escolhidos por você para ir a Las Vegas se você ganhar.",
  transactionWithdraw: "Retirado",
  transactionTransactionID: "ID da transação:",
  ticketsToggle1: "Bilhetes para Vegas:",
  ticketsToggle2: "Pontos:",
  myReferBtn: "Indique seus amigos 👬",
  authNotificationBtn: "Abrir em",
  authNotificationBtnSpan: "Aplicativo de e-mail",
  newVisaFlowInput1: "Nome completo",
  newVisaFlowInput2: "Endereço de e-mail",
  newVisaFlowSelect1: "Cidadania",
  newVisaFlowInput3: "Data de nascimento",
  newVisaFlowCheckbox: "Você concorda com nossa",
  newVisaFlowCheckboxSpan: "política de privacidade",
  transactionStatus: "Status:",
  transactionPending: "Pendente",
  transactionApproved: "Aprovado",
  transactionDeclined: "Recusado",
  transactionNoData: "Sem dados",
  referralInvite: "Convide seus amigos",
  bannerBtn1: "OBTER OS CRÉDITOS E OS BILHETES",
  bannerBtn2: "segundos",
  bannerBtn2Done: "Continuar",
  doubleCompleteTitle1: "Parabéns, você tem ",
  doubleCompleteTitle2: " esperando por você, vá atrás deles e os saque!",
  banner1text1: "Não deixe",
  banner1text1span: "espere... ela está ansiosa para aprofundar a conexão!",
  banner1text2: "Você nem sequer se despediu...",
  banner1text3: "Faça agora",
  banner1text4:
    "Obtenha 12 créditos com +38% de valor em seus R$50 e retorne para",
  banner1text4span2: "e retorne para",
  banner1text4span: "agora",
  banner2text1: "Ganhe US$6,90 em vez de US$5",
  banner2text2: "GANHA-GANHA",
  banner2text3: "Pegue os créditos, obtenha 30 pontos de competição, torne-se",
  banner2text3span:
    "na competição diária e ganhe US$ 6,90 ao terminar entre os 10 primeiros!",
  banner3text1: "Promoção de Las Vegas",
  banner3text2:
    "Você tem 0 bilhetes para Las Vegas agora. Aumente seus R$50 em 38% para ganhar 12 créditos E 5 bilhetes para ter a chance de ganhar a Promoção de Las Vegas!",
  banner3text3:
    "Passeie por Las Vegas em um Cadillac com duas amigas VIP. Experimente o brilho e o glamour com dois de seus melhores amigos. Curta um jantar + boate com suas novas amigas VIP!",
  banner3text4: "Você pode ter +5 bilhetes",
  withdrawBannerMessage2:
    "esperando! Você não consegue dar conta... ou consegue? 🙄",
  visaFlowSubTitle2: "nesse tipo de pagamento",
  noTransactionsToday: "Você ainda não fez nenhuma transação hoje",
  banner1offer: "créditos para recuperar ",
  banner1offer2: "",
  banner1offerSpan: "!",
  banner2winner:
    "Ao adquirir créditos, você recebe imediatamente 30 pontos para o resultado da competição de hoje, o que pode lhe garantir o primeiro lugar",
  banner2winnerSpan: "no valor de ",
  homepageNewTitle1: "Experimente ação AO VIVO intensa como nunca:",
  homepageNewTitle2: "1. Dobre seu dinheiro - valorizamos seu tempo",
  homepageNewTitle3: "2. Ganhe $6.9 na competição diária",
  homepageNewTitle4: "3. Tenha uma chance na promoção de Vegas",
  banner3text5Span: "Você tem:",

  banner3text5: "bilhetes",
  homepageFinishCTA: "Ganhe a Competição Diária de US$6.9!",
  newTaskDouble: "Compre R$25, gaste-os e receba R$50 imediatamente como ",

  homepageBtnNew1: "1. Seja pago para experimentar ImLive 👑",
  homepageBtnNew2: "2. Ganhe $6.9 na competição diária",
  homepageBtnNew3: "3. Tenha a chance de ganhar a promoção de Vegas",
  homepageNewPoint1: "Compre e gaste R$25 na",
  homepageNewPoint2: "Ganhe $6.9 na competição diária",
  homepageNewPoint3: "⁠Tenha a chance de ganhar a promoção de Vegas",
  homepageStep1: "Cadastre-se",
  homepageStep2: "Compre ",
  homepageStep3: "Gaste tudo",
  homepageStep4: "Receba ",

  newHomepageTitle: "Experiência",
  newHomepageTitleSpan1: "ADULTA AO VIVO",
  newHomepageTitleSpan2: "ação como nunca antes &",
  newHomepageBtn1: "1. Aproveite a ImLive 👑",
  newHomepageBtn2: "2. Deposite R$25 para experiências QUENTES",
  newHomepageBtn3: "3. Gaste 5 créditos para ganhar cashback de R$25",
  newHomepageBtn4: "Ganhe o Fim de Semana em Las Vegas",
  newVegasTitle: "Não perca a chance de ganhar",
  newVegasSubTitle: "A PROMOÇÃO DE LAS VEGAS",
  newVegasBtn1: "Curta a ImLive 💃",
  newVegasBtn2: "Indique um Amigo 🤑",
  newVegasBtn2Copy: "Link Copiado!",
  newVegasUnderBtn1: "1 crédito comprado = 1 bilhete para Las Vegas",
  newVegasUnderBtn2:
    "Oferta concluída por um Amigo = 30 bilhetes para Las Vegas",
  newCounterPromotion: "Contagem regressiva para a Promoção de Las Vegas",
  newCounterDay: "Contagem regressiva para o Fim do Dia",
  newMenuBottom2Btn1: "Gire a Roleta 🎰",
  newMenuBottom2Btn2: "Dobro ou Nada 💵",
  newMenuBottom3Link1: "Faça o tour em Las Vegas",
  newMenuBottom3Link2: "O que é a Promoção de Las Vegas?",
  newMenuBottom3Btn1: "Curta a ImLive 💃",
  newMenuBottom3Btn2: "Indique um Amigo 🤑",
  newMenuBottom3UnderBtn1: "1 crédito comprado = 1 bilhete para Las Vegas",
  newMenuBottom3UnderBtn2:
    "Oferta concluída por um Amigo = 30 bilhetes para Las Vegas",
  newMenuLink1: "Início",
  newMenuLink2: "$6.9 Diariamente",
  newMenuLink3: "Las Vegas",
  newMenuLink4: "Indique",
  newMenuLink5: "Menu",
  LPmenuTitle: "UMA EXPERIÊNCIA",
  LPmenuTitleSpan: "AO VIVO",
  LPmenuTitle2: ", na " + getDomain() + ", tenha a chance de ganhar ",
  LPmenuSubTitle: "A PROMOÇÃO DE LAS VEGAS",
  LPsingUpBtn: "Registrar",
  LPsingUpInput: "Insira seu e-mail",
  LPsingUpLink1: "Já tem uma conta?",
  LPsingUpLink2: "Login",
  LPfooterLink1: "Política de Recompensa",
  LPfooterLink2: "Termos de Uso",
  LPfooterLink3: "Política de Privacidade",
  LPfooterLink4: "Política de Cookies",
  LPfooterCopyright: "Todos os Direitos Reservados 2023",
  LPmainTitle: "Registre-se para",
  LPhowTitle: "Como",
  LPhowTitle2: "funciona?",
  LPhowWork1Title: "Ganhe uma promoção QUENTE e AO VIVO de 100% de cashback",
  LPhowWork1SubTitle:
    "Aproveite nosso site parceiro AO VIVO com uma compra de $5! Não é para você? $5 de volta - obtenha seu reembolso.",
  LPhowWork1SubTitle2:
    "❤️ Amou? Receba créditos AO VIVO no valor de $10 de presente - duplique seu reembolso!",
  LPhowWork2Title1: "Tenha a chance de ganhar",
  LPhowWork2Title2: "A PROMOÇÃO DE LAS VEGAS",
  LPhowWork2SubTitle:
    "Adquira bilhetes para Vegas para ter a chance de ganhar nossa incrível promoção de Vegas - um fim de semana dos seus sonhos que você nunca esquecerá!",
  LPhowWork3Title: "Conquiste a competição diária de US$ 6,90",
  LPhowWork3SubTitle:
    "Ganhe pontos ao se envolver mais no " +
    getDomain() +
    ". Termine em primeiro lugar no nosso top 10 para ganhar $6,9 diariamente!",
  LPjoinBtn: "Junte-se à " + getDomain() + "",
  LPpromotion: "A PROMOÇÃO",
  LPpromotion2: "DE LAS VEGAS",
  LPplacesTitle: "Os 3 primeiros lugares",
  LPwinTitle: "GANHAM",
  LPwinTitle2: "US$ 6,90 DIARIAMENTE!",
  LPwinDesk:
    "Acumule pontos e tenha a chance de ganhar $6,90 dólares todos os dias em nossa Competição Diária!",
  LPwinSubTitle: "PRINCIPAL PARCEIRO!",
  LPcurrenciesMain: "O que você pode ganhar?",
  LPcurrencies1Title: "Dólar",
  LPcurrencies1SubTitle:
    "Aproveite o cashback de 100% através do nosso parceiro, ganhe $1 para cada amigo indicado e compita diariamente por uma chance de ganhar $6,90.",
  LPcurrencies2Title: "BILHETES PARA LAS VEGAS",
  LPcurrencies2SubTitle:
    "Obtenha ingressos para participar da Promoção de Vegas e tenha a chance de ir para Las Vegas com dois amigos e muitos benefícios!",
  LPcurrencies3Title: "PONTOS DE COMPETIÇÃO",
  LPcurrencies3SubTitle:
    "Ganhe pontos indicando amigos e comprando mais créditos na plataforma do nosso parceiro. Quanto mais pontos você tiver, mais chances terá de ganhar DINHEIRO em nossa Competição Diária!",
  LPcurrenciesMainSubtitle: "Aqui estão algumas das ",
  LPcurrenciesMainSubtitle2: "marcas com as quais trabalhamos",
  newDoubleTitle: "Você tem",
  newDoubleTitleSpan: "Pontos de Competição",
  newDoubleSubtitle: "Você ganhou",
  newDoubleSubtitleSpan: "pontos!",
  newDoubleLose: "Você perdeu! Boa sorte na próxima vez!",
  newSpinTitle: "Você pode girar a Roleta",
  newSpinTitleSpan: "Vez",
  newSpinSubtitle: "Você multiplica seu ponto por",
  newSpinSubtitleSpan: "pontos!",
  newSpinSubtitleSpan2: ", agora você tem",
  GameNoPointsTitle: 'Você não pode jogar o "Dobro ou Nada"',
  GameNoPointsTitle2: "Você não pode girar a roleta",
  GameNoPointsSubTitle:
    'Você pode jogar "Dobro ou Nada" todos os dias, desde que tenha pontos de competição o suficiente.',
  GameNoPointsSubTitle2:
    "Você pode girar a roleta uma vez por dia contanto que tenha pontos de competição.",
  GameNoPointsBtn1: "Ganhe mais Pontos",
  GameNoPointsBtn2: "Continuar",

  leaderboardNoUser: "Nenhum usuário",

  newRegisterTitle: "Cadastrar na",
  newRegisterUnderTerms:
    "Ao clicar em cadastrar, você confirma que tem 18 anos ou mais e concorda com nossos",
  newRegisterUnderLink1: "Termos de Uso",
  newRegisterUnderLink2: "Política de Privacidade",
  newRegisterUnderLink3: "Política de Cookies",
  newRegisterContinueBtn: "Continuar com",
  newRegisterContinueBtn2: "E-mail",
  newRegisterJoinWith: "Entrar com",
  newMenuToggle1: "Ganhar",
  newMenuToggle2: "Jogo",
  newCompetitionTitle: "Conquiste a Competição Diária de $6.9!",
  newMenuBottom4UnderBtn1: "Multiplique seus pontos diariamente!",
  newMenuBottom4UnderBtn2: "Tente a sorte e dobre seus pontos!",
  newNoTransaction: "Sem transações ainda.",
  newSpinSubTitle2:
    "A Roda irá somar os seus pontos de competição e dar-lhe mais!",
  newDoubleSubTitle2:
    "Como está a sua sorte hoje? Tente dobrar seus pontos agora!",

  typeValidEmail: "Digite um e-mail válido",

  pixTypeAll: "PIX Key",
  pixTypeOption5: "PIX Key Option",
  pixTypeOption6: "PIX Key Data",
  pixTypeOption7: "CPF of the user",
  newHomepageBtnLeft: "Ganhe créditos! Aproveite as lives quentes da ImLive",
  newHomepageBtnRight: "Pegue o seu dinheiro de volta",
  newHomepageBtnOr: "Não, obrigado",
  newHomepageBtnDone1: "Todas as tarefas estão completas!",
  newHomepageBtnDone2: "Agora, escolha o seu caminho:",
  homepageNewPointSpan: "ganhe R$50 a mais GRÁTIS ou um reembolso completo.",
  messageCopy: "Copy message",
  messageCopyDone: "Message copied!",
  newHomepageSteps4Top: "Ganhe créditos e aproveite a ImLive",
  newHomepageSteps4Bot: "Receba seu dinheiro de volta",

  homepageLiveTitle1: "",
  homepageLiveTitleSpan: "Experiências para Adultos",
  homepageLiveTitle2: "em Webcam no ImLive – Sem Riscos!",
  homepageLiveUnderSlideTitle: "Compre e gaste R$25 na ImLive",
  homepageLiveUnderSlideSubTitle: "",
  homepageLiveUnderOr: "ou",
  homepageLiveUnderOrLeft: "Dinheiro de volta",
  homepageLiveUnderOrRight: `${getAmount(10)} créditos extras gratuitos`,
  homepageLiveMenuBtn1: `Compre & gaste ${getAmount(5)} na ImLive 👑`,
  homepageLiveMenuTitle1: "para comprar antes que acabe!",
  homepageLiveMenuBtn2: "Me dê meus 10 créditos EXTRA gratuitos! 👑",
  homepageLiveMenuTitle2: "Seu dinheiro de volta",
  LPmenuTitleSpan2: "QUE VOCÊ QUASE PODE TOCAR",
  LPmenuJoinNow: "Quero participar!",
  LPUnderSlideTitle: "INTERAJA COM ESSAS GATAS E ",
  LPUnderSlideTitleSpan: " RECEBA GRÁTIS",
  

  LPTitle: `Depoimentos`,
  LPblock1Title1: `Estamos ativos nas redes sociais, nos encontre em:`,
  LPblock1SubTitle1: `More then `,
  LPblock1SubTitleSpan: `Members On`,
  LPblock1SubTitle2: `Discord`,
  LPblock1SubTitle3: `Server`,
  LPblock2Title: `As garotas mais selvagens do mundo`,
  LPblock2SubTitle: `Delicie-se com garotas de todo o mundo, sempre que quiser!`,
  LPblock3Title: `Ação quente ao vivo SEM RISCOS`,
  LPblock3SubTitle: `Compre & Gaste R$25 em nosso site parceiro QUENTE. Gostou? 10 créditos extras gratuitos. Não gostou? Reembolso total!`,
  LPblock4Title: `Prazer garantido com os melhores BÔNUS se você começar agora!`,
  LPblock4SubTitle: `🎰✈️ A aventura da sua vida! 🎰✈️`,
  LPblock4SubTitleLi1: `100% de reembolso`,
  LPblock4SubTitleLi2: `Vá para Las Vegas com amigos e modelos sensuais`,
  LPblock4SubTitleLi3: `Incríveis recompensas em nossa competição diária!`,
  LPFooterRow1: `Sobre nós`,
  LPFooterRow2: `Missão`,
  LPFooterRow3: `Políticas`,
  LPFooterRow4: `Follow us on our medias`,
  LPblock2TitleOver: `Nós temos tudo o que você precisa, JUNTE-SE a nós agora mesmo.`,
  homePageUnderTitle: `O que nossos usuários falam sobre nós:`,
  homePageUnderLeftTitle: `Estamos ativos nas redes sociais, nos encontre em:`,
  homePageUnderLeftBtn1: `Junte-se ao nosso Discord`,
  homePageUnderLeftBtn2: `Junte-se ao nosso Instagram`,
  homePageUnderLeftSubTitle: `Veja nossas avaliações:`,
  LPblock2TitleOverRewiew: `Veja nossas avaliações!`,
  Rewiew1Desc: `Eu fiz o processo do Ela Manda e foi muito tranquilo e super divertido!`,
  Rewiew2Desc: `Eu fiz o processo aqui do Ela Manda gente, vale muito a pena e da super certo! Fora da gente poder se divertir com cada menina linda! Eu não perco a oportunidade!`,
  Rewiew3Desc: `Eu fiz o processo do Ela Manda e deu muito certo! Foi muito divertido, a gente chegou lá na ImLive e brincou muito com as meninas, foi muito legal!`,
  Rewiew4Desc: `Salve galera, eu fiz o processo do Ela Manda e foi muito divertido, muito bom viu?! Recomendo muito!`,
  Rewiew5Desc: `Oi gente, eu fiz o processo do Ela Manda com a galera e a gente se divertiu muito, foi uma experiência incrível!`,

  QuestionaireBtnNext: `Próximo`,
  QuestionaireStep1Title: `Conte-nos o que você gosta.`,
  QuestionaireStep1SubTitle: `🔥 Quem você quer ver?`,
  QuestionaireStep1Select1: `mulher`,
  QuestionaireStep1Select2: `homem`,
  QuestionaireStep1Select3: `trans`,
  QuestionaireStep2SubTitle: `Em qual idioma ela obedecerá você?`,
  QuestionaireStep2Select1: `inglês`,
  QuestionaireStep2Select2: `espanhol`,
  QuestionaireStep2Select3: `português`,
  QuestionaireStep3SubTitle: `Qual cor de cabelo te excita:`,
  QuestionaireStep3Select1: `ruivo`,
  QuestionaireStep3Select2: `castanho`,
  QuestionaireStep3Select3: `loiro`,
  "verifyCodeEmailBtn": `abrir gmail`,


  "EmailAuthModalTitle1": `Verifique seu email para se cadastrar`,

  "NewLPmenuTitleOther": "UMA EXPERIÊNCIA",
  "NewLPmenuTitle": "AO VIVO",
  "NewLPmenuTitleSpan": "QUE VOCÊ QUASE PODE TOCAR",
  "NewLPmenuTitle2": "INTERAJA COM ESSAS GATAS E",
  "NewLPmenuTitle2Span": "RECEBA GRÁTIS",

  "homepageLiveMenuBtn3": "Ao vivo com essas garotas agora 👑",
  "homepageLiveOverTitle": `Compre & gaste ${getAmount(5)} na ImLive`,
  "homepageLiveUnderSlideChoose": "depois escolha:",


  LPUnderOrLeft: "SEU DINHEIRO",
  LPUnderOrLeft2: "DE VOLTA ",
  LPUnderOrRight: `${getAmount(10)} EM CRÉDITOS`,
  LPUnderOrRight2: `EXTRAS GRÁTIS`,






  "RedWelcomeHomeTitle": `Bem vindo ao`,
  "RedWelcomeHomeSubTitle": `Ela Manda`,
  "RedWelcomeHomeDesc": `Você se acha foda o suficiente para fazer parte desse grupo exclusivo?`,
  "RedWelcomeHomeBtn": `I Eu mereço fazer parte`,
  "RedWelcomeHeaderLink1": `Homepage`,
  "RedWelcomeHeaderLink2": `Partners`,
  "RedWelcomeHeaderLink3": `Benefits`,
  "RedWelcomeHeaderLink4": `Girls`,
  "RedWelcomeHeaderLink5": `Contact us`,
  "RedWelcomeHeaderBtn": `JOIN US!`,
  "RedWelcomeTrust": `Parceiros`,
  "RedWelcomeOurTitle": `Nossos benefícios:`,
  "RedWelcomeOurSubTitle": `Nossos membros tem acesso:`,
  "RedWelcomeOurSectionTitle1": `OFERTAS`,
  "RedWelcomeOurSectionTitle2": `SORTEIOS`,
  "RedWelcomeOurSectionTitle3": `CLUBE SECRETO`,
  "RedWelcomeOurSectionTitle4": `LIVES EXCLUSIVAS`,
  "RedWelcomeOurDesk1": `Entrando no`,
  "RedWelcomeOurDeskSpan": `Ela Manda`,
  "RedWelcomeOurDesk2": `, você vai ter a oportunidade de interagir intimamente com nossas garotas e participar de eventos especiais, exclusivos para os nossos membros.`,
  "RedWelcomeCamTitle": `CamGirls`,
  "RedWelcomeCamSubTitle": `Nossas Hosts`,
  "RedWelcomeReviewTitle": `Depoimentos`,
  "RedWelcomeReviewSubTitle": `Reviews dos membros`,
  "RedWelcomeReview1Desc": `Foi uma surpresa ser convidada para o Ela Manda, e desde que entrei tive experiências incríveis! `,
  "RedWelcomeReview1Name": `C. Winter`,
  "RedWelcomeReview1Role": `Dona da Red Hot`,
  "RedWelcomeReview2Desc": `Depois de entrar no Ela Manda, tive experiências e conversei com garotas que nunca achei que conseguiria antes. `,
  "RedWelcomeReview2Name": `T. Shake`,
  "RedWelcomeReview2Role": `Membro`,
  "RedWelcomeReview3Desc": `Desde que entrei no Ela Manda conheci pessoas e tive experiências que foram além das minhas expectativas.`,
  "RedWelcomeReview3Name": `C. Gray`,
  "RedWelcomeReview3Role": `Membro`,
  "RedWelcomeCertificateTitle": `Você acha que tem tudo o que precisa para entrar no`,
  "RedWelcomeCertificateTitleSpan": `Ela Manda?`,
  "RedWelcomeCertificateBtn": `Estou pronto`,
  "RedWelcomeSpecialsTitle": `Membros Especiais`,
  "RedWelcomeMember1Name": `Cahyra Winter`,
  "RedWelcomeMember1Role": `Owner da Red Hot`,
  "RedWelcomeMember2Name": `Ruan 1001`,
  "RedWelcomeMember2Role": `Papum no Barraco`,
  "RedWelcomeMember3Name": `Raissa Precioso`,
  "RedWelcomeMember3Role": `CamGirl`,
  "RedWelcomeMember4Name": `Alexandra Repetto `,
  "RedWelcomeMember4Role": `CamGirl`,
  "withdrawNoMoney": `Você não tem dinheiro para sacar`,
  "settingsId": `Meu ID de usuário`,
  "ReferBtnTitle": `Ganhe R$5 + 30 ingressos para Vegas e 10 pontos de competição.`,
  "ReferBtn": `Indique um amigo`,

};
