import React from "react";
import { Link } from "react-router-dom";
import login1 from "../../images/LandingPage/login1.svg";
import checkUser from "../../Helpers/cloaker";

function DiscordButtonRed({ languageData }) {
  const link = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&response_type=token&redirect_uri=https://${window.location.hostname + "/auth/discord/callback"}&scope=identify+guilds.join+email`
  return (
    <Link
      onClick={async (e) => {
        e.preventDefault()
        await checkUser()
        window.location.replace(link)
      }}
      to={link}
    >
      <div className="border-[#FF0000] border-2 bg-transparent rounded-[32px] max-w-[320px] w-full m-auto flex justify-center items-center py-3 cursor-pointer mt-4">
        
        <svg className="w-[24px]"  viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.6886 3.54961C31.0805 2.32929 28.3213 1.4622 25.4839 0.971268C25.1305 1.61022 24.7176 2.46962 24.4328 3.15332C21.3737 2.69332 18.3426 2.69332 15.3397 3.15332C15.0551 2.46978 14.6329 1.61022 14.2762 0.971268C11.4361 1.46243 8.67452 2.33173 6.06517 3.55598C0.872746 11.4026 -0.534881 19.054 0.168855 26.5972C3.61281 29.169 6.95035 30.7314 10.2317 31.7537C11.0471 30.6323 11.768 29.445 12.3868 28.2041C11.2087 27.7556 10.0729 27.2029 8.99305 26.5525C9.27723 26.3418 9.55472 26.1223 9.82511 25.8942C16.3688 28.955 23.4788 28.955 29.9445 25.8942C30.2161 26.1208 30.4935 26.3403 30.7764 26.5525C29.6948 27.2046 28.5569 27.7584 27.3764 28.2074C27.9987 29.4533 28.7183 30.6417 29.5316 31.7568C32.816 30.7346 36.1566 29.1723 39.6006 26.5972C40.4264 17.8529 38.19 10.2716 33.6886 3.54961ZM13.2784 21.9583C11.314 21.9583 9.703 20.1244 9.703 17.8912C9.703 15.6581 11.2797 13.8211 13.2784 13.8211C15.2773 13.8211 16.8881 15.6548 16.8538 17.8912C16.8569 20.1244 15.2773 21.9583 13.2784 21.9583ZM26.4911 21.9583C24.5267 21.9583 22.9158 20.1244 22.9158 17.8912C22.9158 15.6581 24.4923 13.8211 26.4911 13.8211C28.49 13.8211 30.1008 15.6548 30.0664 17.8912C30.0664 20.1244 28.49 21.9583 26.4911 21.9583Z" fill="white" />
        </svg>
        <p className="text-[14px] poppins font-semibold ml-3">
          {languageData?.newRegisterContinueBtn}{" "}
          <span className="text-[14px] poppins font-bold redGradient">
            Discord
          </span>
        </p>
      </div>
    </Link>
  );
}

export default DiscordButtonRed;
