import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation } from 'swiper/modules';
import avatar1 from "./avatar1.png";
import avatar2 from "./avatar2.png";
import avatar3 from "./avatar3.png";
import avatar4 from "./avatar4.png";
import avatar5 from "./avatar5.png";

function ReviewsSlider({ languageData }) {
    const reviewsUser = [
        {
            name: "Cahyra",
            review: languageData?.Rewiew1Desc,
            image: avatar1
        },
        {
            name: "Shake",
            review: languageData?.Rewiew2Desc,
            image: avatar3
        },
        {
            name: "Hyo",
            review: languageData?.Rewiew3Desc,
            image: avatar2
        },
        {
            name: "Tio Feh",
            review: languageData?.Rewiew4Desc,
            image: avatar4
        },
        {
            name: "Gray",
            review: languageData?.Rewiew5Desc,
            image: avatar5
        },
    ];
    const slides = reviewsUser.map((index) => (
        <SwiperSlide key={index}>

            <div className="flex justify-center ">
                <div className=" w-[400px] sm:w-[430px] h-[250px] sm:h-[285px] border border-[#A30023] rounded-[8px] p-4 bg-[#1E1E1E]">
                    <div className="flex items-center">
                        <img className="w-[34px] sm:w-[44px] rounded-full mr-2" src={index.image} alt="avatar" />
                        <p className="saira text-[16px] sm:text-[24px] font-semibold">{index.name}</p>
                    </div>
                    <p className="saira text-[12px] sm:text-[16px] font-medium mt-3 min-h-[90px] sm:min-h-[110px]">"{index.review}"</p>
                   
                    <svg className="mt-12" width="146" height="25" viewBox="0 0 146 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.12142 8.12389L12.4288 0.618011L16.4995 8.12389L24.98 9.06212L18.5348 15.2886L20.2309 23.818L12.4288 20.1504L5.05073 23.818L6.66204 15.2886L0.980042 9.06212L9.12142 8.12389Z" fill="#FAFF00" stroke="white" stroke-linejoin="round" />
                        <path d="M39.1214 8.12389L42.4288 0.618011L46.4995 8.12389L54.98 9.06212L48.5348 15.2886L50.2309 23.818L42.4288 20.1504L35.0507 23.818L36.662 15.2886L30.98 9.06212L39.1214 8.12389Z" fill="#FAFF00" stroke="white" stroke-linejoin="round" />
                        <path d="M69.1214 8.12389L72.4288 0.618011L76.4995 8.12389L84.98 9.06212L78.5348 15.2886L80.2309 23.818L72.4288 20.1504L65.0507 23.818L66.662 15.2886L60.98 9.06212L69.1214 8.12389Z" fill="#FAFF00" stroke="white" stroke-linejoin="round" />
                        <path d="M99.1214 8.12389L102.429 0.618011L106.499 8.12389L114.98 9.06212L108.535 15.2886L110.231 23.818L102.429 20.1504L95.0507 23.818L96.662 15.2886L90.98 9.06212L99.1214 8.12389Z" fill="#FAFF00" stroke="white" stroke-linejoin="round" />
                        <path d="M129.121 8.12389L132.429 0.618011L136.499 8.12389L144.98 9.06212L138.535 15.2886L140.231 23.818L132.429 20.1504L125.051 23.818L126.662 15.2886L120.98 9.06212L129.121 8.12389Z" fill="#FAFF00" stroke="white" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </SwiperSlide>
    ));
    return (
        <div className="w-full m-auto max-w-[1400px] flex items-center">
            <svg className="buttonPrev w-[30px] cursor-pointer mr-1 hidden md:block" xmlns="http://www.w3.org/2000/svg" width="17" height="30" viewBox="0 0 17 30" fill="none">
                <path d="M0.390123 16.223L14.3226 29.498C14.5551 29.7196 14.8639 29.8432 15.1851 29.8432C15.5063 29.8432 15.8152 29.7196 16.0476 29.498L16.0626 29.483C16.1757 29.3756 16.2658 29.2462 16.3273 29.1029C16.3888 28.9596 16.4206 28.8052 16.4206 28.6492C16.4206 28.4933 16.3888 28.3389 16.3273 28.1956C16.2658 28.0522 16.1757 27.9229 16.0626 27.8155L2.94262 15.3155L16.0626 2.82049C16.1757 2.71306 16.2658 2.58374 16.3273 2.44041C16.3888 2.29708 16.4206 2.14273 16.4206 1.98674C16.4206 1.83076 16.3888 1.67641 16.3273 1.53307C16.2658 1.38974 16.1757 1.26042 16.0626 1.15299L16.0476 1.13799C15.8152 0.916379 15.5063 0.792751 15.1851 0.792751C14.8639 0.792751 14.5551 0.916379 14.3226 1.13799L0.390123 14.413C0.267592 14.5297 0.170044 14.6701 0.103392 14.8257C0.0367405 14.9813 0.0023721 15.1488 0.00237211 15.318C0.00237211 15.4872 0.0367406 15.6547 0.103392 15.8103C0.170044 15.9658 0.267592 16.1062 0.390123 16.223Z" fill="white" />
            </svg>
            <Swiper
                className=' relative z-10'
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                centeredSlides={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1.2,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}

                loop={true}
                navigation={{
                    prevEl: '.buttonPrev',
                    nextEl: '.buttonNext',
                }}
                autoplay={{
                    delay: 5000,
                }}

            >
                {slides}
            </Swiper>
            <svg className="buttonNext w-[30px] cursor-pointer ml-1 hidden md:block" xmlns="http://www.w3.org/2000/svg" width="17" height="30" viewBox="0 0 17 30" fill="none">
                <path d="M16.6098 14.413L2.67731 1.13799C2.44484 0.916382 2.13598 0.792755 1.81481 0.792755C1.49363 0.792755 1.18478 0.916382 0.952309 1.13799L0.937307 1.15299C0.824221 1.26043 0.734171 1.38974 0.672636 1.53308C0.611103 1.67641 0.579371 1.83076 0.579371 1.98674C0.579371 2.14273 0.611103 2.29708 0.672636 2.44041C0.734171 2.58374 0.824221 2.71306 0.937307 2.82049L14.0573 15.3205L0.937308 27.8155C0.824221 27.9229 0.734171 28.0522 0.672636 28.1956C0.611103 28.3389 0.579371 28.4933 0.579371 28.6492C0.579371 28.8052 0.611103 28.9596 0.672636 29.1029C0.734171 29.2462 0.824221 29.3756 0.937308 29.483L0.952309 29.498C1.18478 29.7196 1.49363 29.8432 1.81481 29.8432C2.13598 29.8432 2.44484 29.7196 2.67731 29.498L16.6098 16.223C16.7323 16.1063 16.8299 15.9658 16.8965 15.8103C16.9632 15.6547 16.9976 15.4872 16.9976 15.318C16.9976 15.1488 16.9632 14.9813 16.8965 14.8257C16.8299 14.6701 16.7323 14.5297 16.6098 14.413Z" fill="white" />
            </svg>
        </div>
    )
}

export default ReviewsSlider
