import React from "react";

function LandingLoginBtnRed({ icon, text, span }) {
  return (
    <div className="border-[#FF0000] border-2 bg-transparent rounded-[32px] max-w-[320px] w-full m-auto flex justify-center items-center py-3 cursor-pointer mt-4">


      <svg className="w-[24px]" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2614 17.5663L19.8888 21.414L25.3134 17.6716L37.4729 29.6682C37.1528 29.7716 36.8108 29.8272 36.4548 29.8272H3.3148C2.87734 29.8272 2.45975 29.7417 2.07598 29.5886L14.2614 17.5663ZM39.7696 7.70736V26.5124C39.7696 27.0036 39.6623 27.4689 39.4714 27.8885L27.5524 16.1286L39.7696 7.70736ZM1.87634e-06 7.81275L12.0144 16.0292L0.210781 27.6777C0.0709823 27.3051 -0.000422022 26.9104 1.87634e-06 26.5124V7.81275ZM36.4548 0C38.2842 0 39.7696 1.48341 39.7696 3.3148V4.48005L19.8808 18.1906L1.87634e-06 4.59339V3.3148C1.87634e-06 1.4854 1.48341 0 3.3148 0H36.4548Z" fill="white" />
      </svg>

      <p className="text-[14px] poppins font-semibold ml-3">
        {text}{" "}
        <span className="text-[14px] poppins font-bold redGradient">
          {span}
        </span>
      </p>
    </div>
  );
}

export default LandingLoginBtnRed;
