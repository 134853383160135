import { create } from "zustand";
import gamesAPI from "../Requests/games";

export const competitionStore = create((set) => ({
    mode: 'default',
    changeMode: (mode) => set({ mode }),

    doubleStarted: false,
    doubleWin: false,
    clearDouble: () => {
        set({ doubleStarted: false, doubleWin: false });
    },
    setDoubleWin: async () => {
        let isWinner;

        try {
            const response = await gamesAPI.runDoubleGame();
            console.log(response.data);
            if (response.data.isWin === true) {
                isWinner = true;
            } else {
                isWinner = false;
            }

        } catch (error) {
            isWinner = false;
        }

        set({ doubleWin: isWinner, doubleStarted: true });

    },

    wheelStarted: false,
    wheelWin: false,
    wheelMultiplier: null,
    wheelLimit: false,
    clearWheelLimit: () => {
        set({ wheelLimit: false });
    },
    clearWheel: () => {
        set({ wheelStarted: false, wheelWin: false });
    },
    setWheelWin: async () => {

        let winMultiplier;

        try {
            const response = await gamesAPI.runSpinGame();
            console.log(response.data);
            winMultiplier = response.data.multiplier;
            set({ wheelMultiplier: winMultiplier, wheelStarted: true });
        } catch (error) {
            set({ wheelLimit: true });

            console.log('Wheel Game error: ', error);
        }
    },
}));