import React, { useState, useEffect } from "react";
import bgDesk from "../images/NewDesign2/MenuBottom/bg-desk2.svg";
import bgMob from "../images/NewDesign2/MenuBottom/bg-mob2.svg";
import HoverBtn from "./Buttons/HoverBtn";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
import moengage from "@moengage/web-sdk";
import moment from "moment";
import { withdrawPartner } from "../Requests/withdraw";
import mixpanelTrack from "../Helpers/mixpanel";

function MainMenuForHomepage({
  languageData,
  activePageIndex,
  PartnerURL,
  user,
  setWithdrawModal,
  setWithdrawComplete,
  setWitdrawError,
}) {
  const { language } = useLanguage();
  const [lastTask, setLastTask] = useState(null);

  useEffect(() => {
    if (user === null) return setLastTask(0);

    let tasks = JSON.parse(user?.completed_tasks);
    //let tasks = [1, 2, 3];
    if (tasks === null) return setLastTask(0);
    let lastTask = tasks[tasks.length - 1];
    setLastTask(lastTask);
  }, [user]);

  const handleEvent = (e) => {
    mixpanelTrack("Homepage_button_click", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });

    moengage.track_event("Homepage_button_click", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });
  };

  const [timeLeft, setTimeLeft] = useState(moment.duration(0));

  useEffect(() => {
    const registerTime = user?.timestamp;

    if (!registerTime) {
      setTimeLeft(moment.duration(0));
      return;
    }

    const now = moment();
    const then = moment(registerTime * 1000);
    const diff = now.diff(then, "seconds");

    if (diff >= 420) {
      setTimeLeft(moment.duration(0));
      return;
    }

    const initialRemainingTime = moment.duration(420 - diff, "seconds");
    setTimeLeft(initialRemainingTime);

    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev.asSeconds() <= 0) {
          clearInterval(interval);
          return moment.duration(0);
        }

        return moment.duration(prev.asSeconds() - 1, "seconds");
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [user]);

  const handleWithdraw = async (e) => {
    try {
      const res = await withdrawPartner();

      if (res.data.status === "success") {
        setWithdrawComplete(true);
        setWitdrawError(false);
      } else {
        setWitdrawError(true);
        alert(res.data.message);
      }
    } catch (error) {
      setWitdrawError(true);
    }
  };

  return (
    <div className={`w-full absolute bottom-0 z-30 `}>
      <img
        className="w-full object-cover sm:block hidden pointer-events-none select-none scale-[1.02] mb-[-2px]"
        src={bgDesk}
        alt="bgDesk"
      />
      <img
        className="w-full object-cover block sm:hidden pointer-events-none select-none scale-[1.02]"
        src={bgMob}
        alt="bgDesk"
      />
      <div
        className="bg-bottomMenu bg-no-repeat bg-cover w-full justify-center flex pb-2 2xl:pb-4"
        style={{ transition: "all 0.2s ease-in-out" }}
      >
        <div className=" max-w-[1000px] w-full px-2">
          {activePageIndex === 0 &&
            (lastTask !== 3 ? (
              <div className="mb-6 sm:my-6 sm:mb-8">
                <p className="text-center text-[15px] sm:text-[32px] font-bold saira mb-5 sm:mt-0">
                  <span className="text-center text-[20px] sm:text-[32px] font-bold saira">
                    {language === "es" && "¡"}
                    {timeLeft.minutes().toString().padStart(2, "0")}:
                    {timeLeft.seconds().toString().padStart(2, "0")}
                  </span>{" "}
                  {languageData?.homepageLiveMenuTitle1}
                </p>
                <HoverBtn
                  disabled={
                    moment
                      .duration(timeLeft.asSeconds(), "seconds")
                      .asSeconds() < 1
                  }
                  onClick={(e) => {
                    handleEvent(e);
                    window.open(PartnerURL);
                  }}
                  text={languageData?.homepageLiveMenuBtn3}
                  mAuto={"m-auto !max-w-[440px]"}
                  bgColor={"!bg-[#A30023]"}
                  heightBtn={"!h-[55px]"}
                />
              </div>
            ) : (
              <div className="mb-4 mt-6">
                <HoverBtn
                  onClick={(e) => handleWithdraw()}
                  text={languageData?.homepageLiveMenuBtn2}
                  mAuto={"m-auto"}
                  bgColor={"!bg-[#A30023]"}
                  heightBtn={"!h-[70px]"}
                />
                <p
                  onClick={() => setWithdrawModal(true)}
                  className="cursor-pointer text-center text-[16px] sm:text-[28px] font-bold saira mt-2 underline"
                >
                  {languageData?.homepageLiveMenuTitle2}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MainMenuForHomepage;
