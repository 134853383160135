import React, { useEffect } from "react";
import { useDesign } from "../Helpers/Design/DesignContext";

function LeaderboardUser({ languageData, user, index, maskEmail }) {
  const { design } = useDesign();

  function maskEmail(email) {
    let [username, domain] = email.split("@");
    let maskedUsername = username.slice(0, 3);
    let maskedDomain = maskedUsername + "******@*****.***";
    return maskedDomain;
  }

  return (
    <div className=" ">
      <div
        className={`bg-[#EAEAEA] ${design === "0" ? "bg-opacity-40 " : "bg-opacity-20 "} mt-2 flex items-center justify-between rounded-[16px]  pr-6 text-center backdrop-blur-sm border-2 border-[#8B007D]`}
      >

        <div className="flex items-center ">
          <p
            className={`saira text-[16px] font-bold px-4`}
          >
            #{index + 4}
          </p>
          <p
            className={`saira gradient-homepageBtn h-[30px] w-[30px] rounded-full pt-2 text-[16px] font-bold text-[#1E1E1E] my-[6px]`}
          >
            
          </p>
          <p className="saira ml-[15px] w-[120px] truncate text-center text-[12px] font-medium md:text-[14px]">
            {maskEmail(user?.email)}
          </p>
        </div>
        
        <div className="leading-[14px] flex items-center">
          <p
            className={`saira text-[12px] font-semibold md:text-[14px] `}
          >
            {Number(user?.points).toFixed(0)} {languageData?.leaderboardsPoints}
          </p>
          
        </div>
      </div>
    </div>
  );
}

export default LeaderboardUser;
