import React, { useState } from "react";
import transaction from "../../../images/CyberSaysPage/TransactionLogo.png";
import { useDesign } from "../../../Helpers/Design/DesignContext";
import userAPI from "../../../Requests/user";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Loading";

function Earned({
  setOpen,
  languageData,
  user,
  setTransactionsModal,
  userCountry,
}) {
  const { design } = useDesign();
  const [transactionsData, setTransactionsData] = useState(null);

  const { isLoading } = useQuery({
    queryKey: ["transactions"],
    queryFn: async () => {
      const res = await userAPI.getTransactions();
      setTransactionsData(res.data.transactions[0]);
      return res;
    },
  });

  const getMarkup = (type, datetime, amount, id, status, competition_rank) => {
    switch (type) {
      case "doubling_referral":
        return (
          <div className="mt-4 flex items-center justify-between ">
            <div className="flex items-center">
              <img
                className="mr-2 h-[32px] w-[32px] md:mr-4"
                src={transaction}
                alt="transaction"
              />
              <div className="w-[200px] md:w-[400px]">
                <p className="saira mb-2 text-[12px] font-semibold  leading-4 md:text-[14px]">
                  {languageData?.ticketsSection1Left}
                </p>
                <p className="saira text-[12px] font-normal md:text-[14px] ">
                  {moment
                    .unix(Number(datetime))
                    .format("DD MMMM, YYYY, hh:mm A")}
                </p>
              </div>
            </div>
            <div className="w-[100px] leading-[18px]">
              <p className="saira mb-1 text-right text-[20px] font-semibold text-[#93CC8E]">
                +{userCountry === "BR" || userCountry === "UA" ? "R$" : "$"}
                {amount}
              </p>
              <p className="saira text-right text-[12px]  font-normal">
                {languageData?.transactionsSection1Right}
              </p>
            </div>
          </div>
        );
      case "doubling":
        return (
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                className="mr-2 h-[32px]  w-[32px] md:mr-4"
                src={transaction}
                alt="transaction"
              />
              <div className="w-[200px] md:w-[400px]">
                <p className="saira w-[200px] text-[12px] font-semibold leading-4 md:w-[unset] md:text-[14px]">
                  {languageData?.transactionsSection1Left}
                </p>
                <p className="saira text-[12px] font-normal md:text-[14px]">
                  {moment
                    .unix(Number(datetime))
                    .format("DD MMMM, YYYY, hh:mm A")}
                </p>
              </div>
            </div>
            <div className="w-[100px] leading-[18px]">
              <p className="saira mb-1 text-right text-[20px] font-semibold text-[#93CC8E]">
                +{userCountry === "BR" || userCountry === "UA" ? "R$" : "$"}
                {amount}
              </p>
              <p className="saira text-right text-[12px] font-normal">
                {languageData?.transactionsSection2Right}
              </p>
            </div>
          </div>
        );
      case "withdraw":
      case "paxum_withdraw":
      case "visa_withdraw":
      case "xoxoday_withdraw":
      case "imlive_withdraw":
      case "credits_withdraw":
      case "points_withdraw":
        return (
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                className="mr-2 h-[32px]  w-[32px] md:mr-4"
                src={transaction}
                alt="transaction"
              />
              <div className="w-[200px] md:w-[320px]">
                <p className="saira w-[130px] text-[10px] font-semibold leading-3 md:w-[unset] md:text-[14px] lg:leading-4">
                  {languageData?.transactionPendingTitle + " "}
                  <span className="saira">
                    {type === "paxum_withdraw"
                      ? "PIX"
                      : type === "visa_withdraw"
                        ? "Visa"
                        : type === "xoxoday_withdraw"
                          ? "Xoxoday"
                          : type === "imlive_withdraw" ||
                              type === "credits_withdraw"
                            ? "Partner"
                            : "PIX"}{" "}
                  </span>
                  {" " + status === 1 || status === 2
                    ? languageData?.transactionPendingSpan
                    : status === 3
                      ? languageData?.transactionApprovedSpan
                      : languageData?.transactionRejectedSpan}
                </p>
                <p className="saira text-[10px] font-light text-[#D7D7D7] md:text-[14px]">
                  {moment
                    .unix(Number(datetime))
                    .format("DD MMMM, YYYY, hh:mm A")}
                </p>
                <p className="saira text-[10px] font-light leading-3 text-[#D7D7D7] md:text-[14px]">
                  {languageData?.transactionID} {id}
                </p>
              </div>
            </div>
            <div className="w-[150px] leading-[18px]">
              <p
                className={`saira mb-1 text-right text-[20px] font-semibold ${status === 1 || status === 2 ? "text-[#FF9636]" : status === 3 ? "text-[#50EA56]" : "text-[#FF3C3C]"}`}
              >
                -{userCountry === "BR" || userCountry === "UA" ? "R$" : "$"}
                {amount}
              </p>
              <p
                className={`saira text-right text-[12px] font-normal ${status === 1 || status === 2 ? "text-[#FF9636]" : status === 3 ? "text-[#50EA56]" : "text-[#FF3C3C]"}`}
              >
                {languageData?.transactionWithdraw}
              </p>
            </div>
          </div>
        );
      case "competition":
        return (
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                className="mr-2 h-[32px]  w-[32px] md:mr-4"
                src={transaction}
                alt="transaction"
              />
              <div className="w-[200px] md:w-[400px]">
                <p className="saira  w-[200px] text-[12px] font-semibold leading-4 md:w-[unset] md:text-[14px]">
                  {languageData?.TransactionCompetitionWinner}
                  {competition_rank}{" "}
                  {languageData?.TransactionCompetitionWinner2}
                </p>
                <p className="saira  text-[12px] font-normal md:text-[14px]">
                  {moment
                    .unix(Number(datetime))
                    .format("DD MMMM, YYYY, hh:mm A")}
                </p>
              </div>
            </div>
            <div className="w-[100px] leading-[18px]">
              <p className="saira mb-1 text-right text-[20px] font-semibold text-[#93CC8E]">
                +{userCountry === "BR" || userCountry === "UA" ? "R$" : "$"}
                {amount}
              </p>
              <p className="saira text-right text-[12px]  font-normal">
                {languageData?.transactionsSection2Right}
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <div onClick={(e) => setOpen(false)} className="items-center">
      <div
        onClick={(e) => e.stopPropagation()}
        className={`relative m-auto w-full max-w-[600px] rounded-[12px] border-[1px] bg-[#0A1225B2] bg-opacity-70 p-2 backdrop-blur-md lg:px-4 border-[#8B007D]`}
      >
        <div className="flex justify-end md:mt-4 ">
          <svg
            onClick={(e) => setOpen(false)}
            className="h-[24px] w-[24px] cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20 20L4 4M20 4L4 20"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <p className=" text-center text-[18px] font-semibold lg:text-[24px]">
          {userCountry === "BR" || userCountry === "UA"
            ? languageData?.earnedModalTitleBR
            : languageData?.earnedModalTitle}
        </p>
        <div className="m-auto h-[250px] w-full max-w-[345px] overflow-scroll md:max-w-[600px]">
          {isLoading === true || transactionsData === null ? (
            <div className="flex h-full items-center justify-center ">
              <Loading />
            </div>
          ) : transactionsData?.length > 0 ? (
            transactionsData?.reverse().map((transaction, index) => {
              return getMarkup(
                transaction.type,
                transaction.datetime,
                userCountry === "BR" || userCountry === "UA"
                  ? transaction.amount * 5
                  : transaction.amount,
                transaction.id,
                transaction.withdraw_status,
                transaction.competition_rank
              );
            })
          ) : (
            <div className="flex h-[200px] items-center justify-center">
              <p className="saira text-[16px] font-semibold text-center my-5">
                {languageData?.newNoTransaction}
              </p>
            </div>
          )}
        </div>
        <div className=" flex justify-center">
          <p
            onClick={(e) => {
              setTransactionsModal(true);
              setOpen(false);
            }}
            className="text-[#FF00E5] mt-2 cursor-pointer text-center text-[12px] font-semibold underline"
          >
            {languageData?.earnedModalLink}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Earned;
