import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthError from "./Auth/AuthError";
import { jwtDecode } from "jwt-decode";
import moengage from "@moengage/web-sdk";

function AuthToken() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  let urlToken = window.location.href.split("/")[4];
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    async function checkToken() {
      if (!urlToken) return setError(true);

      const tokenParts = urlToken.split("?");
      const token = tokenParts[0];

      try {
        const decoded = jwtDecode(token);
        if (decoded.userId) {
          ;
          window.localStorage.setItem("token", token);
          await moengage.add_unique_user_id(decoded.userId);

          let fbclid = params.get("fbclid");
          if (fbclid) {
            let _fbc = `fb.1.${new Date().getTime()}.${fbclid}`
            document.cookie = `_fbc=${_fbc}; path=/; max-age=31536000; SameSite=None; Secure`;
          }
          navigate(`/`);
        }
      } catch (error) {
        navigate(`/`);
      }

    }
    checkToken();
  }, [urlToken]);

  return <AuthError error={error} />;
}

export default AuthToken;
