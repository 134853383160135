import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import mixpanelTrack from "../../Helpers/mixpanel";
import moengage from "@moengage/web-sdk";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";
import HomePageHeader from "../../Components/HomePage/HomePageHeader";

function Refferals({
  user,
  languageData,
  dataMessage,
  setOpenMassege,
  setOpenAvatar,
  selectedMessage,
  setSelectedMassege,
  copyToMessage,
  message,
  uploadedPhotos,
  setSelectedImage,
  setInfoOffer,
  setEarnedModal,
  setTicketShortModal,
  setPointsModal,
  userCountry,
  scrollToPage,
}) {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  let swiperRef;

  const { language } = useLanguage();

  const [blobImage, setBlobImage] = useState(null);
  const [selectedGif, setSelectedGif] = useState(0);
  const [toolTip, setToolTip] = useState(false);

  const [isLinkShared, setIsLinkShared] = useState(false);

  useEffect(() => {
    getBlob();
    async function getBlob() {
      const blob = await fetch(uploadedPhotos[selectedGif]).then((r) =>
        r.blob()
      );
      setBlobImage(blob);
    }
  }, [selectedGif]);

  useEffect(() => {
    console.log(selectedGif);
  }, [selectedGif]);

  const shareRefferalLink = () => {
    mixpanelTrack("referral_share", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      page_name: "Refferals",
    });
    moengage.track_event("referral_share", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      page_name: "Refferals",
    });

    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          //text: dataMessage[selectedMessage]?.desc + '. Image Link: ' + 'https://' + window.location.host + uploadedPhotos[selectedGif],
          text:
            dataMessage[selectedMessage]?.desc +
            " Referral link: " +
            "https://" +
            window.location.host +
            `/share/${language}-${selectedGif + 1}/index.html?ref=` +
            user?.referral_code,
        })
        .then(() => console.log("Successful share! 🎉"));
    } else {
      window.navigator.clipboard.writeText(
        window.location.host + "/" + user?.referral_code
      );
      setIsLinkShared(true);

      setTimeout(() => {
        setIsLinkShared(false);
      }, 3000);
    }
  };

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handlePhotoClick = (index, e) => {
    e.preventDefault();
    setSelectedImage(uploadedPhotos[index]);
  };

  const [photoLoading, setPhotoLoading] = useState(false);

  const downloadPhoto = async (avatar) => {
    if (photoLoading) return;
    setPhotoLoading(true);
    try {
      const response = await fetch(uploadedPhotos[avatar]);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "photo.jpg");
      link.click();
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading photo:", error);
    }
    console.log(uploadedPhotos[avatar]);
    setPhotoLoading(false);
  };

  const [linkShareCopied] = useState(false);

  const copyShareLink = () => {
    mixpanelTrack("referral_link", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      page_name: "Refferals",
    });
    moengage.track_event("referral_link", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      page_name: "Refferals",
    });
    window.navigator.clipboard.writeText(
      window.location.host + "/" + user?.referral_code
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateSlidesPerView = () => {
    const { width, height } = dimensions;
    if (height < 800 && width > 1200) {
      return 4;
    }
    if (width < 1200) {
      return 2.4;
    }
    return 4;
  };

  return (
    <div
      className={` h-screen w-screen bg-homepageMob sm:bg-homepageDesk  relative z-10 bg-cover bg-center bg-no-repeat`}
    >
      <div className="m-auto  w-full max-w-[990px] 2xl:max-w-[1170px] px-2 pt-2 sm:pt-[20px] 2xl:pt-[40px] ">
        <div className="max-w-[1000px] m-auto w-full">
          <HomePageHeader
            scrollToPage={scrollToPage}
            setPointsModal={setPointsModal}
            setTicketShortModal={setTicketShortModal}
            setEarnedModal={setEarnedModal}
            user={user}
            userCountry={userCountry}
            languageData={languageData}
          />
        </div>
        <div className="2xl:flex 3xl:items-center 2xl:h-[65vh] ">
          <div className="max-w-[1170px] w-full">
            <p className={`text-center  saira font-bold mt-1 md:mb-0 ${language !== "en" ? "text-[13px] sm:text-[18px] 2xl:text-[20px]" : "text-[13px] sm:text-[20px]"}`}>
              {languageData?.refferalsTitle}
            </p>
            <p className="text-center text-[11px] sm:text-[16px] saira font-normal ">
              {languageData?.refferalsSubtitle}
            </p>
            <p
              className={`saira flex cursor-pointer items-center justify-center text-[11px] font-medium underline sm:text-[14px] text-[#FF00E5]`}
              onClick={(e) => {
                setInfoOffer(true);
                mixpanelTrack("learnMore", {
                  distinct_id: user?.id,
                  is_referred: user?.referral_id ? "Yes" : "No",
                  vegas_tickets: user?.raffle_tickets,
                  points: user?.points,
                  user_id: user?.id,
                  USD_earned: user?.allTimeEarned,
                  page_name: "Refferals",
                });
                moengage.track_event("learnMore", {
                  distinct_id: user?.id,
                  is_referred: user?.referral_id ? "Yes" : "No",
                  vegas_tickets: user?.raffle_tickets,
                  points: user?.points,
                  user_id: user?.id,
                  USD_earned: user?.allTimeEarned,
                  page_name: "Refferals",
                });
              }}
            >
              {languageData?.refferalsLink1}
            </p>
            <div className="relative flex flex-col-reverse max-w-[900px] 3xl:max-w-[1170px] m-auto">
              <div className="mt-2 sm:mt-[60px] 2xl:mt-[70px] iphone:pt-4 sm:pt-0">
                <div className="mb-[-110px] 2xl:mb-[-120px] hidden w-full pr-2  lg:block">
                  <div className=" mx-10 my-4 flex  justify-between">
                    <svg
                      className="buttonPrevGif mr-3 w-[44px] cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 22L7 12L17 2"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <svg
                      className="buttonNextGif  w-[44px] cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M7 2L17 12L7 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className=" m-auto w-full sm:max-w-[700px] 3xl:max-w-[900px] my-2">
                  <Swiper
                    style={{ zIndex: 0 }}
                    onSwiper={(swiper) => {
                      swiperRef = swiper;
                    }}
                    modules={[Navigation]}
                    navigation={{
                      prevEl: ".buttonPrevGif",
                      nextEl: ".buttonNextGif",
                    }}
                    loop={true}
                    slidesPerView={calculateSlidesPerView()}
                    spaceBetween={10}
                  >
                    {uploadedPhotos.length > 0 ? (
                      uploadedPhotos.map((item, index) => (
                        <SwiperSlide>
                          <div className="flex" key={index}>
                            <img
                              onClick={(e) => {
                                setSelectedGif(index);

                                handlePhotoClick(index, e);
                              }}
                              className={`${selectedGif === index && `border-[1px] !border-[#FF00E5] relative opacity-[1]`}  h-[150px]  w-[150px] cursor-pointer  border-[2px] border-white object-cover opacity-[0.5] sm:h-[170px] 3xl:w-[220px] sm:w-[170px] 3xl:h-[220px]`}
                              src={item}
                              alt="gif1"
                            />
                            <div
                              className="bg-[#38363699] bg-opacity-10 backdrop-blur-sm rounded-full w-[40px] h-[40px] cursor-pointer border-[1px] border-[#FF00E5] absolute left-2 top-2 flex items-center justify-center shadow-lg"
                              onClick={(e) => {
                                mixpanelTrack("image_download", {
                                  distinct_id: user?.id,
                                  is_referred: user?.referral_id ? "Yes" : "No",
                                  vegas_tickets: user?.raffle_tickets,
                                  points: user?.points,
                                  user_id: user?.id,
                                  USD_earned: user?.allTimeEarned,
                                  page_name: "Refferals",
                                });
                                moengage.track_event("image_download", {
                                  distinct_id: user?.id,
                                  is_referred: user?.referral_id ? "Yes" : "No",
                                  vegas_tickets: user?.raffle_tickets,
                                  points: user?.points,
                                  user_id: user?.id,
                                  USD_earned: user?.allTimeEarned,
                                  page_name: "Refferals",
                                });
                                downloadPhoto(index);
                              }}
                            >
                              <svg
                                className=" "
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 23 24"
                                fill="none"
                              >
                                <path
                                  d="M4.25 17V19C4.25 19.5304 4.44315 20.0391 4.78697 20.4142C5.13079 20.7893 5.5971 21 6.08333 21H17.0833C17.5696 21 18.0359 20.7893 18.3797 20.4142C18.7235 20.0391 18.9167 19.5304 18.9167 19V17M7 11L11.5833 16M11.5833 16L16.1667 11M11.5833 16V4"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <p className="saira mb-8 text-center text-[24px] font-medium se:mt-4 md:mt-12">
                        {languageData?.refferalsSome}{" "}
                      </p>
                    )}
                  </Swiper>
                </div>
              </div>
              <div className="">
                <div className=" hidden w-full pr-2 lg:block">
                  <div className=" mx-10 my-2 mb-[-25px] flex justify-between">
                    <svg
                      className="buttonPrevMessage mr-3 w-[44px] cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 22L7 12L17 2"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <svg
                      className="buttonNextMessage  w-[44px] cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M7 2L17 12L7 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className=" m-auto w-full sm:max-w-[700px] 3xl:max-w-[900px] iphone:pt-4 sm:pt-0 ">
                  <Swiper
                    style={{ zIndex: 0 }}
                    onSwiper={(swiper) => {
                      swiperRef = swiper;
                    }}
                    modules={[Navigation]}
                    navigation={{
                      prevEl: ".buttonPrevMessage",
                      nextEl: ".buttonNextMessage",
                    }}
                    onSlideChange={(swiper) => {
                      const realIndex = swiper.realIndex + 0;
                      setSelectedMassege(realIndex);
                    }}
                    loop={true}
                    breakpoints={{
                      0: {
                        slidesPerView: 1.4,
                        spaceBetween: 20,
                      },
                      650: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                      },
                      940: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                      },
                    }}
                  >
                    {dataMessage.map((item, index) => (
                      <SwiperSlide>
                        <div
                          key={index}
                          onClick={(e) => setSelectedMassege(index)}
                          className={`${selectedMessage === index && ` border-[1px] !border-[#FF00E5]`} flex w-full   sm:w-[170px] 3xl:w-[220px] cursor-pointer items-center  justify-between border-[1px] border-[#555555] se:px-2 iphone:px-2 py-[7px] lg:max-w-[220px] rounded-[50px] bg-[#38363699] bg-opacity-10 backdrop-blur-sm`}
                        >
                          <p className="saira w-[170px] truncate text-[14px] font-normal lg:w-[160px]">
                            {item.desc}
                          </p>
                          <svg
                            onClick={(e) => copyToMessage(message)}
                            className="ml-1 cursor-pointer"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 9.667C7 8.95967 7.28099 8.28131 7.78115 7.78115C8.28131 7.28099 8.95967 7 9.667 7H18.333C18.6832 7 19.03 7.06898 19.3536 7.20301C19.6772 7.33704 19.9712 7.53349 20.2189 7.78115C20.4665 8.0288 20.663 8.32281 20.797 8.64638C20.931 8.96996 21 9.31676 21 9.667V18.333C21 18.6832 20.931 19.03 20.797 19.3536C20.663 19.6772 20.4665 19.9712 20.2189 20.2189C19.9712 20.4665 19.6772 20.663 19.3536 20.797C19.03 20.931 18.6832 21 18.333 21H9.667C9.31676 21 8.96996 20.931 8.64638 20.797C8.32281 20.663 8.0288 20.4665 7.78115 20.2189C7.53349 19.9712 7.33704 19.6772 7.20301 19.3536C7.06898 19.03 7 18.6832 7 18.333V9.667Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.012 16.737C3.70534 16.5622 3.45027 16.3095 3.27258 16.0045C3.09488 15.6995 3.00085 15.353 3 15V5C3 3.9 3.9 3 5 3H15C15.75 3 16.158 3.385 16.5 4"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <svg
                            onClick={() => {
                              setSelectedMassege(index);
                              mixpanelTrack("full_text", {
                                distinct_id: user?.id,
                                is_referred: user?.referral_id ? "Yes" : "No",
                                vegas_tickets: user?.raffle_tickets,
                                points: user?.points,
                                user_id: user?.id,
                                USD_earned: user?.allTimeEarned,
                                page_name: "Refferals",
                              });
                              moengage.track_event("full_text", {
                                distinct_id: user?.id,
                                is_referred: user?.referral_id ? "Yes" : "No",
                                vegas_tickets: user?.raffle_tickets,
                                points: user?.points,
                                user_id: user?.id,
                                USD_earned: user?.allTimeEarned,
                                page_name: "Refferals",
                              });
                              setOpenMassege(true, item.desc);
                            }}
                            className="cursor-pointer"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21 21L16.657 16.657M16.657 16.657C17.3998 15.9141 17.9891 15.0321 18.3912 14.0615C18.7932 13.0909 19.0002 12.0506 19.0002 11C19.0002 9.94936 18.7932 8.90905 18.3912 7.93842C17.9891 6.96779 17.3998 6.08585 16.657 5.34296C15.9141 4.60007 15.0321 4.01078 14.0615 3.60874C13.0909 3.20669 12.0506 2.99976 11 2.99976C9.94936 2.99976 8.90905 3.20669 7.93842 3.60874C6.96779 4.01078 6.08585 4.60007 5.34296 5.34296C3.84263 6.84329 2.99976 8.87818 2.99976 11C2.99976 13.1217 3.84263 15.1566 5.34296 16.657C6.84329 18.1573 8.87818 19.0002 11 19.0002C13.1217 19.0002 15.1566 18.1573 16.657 16.657Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>

            <div className=" m-auto flex w-full justify-center  iphone:pt-10 sm:pt-0 relative z-50">
              <div className="mx-4 w-full ">
                <div className="mt-6 flex justify-center mac:mt-0">
                  <ul
                    onClick={(e) => {
                      copyShareLink();
                      setIsLinkShared(true);
                    }}
                    className={`max-w-[600px] w-full btnNewHover mt-3 sm:mt-4 2xl:mt-[50px]"}`}
                  >
                    <li className={`h-[45px] 3xl:h-[50px]  cursor-pointer`}>
                      <span></span>
                      <span
                        className={`fab fa-css3-alt text-[18px] sm:text-[20px] font-semibold saira`}
                      >
                        {isLinkShared === false
                          ? languageData?.referralShareBtn1
                          : languageData?.referralShareBtn2}
                      </span>
                    </li>
                  </ul>
                </div>
                <p
                  onClick={(e) => {
                    copyShareLink();
                    setIsLinkCopied(true);
                  }}
                  className={`saira mb-[-5px] cursor-pointer py-2 pb-3 text-center text-[14px] font-semibold underline `}
                
                >
                  {isLinkCopied === true
                    ? languageData?.CopyLinkDone
                    : languageData?.CopyLink}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Refferals;
