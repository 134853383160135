import React from "react";

function WithdrawError({ languageData, setOpen }) {
  return (
    <div className="w-screen h-screen fixed top-0 z-[60] bg-[#1E1E1E] bg-opacity-60 backdrop-blur-md p-4 flex">
      <div className=" m-auto max-w-[600px] w-full">
        <div
          className={` backdrop-blur-xl bg-opacity-60 p-6 bg-black rounded-[20px] border-[#8B007D] border`}
        >
          <div className="flex justify-end mb-2">
            <svg
              onClick={(e) => {
                setOpen(false);
              }}
              className="h-[24px] w-[24px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 20L4 4M20 4L4 20"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <svg
            className="m-auto my-4"
            width="100"
            height="101"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50 0.499878C22.4 0.499878 0 22.8999 0 50.4999C0 78.0999 22.4 100.5 50 100.5C77.6 100.5 100 78.0999 100 50.4999C100 22.8999 77.6 0.499878 50 0.499878ZM55 75.4999H45V65.4999H55V75.4999ZM55 55.4999H45V25.4999H55V55.4999Z"
              fill="#FF4B60"
            />
          </svg>
          <h1 className="text-[24px] text-center text-[#FF4B60]  w-full font-semibold">
            {languageData?.withdrawError}
          </h1>

          <div className="flex items-center gap-4">
            <ul
              onClick={(e) => {
                setOpen(false);
              }}
              className={`w-full btnNewHover mt-3 sm:mt-4 cursor-pointer m-auto`}
            >
              <li className={`!h-[50px]`}>
                <span></span>
                <span
                  className={`fab fa-css3-alt text-[18px]  sm:text-[20px] font-semibold saira`}
                >
                  {languageData?.withdrawErrorTry}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawError;
