import React, { useState } from 'react';

function Step3({ selectedColor, setSelectedColor, languageData }) {
    

    const arrayColor = [
        {
            option: languageData?.QuestionaireStep3Select1
        },
        {
            option: languageData?.QuestionaireStep3Select2
        },
        {
            option: languageData?.QuestionaireStep3Select3
        }
    ];

    const toggleColor = (color) => {
        if (selectedColor.includes(color)) {
            setSelectedColor(selectedColor.filter(lang => lang !== color));
        } else {
            setSelectedColor([...selectedColor, color]);
        }
    };

    return (
        <div>
            <p className='text-[28px] inter text-center font-semibold pt-[130px] 2xl:pt-[230px]'>{languageData?.QuestionaireStep1Title}</p>
            <p className='text-[16px] inter text-center font-medium pt-[30px]'>{languageData?.QuestionaireStep3SubTitle}</p>
            <div className='pt-[30px] flex justify-between max-w-[400px] w-full'>
                {arrayColor.map((color, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 m-2 text-[16px] inter font-semibold rounded-full text-white outline-none ${selectedColor.includes(color.option) ? 'bg-[#11C337]' : 'bg-[#58585899]'}`}
                        onClick={() => toggleColor(color.option)}
                    >
                        {color.option}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Step3;
