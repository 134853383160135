import axios from "axios";


/* SOCIAL AUTH */

async function socialUserAuth(email, token, refferalCode, special, country, lang, utms) {
    let testing_campaign = window.localStorage.getItem("testing_campaign");

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/createBySocial', {
        email,
        token,
        refferalCode,
        special,
        country,
        lang,
        testing_campaign,
        utms
    });
    return response.data;
}

/* CREATE USER EMAIL */

async function createUser(token, email, refferalCode, country, lang, special, fbclid) {
    let host = window.location.hostname;
    let testing_campaign = window.localStorage.getItem("testing_campaign");

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/addUserRequest', {
        token,
        email,
        refferalCode,
        country,
        lang,
        special,
        host,
        testing_campaign,
        fbclid
    });
    return response;
}

/* OTP VERIFY */

async function otpVerify(token, code, refferalCode, email, special, country) {
    let testing_campaign = window.localStorage.getItem("testing_campaign");

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/create', {
        token,
        code,
        refferalCode,
        email,
        special,
        country,
        testing_campaign
    });
    return response;
}

/* PROCESS USER REGISTRATION */

async function processUserRegistration(token, lang, utms) {
    const res = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/addUser', {
        token, lang, utms
    });
    return res
}

/*
async function checkTokenValidity(token) {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/v1/auth/verify', {
        headers: {
            token: `${token}`
        }

    });
    return response;
}
*/

/* DISCORD AUTH */

async function discordUserAuth(access_token, type, country, refferalCode, lang, special, utms) {
    let testing_campaign = window.localStorage.getItem("testing_campaign");
    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/createByDiscord', {
        access_token, type, country, refferalCode, lang, special, testing_campaign, utms
    });
    return response;
}

export { socialUserAuth, createUser, otpVerify, discordUserAuth, processUserRegistration };