import React from "react";
import { Link } from "react-router-dom";
import login1 from "../../images/LandingPage/login1.svg";
import checkUser from "../../Helpers/cloaker";

function DiscordButton({ languageData }) {
  const link = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&response_type=token&redirect_uri=https://${window.location.hostname + "/auth/discord/callback"}&scope=identify+guilds.join+email`
  return (
    <Link
      onClick={async (e) => {
        e.preventDefault()
        await checkUser()
        window.location.replace(link)
      }}
      to={link}
    >
      <div className="border-[#FF00E5] border-2 bg-transparent rounded-[32px] max-w-[320px] w-full m-auto flex justify-center items-center py-3 cursor-pointer mt-4">
        
      <img className="w-[24px]" src={login1} alt="login1" />
        <p className="text-[14px] poppins font-semibold ml-3">
          {languageData?.newRegisterContinueBtn}{" "}
          <span className="text-[14px] poppins font-bold text-[#0FF]">
            Discord
          </span>
        </p>
      </div>
    </Link>
  );
}

export default DiscordButton;
