import React, { useEffect } from "react";
import infoAPI from "../../Requests/info";
import { useDesign } from "../../Helpers/Design/DesignContext";
import cn from "classnames";
import { competitionStore } from "../../stores/competitionStore";
import DefaultCompetition from "./Competitions/DefaultCompetition";
import HomePageHeader from "../../Components/HomePage/HomePageHeader";
import Spin from "./Competitions/Spin";
import Double from "./Double";

function Competition({
  PartnerURL,
  user,
  setPointsModal,
  setTicketShortModal,
  setEarnedModal,
  userCountry,
  setSpinNoTimes,
  languageData,
  activePageIndex,
  scrollToPage,
}) {
  const { design } = useDesign();

  useEffect(() => {
    const fetchData = async () => {
      const res = await infoAPI.getLeaderboardData();
      console.log(res.data);

      //setLoading(false);
    };
    if (activePageIndex !== 1) {
      competitionStore.getState().mode = "default";
    }
    fetchData();
  }, [activePageIndex]);

  const activeMode = competitionStore((state) => state.mode);

  const getMarkup = () => {
    switch (activeMode) {
      case "default":
        return (
          <DefaultCompetition
            languageData={languageData}
            user={user}
            PartnerURL={PartnerURL}
          />
        );
      case "spin":
        return (
          <Spin
            setSpinNoTimes={setSpinNoTimes}
            languageData={languageData}
            user={user}
          />
        );
      case "double":
        return <Double languageData={languageData} user={user} />;
    }
  };

  return (
    <div
      className={cn(
        `h-screen w-screen  relative z-10 bg-cover bg-center bg-no-repeat`,
        {
          "bg-double2Desk": activeMode === "default",
          "bg-doubleDesk": activeMode !== "default",
        }
      )}
    >
      <div className="m-auto  max-w-[1000px] px-4 pt-2 sm:pt-[20px] 2xl:pt-[40px] relative z-20">
        <HomePageHeader
          scrollToPage={scrollToPage}
          languageData={languageData}
          setEarnedModal={setEarnedModal}
          setTicketShortModal={setTicketShortModal}
          setPointsModal={setPointsModal}
          user={user}
          userCountry={userCountry}
        />
        <div className="2xl:flex 2xl:items-center 3xl:h-[65vh] scaleFrom125">
          <div className="max-w-[1000px] w-full">{getMarkup()}</div>
        </div>
      </div>
    </div>
  );
}

export default Competition;
