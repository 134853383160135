import React, { useState } from "react";
import close from "../images/CyberSaysPage/closeMenu.png";
import { useDesign } from "../Helpers/Design/DesignContext";
import { Link } from "react-router-dom";
import PartnerLogo from "../images/CyberSaysPage/PartnerLogo.png";
import doubleIcon from "../images/NewDesign/doubleIcon.svg";

function WinTicketModal({ setOpen, languageData, user, scrollToPage }) {
  const [pdfLoading, setPdfLoading] = useState(false);

  const { design } = useDesign();

  return (
    <div className=" top-0 z-[99999]  h-full w-screen bg-[#1E1E1E] bg-opacity-60 p-4 backdrop-blur-md lg:flex">
      <div className="relative m-auto max-w-[600px] w-full pt-8 sm:pt-0">
        <div className="right-0 top-[-70px] flex justify-end md:my-4 lg:absolute lg:mb-10">

          <svg onClick={(e) => setOpen(false)} className="h-[24px] w-[24px] cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 20L4 4M20 4L4 20" stroke="white" stroke-width="2" stroke-linecap="round" />
          </svg>
        </div>
        <p className=" m-auto text-center text-[18px] font-semibold lg:text-[32px] max-w-[400px] w-full sm:leading-10 saira">{languageData?.winTicketTitle}</p>
        <p className="text-[#FF00E5] saira text-center text-[14px] font-medium leading-4 max-w-[440px] w-full m-auto mt-2">{languageData?.winTicketSubTitle}</p>
        <div>
          <div className="mt-4 rounded-[14px] bg-[#EAEAEA] bg-opacity-30 p-2 sm:p-4 backdrop-blur-md lg:rounded-[14px] w-full">
            <div className="flex items-center justify-center my-4">
              <p className="saira text-[16px] font-semibold">{languageData?.winTicketLiTitle}</p>
            </div>
            <ul className="ml-[20px] mt-1 list-disc">
              <li className="saira text-[12px] font-medium lg:text-[14px]">{languageData?.winTicketLi1}</li>
              <li className="saira text-[12px] font-medium lg:text-[14px]">{languageData?.winTicketLi2}</li>
              <li className="saira text-[12px] font-medium lg:text-[14px]">{languageData?.winTicketLi3}</li>
            </ul>
          </div>
          <ul onClick={(e) => {
            scrollToPage(1);
            setOpen(false);
          }} className="max-w-[600px] w-full btnNewHover mt-2 sm:mt-4 ">
            <li className={`h-[50px]`}>
              
                <span className={``}></span>
                <span className={`fab fa-css3-alt text-[18px] font-semibold saira `}>{languageData?.winTicketBtn}<img className="ml-1" src={doubleIcon} alt="doubleIcon" /></span>
              
            </li>
          </ul>
          <p className="text-[#FFED63] mt-2 text-center text-[18px] font-semibold  lg:text-[24px]">{languageData?.winTicketEnd}</p>
        </div>
      </div>
      <div className=" absolute bottom-2 left-1/2 w-full max-w-[235px] -translate-x-1/2 transform">
        <Link onClick={(e) => setOpen(false)} to='https://www.cyber-says.club/terms' target="_blank">
          <p className="text-[#FF00E5] mt-2 text-center text-[12px] font-semibold underline ">{languageData?.winTicketLink}</p>
        </Link>
      </div>
    </div>
  );
}

export default WinTicketModal;
