import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Homepage from "./CyberSaysPages/Homepage";
import ModalMenu from "../Components/ModalMenu";
import userAPI from "../Requests/user";
import { useQuery } from "@tanstack/react-query";
import AuthCheck from "../hoc/AuthCheck";
import Competition from "./CyberSaysPages/Competition";
import Refferals from "./CyberSaysPages/Refferals";
import Withdraw from "../Components/Transactions/Withdraw";
import TourModal from "../Components/DoubleMoneyPage/TourModal";
import LeaderboardModal from "../Components/LeaderboardModal";
import { SwiperSlide } from "swiper/react";
import { Swiper } from "swiper/react";
import ChatModal from "../Components/ChatModal";
import infoAPI from "../Requests/info";
import MyReferralsModal from "../Components/ProfileReferrals/MyReferralsModal";
import Message from "./CyberSaysPages/Modals/Message";
import AvatarModal from "../Components/ProfileReferrals/AvatarModal";
import API from "../Helpers/API";
import Verify from "./Verify";
import WinVegasModal from "../Components/WinVegasModal";
import CompetitionRules from "../Components/CompetitionRules";
import ToolTipInfo from "../Components/ToolTipInfo";
import PartnerLinks from "../Helpers/PartnerLinks.json";
import PartnerLinksTesting from "../Helpers/PartnerLinksTesting.json";
import WinTicketModal from "../Components/WinTicketModal";
import InfoOfferModal from "../Components/InfoOfferModal";
import Earned from "../Components/Transactions/Modals/Earned";
import Points from "../Components/Transactions/Modals/Points";
import Ticket from "../Components/Transactions/Modals/Ticket";
import TransactionHistory from "../Components/Transactions/TransactionHistory";
import TicketsHistory from "../Components/Transactions/TicketsHistory";
import SocialLink from "../Components/SocialLink";
import DoubleComplete from "../Components/DoubleComplete";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
import PromoModal from "../Components/Withdraw/PartnerPromoModal";
import moengage from "@moengage/web-sdk";
import MainMenu from "../Components/MainMenu";
import Popup from "react-animated-popup";
import { useSwipeable } from "react-swipeable";
import Vegas from "./CyberSaysPages/Vegas";
import infoBtn from "../images/NewDesign2/other/infoTool.png";
import GameNoPoints from "../Components/GameNoPoints";
import SpinNoTimes from "../Components/SpinNoTimes";
import WithdrawComplete from "../Components/Withdraw/Modals/WithdrawComplete";
import WithdrawError from "../Components/Withdraw/Modals/WithdrawError";
import mixpanelTrack from "../Helpers/mixpanel";

import cn from "classnames";

import Zendesk from "react-zendesk";
import MoneyError from "../Components/Transactions/MoneyError";
import user from "../Requests/user";
const ZENDESK_KEY = "d375ce1a-ab79-42a4-b4b6-d923b2e2f8fb";

var mixpanel = require("mixpanel-browser");

function NewMain({ languageData, testingCampaign }) {
  const dataMessage = [
    {
      desc: languageData?.dataMessageDesc1,
    },
    {
      desc: languageData?.dataMessageDesc2,
    },
    {
      desc: languageData?.dataMessageDesc3,
    },
    {
      desc: languageData?.dataMessageDesc4,
    },
    {
      desc: languageData?.dataMessageDesc5,
    },
    {
      desc: languageData?.dataMessageDesc6,
    },
    {
      desc: languageData?.dataMessageDesc7,
    },
    {
      desc: languageData?.dataMessageDesc8,
    },
  ];

  const [messageCopied, setMessagetCopied] = useState(
    Array(dataMessage.length).fill(false)
  );
  const copyToMessage = (text, messageIndex) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    const newTextCopied = [...messageCopied];
    newTextCopied[messageIndex] = true;
    setMessagetCopied(newTextCopied);
  };

  const { language } = useLanguage();

  const [promoModal, setPromoModal] = useState(false);

  const [PartnerURL, setPartnerURL] = useState(null);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const [menuOpen, setMenuOpen] = useState(false);

  const [userData, setUserData] = useState(null);
  const [tourModal, setTourModal] = useState(false);
  const [leaderboardModal, setLeaderboardModal] = useState(false);

  const [loading] = useState(true);
  const [leaderboardData] = useState([]);

  const [siteData, setSiteData] = useState(null);
  const [withdrawModal, setWithdrawModal] = useState(false);

  const [chatModal, setChatModal] = useState(false);
  const [openMessage, setOpenMassege] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [winMoadal, setWinModal] = useState(false);
  const [winTicketMoadal, setWinTicketModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [toolInfo, setToolInfo] = useState(false);
  const [infoOffer, setInfoOffer] = useState(false);
  const [earnedModal, setEarnedModal] = useState(false);
  const [ticketShortModal, setTicketShortModal] = useState(false);
  const [pointsModal, setPointsModal] = useState(false);
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [ticketsModal, setTicketsModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("ticket");
  const [socialLink, setSocialLink] = useState(false);
  const [doubleComplete, setDoubleComplete] = useState(false);
  const [gamePoint, setGamePoint] = useState(false);
  const [spinNoTimes, setSpinNoTimes] = useState(false);

  const [witdrawComplete, setWithdrawComplete] = useState(false);
  const [witdrawError, setWitdrawError] = useState(false);

  const [selectedMessage, setSelectedMassege] = useState(null);

  const [moneyError, setMoneyError] = useState(false);

  const [visible, setVisible] = useState(false);
  let utmData = {
    utm_source:
      urlParams.get("utm_source") || window.localStorage.getItem("utm_source"),
    utm_medium:
      urlParams.get("utm_medium") || window.localStorage.getItem("utm_medium"),
    utm_campaign:
      urlParams.get("utm_campaign") ||
      window.localStorage.getItem("utm_campaign"),
    utm_term:
      urlParams.get("utm_term") || window.localStorage.getItem("utm_term"),
    utm_content:
      urlParams.get("utm_content") ||
      window.localStorage.getItem("utm_content"),
  };

  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
    getUserCountry();
    async function getUserCountry() {
      const res = await API.getUserCountry();
      setUserCountry(res);
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#double") {
      setActivePageIndex(1);
    } else if (window.location.hash === "#vegas") {
      setActivePageIndex(2);
    } else if (window.location.hash === "#competition") {
      setActivePageIndex(3);
    } else if (window.location.hash === "#referrals") {
      setActivePageIndex(4);
    }
  }, []);

  useQuery({
    queryKey: ["userData"],
    queryFn: async () => {
      const res = await userAPI.getUserData();
      setUserData(res);

      let params = new URLSearchParams(window.location.search);
      let fbclid = params.get("fbclid");

      if (res.id === undefined) return navigate(`/welcome`);

      await mixpanel.identify(res?.id);
      mixpanel.people.set({
        ...utmData,
      });

      if (res.first_login === 0) {
        await user.updateFirstLogin();
        return navigate(`/landing` + (fbclid ? `?fbclid=${fbclid}` : ''))
      } else {
        navigate(`/`);
      };
      
      return res;
    },
  });

  useQuery({
    queryKey: ["siteData"],
    queryFn: async () => {
      const res = await infoAPI.getInfoData();
      console.log(res.data);
      setSiteData(res.data);
      return res;
    },
  });

  useEffect(() => {
    /* MIXPANEL */

    // delete nulls

    mixpanelTrack("page_view", {
      distinct_id: userData?.id,
      ...utmData,
      is_referred: userData?.referral_id ? "Yes" : "No",
      vegas_tickets: userData?.raffle_tickets,
      points: userData?.points,
      user_id: userData?.id,
      USD_earned: userData?.allTimeEarned,
      page_name:
        activePageIndex === 0
          ? "Home"
          : activePageIndex === 1
            ? "Double"
            : activePageIndex === 2
              ? "Vegas Weekend"
              : activePageIndex === 3
                ? "Competition"
                : activePageIndex === 4
                  ? "My referrals"
                  : "Not set",
    });
    moengage.track_event("page_view", {
      distinct_id: userData?.id,
      ...utmData,
      is_referred: userData?.referral_id ? "Yes" : "No",
      vegas_tickets: userData?.raffle_tickets,
      points: userData?.points,
      user_id: userData?.id,
      USD_earned: userData?.allTimeEarned,
      page_name:
        activePageIndex === 0
          ? "Home"
          : activePageIndex === 1
            ? "Double"
            : activePageIndex === 2
              ? "Vegas Weekend"
              : activePageIndex === 3
                ? "Competition"
                : activePageIndex === 4
                  ? "My referrals"
                  : "Not set",
    });
  }, []);

  const [activePageIndex, setActivePageIndex] = React.useState(0);

  async function scrollToPage(pageIndex) {
    setMenuOpen(false);
    setActivePageIndex(pageIndex);
  }

  function handleSwiperChange(swiper) {
    setActivePageIndex(swiper.activeIndex);
    if (swiper.realIndex === 5) {
      swiper.slideTo(0);
    }
  }

  const [mainSwiper, setMainSwiper] = useState(null);

  useEffect(() => {
    console.log(activePageIndex);
    if (mainSwiper) {
      mainSwiper.slideTo(activePageIndex);
    }
  }, [activePageIndex]);

  const [referralsOpen, setReferralsOpen] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const inputRef = useRef();
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  useEffect(() => {
    let data = [];

    if (language === "pt") {
      data = [
        "/images/memes/pt/pt_1.png",
        "/images/memes/pt/pt_2.png",
        "/images/memes/pt/pt_3.png",
        "/images/memes/pt/pt_4.png",
        "/images/memes/pt/pt_5.png",
        "/images/memes/pt/pt_6.png",
        "/images/memes/pt/pt_7.png",
        "/images/memes/pt/pt_8.png",
        "/images/memes/pt/pt_9.png",
        "/images/memes/pt/pt_10.png",
      ];
    } else if (language === "es") {
      data = [
        "/images/memes/es/es_1.png",
        "/images/memes/es/es_2.png",
        "/images/memes/es/es_3.png",
        "/images/memes/es/es_4.png",
        "/images/memes/es/es_5.png",
        "/images/memes/es/es_6.png",
        "/images/memes/es/es_7.png",
        "/images/memes/es/es_8.png",
        "/images/memes/es/es_9.png",
        "/images/memes/es/es_10.png",
      ];
    } else {
      data = [
        "/images/memes/en/en_1.png",
        "/images/memes/en/en_2.png",
        "/images/memes/en/en_3.png",
        "/images/memes/en/en_4.png",
        "/images/memes/en/en_5.png",
        "/images/memes/en/en_6.png",
        "/images/memes/en/en_7.png",
        "/images/memes/en/en_8.png",
        "/images/memes/en/en_9.png",
        "/images/memes/en/en_10.png",
      ];
    }

    setUploadedPhotos(data);
  }, [language]);

  const savePhotosToLocalStorage = (photos) => {
    console.log(photos);
    localStorage.setItem("uploadedPhotos", JSON.stringify(photos));
  };

  useQuery({
    queryKey: ["userData"],
    queryFn: async () => {
      const res = await userAPI.getUserData();
      setUserData(res);

      if (res.avatar !== null) {
        setSelectedImage(res.avatar);
      }

      return res;
    },
  });

  const saveAvatar = async () => {
    try {
      const res = await userAPI.updateUserAvatar(inputRef.current.files[0]);
      console.log(res);

      console.log(res.data.url);
      setUploadedPhotos((prevPhotos) => [...prevPhotos, res.data.url]);
      let photos = [...uploadedPhotos, res.data.url];
      savePhotosToLocalStorage(photos);
    } catch (error) {
      alert(error);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        {
          /* setUploadedPhotos((prevPhotos) => [...prevPhotos, { image: base64String }]);*/
        }
        setSelectedImage(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (userData === null || userData === undefined) return;

    if (userData?.testing_campaign === "null") {
      processPartnerURL(PartnerLinks);
    } else {
      processPartnerURLTesting(PartnerLinksTesting);
    }
  }, [userData, userCountry]);

  const processPartnerURL = (links) => {
    if (userData && userCountry) {
      let linkData = links.find(
        (link) => link.CultureCode.indexOf(userCountry) !== -1
      );
      if (linkData === undefined)
        linkData = links.find((link) => link.CultureCode.indexOf("all") !== -1);

      let link = linkData.Link + `&promocode=${userData?.id}&func=signup`;
      console.log(link);
      setPartnerURL(link);
    }
  };

  const processPartnerURLTesting = (links) => {
    let type =
      userData?.testing_campaign === "b"
        ? "flc"
        : userData?.testing_campaign === "a"
          ? "top_host"
          : userData?.testing_campaign === "c"
            ? "questionaire"
            : "flc";

    if (type === "questionaire") {
      setPartnerURL(window.location.href + `questionaire`);
    } else {
      if (userData && userCountry) {
        let linkData = links.find(
          (link) =>
            link.CultureCode.indexOf(userCountry) !== -1 && link.type === type
        );
        if (linkData === undefined)
          linkData = links.find(
            (link) =>
              link.CultureCode.indexOf("all") !== -1 && link.type === type
          );

        let link = linkData.Link + `&promocode=${userData?.id}&func=signup`;
        console.log(linkData);

        setPartnerURL(link);
      }
    }
  };

  useEffect(() => {
    if (userData === null) return;

    if (
      window.localStorage.getItem("double") !== "completed" &&
      userData?.completed_tasks &&
      userData?.completed_tasks?.indexOf("3") !== -1
    ) {
      setDoubleComplete(true);
      window.localStorage.setItem("double", "completed");
    }
  }, [userData]);

  useEffect(() => {
    //remove all params from url

    if (window.location.search !== "") {
      //window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Right") {
        if (activePageIndex > 0) {
          setActivePageIndex(activePageIndex - 1);
        }
      } else if (eventData.dir === "Left") {
        if (activePageIndex < 4) {
          setActivePageIndex(activePageIndex + 1);
        }
      }
    },
  });

  const handler = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Left") {
        if (activePageIndex === 3) {
          setActivePageIndex(0);
        }
      } else if (eventData.dir === "Right") {
        if (activePageIndex === 0) {
          setActivePageIndex(3);
        }
      }
    },
  });

  const HomepageSwiper = () => {
    return (
      <div {...handler} className="overflow-x-hidden overflow-y-hidden">
        <Swiper
          className="h-screen w-screen"
          initialSlide={activePageIndex}
          onSwiper={(swiper) => {
            setMainSwiper(swiper);
          }}
          spaceBetween={0}
          slidesPerView={1}
          loop={false}
          onSlideChange={(swiper) => handleSwiperChange(swiper)}
        >
          <SwiperSlide>
            <div className=" h-full w-full">
              <Homepage
                scrollToPage={scrollToPage}
                user={userData}
                languageData={languageData}
                setMenuOpen={setMenuOpen}
                setTourModal={setTourModal}
                setWinTicketModal={setWinTicketModal}
                setWinModal={setWinModal}
                setVisible={setVisible}
                activePageIndex={activePageIndex}
                setActivePageIndex={setActivePageIndex}
                userCountry={userCountry}
                PartnerURL={PartnerURL}
                setGamePoint={setGamePoint}
                setSpinNoTimes={setSpinNoTimes}
                setWithdrawComplete={setWithdrawComplete}
                setWitdrawError={setWitdrawError}
                setWithdrawModal={setWithdrawModal}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {
              <Competition
                languageData={languageData}
                setPointsModal={setPointsModal}
                setTicketShortModal={setTicketShortModal}
                setEarnedModal={setEarnedModal}
                userCountry={userCountry}
                user={userData}
                setSpinNoTimes={setSpinNoTimes}
                activePageIndex={activePageIndex}
                scrollToPage={scrollToPage}
              />
            }
          </SwiperSlide>
          <SwiperSlide>
            <Vegas
              setPointsModal={setPointsModal}
              setTicketShortModal={setTicketShortModal}
              setEarnedModal={setEarnedModal}
              setWinTicketModal={setWinTicketModal}
              scrollToPage={scrollToPage}
              setWinModal={setWinModal}
              setActivePageIndex={setActivePageIndex}
              activePageIndex={activePageIndex}
              user={userData}
              languageData={languageData}
              PartnerURL={PartnerURL}
              setTourModal={setTourModal}
              userCountry={userCountry}
            />
          </SwiperSlide>

          <SwiperSlide>
            <Refferals
              userCountry={userCountry}
              setPointsModal={setPointsModal}
              setTicketShortModal={setTicketShortModal}
              setEarnedModal={setEarnedModal}
              setInfoOffer={setInfoOffer}
              setImageModal={setImageModal}
              imageModal={imageModal}
              uploadedPhotos={uploadedPhotos}
              saveAvatar={saveAvatar}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              message={
                selectedMessage !== null
                  ? dataMessage[selectedMessage].desc
                  : ""
              }
              copyToMessage={copyToMessage}
              setSelectedMassege={setSelectedMassege}
              selectedMessage={selectedMessage}
              setOpenAvatar={setOpenAvatar}
              setOpenMassege={setOpenMassege}
              dataMessage={dataMessage}
              setReferralsOpen={setReferralsOpen}
              setActivePageIndex={setActivePageIndex}
              activePageIndex={activePageIndex}
              user={userData}
              languageData={languageData}
              scrollToPage={scrollToPage}
            />
          </SwiperSlide>
        </Swiper>
        <div {...handlers} style={{ zIndex: 0 }}>
          <MainMenu
            setTourModal={setTourModal}
            setWinTicketModal={setWinTicketModal}
            setWinModal={setWinModal}
            setVisible={setVisible}
            languageData={languageData}
            activePageIndex={activePageIndex}
            setActivePageIndex={setActivePageIndex}
            user={userData}
            scrollToPage={scrollToPage}
            setMenuOpen={setMenuOpen}
            userCountry={userCountry}
            PartnerURL={PartnerURL}
            setGamePoint={setGamePoint}
            setSpinNoTimes={setSpinNoTimes}
            setWithdrawComplete={setWithdrawComplete}
            setWitdrawError={setWitdrawError}
            setWithdrawModal={setWithdrawModal}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Routes>
        <Route path="/verify" element={<Verify userData={userData} />} />
        <Route path="/*" element={<AuthCheck>{HomepageSwiper()}</AuthCheck>} />
        <Route
          path="/competition"
          element={
            <AuthCheck>
              <Competition PartnerURL={PartnerURL} user={userData} />
            </AuthCheck>
          }
        />
      </Routes>
      {leaderboardModal && (
        <LeaderboardModal
          user={userData}
          languageData={languageData}
          setOpen={setLeaderboardModal}
          loading={loading}
          leaderboardData={leaderboardData}
        />
      )}
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <ModalMenu
          PartnerURL={PartnerURL}
          setPromoModal={setPromoModal}
          setSocialLink={setSocialLink}
          setSelectedButton={setSelectedButton}
          selectedButton={selectedButton}
          setTransactionsModal={setTransactionsModal}
          userCountry={userCountry}
          languageData={languageData}
          setChatModal={setChatModal}
          chatModal={chatModal}
          siteData={siteData}
          scrollToPage={scrollToPage}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          user={userData}
          setMoneyError={setMoneyError}
        />
      </Popup>
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none !absolute !top-0"
        visible={tourModal}
        onClose={() => setTourModal(false)}
      >
        <TourModal
          user={userData}
          languageData={languageData}
          setOpen={setTourModal}
          PartnerURL={PartnerURL}
          scrollToPage={scrollToPage}
        />
      </Popup>
      {withdrawModal && (
        <Withdraw
          languageData={languageData}
          setOpen={setWithdrawModal}
          setMoneyError={setMoneyError}
          user={userData}
          userCountry={userCountry}
        />
      )}
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none !absolute"
        visible={referralsOpen}
        onClose={() => setReferralsOpen(false)}
      >
        <MyReferralsModal
          languageData={languageData}
          setOpen={setReferralsOpen}
          user={userData}
        />
      </Popup>
      {chatModal && (
        <ChatModal
          languageData={languageData}
          setOpen={setChatModal}
          user={userData}
          userCountry={userCountry}
        />
      )}
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none !h-screen"
        visible={winMoadal}
        onClose={() => setWinModal(false)}
      >
        <WinVegasModal
          languageData={languageData}
          setOpen={setWinModal}
          user={userData}
        />
      </Popup>
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none !h-screen"
        visible={winTicketMoadal}
        onClose={() => setWinTicketModal(false)}
      >
        <WinTicketModal
          scrollToPage={scrollToPage}
          languageData={languageData}
          setOpen={setWinTicketModal}
          user={userData}
        />
      </Popup>
      {rulesModal && (
        <CompetitionRules setOpen={setRulesModal} languageData={languageData} />
      )}
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={infoOffer}
        onClose={() => setInfoOffer(false)}
      >
        <InfoOfferModal setOpen={setInfoOffer} languageData={languageData} />
      </Popup>
      <Popup
        className="!p-1 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={earnedModal}
        onClose={() => setEarnedModal(false)}
      >
        <Earned
          setTransactionsModal={setTransactionsModal}
          languageData={languageData}
          setOpen={setEarnedModal}
          user={userData}
          userCountry={userCountry}
        />
      </Popup>
      <Popup
        className="!p-1 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={ticketShortModal}
        onClose={() => setTicketShortModal(false)}
      >
        <Ticket
          scrollToPage={scrollToPage}
          setTicketsModal={setTicketsModal}
          languageData={languageData}
          setOpen={setTicketShortModal}
          user={userData}
          setSelectedButton={setSelectedButton}
        />
      </Popup>
      <Popup
        className="!p-1 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={pointsModal}
        onClose={() => setPointsModal(false)}
      >
        <Points
          scrollToPage={scrollToPage}
          setTicketsModal={setTicketsModal}
          languageData={languageData}
          setOpen={setPointsModal}
          user={userData}
          setSelectedButton={setSelectedButton}
        />
      </Popup>
      {transactionsModal && (
        <TransactionHistory
          scrollToPage={scrollToPage}
          setWithdrawModal={setWithdrawModal}
          languageData={languageData}
          setOpen={setTransactionsModal}
          user={userData}
          userCountry={userCountry}
        />
      )}
      {ticketsModal && (
        <TicketsHistory
          scrollToPage={scrollToPage}
          languageData={languageData}
          setOpen={setTicketsModal}
          user={userData}
          setSelectedButton={setSelectedButton}
          selectedButton={selectedButton}
        />
      )}
      <Popup
        className="!p-0 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={openMessage}
        onClose={() => setOpenMassege(false)}
      >
        <Message
          user={userData}
          onCloseCopied={() => {
            setMessagetCopied(Array(dataMessage.length).fill(false));
          }}
          selectedMessage={selectedMessage}
          messageCopied={messageCopied}
          copyToMessage={copyToMessage}
          message={
            selectedMessage !== null ? dataMessage[selectedMessage].desc : ""
          }
          setOpenMassege={setOpenMassege}
          languageData={languageData}
        />
      </Popup>
      {openAvatar && (
        <AvatarModal
          inputRef={inputRef}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          handleImageChange={handleImageChange}
          saveAvatar={saveAvatar}
          setOpenAvatar={setOpenAvatar}
        />
      )}
      {socialLink && <SocialLink setOpen={setSocialLink} />}
      <div
        className={`fixed top-4 right-2 z-20 sm:top-8 sm:right-8 ${menuOpen && "hidden"}`}
      >
        <img
          onClick={(e) => setToolInfo(!toolInfo)}
          className={cn("w-[24px] cursor-pointer sm:w-12 duration-300", {
            "opacity-0": activePageIndex === 0,
          })}
          src={infoBtn}
          alt="Chat"
        />
      </div>
      <Popup
        className="!p-0 sm:!p-1 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none !absolute !top-0 sm:!right-2 !origin-right"
        visible={toolInfo}
        onClose={() => setToolInfo(false)}
      >
        <ToolTipInfo
          setToolInfo={setToolInfo}
          languageData={languageData}
          user={userData}
        />
      </Popup>
      <Popup
        className="!p-1 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={gamePoint}
        onClose={() => setGamePoint(false)}
      >
        <GameNoPoints
          setOpen={setGamePoint}
          languageData={languageData}
          title={languageData?.GameNoPointsTitle}
          subtitle={languageData?.GameNoPointsSubTitle}
          btn1={languageData?.GameNoPointsBtn1}
          btn2={languageData?.GameNoPointsBtn2}
        />
      </Popup>
      <Popup
        className="!p-1 !max-w-[unset] !bg-[unset] w-full !m-0 !shadow-none"
        visible={spinNoTimes}
        onClose={() => setSpinNoTimes(false)}
      >
        <SpinNoTimes
          setOpen={setSpinNoTimes}
          languageData={languageData}
          title={languageData?.GameNoPointsTitle2}
          subtitle={languageData?.GameNoPointsSubTitle2}
          btn1={languageData?.GameNoPointsBtn1}
          btn2={languageData?.GameNoPointsBtn2}
        />
      </Popup>
      {doubleComplete && (
        <DoubleComplete
          setOpen={setDoubleComplete}
          languageData={languageData}
          user={userData}
          userCountry={userCountry}
          scrollToPage={scrollToPage}
        />
      )}
      {promoModal && (
        <PromoModal
          setOpen={setPromoModal}
          languageData={languageData}
          user={userData}
        />
      )}
      {witdrawComplete && (
        <WithdrawComplete
          setOpen={setWithdrawComplete}
          languageData={languageData}
        />
      )}
      {witdrawError && (
        <WithdrawError setOpen={setWitdrawError} languageData={languageData} />
      )}
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => console.log("Zendesk is loaded")}
      />

      {moneyError && (
        <MoneyError
          setOpen={setMoneyError}
          languageData={languageData}
          setWithdrawModal={setWithdrawModal}
        />
      )}
    </>
  );
}

export default NewMain;
