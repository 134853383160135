import React from 'react'
import footerWomen from './Image/footerWomen.png'
import { Link } from 'react-router-dom'

function FooterWelcome() {
  return (
    <div className='bg-[#0A0A0A] w-full lg:h-[350px] relative lg:flex justify-end items-start pt-12'>
      <div className='flex justify-between m-auto lg:m-[unset] max-w-[120px] mb-6 lg:mr-6'>
        <Link to='https://discord.gg/VsMZKKWsr4' target='_blank'>
          <svg className='w-[48px] lg:w-[64px] cursor-pointer lg:mr-4' viewBox="0 0 64 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_40_55)">
              <path d="M54.2617 5.27415C50.1189 3.33582 45.6891 1.92712 41.058 1.125C40.4892 2.1533 39.8248 3.53637 39.3666 4.63661C34.4436 3.89625 29.5658 3.89625 24.7334 4.63661C24.2753 3.53637 23.5958 2.1533 23.0219 1.125C18.3858 1.92712 13.951 3.341 9.80812 5.28441C1.45199 17.9116 -0.813222 30.2252 0.319385 42.3639C5.86161 46.5027 11.2327 49.0169 16.5131 50.6621C17.8169 48.8678 18.9797 46.9603 19.9814 44.9501C18.0736 44.2251 16.2464 43.3305 14.5198 42.292C14.9778 41.9526 15.4259 41.5979 15.8588 41.2328C26.3895 46.1583 37.8313 46.1583 48.2362 41.2328C48.6742 41.5979 49.1221 41.9526 49.5752 42.292C47.8436 43.3356 46.0113 44.2302 44.1035 44.9552C45.1052 46.9603 46.263 48.873 47.5718 50.6672C52.8573 49.022 58.2334 46.5079 63.7756 42.3639C65.1045 28.292 61.5054 16.0916 54.2617 5.27415ZM21.4161 34.8987C18.2549 34.8987 15.6624 31.9475 15.6624 28.3537C15.6624 24.7599 18.1995 21.8036 21.4161 21.8036C24.6327 21.8036 27.2251 24.7547 27.1697 28.3537C27.1747 31.9475 24.6327 34.8987 21.4161 34.8987ZM42.6789 34.8987C39.5177 34.8987 36.9253 31.9475 36.9253 28.3537C36.9253 24.7599 39.4623 21.8036 42.6789 21.8036C45.8955 21.8036 48.4879 24.7547 48.4326 28.3537C48.4326 31.9475 45.8955 34.8987 42.6789 34.8987Z" fill="#D82323" />
            </g>
            <defs>
              <clipPath id="clip0_40_55">
                <rect width="64" height="53" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <Link to='https://www.instagram.com/elamandabr_/' target='_blank'>
          <svg className='w-[42px] lg:w-[54px] cursor-pointer lg:mr-4' viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_40_61)">
              <path d="M26.6667 4.80667C33.7867 4.80667 34.6311 4.83333 37.4444 4.96222C44.6711 5.29111 48.0467 8.72 48.3756 15.8933C48.5044 18.7044 48.5289 19.5489 48.5289 26.6689C48.5289 33.7911 48.5022 34.6333 48.3756 37.4444C48.0444 44.6111 44.6778 48.0467 37.4444 48.3756C34.6311 48.5044 33.7911 48.5311 26.6667 48.5311C19.5467 48.5311 18.7022 48.5044 15.8911 48.3756C8.64667 48.0444 5.28889 44.6 4.96 37.4422C4.83111 34.6311 4.80444 33.7889 4.80444 26.6667C4.80444 19.5467 4.83333 18.7044 4.96 15.8911C5.29111 8.72 8.65778 5.28889 15.8911 4.96C18.7044 4.83333 19.5467 4.80667 26.6667 4.80667ZM26.6667 0C19.4244 0 18.5178 0.0311111 15.6733 0.16C5.98889 0.604444 0.606667 5.97778 0.162222 15.6711C0.0311111 18.5178 0 19.4244 0 26.6667C0 33.9089 0.0311111 34.8178 0.16 37.6622C0.604444 47.3467 5.97778 52.7289 15.6711 53.1733C18.5178 53.3022 19.4244 53.3333 26.6667 53.3333C33.9089 53.3333 34.8178 53.3022 37.6622 53.1733C47.3378 52.7289 52.7333 47.3556 53.1711 37.6622C53.3022 34.8178 53.3333 33.9089 53.3333 26.6667C53.3333 19.4244 53.3022 18.5178 53.1733 15.6733C52.7378 5.99778 47.3578 0.606667 37.6644 0.162222C34.8178 0.0311111 33.9089 0 26.6667 0ZM26.6667 12.9733C19.1044 12.9733 12.9733 19.1044 12.9733 26.6667C12.9733 34.2289 19.1044 40.3622 26.6667 40.3622C34.2289 40.3622 40.36 34.2311 40.36 26.6667C40.36 19.1044 34.2289 12.9733 26.6667 12.9733ZM26.6667 35.5556C21.7578 35.5556 17.7778 31.5778 17.7778 26.6667C17.7778 21.7578 21.7578 17.7778 26.6667 17.7778C31.5756 17.7778 35.5556 21.7578 35.5556 26.6667C35.5556 31.5778 31.5756 35.5556 26.6667 35.5556ZM40.9022 9.23333C39.1333 9.23333 37.7 10.6667 37.7 12.4333C37.7 14.2 39.1333 15.6333 40.9022 15.6333C42.6689 15.6333 44.1 14.2 44.1 12.4333C44.1 10.6667 42.6689 9.23333 40.9022 9.23333Z" fill="url(#paint0_linear_40_61)" />
            </g>
            <defs>
              <linearGradient id="paint0_linear_40_61" x1="26.6667" y1="0" x2="26.6667" y2="53.3333" gradientUnits="userSpaceOnUse">
                <stop offset="0.55" stop-color="#D82323" />
                <stop offset="1" stop-color="#721212" />
              </linearGradient>
              <clipPath id="clip0_40_61">
                <rect width="53.3333" height="53.3333" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        {/*
        <Link to='https://discord.gg/VsMZKKWsr4' target='_blank'>
          <svg className='w-[42px] lg:w-[54px] cursor-pointer lg:mr-4' viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.738 0.502197L21.1162 27.7498L0.609314 49.9033H5.2246L23.1784 30.5076L37.6845 49.9033H53.3905L31.8657 21.123L50.9534 0.502197H46.3381L29.8036 18.3653L16.444 0.502197H0.738ZM7.52512 3.90182H14.7405L46.6024 46.5032H39.387L7.52512 3.90182Z" fill="url(#paint0_linear_40_59)" />
            <defs>
              <linearGradient id="paint0_linear_40_59" x1="26.9999" y1="0.502197" x2="26.9999" y2="49.9033" gradientUnits="userSpaceOnUse">
                <stop offset="0.55" stop-color="#D82323" />
                <stop offset="1" stop-color="#721212" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        */}
      </div>
      <img className='max-w-[235px] lg:max-w-[435px] m-auto lg:absolute lg:bottom-0 lg:left-1/2 lg:transform lg:-translate-x-1/2' src={footerWomen} alt="footerWomen" />

    </div>
  )
}

export default FooterWelcome
