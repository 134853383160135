import React, { useEffect, useState, useRef } from "react";

import gifs from "../../Helpers/gifs.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import { useDesign } from "../../Helpers/Design/DesignContext";

function TourModal({ setOpen, languageData, user, scrollToPage}) {
  const { design } = useDesign();

  const dataShort = [
    {
      video: gifs.video1,
      title: languageData?.tourSlide1Title,
      descShort: languageData?.tourSlide1SubTitle,
    },
    {
      video: gifs.video2,
      title: languageData?.tourSlide2Title,
      descShort: languageData?.tourSlide2SubTitle,
    },
    {
      video: gifs.video3,
      title: languageData?.tourSlide3Title,
      descShort: languageData?.tourSlide3SubTitle,
    },
  ];

  const prevCurrentSlide = useRef(null);

  useEffect(() => {
    window.localStorage.setItem("visitedTour", true);
  }, []);

  const [blobImage, setBlobImage] = useState(null);
  const [selectedButton, setSelectedButton] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(null);

  useEffect(() => {
    if (prevCurrentSlide.current !== currentSlide) {
      getBlob();
      prevCurrentSlide.current = currentSlide;
    }

    async function getBlob() {
      if (dataShort[currentSlide]) {
        console.log(dataShort[currentSlide]);
        let url = window.location.href + dataShort[currentSlide].video;
        url = url.replace("/static", "static");
        const blob = await fetch(url).then((r) => r.blob());
        console.log(window.location.href + dataShort[currentSlide].video);
        console.log(blob);
        setBlobImage(blob);
      }
    }
  }, [currentSlide, selectedButton]);

  const handleShare = () => {
    if (currentSlide !== null) {
      const selectedSlide = dataShort[currentSlide];

      if (navigator.share) {
        navigator
          .share({
            title: "CyberSays",
            text: selectedSlide.descShort,
            files: [
              new File([blobImage], "file.mp4", {
                type: blobImage.type,
              }),
            ],
            url: "/" + user?.referral_code,
          })
          .then(() => console.log("Successful share! 🎉"));
      } else {
        window.navigator.clipboard.writeText(
          window.location.host + "/" + user?.referral_code
        );
      }
    }
  };
  const [linkCopied, setLinkCopied] = useState(false);
  const copyPartnerLink = () => {
    navigator.clipboard.writeText(
      window.location.host + "/" + user?.referral_code
    );
    setLinkCopied(true);
  };
  return (
    <div className="w-screen h-screen  top-0 z-[60] bg-[#1E1E1E] bg-opacity-60 backdrop-blur-md p-4 absolute">
      <div className="flex justify-end max-w-[600px] m-auto md:my-4 pt-8 sm:pt-0">
        <svg
          onClick={(e) => setOpen(false)}
          className="h-[24px] w-[24px] cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 20L4 4M20 4L4 20"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      {/*
            <div class=" justify-center flex my-2">
                <div class={`flex border-[1px]  m-auto ${design === '0' ? 'rounded-[50px] border-[#FFED63]' : 'rounded-[14px] border-[#FDA62D]'}   `}>
                    <div onClick={e => setSelectedButton(1)} className={`${selectedButton === 1 && (design === '0' ? 'bg-[#FFED63]' : 'gradient-tourToggle')} ${design === '0' ? 'rounded-[50px]' : 'rounded-[12px]'}  px-[20px] py-[5px] md:py-[8px]  cursor-pointer`}>
                        <p className={`${selectedButton === 1 && '!text-black'} text-white saira font-bold text-[14px]`}>{languageData?.tourShortToggle}</p>
                    </div>
                    <div onClick={e => setSelectedButton(2)} className={`${selectedButton === 2 && (design === '0' ? 'bg-[#FFED63]' : 'gradient-tourToggle')} ${design === '0' ? 'rounded-[50px]' : 'rounded-[12px]'} px-[20px] py-[5px] md:py-[8px] cursor-pointer`}>
                        <p className={`${selectedButton === 2 && '!text-black'} text-white saira font-bold text-[14px]`}>{languageData?.tourLongToggle}</p>
                    </div>
                </div>
            </div>
*/}
      <div className=" md:max-w-[600px]  m-auto">
        <Swiper
          pagination={{ clickable: true }}
          modules={[Pagination]}
          onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {dataShort.map((item, index) => (
            <SwiperSlide className="px-4" key={item}>
              <p className=" text-[16px] md:text-[22px] font-semibold text-center text-[#00FFFF]">
                {item.title}
              </p>
              <img
                className=" h-[300px] object-cover md:w-full m-auto mt-3 rounded-[12px]"
                src={item.video}
                alt=""
              />
              <p className="saira font-medium text-center text-[14px]  lg:mt-4">
                {item.descShort}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex justify-center mx-2">
          <ul
            onClick={(e) => {
              scrollToPage(3);
              setOpen(false);
            }}
           
            className="max-w-[600px] w-full btnNewHover mt-2 sm:mt-4 "
          >
            <li className={`h-[50px]`}>
              <span></span>
              <span
                className={`fab fa-css3-alt text-[18px] sm:text-[20px] font-semibold saira`}
              >
                {linkCopied === true
                  ? languageData?.CopyLinkDone
                  : languageData?.tourShortBtn}
                <svg
                  className="ml-2"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 12.5L14 5.5V9.5C7 10.5 4 15.5 3 20.5C5.5 17 9 15.4 14 15.4V19.5L21 12.5Z"
                    fill="#1E1E1E"
                  />
                </svg>
              </span>
            </li>
          </ul>
        </div>
        <p
          className={`text-[10px] lg:text-[12px] saira font-medium mt-2 sm:mt-[80px] text-center`}
        >
          {languageData?.tourUnderSlide}
        </p>
      </div>
    </div>
  );
}

export default TourModal;
