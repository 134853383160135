import React, { useEffect, useState } from "react";
import { competitionStore } from "../../../stores/competitionStore";
import gifs from "../../../Helpers/gifs.json";
import wheel from "../../../images/NewDesign2/Games/wheel.png";

function Spin({ languageData, user, setSpinNoTimes }) {
  const isStarted = competitionStore((state) => state.wheelStarted);
  const wheelMultiplier = competitionStore((state) => state.wheelMultiplier);

  const [winMessage, setWinMessage] = useState(false);
  const [winMultiplier, setWinMultiplier] = useState(null);
  const [winPoints, setWinPoints] = useState(null);

  const wheelLimit = competitionStore((state) => state.wheelLimit);

  useEffect(() => {
    if (wheelLimit === true) {
      setSpinNoTimes(true);
    } else {
      setSpinNoTimes(false);
    }
  }, [wheelLimit]);

  useEffect(() => {
    if (isStarted === true) {
      setWinMultiplier(wheelMultiplier);
      setTimeout(() => {
        let newPoints = user.points * Number(wheelMultiplier.replace("X", ""));
        setWinPoints(newPoints);

        setWinMessage(true);
        // setWinMultiplier(null);
        competitionStore.getState().clearWheel();
      }, 4900);
    }
  }, [isStarted, wheelMultiplier]);

  const renderGif = (amount) => {
    switch (amount) {
      case "X2":
        return gifs.spin_2;
      case "X5":
        return gifs.spin_5;
      case "X10":
        return gifs.spin_10;
      case "X15":
        return gifs.spin_15;
      case "X20":
        return gifs.spin_20;
      case "X50":
        return gifs.spin_50;
      default:
        return null;
    }
  };

  return (
    <div className="relative">
      <svg
        className="cursor-pointer absolute top-[45px] sm:top-[25px] 2xl:top-[60px]"
        onClick={(e) => competitionStore.getState().changeMode("default")}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clip-path="url(#clip0_325_26259)">
          <path
            d="M11.3333 14.6667L4.66666 8L11.3333 1.33334"
            stroke="white"
            stroke-width="1.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_325_26259">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(16) rotate(90)"
            />
          </clipPath>
        </defs>
      </svg>
      <div className="m-auto max-w-[1000px] flex  items-center sm:h-[unset] sm:block">
        <div className="flex items-center justify-center">
          <div>
            <p className="text-[16px] sm:text-[28px] 2xl:text-[32px] font-bold saira text-center sm:mt-0 2xl:mt-0 ">
              {languageData?.newSpinTitle}{" "}
              <span className="text-[16px] sm:text-[28px] 2xl:text-[32px] font-bold saira text-[#00FFFF]">
                {user?.spinWheelDate?.split("T")[0] ===
                  new Date().toISOString().split("T")[0] || user?.points == 0
                  ? 0
                  : 1}
              </span>{" "}
              {languageData?.newSpinTitleSpan}{" "}
            </p>
            <p className="saira text-[14px] sm:text-[18px] font-normal text-center px-6 sm:px-0 mt-1">
              {languageData?.newSpinSubTitle2}
            </p>
            <div className="sm:h-[unset]">
              {isStarted === false && winPoints === null ? (
                <img
                  className="w-[240px] sm:w-[200px] 3xl:w-[240px] m-auto rotate-[10deg]"
                  src={wheel}
                  alt="gif"
                />
              ) : (
                <img
                  className="w-[240px] sm:w-[200px] 2xl:w-[240px] m-auto rotate-[10deg]"
                  src={renderGif(winMultiplier)}
                  alt="gif"
                />
              )}
            </div>
            <p className="text-[20px] sm:text-[28px] 2xl:text-[32px] font-bold saira text-center sm:mt-2 2xl:mt-10">
              {winMessage === true
                ? `${languageData?.newSpinSubtitle} ${wheelMultiplier} ${languageData?.newSpinSubtitleSpan2} ${winPoints} ${languageData?.newSpinSubtitleSpan}`
                : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spin;
