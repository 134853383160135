import React from "react";

function LandingLoginBtn({ icon, text, span }) {
  return (
    <div className="border-[#FF00E5] border-2 bg-transparent rounded-[32px] max-w-[320px] w-full m-auto flex justify-center items-center py-3 cursor-pointer mt-4">


<img className="w-[24px]" src={icon} alt="login1" />

      <p className="text-[14px] poppins font-semibold ml-3">
        {text}{" "}
        <span className="text-[14px] poppins font-bold text-[#0FF]">
          {span}
        </span>
      </p>
    </div>
  );
}

export default LandingLoginBtn;
