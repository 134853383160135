import React from "react";
import banner from "../../../images/NewDesign/withdraw/Banners/banner3.png";

import { useState, useEffect } from "react";

function Banner3({ languageData, setPartnerSelected, selectPayment, user, setOpen, handleButtonClick, withdraw }) {
  const [disabled, setDisabled] = useState(true);
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
      setCountdown(0);
    }, 10000);

    const countdownTimer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownTimer);
    };
  }, []);

  return (
    <div>
      <div onClick={(e) => selectPayment("Partner")} className=" relative m-auto mb-[5px] mt-2  w-full max-w-[345px] cursor-pointer sm:max-w-[370px] ">
        <p className="saira bannerTextBtn3 text-center text-[36px] font-extrabold">{languageData?.banner3text1}</p>
        <p className="saira bannerTextBtn3 text-center text-[13px] font-extrabold leading-4">{languageData?.banner3text2}</p>
        <img
          onClick={(e) => {
            setPartnerSelected(true);
          }}
          className=" relative z-10 m-auto mt-2 min-h-full w-full max-w-[345px] sm:max-w-[370px]"
          src={banner}
          alt="banner"
        />

        <p className="saira mt-4 text-center text-[12px] font-semibold leading-[14px]">{languageData?.banner3text3}</p>
        <p className="bannerTextBtn2 text-center text-[22px] font-semibold sm:text-[26px]">
          {languageData?.banner3text5Span} {user.raffle_tickets} {languageData?.banner3text5}
        </p>
        <p className="bannerTextBtn2 text-center text-[22px] font-semibold leading-5 sm:text-[26px]">{languageData?.banner3text4}</p>
      </div>
      <div className="mt-2 flex justify-center lg:mt-4">
        <ul onClick={(e) => {
          setPartnerSelected(true);
          setOpen(false);
        }}
          className={` max-w-[370px] w-full btnNewHover  ${disabled ? `py-2 opacity-[1]` : `py-3`}`}>
          <li className={`!h-[50px]`}>
            
              <span></span>
              <span className={`fab fa-css3-alt text-[18px] font-semibold saira`}>
                <div>
                  <p className="text-[#1E1E1E] text-[14px] sm:text-[18px] font-semibold saira">{languageData?.bannerBtn1}</p>
                  <p className="saira text-[18px] leading-5  text-center text-[#FF00E5]">{disabled ? `${countdown} ${languageData?.bannerBtn2} ⏱️` : ""}</p>
                </div>
              </span>

           
          </li>
        </ul>
      </div>
      <div className="mt-2 flex justify-center">

        <ul onClick={handleButtonClick}
          disabled={disabled}
          className={`${disabled ? 'disabledBanner' : ''} max-w-[370px] w-full btnNewHover `}>
          <li className={`h-[45px]`}>
           
              <span></span>
              <span className={`fab fa-css3-alt text-[18px] font-semibold saira`}>
                {languageData?.bannerBtn2Done}
              </span>

            
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Banner3;
