const { getDomain, getAmount } = require("../../domainHelper");

module.exports = {
  "siteTitle": "" + getDomain() + "",
  "metaDescription": "Enjoy and earn in TreatCoin through " + getDomain() + "!",
  "HeaderTitle": "" + getDomain() + "... <span class='text-gradient'>Double your money!</span>",
  "HeaderSubTitle": "Buy, enjoy, X2 your money!",
  "HeaderButton": "Get paid to try ImLive",
  "LatestOffers": "Latest Offers",
  "OfferTitle": "Our partner - Adult Webcam Experiences",
  "OfferDescription": "Register and deposit $5 on our partner! Our partner will double your credits, giving you 10 instead of 5. After using your our partner credits, " + getDomain() + " will double your money, providing you with $10 back in TreatCoin!",
  "listTexts": [
    "Register on our partner's site",
    "Buy 5 credits and get another 5 for free",
    "Enjoy using the full 10 credits on our partner",
    "Get 10$ from " + getDomain() + " - doubling the 5$ you spent!"
  ],
  "OfferButton": "Earn X2 Now!",
  "CopyLink": "Copy Link",
  "TreatCoins": "Treat Coins",
  "LinkCopied": "Link Copied!",
  "tasks": {
    "task1": {
      "taskTitle": "Register on our partner's site  🎉",
      "taskTitleOpen": "Register on our partner's site  🎉",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task1.png",
          "newTaskImage": "images/newTask/bg_task1.png",
          "taskImageDesk": "images/BgTaskDesk1.png",
          "newTaskImageDesk": "images/BgNewTaskDesk1.png",
          "taskText": "Enjoy our partner, be treated like a KING 👑 - and fulfill your fantasies!"
        }
      ],
      "taskButton": "1/3: Start now 🎉"
    },
    "task2": {
      "taskTitle": "Deposit",
      "taskTitleOpen": "Royal welcome from our partner: buy $5 and experience pleasure",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task2.png",
          "newTaskImage": "images/newTask/bg_task2.png",
          "taskImageDesk": "images/BgTaskDesk2.png",
          "newTaskImageDesk": "images/BgNewTaskDesk2.png",
          "taskText": "Deposit $5 on our partner, and " + getDomain() + " will double the money!"
        }
      ],
      "taskButton": "2/3: Deposit $5, get 5 credits 💸"
    },
    "task3": {
      "taskTitle": "Spend",
      "taskTitleOpen": "Use all credits to get 10$ CashBack from " + getDomain() + "",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task3.png",
          "newTaskImage": "images/newTask/bg_task3.png",
          "taskImageDesk": "images/BgTaskDesk3.png",
          "newTaskImageDesk": "images/BgNewTaskDesk3.png",
          "taskText": "Spend your 5 partner credits, be treated like a king, and get $10 cash from " + getDomain() + "."
        }
      ],
      "taskButton": "3/3: Spend all credits, get $10 back 🤑"
    },
    "task4": {
      "taskTitle": "Withdraw your cash 🤑",
      "taskTitleOpen": "Withdraw your cash 🤑",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task4.png",
          "newTaskImage": "images/newTask/bg_task4.png",
          "taskImageDesk": "images/BgTaskDesk1.png",
          "newTaskImageDesk": "images/BgTaskDesk1.png",
          "taskText": "our partner doubles your credit, we double your money – it's a win-win for you with an extra $10 in your wallet!"
        }
      ],
      "taskButton": "Get $10 from " + getDomain() + ""
    }
  },
  "homepageTitle1": "1. Spend $5 for our partner’s royal treatment & get $10 cashback from " + getDomain() + "",
  "homepageTitle2": "2. Get as many tickets as you can for the best chance to win Vegas promotion!",
  "homepageTitle3": "3. Finish in the top 10 and win the 6.9$ daily competition!",
  "homepageTitle4": "Double your money and have the chance to win the Vegas weekend!",
  "homepageTitle5": "Get the king treatment in our partner and win the Vegas weekend",
  "homepageBtn1": "Double your money ",
  "homepageBtn2": "2/3: Deposit for 200% Cashback",
  "homepageBtn3": "3/3: Spend for 200% Cashback 🤑",
  "homepageBtn4": "Win the Vegas Weekend 💃",
  "timeCounterTitle": "Countdown to Vegas Promotion:",
  "timeCounterTitle2": "Countdown to day’s end:",
  "timeCounterDays": "Days",
  "timeCounterHours": "Hours",
  "timeCounterMinutes": "Minutes",
  "timeCounterSeconds": "Seconds",
  "raffleInfoTitle1": "Vegas eligibility indicates that you, your referred friends, or the friend who referred you are qualified to take part in the Vegas promotion through one of two methods:",
  "raffleInfoTitle2": "Selection for a Trip to Vegas:",
  "raffleInfoTitle2Span": "If your friend or your referrer have won, you may be chosen to go to Vegas.",
  "raffleInfoTitle3": "Promotion Participation:",
  "raffleInfoTitle3Span": "Alternatively, you can participate in the promotion by possessing promotion tickets.",
  "raffleInfoTitle4": " For additional information, please refer to the",
  "raffleInfoTitle4Span": "competition details.",
  "raffleTitle1": "Don’t miss a chance to win the amazing Vegas Promotion",
  "raffleSubTitle1": "If you took our partner's double-your-money offer, you can be picked by your referral or referrer to go to Vegas if they win.",
  "raffleLink1": "How do I win the vegas weekend?",
  "raffleTitle2": "Win the Vegas weekend!",
  "raffleSubTitle2": "Eligible to go to vegas",
  "raffleLink2": "How do I win the vegas weekend promotion?",
  "raffleUnderBtn": "Collect as many Vegas tickets as you can & better your chance to win:",
  "raffleUnderBtn2": "Earn more Vegas tickets to increase your draw-winning chances:",
  "raffleLeftBtn": "Enjoy Live Experiences 💃",
  "raffleLeftSection1": "1 credit bought =",
  "raffleLeftSection2": "1  Vegas ticket",
  "raffleRightBtn": "Refer friends 🤑",
  "raffleRightBtn2": "Link copied!",
  "raffleRightSection1": "Friend doubled money =",
  "raffleRightSection2": "30 Vegas tickets + 1$",
  "raffleRulesDesc": "*No purchase needed to participate in the promotion.",
  "raffleRulesLink": "Promotion rules",
  "doubleTitle": "Double your money - have fun!",
  "doubleSubtitleSpan": "The",
  "doubleSubtitle": "will appear after task is completed",
  "doublePaid": "Withdraw",
  "refferalsTitle": "Earn $1, 30 Vegas tickets, and 10 competition points per each friend referred that completes the offer!",
  "refferalsLink1": "Read More about your referral benefits",
  "refferalsSubtitle": "Select a text and image or simply share your referral link with friends",
  "refferalsImage": "Choose an image",
  "refferalsLink2": "Choose personalize experience",
  "refferalsSome": "Add some photos",
  "refferalsInfoTitle": "Be the king",
  "refferalsInfo1": "You will be the kind that sends your friends to double their money and have fun 👬",
  "refferalsInfo2": "For eveery friend that doubles the money you will get 30 promotion tickets and 1$ 🃏",
  "refferalsInfo3": "If your friends double their money, you can take them to Vegas if you win the promotion, and they can take you if they win and you took the double-the-money offer",
  "competitionTitleSpan": "daily competition",
  "competitionPoints": "Points",
  "competitionSubtitle": "First 2 places get $6.9 each, 3-10 $6.90 each.",
  "competitionLeftBtn": "Enjoy Live Experiences 💃",
  "competitionLeftSection1": "1 credit bought =",
  "competitionLeftSection2": "0.5 competition points",
  "competitionRightBtn": "Refer friends 🤑",
  "competitionRightBtn2": "Link copied!",
  "competitionRightSection1": "Friend doubled money =",
  "competitionRightSection2": "10 competition points + US$1",
  "competitionRightLink1": "Leaderboards",
  "competitionRightRules": "Rules of the competition",
  "header1": "Earned:",
  "header2": "Competition Points:",
  "header3": "Vegas Tickets:",
  "modalMenuLink1": "Home page",
  "modalMenuLink2": "Double your money",
  "modalMenuLink3": "The  Vegas weekend",
  "modalMenuLink4": "Refer to get tickets",
  "modalMenuLink5": "My Eligible Referrals for Vegas",
  "modalMenuLink6": "The",
  "modalMenuLink6Span": "$6.9 Daily Competition",
  "modalMenuLink7": "Withdraw your money",
  "modalMenuLink8": "Chat room",
  "modalMenuLink9": "Transaction History",
  "modalMenuLink10": "My Tickets & Points",
  "modalMenuLink11": "FAQ",
  "modalMenuLink12": "Our mission",
  "modalMenuLink13": "Why join us?",
  "modalMenuBottom1": `About ${getDomain()}`,
  "modalMenuBottom2": "Legal",
  "modalMenuBottom3": "Account Settings",
  "modalMenuBottom4": "Contact Us",
  "modalMenuBottom5": "Vegas Weekend Tour",
  "chatOnline": "online",
  "chatTitle": "Chat Room",
  "chatNotMessages": "There are no messages yet",
  "chatGif": "Choose GIF",
  "chatNotGif": "No results found",
  "withdrawTitle1": "Withdraw",
  "withdrawSubtitle": "It’s a full withdrawal or nothing at all.",
  "withdrawPrice": "Available",
  "withdrawTitle2": "Choose your way to get paid:",
  "withdrawTitle3": "You’ll get a cash voucher to an email of your choice:",
  "withdrawLink": "Click to see what payment methods are avaliable in your country",
  "withdrawBtn": "Withdraw",
  "noTransactions": "You haven't made any transactions yet",
  "ransactionsTitle": "Transaction History",
  "transactionsSection1Left": "Doubling your money and enjoying our partner!",
  "transactionsSection1Right": "Added to Balance",
  "transactionsSection2Left": "Your friend doubled their money",
  "transactionsSection2Right": "Added to Balance",
  "transactionsSection3Left": "Withdraw money",
  "transactionsSection3Left2": "to Pix",
  "transactionsSection3Right": "Added to Balance",
  "transactionsSection4Left": "Withdraw money",
  "transactionsSection4Left2": "/ Pix",
  "transactionsSection4Right": "Withdrawn",
  "ticketsTitle": "My Tickets & Points",
  "ticketsSection1Left": "Your friend doubled their money",
  "ticketsSection1Right": "Vegas tickets",
  "ticketsSection2Left": "Sent mail",
  "ticketsSection2Right": "Vegas tickets",
  "ticketsSection3Left": "For",
  "ticketsSection3Left2": "credits purchased on our partner!",
  "ticketsSection3Right": "Vegas tickets",
  "ticketsSection4Left": "Friend completed succfully double the money and to rceive points",
  "ticketsSection4Right": "Points",
  "ticketsSection5Left": "Sent mail",
  "ticketsSection5Right": "Points",
  "ticketsSection6Left": "For",
  "ticketsSection6Left2": "credits purchased on our partner!",
  "ticketsSection6Right": "Points",
  "aboutModalTitle": "About us",
  "aboutModalDesc1": `Welcome to ${getDomain()}, your gateway to transforming online engagement into tangible, thrilling rewards!`,
  "aboutModalDesc2": "In a world where the value of your digital actions often feels intangible, we provide a platform that not only appreciates your participation but also rewards it handsomely.",
  "aboutModalDesc3": `From doubling your money with a simple transaction to winning all-expenses-paid adventures, ${getDomain()} is here to make every click count.`,
  "aboutModalDesc4": `${getDomain()} - where every click brings a reward, and every reward brings a (profitable) smile.`,
  "contactModalTitle": "Contact us",
  "contactModalSubTitle": "Send us your email and message and we will contact you",
  "contactModalBtn1": "Sended!",
  "contactModalBtn2": "Send message",
  "legalModalTitle": "Legal",
  "legalModalDesc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "messageBtn": " Copy message",
  "settingsTitle": "Account settings",
  "settingsUserName": "User name",
  "settingsUserEmail": "E-mail",
  "settingsCheckbox": "Receive notifications and reminders via email",
  "settingsDeleteStep": "Delete account",
  "settingsDeleteSure": "If you delete your account all your achievements will be lost",
  "settingsDeleteSure2": "Are you sure you want to delete your account?",
  "settingsDeleteNo": "No",
  "settingsDeleteYes": "Yes",
  "settingsBtn": "Apply changes",
  "settingsBtnDone": "Saved!",
  "leaderboardsTitle": "Leaderboards",
  "leaderboardsPoints": "Pts",
  "leaderboardsPrize": "Prize",
  "tourShortTitle": "Lorem ipsum",
  "tourLongTitle": "Lorem ipsum",
  "tourShortToggle": "Short version",
  "tourLongToggle": "Long version",
  "tourShortBtn": "Share with your friends",
  "tourLongBtn": "Share the tour",
  "authSlide1": "“My wish is your command - let the thrill invade you”",
  "authSlide1li1": "1. Double your money - we value your time",
  "authSlide1li2": "2. Have a chance to win Vegas promotion",
  "authSlide1li3": "3. Conquer the 6.9$ daily competition",
  "authSlide2": "1. Choose a 200% | 400% HOT LIVE cashback promotion!",
  "authSlide2li1": "Enjoy our HOT LIVE partner site with a $5 purchase! ",
  "authSlide2li2": "Not for you? $10 back—double your cashback.",
  "authSlide2li3": "❤️ Obviously love it? Get an hour of HOT LIVE credits worth $20 on us - quadruple your cashback!",
  "authSlide3": "2. Vegas with Friends!",
  "authSlide3li1": "Get Vegas tickets for a chance to win our amazing Vegas promotion - a weekend of your dreams you will never forget!",
  "authSlide4": "3. Win the 6.9$ daily competition",
  "authSlide4li1": "Earn points by engaging further in " + getDomain() + ". Finish first in our top 10 to win 6.9$, daily! ",
  "authEnterEmail": "Enter your e-mail",
  "authContinue": "Join/Login",
  "authDiscord": "Join/Login with",
  "authGoogle": "Join/Login with",
  "authFacebook": "Join/Login with",
  "verifyCodeTitle": "Verify your account",
  "verifyCodePlaceholder": "Enter verification code sent by e-mail",
  "verifyCodeError": "Code is wrong",
  "verifyCodeBtn": "Submit",
  "verifyCodeLink": "Already have an account?",
  "verifyCodeLinkSpan": "Sign in",
  "verifyCodeEmailTitle": "We've sent a verification email to",
  "openEmailApp": "PLEASE OPEN YOUR EMAIL APP",
  "logoutConfirmTitle": "Are you sure you want to log out?",
  "logoutConfirmNo": "No",
  "logoutConfirmYes": "Yes",
  "winTitle": "How can I get Vegas Tickets to win the Vegas Promotion?",
  "winSubTitle": "How can I get Vegas Tickets to win the Vegas Promotion?",
  "winPoint1Title": "Buy more credits in our partner",
  "winPoint1li1": "⁠Every extra credit you buy on top of the first 5 you buy in the double the money offer will give you 1 Vegas tickets (no bonus credits included)",
  "winPoint2Title": "Refer friends that complete the double the money offer",
  "winPoint2li1": "Refer your friends and get 30 Vegas tickets for each friend that completes the double the money offer",
  "winPoint3Title": "Send a Free Post Form by mail to our office",
  "winPoint3li1": "⁠Download the form",
  "winPoint3li2": "⁠⁠Fill your details and send it to our offices in Cyprus (address detailed on the downloadable form)",
  "winPoint3li3": "Every letter you send can include one form only and will reward you with one ticket",
  "winPointEnd": "Good luck!",
  "winPointEndLink": "To the Vegas Promotion full terms",
  "rulesTitle": "🎉 Welcome to the " + getDomain() + " Competition! 🎉",
  "rulesSubTitle": "Starting on March 15th, 2024, and continuing daily, join us for the " + getDomain() + " Competition.",
  "rulesPoint1Title": "To enter:",
  "rulesPoint1li1": "Sign in to our partner",
  "rulesPoint1li2": "⁠Purchase 5 credits and receive a bonus of 5 more",
  "rulesPoint2Title": "Ways to earn rewards:",
  "rulesPoint2li1": `Spend all 10 credits on our partner and receive $10 USD on ${getDomain()}`,
  "rulesPoint2li2": "$6.9 USD for the 1st place and $6.90 USD for the 2nd – 10th places each – daily!",
  "rulesPoint2li3": "$1 USD + 30 Vegas Tickets + 10 Points for each successful referral who completes the Offer",
  "rulesPoint2li4": "1 Vegas Ticket + 0.5 Points for every credit bought after the first purchase on our partner",
  "rulesEnd": `Best of luck to all participants in the ${getDomain()} Competition!`,
  "toolInfoTitle": `What can I get in ${getDomain()} and how?`,
  "toolInfoWhat": "What?",
  "toolInfoHow": "How?",
  "toolInfolef1": "10$ in your pocket",
  "toolInfolef2": "Win the Vegas Promotion",
  "toolInfolef3": "Win the 6.9$ daily competition",
  "toolInfoRight1": "Complete the “Double your money” offer",
  "toolInfoRight2": "Collect ticket by buying our partner credits and refer friends that complete the offer",
  "toolInfoRight3": "Get points by buying our partner credits and refer friends that complete the offer",
  "ourMissionTitle": "Our Mission",
  "ourMissionSubTitle1": `${getDomain()} emerged from the simple idea that online platforms should offer more direct value to their users.`,
  "ourMissionSubTitle2": "We're dedicated to creating an environment where every action—whether it's a deposit, a referral, or a purchase—has a rewarding outcome.",
  "ourMissionSubTitle3": "Our platform is designed to celebrate and reward the community for their engagement and loyalty, turning everyday activities into opportunities for joy and excitement.",
  "whyJoinTitle": "Why Join Us?",
  "whyJoinSubTitle1": `${getDomain()} stands at the intersection of innovation, reward, and community. We're more than a platform; we're a movement towards creating a more rewarding online ecosystem. Our dedication to providing transparent, immediate, and substantial rewards sets us apart, ensuring that your time spent with us is not only enjoyable but also fruitful.`,
  "whyJoinSubTitle2": `Our community is the core of our success, and we're committed to fostering a space where every member can experience the joy of winning. From cash rewards to dream vacations, ${getDomain()} is dedicated to turning your digital engagement into real-world delights.`,
  "FAQ_Questions": [
    {
      "question": "What am I doing here?",
      "answers": [
        "You are here to guaranteed 100% double your initial cash deposit, compete for a chance to win a thrilling Vegas weekend, and participate in our 6.9$ daily contest."
      ]
    },
    {
      "question": "What are the requirements to qualify for the $5 instant cash back?",
      "answers": [
        "Simply register on our partner, deposit $5 to receive 5 credits, spend those credits, and you'll receive 100% cashback instantly here."
      ]
    },
    {
      "question": "Can I withdraw my $5 cash back immediately after receiving it?",
      "answers": [
        "Yes, your $5 cash back is available for immediate withdrawal"
      ]
    },
    {
      "question": `How can I invite friends to join ${getDomain()}, and what are our benefits?`,
      "answers": [
        "Use the 'Refer Your Friends' page. For every friend that completes the offer, you get $1 cash, 30 Vegas tickets, and 10 daily contest points. Plus, if you win the Vegas promotion and they have completed the “Double your money offer”, you can take two of them to Vegas with you!"
      ]
    },
    {
      "question": "What steps must my friends complete for them to qualify for the Vegas trip?",
      "answers": [
        "Your friends need to register through your referral link and complete the double money offer for you to be eligible to invite up to 2 of them as your travel companions for the Vegas trip."
      ]
    },
    {
      "question": "What are the Vegas promotion rules?",
      "answers": ["Lorem ipsum"]
    },
    {
      "question": `Is there a limit to how many friends I can refer to ${getDomain()}?`,
      "answers": [
        "No, there is no limit. Refer as many friends as you like and earn more!"
      ]
    },
    {
      "question": "How are winners for the Vegas weekend chosen?",
      "answers": [
        "Winners are selected through a live draw in the presence of a lawyer and an accountant, ensuring fairness and transparency. Link to Vegas weekend rules"
      ]
    },
    {
      "question": "What expenses are covered in the Vegas weekend prize?",
      "answers": [
        "The Vegas weekend prize covers your travel and entertainment extravagantly. It includes round-trip tickets to Las Vegas for you and two friends that have completed the double the money offer if you choose to take them, two nights of 5-star hotel accommodation, and $1,000 in our partner credits for the winner. Additionally, you'll receive $1,000 cash, and if applicable, each of your two friends will get $500 to enjoy. The package is crowned with a special dinner and a Cadillac drive experience with 2 porn stars, valuing the total prize at approximately $5,000."
      ]
    },
    {
      "question": "How do I purchase additional partner credits?",
      "answers": [
        "Click here to buy more credits and increase your chances of winning the Vegas promotion and 6.9$ daily contest points"
      ]
    },
    {
      "question": "Are there any limits on how many credits I can buy?",
      "answers": [
        "No, there are no limits to how many credits you can purchase. As long as you purchase more, you have better chance to win the Vegas promotion or finish in the top 10 in the 6.9$ daily contest."
      ]
    },
    {
      "question": "How does the $6.9 daily leaderboard work, and how can I earn points?",
      "answers": [
        "Earn points by referring friends and purchasing our partner credits. The more you engage, the higher you climb on the leaderboard. For every friend you refer that completed the offer, you get 10 points. For every extra credit on our partner that you purchase, you get 0.5 points. "
      ]
    },
    {
      "question": "What are the rewards for the top 10 positions in the daily leaderboard?",
      "answers": [
        "The top two places win $6.9 each, and places 3-10 win $6.90 each, every day."
      ]
    },
    {
      "question": "How often are the leaderboard and rewards updated?",
      "answers": [
        "The leaderboard and rewards are updated immediately, in real-time."
      ]
    },
    {
      "question": "Can I win daily rewards multiple times?",
      "answers": [
        "Yes, you can win daily rewards as often as you top the leaderboard top 10."
      ]
    },
    {
      "question": "What happens if there’s a tie on the daily leaderboard?",
      "answers": [
        "In the event of a tie, the user with the most referred friends is chosen, followed by the one with the most purchased credits, and then by the earliest completion of the offer."
      ]
    },
    {
      "question": "Are there any fees or charges for depositing or withdrawing money?",
      "answers": [
        "No, there are no fees or charges for any financial transactions on our platform."
      ]
    },
    {
      "question": "How can I track my referrals and rewards in real-time?",
      "answers": [" to monitor your referrals and rewards as they happen."],
      "link": "[referralsModal]",
      "linkName": "Click here"
    },
    {
      "question": "Why don't I see my deposit on the partner even after depositing?",
      "answers": [
        `Deposits typically reflect on your ${getDomain()} account within 3 minutes, crediting you with the corresponding funds. If you do not see your deposit after 30 minutes, we recommend reaching out to our customer support for assistance. `
      ]
    },
    {
      "question": "Who can I contact for support if I encounter issues with my account or rewards?",
      "answers": [" to reach our chat for any assistance you need."],
      "link": "[contactForm]",
      "linkName": "Click here"
    },
    {
      "question": "What are the terms of service?",
      "answers": [
        "For all the details on our policies: ",
        "- Terms of Service",
        "- Daily Contest Terms",
        "- Promotion Terms",
        "- Cookie Policy ",
        "- Privacy Policy",
        "- Affiliation Terms"
      ]
    }
  ],
  "dataMessageDesc1": "Imagine us in Vegas if we win. Game for an adventure?",
  "dataMessageDesc2": "I'm handing out free cash by referring you. Interested in a boost?",
  "dataMessageDesc3": "Daily contests are up. Think you can outdo me? Let's see.",
  "dataMessageDesc4": "Vegas with me? Win and we're jet-setting. Let's gamble on me.",
  "dataMessageDesc5": "Yo, playing royal banker here. Join me, get free cash. It's a reign of money!",
  "dataMessageDesc6": "Joining me = non-stop fun. Seriously, it's a blast.",
  "dataMessageDesc7": "Join my royal entourage and let's turn your membership into my kingdom's treasury. Win-win, right?",
  "dataMessageDesc8": "Hop on board and let's turn your membership into my crown jewels. Sounds like a royal deal, huh?",
  "tourSlide1Title": "Vegas Promotion Awaits",
  "tourSlide1SubTitle": "Flight tickets to Vegas on our expense to you and 2 friends you picked* who completed the “Double your money” offer. 2 nights in a 5-star hotel, 1,000$ for your spending and 500$ for each of your friend’s 🎉",
  "tourSlide2Title": "Glamorous Cadillac Cruise",
  "tourSlide2SubTitle": "Zoom through Vegas in a Cadillac with 2 hot stars. Experience the glitz and glamour up close with two of your best friends. Be the center of attention in the streets of Vegas for a few hours 🔥",
  "tourSlide3Title": "Dinner + Nightclub Fun With your New Star Friends",
  "tourSlide3SubTitle": "Dinner on us with your new hot star friends, followed by a nightout in a Las Vegas Elite club. Make unforgettable memories in Vegas that will stay an eternity 🏆",
  "withdrawInputEmail": "Enter your e-mail",
  "withdrawContinue": "Continue",
  "verificationTitle": "OTP Verification",
  "verificationSubTitle": "We have sent you a one-time-password at ",
  "PIXTitle": "Please insert your Pix details:",
  "PIXInpu1": "Pix Key:",
  "PIXInpu2": "CPF",
  "withdrawConfirm": "Your request to withdraw has been received...",
  "withdrawError": "An error occurred, Please try again or contact Customer Support.",
  "withdrawErrorTry": "Close and Try again",
  "withdrawLi1": "1. Submit your email to receive your exclusive voucher.",
  "withdrawLi2": "2. Check your inbox for our email and click on the 'Redeem' option within the voucher.",
  "withdrawLi3": "3. You'll be directed to our voucher store, hosted by XOXOday.",
  "withdrawLi4": "4. Once there, choose a way to redeem your vouchers, such as through PayPal or with Visa prepaid cards.",
  "withdrawLi5": "5. They will process your request and send you the funds.",
  "withdrawLi6": "6. In case you need any assistance, contact our",
  "withdrawLi6Span": "customer support.",
  "myReferralsTitle": "My Eligible Referrals for Vegas",
  "myReferralsSubTitle": "See if your referred friends took the our partner double the money offer—only then can you take them to Vegas if you win!",
  "myReferralsDoneTitle": "You’re referred by: ",
  "myReferralsDoneSubTitle": "They completed the offer and are eligible for Vegas pick if you win the draw!",
  "myReferralsUnDoneTitle": "You’re referred by:",
  "myReferralsUnDoneSubTitle": "They still didn’t complete the offer and are not eligible for Vegas pick if you win the draw :(",
  "myReferralsSheet1": "Name",
  "myReferralsSheet2": "Took Offer",
  "myReferralsSheet3": "Send Email",
  "myReferralsSend": "Send",
  "myReferralsSubTitle2": "You haven't referred friends yet",
  "winTicketTitle": "Can I still go to Vegas if my ticket didn’t win?",
  "winTicketSubTitle": "Yes! In order for you, your referrer or your referral to be picked by the other if they win the Vegas promotion, you will need to:",
  "winTicketLiTitle": "Join the " + getDomain() + " Promotion on our partner:",
  "winTicketLi1": "Create a new account or use an existing one.",
  "winTicketLi2": "Make a minimum purchase of five credits to win five more.",
  "winTicketLi3": "Spend the entire five (5) credits received on our partner.",
  "winTicketBtn": "Go to Double Your Money page",
  "winTicketEnd": "Good luck!",
  "winTicketLink": "To the Vegas Promotion full terms",
  "leaderSheetYou": "Your place",
  "leaderSheetPoints": "Competition Points",
  "leaderSheetFriends": "Friends Referred",
  "raffleRulesDescSpan": "Read More.",
  "competitionOverBtn": "Get as many points as you can to win the 6.9$ daily competition:",
  "infoOfferTitle": "What do I get when I refer a friend that completes the “Double your money” offer?",
  "infoOfferPoint1": "A friend that will thank you for making him free money",
  "infoOfferPoint2": "1$ you can withdraw as cash immediately",
  "infoOfferPoint3": "30 Vegas Tickets that better your chance to win the Vegas promotion",
  "infoOfferPoint4": "10  6.9$ daily competition points that gets you closer to the top 10",
  "infoOfferPoint5": "A friend you can pick to Vegas if you win the promotion",
  "infoOfferPoint6": "Your friend will be able to pick you to Vegas if they won the promotion and you completed the “Double your money” offer",
  "earnedModalTitle": "Latest USD earnings",
  "TicketModalTitle": "Latest Vegas Tickets earnings",
  "PointsModalTitle": "Latest contest points earnings",
  "earnedModalLink": "View full information",
  "toolInfoLeft1": "Action",
  "toolInfoPoint1": "USD earned",
  "toolInfoPoint2": "Points",
  "toolInfoPoint3": "Tickets",
  "contactQuestionTitle": "What is your issue?",
  "contactQuestion1": "I didn't receive my money",
  "contactQuestion2": "I didn't receive my competition points",
  "contactQuestion3": "I didn't receive my Vegas tickets",
  "contactQuestion4": "I have issues with my account",
  "contactQuestion5": "Forgot my password",
  "contactQuestion6": "Complaints",
  "contactQuestion7": "Others - please write about it",
  "contactQuestionEmail": "Your email",
  "contactQuestionDesc": "Description",
  "contactQuestionAttach": "Attach files",
  "contactConfirmTitle": "Your message has been sent! We will contact you soon",
  "contactConfirmBtn": "Continue",
  "timeCounterLink": "*Subject to the daily competition Terms and conditions",
  "raffleBotLink1": "👬Can I still go to Vegas if my ticket didn’t win? 👬",
  "raffleBotLink2": "You will need to double your money",
  "doubleBtnTitle": "" + getDomain() + " commands you to...",
  "doubleBtnAway": "minutes away",
  "doubleBtnFrom": "from withdraw",
  "doubleCompleteTitle": "Congratulations, you have 10$ dollars waiting for you, go get it and withdraw it!",
  "doubleCompleteTitleSpan": "inbox!",
  "doubleCompleteSubTitle": "Be the king of your friends by referring them to " + getDomain() + " and they’ll thank you forever... 👑",
  "doubleCompleteBtn": "Share",
  "doubleTaskBtn1": "1/3: Start now 🎉",
  "doubleTaskBtn2": "2/3: Deposit $5, get 5 credits 💸",
  "doubleTaskBtn3": "3/3: Spend all credits, get $10 back 🤑",
  "referralShareBtn1": "Share Referral Link!",
  "referralShareBtn2": "Link copied!",
  "ModalMenuToggle": "Choose your design",
  "leaderSheetToday": "Today",
  "leaderSheetYesterday": "Yesterday",
  "promoModalTitle": "Withdraw with",
  "promoModalMessage": "Don't be a fool! Take this offer and withdraw 30% more — 65 reais instead of 50.",
  "promoModalLink": "No, thanks",
  "promoImLiveDesc": "Don't be a fool! Take this offer and withdraw 30% more — 65 reais instead of 50.",
  "confirmLiveDesc": "Are you sure you want to Withdraw with our partner?",
  "earnedModalTitleBR": "Latest R$ earnings",
  "modalMenuLink14": "Read More",
  "raffleSubTitleLink": "How can I earn Vegas tickets?",
  "competitionTitle": "Win the ",
  "competitionTitle2": " Daily Competition!",
  "leaderboardsNotUser": "There are no more users",
  "NamePage1": "Homepage",
  "NamePage2": "Double your money",
  "NamePage3": "Vegas Weekend",
  "NamePage4": "Competition",
  "NamePage5": "My referrals",
  "withdrawGiftTitle": "Gift Card / Cash",
  "doubleBtnEndTask": "Win the $6.9 Daily Competition!",
  "paxumFlowTitle": "You’ll get a cash voucher to an email of your choice:",
  "paxumFlowInput": "Enter your Paxum ID",
  "paxumFlowPlaceholder": "Enter your Paxum ID",
  "paxumFlowBtn": "Continue",
  "paxumFlowLink1": "Don’t have Paxum account?",
  "paxumFlowLink2": "Create it here",
  "visaFlowTitle": "You’ll get a cash voucher to an email of your choice:",
  "visaFlowSubTitle": "First withdraw has a minimum of ",
  "visaFlowSubTitle2": " in this type of payment",
  "visaFlowSelectName1": "Transfer to",
  "visaFlowSelect1": "Prepaid Virtual Debit Card",
  "visaFlowInput": "Enter your Paxum ID",
  "visaFlowPlaceholder": "Enter your Paxum ID",
  "visaFlowSelectName2": "Card type",
  "visaFlowSelect2": "Visa USD",
  "visaFlowSelectName3": "Value",
  "visaFlowSelect3": "Visa Prepaid Card",
  "visaFlowBtn": "Continue",
  "aboutModalDesc5": "" + getDomain() + " is a cutting-edge global social referral platform that motivates its community with rewards like contests, prizes, and cashback for interacting with high-quality partner in various sectors.",
  "aboutModalDesc6": "Drawing inspiration from the children's game 'Simon Says'; " + getDomain() + " also includes gamification elements, ending in amazing rewards and unlimited fun for its users.",
  "loginTermsTitle": "By registering to " + getDomain() + " you state you are 18 +years old and you agree to our",
  "loginTermsLink": "Terms & Services",
  "homepageMessageTitle": "Tell her what to do and she will do it… LIVE",
  "SPEITitle": "You’ll get a cash voucher to an email of your choice:",
  "SPEIInput1": "Name",
  "SPEIInput2": "Last name",
  "SPEISelect1": "Type of Document",
  "SPEIInput3": "Document",
  "SPEIInput4": "Email",
  "PSETitle": "You’ll get a cash voucher to an email of your choice:",
  "PSEInput1": "Email",
  "PSEInput2": "Name",
  "PSEInput3": "National identity card (CC)",
  "ServiPagTitle": "You’ll get a cash voucher to an email of your choice:",
  "ServiPagInput1": "Email",
  "ServiPagInput2": "Name",
  "ServiPagInput3": "National identity card (RUT)",
  "TransactionCompetitionWinner": "Finishing #",
  "TransactionCompetitionWinner2": "in the 6.9$ daily competition!",
  "modalMenuLink15": "Join as an influencer",
  "modalMenuLink16": "Partner Program",
  "InfluencerTitle": "Join us as an influencer",
  "InfluencerSubTitle": "We’re looking for worldwide and local leading influencers. Join us to be associated with worldwide leading brands and be exposed to amazing opportunities. ",
  "InfluencerInput1": "Email",
  "InfluencerInput2": "Name",
  "InfluencerSelect1": "Who you are?",
  "InfluencerOptoin1": "Choose who you are",
  "InfluencerInput3": "Your message",
  "InfluencerBtn": "Continue",
  "collaborateTitle": "Partner Program",
  "collaborateSubTitle": "" + getDomain() + " is seeking to collaborate with international businesses that have a global reach, aiming to engage with our high-volume user traffic.",
  "collaborateInput1": "Email",
  "collaborateInput2": "Name",
  "collaborateSelect1": "Who you are?",
  "collaborateOptoin1": "Choose who you are",
  "collaborateInput3": "Your message",
  "collaborateBtn": "Continue",
  "paxumFlowCheckbox": "I will only be paid with Paxum if I have a valid account.",
  "toolInfolef4": "Your ticket didn’t win to Vegas? You can still go!",
  "toolInfoRight4": "If a friend that you referred or your referrer have won the promotion and you completed the offer, they can pick you to go to Vegas!",
  "withdrawConfirmPaxum": "We've received your request. Our team will reply once withdrawal is successful",
  "withdrawBanner": "Get +40%",
  "withdrawBannerSpan": "of your money with our partner.",
  "imLivePromoDesc": "our partner provide you with the best value for your cash: get 1 credit for every 5$ + extra 20% credit",
  "imLivePromoVtn": "Take this offer",
  "InfluencerInputPhone": "Phone",
  "InfluencerWho": "Who are you?",
  "InfluencerWhoSelect": "Choose who you are",
  "InfluencerWhoOption1": "Crypto Influencer",
  "InfluencerWhoOption2": "Top Model",
  "InfluencerWhoOption3": "Content Creator on Instagram",
  "InfluencerWhoOption4": "Content Creator on YouTube",
  "InfluencerWhoOption5": "TikToker",
  "InfluencerWhoOption6": "Other",
  "InfluencerNickname": "Social network nickname",
  "InfluencerFollowers": "Social network and followers",
  "InfluencerThanks": "Thank you for the request. Our team will contact you soon",
  "collaboratePhone": "Phone",
  "collaborateURL": "Website URL",
  "collaborateJob": "Job open",
  "collaborateWho": "Who are you?",
  "collaborateWhoSelect": "Choose who you are",
  "collaborateWhoOption1": "Local business",
  "collaborateWhoOption2": "Agent",
  "collaborateWhoOption3": "Worldwide company",
  "collaborateWhoOption4": "Other",
  "withdrawBannerTitle": "Or get +38% value as our partner credits:",
  "withdrawBannerMessage": "Don’t let",
  "withdrawBannerSpan1": "Get ",
  "withdrawBannerSpan2": "credits",
  "withdrawBannerSpan3": "to spend now!",
  "withdrawBannerBtn": "Get Now",
  "withdrawBannerSubtitle": "* The percentages of the benefit are calculated based on the basic rate of 6.9 BRL for 1 partner credit. Percentages will go down while buying higher credit packages in our partner, however that option always hold a benefit.",
  "modalMenuLink17": "My earnings",
  "modalMenuCopyLinkTitle": "Be the king of your friends - refer them",
  "modalMenuCopyLinkSubTitle": "For every friend that completed offer:",
  "modalMenuLink18": "Customer support",
  "transactionApprovedTitle": "Your withdrawal request through ",
  "transactionApprovedSpan": "is approved",
  "transactionApprovedRight": "Withdraw approved",
  "transactionPendingTitle": "Your withdrawal request through",
  "transactionPendingSpan": "is pending",
  "transactionPendingRight": "Pending",
  "transactionRejectedTitle": "Your withdrawal request through",
  "transactionRejectedSpan": "is rejected",
  "transactionRejectedRight": "Withdraw rejected",
  "transactionID": "Transaction ID:",
  "raffleSubTitleLink2": "Take the Vegas Tour",
  "CopyLinkDone": "Link Copied!",
  "aboutModalDesc": "",
  "leaderSheetDayAgo": "2 days ago",
  "verifyCodeSpam": "Can't find the e-mail? ",
  "verifyCodeSpam2": "Check your spam folder",
  "timeCounterLink2": "Vegas Promotion Rules",
  "pixType": "Pix type",
  "pixTypeOption1": "CPF / CNPJ",
  "pixTypeOption2": "Cellphone",
  "pixTypeOption3": "E-mail",
  "pixTypeOption4": "Random",
  "pixTypeSelectMain": "Select Pix type",
  "pixTypeSelectName1": "Enter CPF / CNPJ",
  "pixTypeSelectName2": "Enter cellphone",
  "pixTypeSelectName3": "Enter recipient email",
  "pixTypeSelectName4": "Random",
  "tourUnderSlide": "*Only friends who got referred by you or your referrer to " + getDomain() + " can be picked by you to go to Vegas if you win",
  "transactionWithdraw": "Withdrawn",
  "transactionTransactionID": "Transaction ID:",
  "ticketsToggle1": "Vegas Tickets:",
  "ticketsToggle2": "Points:",
  "myReferBtn": "Refer your friends 👬",
  "authNotificationBtn": "Open in",
  "authNotificationBtnSpan": "Mail App",
  "newVisaFlowInput1": "Full Name",
  "newVisaFlowInput2": "Email Adress",
  "newVisaFlowSelect1": "Citizenship",
  "newVisaFlowInput3": "Birth Date",
  "newVisaFlowCheckbox": "You agree to our",
  "newVisaFlowCheckboxSpan": "privacy policy",
  "transactionStatus": "Status:",
  "transactionPending": "Pending",
  "transactionApproved": "Approved",
  "transactionDeclined": "Declined",
  "transactionNoData": "No data",
  "referralInvite": "Invite your friends",
  "bannerBtn1": "GET THE CREDITS & THE TICKETS",
  "bannerBtn2": "seconds",
  "bannerBtn2Done": "Continue",
  "doubleCompleteTitle1": "Congratulations, you have ",
  "doubleCompleteTitle2": " dollars waiting for you, go get it and withdraw it!",
  "banner1text1": "Don’t let",
  "banner1text1span": "wait… she’s eager to deepen the connection!",
  "banner1text2": "He didn’t even say goodbye nicely…",
  "banner1text3": "Act now",
  "banner1text4": "Get 12 credits as 38%+ value on your ",
  "banner1text4span2": "and return to",
  "banner1text4span": "now",
  "banner2text1": "Make more money winning the fabulous 6.9$ daily competitin now",
  "banner2text2": "WIN-WIN",
  "banner2text3": "Take the credits, get 30 competition points, become",
  "banner2text3span": "at the daily competition and earn 6.90$ finishing at the top 10!",
  "banner3text1": "Vegas Promotion",
  "banner3text2": "Boost your R$50 by 38% to gain 12 credits and 5 Vegas tickets for a chance to win the Vegas Promotion",
  "banner3text3": "Experience Vegas glam in a Cadillac with two stars and your best friends. Enjoy dinner and a nightclub parting with them!",
  "banner3text4": "You can have +5 tickets",
  "withdrawBannerMessage2": "wait! You can’t finish the job… or can you? 🙄",
  "noTransactionsToday": "You have not made any transactions today",
  "banner1offer": "credits for your ",
  "banner1offer2": "to get back to",
  "banner1offerSpan": "!",
  "banner2winner": "By taking the credits you immediately get 30 points for today’s competition result that will entitle you place",
  "banner2winnerSpan": ", worth ",
  "homepageNewTitle1": "Experience HOT LIVE action like never before:",
  "homepageNewTitle2": "1. Doubling your money - we value your time",
  "homepageNewTitle3": "2. Winning $6.9 in the daily competition ",
  "homepageNewTitle4": "3. Getting a chance at Vegas promotion",
  "banner3text5Span": "You have:",

  "banner3text5": "tickets",
  "homepageFinishCTA": "Win the $6.9 Daily Competition!",
  "newTaskDouble": "Buy $5, spend them, and get $10 immediately as ",
  "homepageBtnNew1": "1. Get paid to try ImLive 👑",
  "homepageBtnNew2": "2. Win $6.9 in the daily competition",
  "homepageBtnNew3": "3. Get a chance to win the Vegas promotion",
  "homepageNewPoint1": "Get paid to try",
  "homepageNewPoint2": "Win $6.9 in the daily competition",
  "homepageNewPoint3": "Get a chance to win the Vegas promotion",
  "homepageStep1": "Sign up",
  "homepageStep2": "Buy ",
  "homepageStep3": "Spend All",
  "homepageStep4": "Get ",

  "newHomepageTitle": "Experience",
  "newHomepageTitleSpan1": "HOT LIVE ADULT",
  "newHomepageTitleSpan2": "action like never before &",
  "newHomepageBtn1": "1. Get started on ImLive 👑",
  "newHomepageBtn2": "2. Deposit R$25 for HOT experience",
  "newHomepageBtn3": "3. Spend 5 credits to get R$25 cashback",
  "newHomepageBtn4": "Win Wegas Weekend!",
  "newVegasTitle": "Don’t miss your chance to win",
  "newVegasSubTitle": "THE VEGAS PROMOTION",
  "newVegasBtn1": "Enjoy ImLive 💃",
  "newVegasBtn2": "Refer Friend 🤑",
  "newVegasBtn2Copy": "Link Copied!",
  "newVegasUnderBtn1": "1 credit bought = 1 Vegas Ticket",
  "newVegasUnderBtn2": "Friend completed offer = 30 Vegas Tickets",
  "newCounterPromotion": "Countdown to Vegas Promotion",
  "newCounterDay": "Countdown to Day End",
  "newMenuBottom2Btn1": "Spin the Wheel 🎰",
  "newMenuBottom2Btn2": "Double or nothing 💵",
  "newMenuBottom3Link1": "Take The Vegas Tour",
  "newMenuBottom3Link2": "What’s the Vegas Promotion?",
  "newMenuBottom3Btn1": "Enjoy ImLive 💃",
  "newMenuBottom3Btn2": "Refer Friend 🤑",
  "newMenuBottom3UnderBtn1": "1 credit bought = 1 Vegas Ticket",
  "newMenuBottom3UnderBtn2": "Friend completed offer = 30 Vegas Tickets",
  "newMenuLink1": "Home",
  "newMenuLink2": "$6.9 Daily",
  "newMenuLink3": "Vegas",
  "newMenuLink4": "Refer",
  "newMenuLink5": "Menu",
  "LPmenuTitle": "Experience",
  "LPmenuTitleSpan": "HOT LIVE ACTION",
  "LPmenuTitle2": ", on " + getDomain() + ", get the chance to win ",
  "LPmenuSubTitle": "THE VEGAS PROMOTION",
  "LPsingUpBtn": "Sign up",
  "LPsingUpInput": "Your e-mail",
  "LPsingUpLink1": "Already have an account?",
  "LPsingUpLink2": "Login",
  "LPfooterLink1": "Reward Policy",
  "LPfooterLink2": "Terms of Use",
  "LPfooterLink3": "Privacy Policy",
  "LPfooterLink4": "Cookie Policy",
  "LPfooterCopyright": "All Rights Reserved 2023",

  "LPmainTitle": "Sign up to",
  "LPhowTitle": "How Does",
  "LPhowTitle2": "it Work?",
  "LPhowWork1Title": "Experience Hot Live Action RISK FREE",
  "LPhowWork1SubTitle": "Buy & Spend R$25 on our HOT partner site. Liked it? 10 free extra credits. Not for you? 100% money back!",
  "LPhowWork1SubTitle2": "❤️ Love it? Get an hour of HOT LIVE credits worth $20 on us - quadruple your cashback!",
  "LPhowWork2Title1": "Have the chance to win",
  "LPhowWork2Title2": "THE VEGAS PROMOTION",
  "LPhowWork2SubTitle": "Get Vegas tickets for a chance to win our amazing Vegas promotion - a weekend of your dreams you will never forget!",
  "LPhowWork3Title": "Conquer the $6.90 daily competition",
  "LPhowWork3SubTitle": "Earn points by engaging further in " + getDomain() + ". Finish first in our top 10 to win 6.9$, daily!",
  "LPjoinBtn": "Join  " + getDomain() + "",
  "LPpromotion": "THE VEGAS",
  "LPpromotion2": "PROMOTION",
  "LPplacesTitle": "First 3 places",
  "LPwinTitle": "WIN THE",
  "LPwinTitle2": "$6.90 DAILY!",
  "LPwinDesk": "Accumulate points and have the chance to win $6.90 dollars everyday on our Daily Competition! ",
  "LPwinSubTitle": "PRIME PARTNER!",
  "LPcurrenciesMain": "What can I win here?",
  "LPcurrencies1Title": "MONEY",
  "LPcurrencies1SubTitle": "Other than RISK FREE HOT LIVE ACTION - you can also earn money every day in our daily competition and through friends you refer.",
  "LPcurrencies2Title": "VEGAS TICKETS",
  "LPcurrencies2SubTitle": "Get Tickets to participate in the Vegas Promotion and have the chance to go to Vegas with two of your besties!",
  "LPcurrencies3Title": "COMPETITON POINTS",
  "LPcurrencies3SubTitle": "Earn points by referring friends and by purchasing more credits on our partner’s platform. Earn $6.9, daily! ",
  "LPcurrenciesMainSubtitle": "Here are some of the ",
  "LPcurrenciesMainSubtitle2": "brands we work with",

  "newDoubleTitle": "You have",
  "newDoubleTitleSpan": "Competitions Points",
  "newDoubleSubtitle": "You won",
  "newDoubleSubtitleSpan": "points!",
  "newDoubleLose": "You lost! Better luck next time!",
  "newSpinTitle": "You can spin the Wheel",
  "newSpinTitleSpan": "Time",
  "newSpinSubtitle": "You multiply your point per",
  "newSpinSubtitleSpan": "points!",
  "newSpinSubtitleSpan2": ", now you have",
  "GameNoPointsTitle": "You can’t play Double or nothing",
  "GameNoPointsTitle2": "You can’t play spin the Wheel",
  "GameNoPointsSubTitle": "You can play double or nothing every day as long as you have competition points.",
  "GameNoPointsSubTitle2": "You can spin the wheel once every day as long as you have competition points.",
  "GameNoPointsBtn1": "Win more Points",
  "GameNoPointsBtn2": "Continue",

  "promoTitle": "CHOOSE A PROMOTION",
  "promoSubtitle": "Choose one of our latest promotions and explore new shows, models, and thrilling experiences! Dive into these fantastic deals and begin your adventure with beautiful models!",
  "promoCardOffer": "One-time offer",
  "promoCard1TitleAbsolute": "Welcome Bonus",
  "promoCard1Title": "100% BONUS",
  "promoCard1SubTitle": "Shop now, double your gain instantly!",
  "promoCard1Btn": "Take it Now!",
  "promoCard2Title": "Hot Offer!",
  "promoCard2SubTitle": "Discover models with Private Show discounts!",
  "promoCard2Btn": "Check Them Out!",
  "promoCard3Title": "Triple Delight Offer",
  "promoCard3SubTitle": "Best Selections at Special Discounts with the best girls -100% Bonus Plus 30% Off Prepaid Sessions!",
  "promoCard3Btn": "Maximize Your Delight! ",

  "leaderboardNoUser": "No users",

  "newRegisterTitle": "Register on",
  "newRegisterUnderTerms": "By clicking register, you confirm that you are 18 years old or over, and that you agree with our",
  "newRegisterUnderLink1": "Terms of Use",
  "newRegisterUnderLink2": "Privacy Policy",
  "newRegisterUnderLink3": "Cookies Policy",
  "newRegisterContinueBtn": "Continue with",
  "newRegisterContinueBtn2": "E-mail",
  "newRegisterJoinWith": "Join with",
  "newMenuToggle1": "Earn",
  "newMenuToggle2": "Game",
  "newCompetitionTitle": "Conquer the $6.9 Daily Competition!",
  "newMenuBottom4UnderBtn1": "Multiply your points daily!",
  "newMenuBottom4UnderBtn2": "Try your lucky and double your points!",
  "newNoTransaction": "No transactions yet.",
  "newSpinSubTitle2": "The Wheel will factor your competition points and give you more!",
  "newDoubleSubTitle2": "How is your lucky today? Try to double your points now!",
  "typeValidEmail": "Type a valid e-mail",

  "pixTypeAll": "PIX Key",
  "pixTypeOption5": "PIX Key Option",
  "pixTypeOption6": "PIX Key Data",
  "pixTypeOption7": "CPF of the user",
  "newHomepageBtnLeft": "Get credits! Enjoy ImLive HOT lives ",
  "newHomepageBtnRight": "Get your money back",
  "newHomepageBtnOr": "No, thanks",
  "newHomepageBtnDone1": "All tasks done!",
  "newHomepageBtnDone2": "Now, choose your way:",
  "homepageNewPointSpan": "get R$50 more for free or a FULL refund",
  "messageCopy": "Copy message",
  "messageCopyDone": "Message copied!",
  "newHomepageSteps4Top": "Get credits! Enjoy ImLive HOT lives",
  "newHomepageSteps4Bot": "Get your money back",

  "homepageLiveTitle1": "",
  "homepageLiveTitleSpan": "ADULT WEBCAM EXPERIENCES",
  "homepageLiveTitle2": "on ImLive - risk free",
  "homepageLiveUnderSlideTitle": "Buy & spend R$25 on",
  "homepageLiveUnderSlideSubTitle": "then choose:",
  "homepageLiveUnderOr": "or",
  "homepageLiveUnderOrLeft": "All money back",
  "homepageLiveUnderOrRight": `${getAmount(10)} free extra credits`,
  "homepageLiveMenuBtn1": `Buy & Spend ${getAmount(5)} on ImLive 👑`,
  "homepageLiveMenuTitle1": "to make the purchase or its gone!",
  "homepageLiveMenuBtn2": "Give me my 10 FREE EXTRA credits! 👑",
  "homepageLiveMenuTitle2": "Take money back",
  "LPmenuTitleSpan2": "- completely risk free",
  "LPmenuJoinNow": "Join Now!",
  "LPUnderSlideTitle": "Buy & Spend R$25",
 


  "LPTitle": `What our users say about us`,
  "LPblock1Title1": `We’re alive on social, find us on:`,
  "LPblock1SubTitle1": `More then `,
  "LPblock1SubTitleSpan": `Members On`,
  "LPblock1SubTitle2": `Discord`,
  "LPblock1SubTitle3": `Server`,
  "LPblock2Title": `The wildest girls in the world`,
  "LPblock2SubTitle": `Delight yourself with girls from all over the world, any time you want it! `,
  "LPblock3Title": `Hot Live Action RISK FREE’`,
  "LPblock3SubTitle": `Buy & Spend R$25 on our HOT partner site. Liked it? 10 free extra credits. Not for you? 100% money back!`,
  "LPblock4Title": `Pleasure yourself with  the best BONUSES if you start now!`,
  "LPblock4SubTitle": `🎰✈️The adventure of your lifetime!🎰✈️`,
  "LPblock4SubTitleLi1": `100% CASHBACK`,
  "LPblock4SubTitleLi2": `Go to VEGAS with friends and HOT MODELS`,
  "LPblock4SubTitleLi3": `AMAZING REWARDS in our daily competition!`,
  "LPFooterRow1": `About us`,
  "LPFooterRow2": `Mission`,
  "LPFooterRow3": `Policie`,
  "LPFooterRow4": `Follow us on our medias`,
  "LPblock2TitleOver": `We have everything you need, JOIN US right now`,
  "homePageUnderTitle": `What our users say about us`,
  "homePageUnderLeftTitle": `We’re alive on social, find us on:`,
  "homePageUnderLeftBtn1": `Join our Discord`,
  "homePageUnderLeftBtn2": `Join our Instagram`,
  "homePageUnderLeftSubTitle": `come and see our reviews`,
  "LPblock2TitleOverRewiew": `come and see our reviews!`,
  "Rewiew1Desc": `I went through the Ella Commands process, and it was very smooth and a lot of fun!`,
  "Rewiew2Desc": `I went through the Ella Commands process here, it's totally worth it and works out great! Besides, we get to have fun with each lovely girl! I never miss the opportunity!`,
  "Rewiew3Desc": `I went through the Ella Commands process, and it worked out really well! It was a lot of fun; we got to ImLive and had a blast playing with the girls. It was awesome!`,
  "Rewiew4Desc": `Hey guys, I went through the Ella Commands process, and it was a lot of fun, really good, you know?! I highly recommend it!`,
  "Rewiew5Desc": `Hi everyone, I went through the Ella Commands process with the gang, and we had a blast; it was an amazing experience!`,


  "QuestionaireBtnNext": `Next`,
  "QuestionaireStep1Title": `Tell us what you like.`,
  "QuestionaireStep1SubTitle": `🔥 Who do you want to see:`,
  "QuestionaireStep1Select1": `Women`,
  "QuestionaireStep1Select2": `Man`,
  "QuestionaireStep1Select3": `Trans`,
  "QuestionaireStep2SubTitle": `🔥 in which language she will obey you?`,
  "QuestionaireStep2Select1": `English`,
  "QuestionaireStep2Select2": `Spanish`,
  "QuestionaireStep2Select3": `Portuguese`,
  "QuestionaireStep3SubTitle": `🔥 What is his hair color:`,
  "QuestionaireStep3Select1": `Redhead`,
  "QuestionaireStep3Select2": `Brown`,
  "QuestionaireStep3Select3": `Blonde`,
  "verifyCodeEmailBtn": `OPEN gmail`,

  "EmailAuthModalTitle1": `Verify your email to sign up:`,
  "NewLPmenuTitleOther": "",
  "NewLPmenuTitle": "A LIVE",
  "NewLPmenuTitleSpan": "EXPERIENCE YOU CAN ALMOST TOUCH",
  "NewLPmenuTitle2": "INTERACT WITH THESE HOT GIRLS AND GET IT",
  "NewLPmenuTitle2Span": "FOR FREE",

  "homepageLiveMenuBtn3": "Live with these girls now 👑",
  "homepageLiveOverTitle": ` Buy & spend ${getAmount(5)} on ImLive`,
  "homepageLiveUnderSlideChoose": "then choose:",

  "LPUnderOrLeft": "YOUR MONEY BACK",
  "LPUnderOrLeft2": "",
  "LPUnderOrRight": `${getAmount(10)} free extra`,
  "LPUnderOrRight2": `credits`,






  "RedWelcomeHomeTitle": `Welcome to`,
  "RedWelcomeHomeSubTitle": `Ella Commands`,
  "RedWelcomeHomeDesc": `Do you think you're badass enough to be part of our exclusive group?`,
  "RedWelcomeHomeBtn": `I Deserve to Join`,
  "RedWelcomeHeaderLink1": `Homepage`,
  "RedWelcomeHeaderLink2": `Partners`,
  "RedWelcomeHeaderLink3": `Benefits`,
  "RedWelcomeHeaderLink4": `Girls`,
  "RedWelcomeHeaderLink5": `Contact us`,
  "RedWelcomeHeaderBtn": `JOIN US!`,
  "RedWelcomeTrust": `Trusted by`,
  "RedWelcomeOurTitle": `Our benefits`,
  "RedWelcomeOurSubTitle": `Our members have access:`,
  "RedWelcomeOurSectionTitle1": `OFFERS`,
  "RedWelcomeOurSectionTitle2": `GIVEAWAY’S`,
  "RedWelcomeOurSectionTitle3": `SECRET CLUB`,
  "RedWelcomeOurSectionTitle4": `EXCLUSIVE LIVES`,
  "RedWelcomeOurDesk1": `By joining the`,
  "RedWelcomeOurDeskSpan": `Ella Commands`,
  "RedWelcomeOurDesk2": `, you will have the opportunity to interact more intimately with our girls and participate in special events, exclusive to our members.`,
  "RedWelcomeCamTitle": `CamGirls`,
  "RedWelcomeCamSubTitle": `Recent Girls`,
  "RedWelcomeReviewTitle": `Testimonials`,
  "RedWelcomeReviewSubTitle": `Reviews from members`,
  "RedWelcomeReview1Desc": `It was a huge surprise to be invited to the ${getDomain()}, and since then I've had incredible experiences.`,
  "RedWelcomeReview1Name": `C. Winter`,
  "RedWelcomeReview1Role": `Owner Red Hot`,
  "RedWelcomeReview2Desc": `After joining the ${getDomain()}, I had experiences and talked to people I never thought I'd have access to.`,
  "RedWelcomeReview2Name": `T. Shake`,
  "RedWelcomeReview2Role": `Member`,
  "RedWelcomeReview3Desc": `Since joining the ${getDomain()}, I've met people and had experiences beyond my expectations.`,
  "RedWelcomeReview3Name": `C. Gray`,
  "RedWelcomeReview3Role": `Member`,
  "RedWelcomeCertificateTitle": `Do you have what it takes to be in `,
  "RedWelcomeCertificateTitleSpan": `${getDomain()}?`,
  "RedWelcomeCertificateBtn": `I do!`,
  "RedWelcomeSpecialsTitle": `Specials Members`,
  "RedWelcomeMember1Name": `Cahyra Winter`,
  "RedWelcomeMember1Role": `Owner Red Hot`,
  "RedWelcomeMember2Name": `Ruan 1001`,
  "RedWelcomeMember2Role": `Papum no Barraco`,
  "RedWelcomeMember3Name": `Raissa Precioso`,
  "RedWelcomeMember3Role": `CamGirl`,
  "RedWelcomeMember4Name": `Alexandra Repetto `,
  "RedWelcomeMember4Role": `CamGirl`,
  "withdrawNoMoney": `You have no money to withdraw`,
  "settingsId": `My User ID`,
  "ReferBtnTitle": `Earn $1, 30 Vegas tickets, and 10 competition points .`,
  "ReferBtn": `Refer a friend`,
}
