import React from "react";
import { useDesign } from "../Helpers/Design/DesignContext";
import promo_logos from "../images/Promo/promo-logos.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../Helpers/API";
import userAPI from "../Requests/user";
import { useQuery } from "@tanstack/react-query";
import PartnerLinks from "../Helpers/PartnerLinks";
import number1 from "../images/Promo/1.svg";
import number2 from "../images/Promo/2.svg";
import number3 from "../images/Promo/3.svg";

function ChoosePromo({ languageData }) {
  const { design } = useDesign();

  const [userCountry, setUserCountry] = useState(null);
  const [partnerURL, setPartnerURL] = useState(null);
  const [userData, setUserData] = useState(null);

  useQuery({
    queryKey: ["userData"],
    queryFn: async () => {
      const res = await userAPI.getUserData();
      setUserData(res);
      return res;
    },
  });

  useEffect(() => {
    getUserCountry();
    async function getUserCountry() {
      const res = await API.getUserCountry();
      setUserCountry(res);
    }
  }, []);

  useEffect(() => {
    if (userData && userCountry) {
      let linkData = PartnerLinks.find((link) => link.CultureCode.indexOf(userCountry) !== -1);
      if (linkData === undefined) linkData = PartnerLinks.find((link) => link.CultureCode.indexOf("en") !== -1);

      let link = `https://imlive.com/wmaster2.ashx?QueryID=197&WID=${linkData.CybersaysWid}&linkID=701&from=freevideo6&cbname=CyberSays&c=${linkData.CultureCode}&promocode=${userData?.id}&func=signup`;

      setPartnerURL(link);
    }
  }, [userData, userCountry]);

  return (
    <section className="bg-promo-desktop h-screen w-screen overflow-y-scroll py-4 md:overflow-hidden bg-no-repeat bg-cover bg-center">
      <div className="flex justify-center text-center">
        <Link to={"/"} target="_blank">
          <img className="m-auto w-[100px] sm:w-[140px]" src={promo_logos} alt="Promo Logos" />
        </Link>
      </div>

      <div className="px-2 text-center sm:mt-4">
        <h1 className="inter sm:mb-4 text-[18px] mt-2 font-medium tracking-widest md:text-[34px]">{languageData?.promoTitle}</h1>
        <p className="inter m-auto max-w-[660px] text-[14px] font-light">{languageData?.promoSubtitle}</p>
      </div>

      <div className="mt-6 grid scale-100 grid-cols-1 justify-center gap-4 text-center md:mt-16 lg:grid-cols-3 max-w-[1300px] sm:px-2 w-full m-auto">

        <Link to={partnerURL} target="_blank">
          <div className="bg-promo-card-1 relative m-auto h-[155px] w-[335px] rounded-xl bg-cover md:h-[220px] md:w-[420px] mt-4 sm:mt-0">
            <img className="w-[37px] absolute z-30 left-[-15px] top-[-30px] sm:hidden" src={number1} alt="number1" />
            <div className="z-1 absolute left-0 top-0 h-full w-full"></div>
            <div className="absolute left-0 right-0 top-[-10px] flex justify-center">
              <span className="saira  rounded-full bg-[#31F81F] px-2 py-1 text-[8px] uppercase md:text-[12px] text-[#112063] font-bold">{languageData?.promoCardOffer}</span>
            </div>
            <div className="flex h-full items-center justify-center lg:gap-4 pl-[80px]">
              <p className="roboto-condensed text-[#4DEE3F] max-w-[150px] rotate-[-15deg] text-center text-[26px] md:text-[40px] leading-6 md:leading-9 2xl:text-[50px] 2xl:leading-[50px]">{languageData?.promoCard1TitleAbsolute}</p>
              <div className="min-w-[200px]">
                <p className="saira text-[16px] font-bold md:text-[20px]">{languageData?.promoCard1Title}</p>
                <p className="saira font-regular mt-2 text-[12px] leading-5 md:text-[14px] max-w-[150px] m-auto">{languageData?.promoCard1SubTitle}</p>
                <div className="flex justify-center mt-4">
                  <button className="saira yellowButton  text-[10px]  px-3 py-1 sm:py-2 2xl:text-[12px]">{languageData?.promoCard1Btn}</button>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link to={partnerURL} target="_blank">
          <div className="bg-promo-card-2 relative m-auto h-[155px] w-[335px] rounded-xl bg-cover md:h-[220px] md:w-[420px] mt-4 sm:mt-0">
          <img className="w-[37px] absolute z-30 left-[-15px] top-[-30px] sm:hidden" src={number2} alt="number2" />
            <div className="absolute left-0 right-0 top-[-10px] flex justify-center">
              <span className="saira  rounded-full bg-[#31F81F] px-2 py-1 text-[8px] font-bold text-[#112063] uppercase md:text-[12px]">{languageData?.promoCardOffer}</span>
            </div>
            <div className="relative  max-w-[200px] text-center m-auto top-1/2 transform -translate-y-1/2">
              <p className="saira text-[16px] font-bold md:text-[20px]">{languageData?.promoCard2Title}</p>
              <p className="saira font-regular mt-2 text-[12px] leading-5 md:text-[14px]">{languageData?.promoCard2SubTitle}</p>
              <button className="saira yellowButton mt-3 text-[10px] md:px-2 px-3 py-1 sm:py-2 2xl:text-[12px]">{languageData?.promoCard2Btn}</button>
            </div>
          </div>
        </Link>
        <Link to={partnerURL} target="_blank" className="rounded-[16px]">
          <div className="bg-promo-card-3 z-2 relative m-auto h-[155px] w-[335px] rounded-xl bg-cover  md:h-[220px] md:w-[420px] mt-4 sm:mt-0">
          <img className="w-[37px] absolute z-30 left-[-15px] top-[-30px] sm:hidden" src={number3} alt="number3" />
            <div className="z-1 absolute left-0 top-0 h-full w-full"></div>
            <div className="absolute left-0 right-0 top-[-10px] flex justify-center">
              <span className="saira rounded-full bg-[#31F81F] px-2 py-1 text-[8px] font-bold text-[#112063] uppercase md:text-[12px]">{languageData?.promoCardOffer}</span>
            </div>
            <div className="relative left-[45px] top-[15px] sm:top-[30px] max-w-[280px] text-center md:left-[160px]">
              <p className="saira text-[16px] font-bold md:text-[20px] uppercase">{languageData?.promoCard3Title}</p>
              <p className="saira font-regular text-[12px] leading-5 md:text-[14px] max-w-[200px] m-auto">{languageData?.promoCard3SubTitle}</p>
              <button className="saira yellowButton text-[10px] px-3 py-1 sm:py-2 2xl:text-[12px] mt-1 sm:mt-2">{languageData?.promoCard3Btn}</button>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
}

export default ChoosePromo;
