import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Helpers/Languages/LanguageContext";
import infoAPI from "../../../Requests/info";
import crown from "../../../images/NewDesign2/other/crown.svg";
import LeaderboardUser from "../../../Components/LeaderboardUser";
import place1 from "../../../images/LandingPage/place1.svg";
import place2 from "../../../images/LandingPage/place2.svg";
import place3 from "../../../images/LandingPage/place3.svg";

function DefaultCompetition({ languageData, user, PartnerURL }) {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();
  const design = 0; //видалити після переробки дизу

  useEffect(() => {
    const fetchData = async () => {
      const res = await infoAPI.getLeaderboardData();
      console.log(res.data);
      setLeaderboardData(res.data);

      setLoading(false);
    };

    fetchData();
  }, []);

  function maskEmail(email) {
    if (email === null || email === undefined) return;
    let [username, domain] = email.split("@");
    let maskedUsername = username.slice(0, 3);
    let maskedDomain = maskedUsername + "******@*****.***";
    return maskedDomain;
  }

  useEffect(() => {
    console.log(leaderboardData);
  }, [leaderboardData]);

  return (
    loading === false && (
      <div className="m-auto  max-w-[1000px]  ">
        <div className="m-auto rounded-[14px]  px-2 text-center   mac-center:w-[600px] se:py-1 iphone:py-2 sm:max-w-[600px] ">
          <p className="saira text-[16px] sm:text-[24px] text-center font-extrabold">
            {languageData?.newCompetitionTitle}
          </p>
          <div className="m-auto flex w-full max-w-[300px]  items-center justify-around sm:max-w-[350px]">
            {
              <div className="mt-[60px] ">
                <img
                  className="w-[56px] sm:w-[70px] m-auto"
                  src={place2}
                  alt="place2"
                />
                <p className="saira mt-1 text-[12px] font-bold leading-3 ">
                  {language !== "en" ? "2º" : "2nd"}
                </p>
                <p className="saira mt-1 text-center text-[16px] font-bold truncate w-[100px] pl-2">
                  {maskEmail(leaderboardData.todayUsers[1]?.email)}
                </p>
                <p className="saira text-[16px] font-medium">
                  {Number(leaderboardData.todayUsers[1]?.points || 0).toFixed(
                    0
                  )}{" "}
                  Pts
                </p>
              </div>
            }

            <div className="mb-5">
              <div className="flex justify-center">
                <img className="h-[28px] w-[28px]" src={crown} alt="crown" />
              </div>

              <img
                className="w-[56px] sm:w-[70px] m-auto"
                src={place1}
                alt="place1"
              />
              <p className="saira mt-1 text-center text-[12px] font-bold leading-3 text-[#FFD600]">
                {language !== "en" ? "1º" : "1st"}
              </p>
              <p className="saira mt-1 text-center text-[16px] font-bold truncate w-[100px] pl-2">
                {maskEmail(leaderboardData.todayUsers[0]?.email)}
              </p>
              <p className="saira text-[16px] font-medium">
                {Number(leaderboardData.todayUsers[0]?.points || 0).toFixed(0)}{" "}
                Pts
              </p>
            </div>

            {
              <div className="mt-[60px] ">
                <img
                  className="w-[56px] sm:w-[70px] m-auto"
                  src={place3}
                  alt="place3"
                />
                <p className="saira mt-1 text-[12px] font-bold leading-3">
                  {language !== "en" ? "3º" : "3rd"}
                </p>
                <p className="saira mt-1 text-center text-[16px] font-bold truncate w-[100px] pl-2">
                  {maskEmail(leaderboardData.todayUsers[2]?.email)}
                </p>
                <p className="saira text-[16px] font-medium">
                  {Number(leaderboardData.todayUsers[2]?.points || 0).toFixed(
                    0
                  )}{" "}
                  Pts
                </p>
              </div>
            }
          </div>
          {leaderboardData?.userRankData && (
            <div className=" m-auto mt-3 flex  max-w-[380px] justify-between rounded-[12px] p-1 px-1 md:max-w-[100%]">
              <div className=" m-auto text-left leading-4">
                <p
                  className={`${design === "0" ? "text-[#FFED63]" : "gradient-linkDouble"} saira ${language === "es" && "text-[12px]"} text-[12px] font-bold  lg:text-[14px]`}
                >
                  {languageData?.leaderSheetYou}{" "}
                  <span className="saira text-[12px]  font-bold">
                    {" "}
                    <span className="saira ml-1 mr-[20px] rounded-[8px] border-[2px] border-[#0FF] px-2 text-[12px]">
                      #
                      {user?.points == 0
                        ? 0
                        : leaderboardData?.userRankData?.position || 0}
                    </span>
                  </span>{" "}
                  {languageData?.leaderSheetPoints}{" "}
                  <span className="saira ml-1 rounded-[8px] border-[2px] border-[#0FF] px-2 text-[12px]">
                    {Number(user?.points).toFixed(0)}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="text-center my-2">
          <p className="gradient-linkDouble saira text-[12px] font-bold lg:text-[16px]">
            {languageData?.leaderSheetYou}{" "}
            <span className="saira text-[12px] font-bold lg:text-[16px] border border-[#00FFFF] rounded-lg px-2 mr-4">
              {" "}
              #
              {user?.points == 0
                ? 0
                : leaderboardData?.userRankData?.position || 0}
            </span>{" "}
            {languageData?.leaderSheetPoints}{" "}
            <span className="saira text-[12px] font-bold lg:text-[16px] border border-[#00FFFF] rounded-lg px-2">
              {Number(user?.points).toFixed(0)}
            </span>
          </p>
        </div>
        <div className="mt-2 justify-center max-w-[600px] w-full m-auto h-[140px] iphone:h-[240px] sm:h-[160px] 2xl:h-[300px] pb-12 iphone:pb-4 overflow-scroll">
          {leaderboardData.todayUsers &&
          leaderboardData.todayUsers.length > 3 ? (
            leaderboardData.todayUsers.slice(3).map((user, index) => {
              return (
                <LeaderboardUser
                  user={user}
                  languageData={languageData}
                  index={index}
                  maskEmail={maskEmail(user?.email)}
                />
              );
            })
          ) : (
            <p className="text-center saira text-[18px] pt-12">
              {languageData?.leaderboardNoUser}
            </p>
          )}
        </div>
      </div>
    )
  );
}

export default DefaultCompetition;
