import React, { useEffect, useState } from "react";
import Confirm from "./Confirm";
import Error from "./Error";
import PartnerPromoModal from "../Withdraw/PartnerPromoModal";
import PartnerFlow from "../Withdraw/Flow/PartnerFlow";
import PixFlow from "../Withdraw/Flow/PixFlow";
import WithdrawMain from "../Withdraw/WithdrawMain";
import { useDesign } from "../../Helpers/Design/DesignContext";
import PaxumFlow from "../Withdraw/Flow/PaxumFlow";
import VisaFlow from "../Withdraw/Flow/VisaFlow";
import XoxodayFlow from "../Withdraw/XoxodayFlow";
import PSEFlow from "../Withdraw/Flow/PSEFlow";
import ServiPagFlow from "../Withdraw/Flow/ServiPagFlow";
import SPEIFlow from "../Withdraw/Flow/SPEIFlow";
import mixpanelTrack from "../../Helpers/mixpanel";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";
import moengage from "@moengage/web-sdk";
import { withdrawPartner } from "../../Requests/withdraw";

function Withdraw({
  user,
  setOpen,
  languageData,
  userCountry,
  setContactModal,
  setMoneyError,
}) {
  const { design } = useDesign();
  const { language } = useLanguage();

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [email, setEmail] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (userCountry === null || userCountry === undefined) return;
  }, [userCountry]);

  const [flowStarted, setFlowStarted] = useState(false);
  const [promoModal, setPromoModal] = useState(true);
  const [PartnerSelected, setPartnerSelected] = useState(false);

  useEffect(() => {
    mixpanelTrack("withdraw_modal", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      language: language,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });
    moengage.track_event("withdraw_modal", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      language: language,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });
  }, []);

  const getMethodComponent = (selectedPayment) => {
    switch (selectedPayment) {
      case "xoxoday":
        return (
          <XoxodayFlow
            setContactModal={setContactModal}
            languageData={languageData}
            setConfirm={setConfirm}
            setError={setError}
            email={email}
          />
        );
      case "pix":
        return (
          <PixFlow
            languageData={languageData}
            setConfirm={setConfirm}
            setError={setError}
            email={user?.email}
          />
        );
      case "paxum":
        return (
          <PaxumFlow
            languageData={languageData}
            setConfirm={setConfirm}
            setSelectedPayment={setSelectedPayment}
            setError={setError}
          />
        );
      case "visa":
        return (
          <VisaFlow
            languageData={languageData}
            setConfirm={setConfirm}
            setError={setError}
            user={user}
            userCountry={userCountry}
          />
        );
      case "Partner":
        return (
          <PartnerFlow
            setConfirm={setConfirm}
            setOpen={setPartnerSelected}
            languageData={languageData}
            closeAll={setOpen}
            setFlowStarted={setFlowStarted}
            setError={setError}
          />
        );
      case "pse":
        return (
          <PSEFlow
            languageData={languageData}
            setConfirm={setConfirm}
            setError={setError}
          />
        );
      case "servipag":
        return (
          <ServiPagFlow
            languageData={languageData}
            setConfirm={setConfirm}
            setError={setError}
          />
        );
      case "spei":
        return (
          <SPEIFlow
            languageData={languageData}
            setConfirm={setConfirm}
            setError={setError}
          />
        );
      default:
        return "Please select a payment method to continue.";
    }
  };

  useEffect(() => {
    if (PartnerSelected === true) {
      const PartnerPayment = async () => {
        try {
          const res = await withdrawPartner();

          //mixpanelTrack("Withdraw_request", { method: "Partner" });

          console.log(res.data);
          if (res.data.status === "success") {
            setConfirm(true);
            setFlowStarted(false);
          } else {
            alert(res.data.message);
            setError(true);
          }
        } catch (error) {
          setError(true);
        }
      };

      PartnerPayment();
    }
  }, [PartnerSelected]);

  const [bannerNumber, setBannerNumber] = useState(1);

  const bannerBack = () => {
    if (bannerNumber === 1) {
      setBannerNumber(3);
    } else {
      setBannerNumber(bannerNumber - 1);
    }
  };

  return (
    <div>
      {user?.earned !== 0 && user?.earned > 0 && promoModal && (
        <PartnerPromoModal
          setBannerNumber={setBannerNumber}
          bannerNumber={bannerNumber}
          setConfirm={setConfirm}
          setError={setError}
          closeAll={setOpen}
          setPartnerSelected={setPartnerSelected}
          setOpen={setPromoModal}
          languageData={languageData}
          user={user}
          userCountry={userCountry}
        />
      )}
      <div className="fixed top-0 z-[60] h-screen w-screen bg-[#1E1E1E] bg-opacity-60 p-4 backdrop-blur-md">
        <div className=" absolute left-1/2 top-4 z-10 w-[95%] max-w-[600px] -translate-x-1/2 transform lg:top-0 lg:w-full ">
          <div
            className={`m-auto flex w-full justify-between md:my-4 pt-8 sm:pt-0`}
          >
            <svg
              onClick={(e) => {
                if (user?.earned === 0) {
                  setOpen(false);
                } else {
                  setPromoModal(true);
                  bannerBack();
                }
              }}
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17 22L7 12L17 2"
                stroke="url(#paint0_linear_26_11821)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_26_11821"
                  x1="17"
                  y1="11.8039"
                  x2="7"
                  y2="11.8039"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8FE5EC" />
                  <stop offset="1" stop-color="#DDBAFC" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              onClick={(e) => setOpen(false)}
              className="h-[24px] w-[24px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 20L4 4M20 4L4 20"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        {flowStarted ? (
          <div className="flex h-screen items-center">
            {getMethodComponent(selectedPayment)}
          </div>
        ) : (
          <WithdrawMain
            setPartnerSelected={setPartnerSelected}
            setFlowStarted={setFlowStarted}
            selectPayment={setSelectedPayment}
            selectedPayment={selectedPayment}
            setMoneyError={setMoneyError}
            userCountry={userCountry}
            user={user}
            languageData={languageData}
          />
        )}
      </div>

      {confirm && (
        <Confirm
          selectPayment={setSelectedPayment}
          selectedPayment={selectedPayment}
          setOpen={setConfirm}
          languageData={languageData}
          closeAll={setOpen}
        />
      )}

      {error && (
        <Error
          setOpen={setError}
          languageData={languageData}
          closeAll={setOpen}
        />
      )}
    </div>
  );
}

export default Withdraw;
