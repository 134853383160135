import React from 'react'



function Review({ img, name, desc, role}) {
    return (
        <div className='relative max-w-[380px] w-full bg-[#1C1831] p-6 rounded-[6px] mt-4 lg:mt-0 m-auto'>
            <p className='satoshi font-bold text-[80px] absolute top-0 left-[-10px] lg:left-[-20px] leading-10'>“</p>
            <p className='satoshi font-medium text-[14px] lg:text-[18px] leading-5 lg:leading-7 md:h-[100px]'>{desc}</p>
            <div className='flex items-center mt-6'>
                <img className='w-[48px] lg:w-[62px] mr-2 lg:mr-4' src={img} alt="avatar" />
                <div>
                   <p className='satoshi font-bold text-[12px] lg:text-[18px] leading-4'>{name}</p> 
                   <p className='satoshi font-regular text-[10px] lg:text-[16px]'>{role}</p>
                </div>
            </div>
        </div>
    )
}

export default Review
