import React, { useState, useEffect } from "react";
import { useLanguage } from "../../Helpers/Languages/LanguageContext.jsx";
import ReviewsSlider from "../../Components/SliderHomepage/ReviewsSlider.jsx";
import { Link } from "react-router-dom";
import block1 from "../../images/LandingPage/block1.png";
import reviewFooter from "../../images/LandingPage/reviewFooter.png";
import HoverBtn from "../../Components/Buttons/HoverBtn.jsx";
import woman1 from "../../images/homepage/woman1.png"
import woman2 from "../../images/homepage/woman2.png"
import moment from "moment";
import mixpanelTrack from "../../Helpers/mixpanel";
import moengage from "@moengage/web-sdk";
import { withdrawPartner } from "../../Requests/withdraw";

function HomepagerUnder({ user, languageData, scrollToPage, setMenuOpen, setWithdrawComplete, setWitdrawError, setWithdrawModal, PartnerURL }) {

  const { language } = useLanguage();
  const [lastTask, setLastTask] = useState(null);

  useEffect(() => {
    if (user === null) return setLastTask(0);

    let tasks = JSON.parse(user?.completed_tasks);
    //let tasks = [1, 2, 3];
    if (tasks === null) return setLastTask(0);
    let lastTask = tasks[tasks.length - 1];
    setLastTask(lastTask);
  }, [user]);

  const handleEvent = (e) => {
    mixpanelTrack("Homepage_button_click", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });

    moengage.track_event("Homepage_button_click", {
      distinct_id: user?.id,
      is_referred: user?.referral_id ? "Yes" : "No",
      language: language,
      vegas_tickets: user?.raffle_tickets,
      points: user?.points,
      user_id: user?.id,
      USD_earned: user?.allTimeEarned,
      number_referrals: user?.referral_id ? user?.referral_id.length : 0,
    });
  };

  const [timeLeft, setTimeLeft] = useState(moment.duration(0));

  useEffect(() => {
    const registerTime = user?.timestamp;

    if (!registerTime) {
      setTimeLeft(moment.duration(0));
      return;
    }

    const now = moment();
    const then = moment(registerTime * 1000);
    const diff = now.diff(then, "seconds");

    if (diff >= 420) {
      setTimeLeft(moment.duration(0));
      return;
    }

    const initialRemainingTime = moment.duration(420 - diff, "seconds");
    setTimeLeft(initialRemainingTime);

    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev.asSeconds() <= 0) {
          clearInterval(interval);
          return moment.duration(0);
        }

        return moment.duration(prev.asSeconds() - 1, "seconds");
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [user]);

  const handleWithdraw = async (e) => {
    try {
      const res = await withdrawPartner();

      if (res.data.status === "success") {
        setWithdrawComplete(true);
        setWitdrawError(false);
      } else {
        setWitdrawError(true);
        alert(res.data.message);
      }
    } catch (error) {
      setWitdrawError(true);
    }
  };
  return (
    <div
      className={` w-screen bg-[#010000] relative z-10 bg-cover bg-center bg-no-repeat pb-[120px]`}
    >
      <div className="m-auto w-full  max-w-[1400px] px-2 pt-2 sm:pt-[20px] 3xl:pt-[40px] relative z-20">
        <div className="flex items-center ">
          <div className="m-auto max-w-[1400px] w-full">
            <div className="lg:flex ">
              <div className="3xl:mb-2 sm:mt-[10px] 3xl:mt-0 max-w-[1400px] w-full">
                <p className="saira text-[22px] sm:text-[36px] font-bold text-center my-6 sm:mb-12">{languageData?.homePageUnderTitle}</p>
                <ReviewsSlider languageData={languageData} />
                <div className="sm:flex items-center mt-12">
                  <div className=" w-full m-auto sm:w-[35%]">
                    <p className="text-center saira text-[18px] sm:text-[30px] font-extrabold leading-10">{languageData?.homePageUnderLeftTitle}</p>
                    <Link to="https://discord.gg/VsMZKKWsr4" target="_blank">
                      <button className={`w-full max-w-[250px] ${language !== 'en' ? 'sm:max-w-[390px]' : 'sm:max-w-[320px]'}  m-auto flex justify-center items-center border-2 border-[#A30023] discordBtnLED outline-none sm:py-2 rounded-[19px] saira text-[14px] sm:text-[22px] font-medium hover:bg-[#FF00E5] duration-200 mt-4 sm:mt-6`}>
                        <svg className="cursor-pointer w-[20px] sm:w-[44px] mr-3" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                          <path d="M36.3202 9.91774C33.8796 8.78001 31.2372 7.95425 28.4846 7.47714C28.4605 7.47636 28.4365 7.48089 28.4143 7.4904C28.3921 7.49992 28.3723 7.51418 28.3562 7.53219C28.0259 8.13775 27.6405 8.92682 27.3836 9.53238C24.4641 9.09197 21.495 9.09197 18.5754 9.53238C18.3185 8.90847 17.9332 8.13775 17.5845 7.53219C17.5661 7.49549 17.5111 7.47714 17.456 7.47714C14.7035 7.95425 12.0794 8.78001 9.62042 9.91774C9.60207 9.91774 9.58372 9.93609 9.56537 9.95444C4.57407 17.423 3.19779 24.6898 3.87675 31.8832C3.87675 31.9199 3.8951 31.9566 3.9318 31.9749C7.23487 34.3972 10.4095 35.8652 13.5474 36.8378C13.6025 36.8561 13.6575 36.8378 13.6759 36.8011C14.4099 35.7918 15.0705 34.7275 15.6394 33.6081C15.6761 33.5347 15.6394 33.4613 15.566 33.4429C14.52 33.0392 13.5291 32.5621 12.5565 32.0116C12.4831 31.9749 12.4831 31.8648 12.5381 31.8098C12.74 31.663 12.9418 31.4978 13.1437 31.351C13.1804 31.3143 13.2355 31.3143 13.2722 31.3326C19.5847 34.2137 26.3927 34.2137 32.6318 31.3326C32.6685 31.3143 32.7236 31.3143 32.7603 31.351C32.9621 31.5162 33.164 31.663 33.3658 31.8281C33.4392 31.8832 33.4392 31.9933 33.3475 32.03C32.3933 32.5988 31.384 33.0576 30.338 33.4613C30.2646 33.4796 30.2463 33.5714 30.2646 33.6264C30.8518 34.7458 31.5124 35.8101 32.2281 36.8194C32.2832 36.8378 32.3382 36.8561 32.3933 36.8378C35.5495 35.8652 38.7241 34.3972 42.0272 31.9749C42.0639 31.9566 42.0823 31.9199 42.0823 31.8832C42.8897 23.5704 40.7427 16.3587 36.3936 9.95444C36.3753 9.93609 36.3569 9.91774 36.3202 9.91774ZM16.5936 27.4974C14.7035 27.4974 13.1253 25.7541 13.1253 23.6071C13.1253 21.4601 14.6668 19.7168 16.5936 19.7168C18.5387 19.7168 20.0801 21.4785 20.0618 23.6071C20.0618 25.7541 18.5204 27.4974 16.5936 27.4974ZM29.3838 27.4974C27.4937 27.4974 25.9156 25.7541 25.9156 23.6071C25.9156 21.4601 27.457 19.7168 29.3838 19.7168C31.3289 19.7168 32.8704 21.4785 32.852 23.6071C32.852 25.7541 31.3289 27.4974 29.3838 27.4974Z" fill="white" />
                        </svg>
                        {languageData?.homePageUnderLeftBtn1}
                      </button>
                    </Link>
                    <Link to="https://www.instagram.com/elamandabr_/" target="_blank">
                      <button className={`w-full max-w-[250px] ${language !== 'en' ? 'sm:max-w-[390px]' : 'sm:max-w-[320px]'} m-auto flex justify-center items-center border-2 border-[#A30023] discordBtnLED outline-none py-1 sm:py-3 rounded-[19px] saira text-[14px] sm:text-[22px] font-medium hover:bg-[#FF00E5] duration-200 mt-6`}>
                        <svg className="cursor-pointer w-[20px] sm:w-[44px] mr-3" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                          <path d="M11.3133 0.807129H26.7276C32.5997 0.807129 37.3708 5.57823 37.3708 11.4504V26.8647C37.3708 29.6874 36.2495 32.3946 34.2535 34.3906C32.2575 36.3866 29.5504 37.5079 26.7276 37.5079H11.3133C5.44115 37.5079 0.670044 32.7368 0.670044 26.8647V11.4504C0.670044 8.6276 1.79138 5.92045 3.78737 3.92446C5.78337 1.92847 8.49051 0.807129 11.3133 0.807129ZM10.9463 4.47721C9.19421 4.47721 7.51391 5.17321 6.27502 6.4121C5.03613 7.65099 4.34012 9.33129 4.34012 11.0833V27.2317C4.34012 30.8834 7.29454 33.8378 10.9463 33.8378H27.0946C28.8467 33.8378 30.527 33.1418 31.7659 31.9029C33.0048 30.6641 33.7008 28.9838 33.7008 27.2317V11.0833C33.7008 7.43162 30.7463 4.47721 27.0946 4.47721H10.9463ZM28.6544 7.22977C29.2627 7.22977 29.8462 7.47143 30.2764 7.90161C30.7065 8.33178 30.9482 8.91521 30.9482 9.52357C30.9482 10.1319 30.7065 10.7154 30.2764 11.1455C29.8462 11.5757 29.2627 11.8174 28.6544 11.8174C28.046 11.8174 27.4626 11.5757 27.0324 11.1455C26.6023 10.7154 26.3606 10.1319 26.3606 9.52357C26.3606 8.91521 26.6023 8.33178 27.0324 7.90161C27.4626 7.47143 28.046 7.22977 28.6544 7.22977ZM19.0204 9.98233C21.4539 9.98233 23.7876 10.949 25.5083 12.6697C27.229 14.3904 28.1956 16.7241 28.1956 19.1575C28.1956 21.5909 27.229 23.9247 25.5083 25.6454C23.7876 27.3661 21.4539 28.3327 19.0204 28.3327C16.587 28.3327 14.2533 27.3661 12.5326 25.6454C10.8119 23.9247 9.84524 21.5909 9.84524 19.1575C9.84524 16.7241 10.8119 14.3904 12.5326 12.6697C14.2533 10.949 16.587 9.98233 19.0204 9.98233ZM19.0204 13.6524C17.5604 13.6524 16.1601 14.2324 15.1277 15.2648C14.0953 16.2972 13.5153 17.6975 13.5153 19.1575C13.5153 20.6176 14.0953 22.0178 15.1277 23.0502C16.1601 24.0826 17.5604 24.6626 19.0204 24.6626C20.4805 24.6626 21.8807 24.0826 22.9131 23.0502C23.9456 22.0178 24.5256 20.6176 24.5256 19.1575C24.5256 17.6975 23.9456 16.2972 22.9131 15.2648C21.8807 14.2324 20.4805 13.6524 19.0204 13.6524Z" fill="white" />
                        </svg>
                        {languageData?.homePageUnderLeftBtn2}
                      </button>
                    </Link>
                    <div className="flex sm:block items-center justify-center">
                      <p className="text-center saira text-[22px] sm:text-[30px] font-extrabold mt-6 mr-4 sm:mr-0">{languageData?.homePageUnderLeftSubTitle}</p>
                      <img className="w-[100px] sm:w-[140px] sm:m-auto mt-6 sm:pt-2" src={reviewFooter} alt="review" />
                    </div>
                  </div>
                  <div className="sm:w-[50%] hidden sm:block" >
                    <img className="w-full" src={block1} alt="block1" />
                  </div>
                </div>
                <div className="mt-[50px] sm:mt-[150px] max-w-[320px] sm:max-w-[600px] m-auto">
                  {lastTask !== 3 ? (
                    <div className="mb-6 sm:my-6">
                      
                      <HoverBtn
                        disabled={
                          moment
                            .duration(timeLeft.asSeconds(), "seconds")
                            .asSeconds() < 1
                        }
                        onClick={(e) => {
                          handleEvent(e);
                          window.open(PartnerURL);
                        }}
                        text={languageData?.homepageLiveMenuBtn1}
                        mAuto={"m-auto !max-w-[350px]"}
                        bgColor={"!bg-[#A30023]"}
                        heightBtn={"!h-[60px]"}
                      />
                    </div>
                  ) : (
                    <div className="mb-4 mt-6">
                      <HoverBtn
                        onClick={(e) => handleWithdraw()}
                        text={languageData?.homepageLiveMenuBtn2}
                        mAuto={"m-auto !max-w-[350px]"}
                        bgColor={"!bg-[#A30023]"}
                        heightBtn={"!h-[60px]"}
                      />
                      <p
                        onClick={() => setWithdrawModal(true)}
                        className="cursor-pointer text-center text-[16px] sm:text-[28px] font-bold saira mt-2 underline"
                      >
                        {languageData?.homepageLiveMenuTitle2}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className=" w-[260px] absolute bottom-[180px] left-[600px] mix-blend-multiply" src={woman1} alt="woman1" />
      <img className=" w-[230px] absolute bottom-0 left-[-20px] mix-blend-multiply" src={woman2} alt="woman1" />
    </div>
  );
}

export default HomepagerUnder;
