import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLanguage } from "./Helpers/Languages/LanguageContext";
import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import Auth from "./Pages/Auth";
import DiscordAuth from "./Pages/Auth/DiscordAuth";
import { DesignProvider } from "./Helpers/Design/DesignContext";
import moengage from "@moengage/web-sdk";
import Admin from "./Pages/Admin";
import RegisterToken from "./Pages/RegisterToken";
import AuthToken from "./Pages/AuthToken";
import NewMain from "./Pages/NewMain";
import WelcomeAuth from "./Pages/WelcomeAuth";
import ChoosePromo from "./Pages/ChoosePromo";
import Questionaire from "./Pages/Questionaire.jsx";
import AuthEmailNotification from "./Pages/Auth/AuthEmailNotification";
import WelcomeAuthOLD from "./Pages/WelcomeAuthOLD";
import Papum from "./Pages/Papum";

function App() {
  moengage.initialize({
    app_id: "GL6LO1GVSQI7L67UCUGV3MRJ",
    debug_logs: 0,
    cluster: "DC_2",
    enableSPA: true,
  });

  const [testingCampaign, setTestingCampaign] = useState(null);

  /* UTM SAVING */
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    const utm_source = params.get("utm_source");
    const utm_medium = params.get("utm_medium");
    const utm_campaign = params.get("utm_campaign");
    const utm_term = params.get("utm_term");
    const utm_content = params.get("utm_content");

    const fbclid = params.get("fbclid");

    if (fbclid) {
      //window.localStorage.setItem("fbclid", fbclid);
    }

    if (utm_source) {
      window.localStorage.setItem("utm_source", utm_source);
    }

    if (utm_medium) {
      window.localStorage.setItem("utm_medium", utm_medium);
    }

    if (utm_campaign) {
      window.localStorage.setItem("utm_campaign", utm_campaign);
    }

    if (utm_term) {
      window.localStorage.setItem("utm_term", utm_term);
    }

    if (utm_content) {
      window.localStorage.setItem("utm_content", utm_content);
    }

    let campaign = params.get("testing_campaign");
    if (campaign) {
      window.localStorage.setItem("testing_campaign", campaign);
      setTestingCampaign(campaign);
    } else {
      window.localStorage.setItem("testing_campaign", null);
    }
  }, []);

  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
    utm_campaign:
      params.get("utm_campaign") || window.localStorage.getItem("utm_campaign"),
    utm_source:
      params.get("utm_source") || window.localStorage.getItem("utm_source"),
    utm_medium:
      params.get("utm_medium") || window.localStorage.getItem("utm_medium"),
    utm_term: params.get("utm_term") || window.localStorage.getItem("utm_term"),
    utm_content:
      params.get("utm_content") || window.localStorage.getItem("utm_content"),
  });

  const ref = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (ref !== null && ref !== undefined && ref !== "" && ref !== "login") {
      window.localStorage.setItem("ref", ref);
    }

    if (params.get("uid") !== null) {
      window.localStorage.setItem("uid", params.get("uid"));
    }

    if (window.localStorage.getItem("design") === null) {
      window.localStorage.setItem("design", "1");
      //window.location.reload();
    }
  }, []);

  if (params.get("email")) {
    window.localStorage.setItem("email", params.get("email"));
  }

  if (params.get("uid")) {
    window.location.href = "/welcome" + params.get("uid");
  }

  useEffect(() => {
    if (params.get("ref") !== null) {
      window.localStorage.setItem("ref", params.get("ref"));
    }
  }, []);

  const { language } = useLanguage();
  const [languageData, setLanguageData] = React.useState(null);

  const languages = {
    en: require("./Helpers/Languages/translations/en.js"),
    pt: require("./Helpers/Languages/translations/pt.js"),
    es: require("./Helpers/Languages/translations/es.js"),
  };

  useEffect(() => {
    let langData = languages[language];
    setLanguageData(langData);
  }, [language]);

  return (
    <DesignProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <NewMain
                languageData={languageData}
                testingCampaign={testingCampaign}
              />
            }
          />

          <Route
            path="/landing"
            element={
              <NewMain
                languageData={languageData}
                testingCampaign={testingCampaign}
              />
            }
          />

          <Route
            path="/verify-login"
            element={<AuthEmailNotification languageData={languageData} />}
          />
          <Route path="/auth/discord/callback" element={<DiscordAuth />} />
          <Route path="/register/*" element={<RegisterToken />} />
          <Route path="/auth/*" element={<AuthToken />} />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/welcome/*"
            element={<WelcomeAuthOLD languageData={languageData} />}
          />
          <Route
            path="/b"
            element={<WelcomeAuth languageData={languageData} />}
          />
          <Route
            path="/choose-promotions"
            element={<ChoosePromo languageData={languageData} />}
          />
          <Route path="/questionaire" element={<Questionaire languageData={languageData} />} />

          <Route path="/papum" element={<Papum languageData={languageData} />} />
        </Routes>
      </BrowserRouter>
    </DesignProvider>
  );
}

export default App;
