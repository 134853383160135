import React from "react";
import login3 from "../../images/LandingPage/login3.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import checkUser from "../../Helpers/cloaker";

function GoogleButton({ loginViaGoogle, languageData }) {

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      loginViaGoogle(userInfo.data.email);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      <div
        onClick={async () => {
          await checkUser()
          googleLogin()
        }}
        className="border-[#FF00E5] border-2 bg-transparent rounded-[32px] max-w-[320px] w-full m-auto flex justify-center items-center py-3 cursor-pointer mt-4"
      >

        <img className="w-[24px]" src={login3} alt="login1" />
        <p className="text-[14px] poppins font-semibold ml-3">
          {languageData?.newRegisterContinueBtn}{" "}
          <span className="text-[14px] poppins font-bold text-[#0FF]">
            Google
          </span>
        </p>
      </div>

      {/*
                 <button  type="button" className='w-[50%] lg:w-full gap-2 lg:gap-4  flex bg-[#1E1E1E] px-2 lg:px-3 py-2  rounded-[8px] lg:rounded-[12px] items-center justify-center'>
                <img className='w-4 lg:w-6 h-4 lg:h-6' src={google} alt="Google" />
                <p className='text-[12px] lg:text-[14px] text-[#888888] font-semibold text-left saira' dangerouslySetInnerHTML={{ __html: languageData?.authGoogle + ' <span class="saira" style="color:#FFFFFF">Google</span>' }} />
            </button>

                <LoginSocialGoogle  className='w-full' scope='https://www.googleapis.com/auth/userinfo.email' client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID} onResolve={(response) => {
                    console.log(response)
                    loginViaGoogle(response.data.email)
                }}
                
                   
                    onReject={(response) => {
                        console.log(response)
                    }}
                >
                    <button type="button" className='w-full flex gap-4 bg-white py-3 rounded-[12px] items-center justify-center'>
                        <img className='w-6 h-6' src={google} alt="Google" />
                        <p className='text-[14px] lg:text-[16px] text-[#8E8E8E] font-semibold text-left saira' dangerouslySetInnerHTML={{ __html: 'Continue with <span class="saira" style="color:#282B54">Google</span>' }} />
                    </button>
                </LoginSocialGoogle>
                */}
    </>
  );
}

export default GoogleButton;
