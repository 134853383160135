import React, { useEffect, useRef, useState } from "react";
import LandingLogin from "../Components/Landing/LandingLogin";
import { useNavigate } from "react-router-dom";
import moengage from "@moengage/web-sdk";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
import Loader from "../Components/Loader";
import EmailAuthModal from "../Components/Landing/EmailAuthModal";
import { getUserCountry } from "../Requests/utills";
import { getHeroImage, getLogotype, getSealImage } from "../Helpers/domainHelper";
import mixpanelTrack from "../Helpers/mixpanel";
import our from "./WelcomeRed/Image/our.png";
import Trusted from "./WelcomeRed/Trusted";
import Review from "./WelcomeRed/Review";
import avatarReview1 from "../Components/SliderHomepage/avatar1.png";
import avatarReview2 from "../Components/SliderHomepage/avatar3.png";
import avatarReview3 from "../Components/SliderHomepage/avatar5.png";
import certificate from "./WelcomeRed/Image/certificate.png";
import Members from "./WelcomeRed/Members";
import member1 from "./WelcomeRed/Image/members/member1.png";
import member2 from "./WelcomeRed/Image/members/member2.png";
import member3 from "./WelcomeRed/Image/members/member3.png";
import member4 from "./WelcomeRed/Image/members/member4.png";
import FooterWelcome from "./WelcomeRed/FooterWelcome";
import CarouselTape from "./WelcomeRed/CarouselTape";
import OurMission from "./CyberSaysPages/Modals/OurMission";
import portfolio from "./WelcomeRed/Image/PortoflioSection.png";
import SliderPortfolio from "./WelcomeRed/SliderPortfolio";
import LandingLoginRed from "../Components/Landing/LandingLoginRed";


function WelcomeAuth({ languageData }) {
  const params = new URLSearchParams(window.location.search);

  const { language } = useLanguage();

  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
    const getUserCountryData = async () => {
      const userCountry = await getUserCountry();
      setUserCountry(userCountry.data.Data.country);
    };
    getUserCountryData();
  }, []);

  const countryLogo = userCountry ? getLogotype(userCountry) : null;

  const [landingLogin, setLandingLogin] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [missionOpen, setMissionOpen] = useState(false);

  let referralID =
    window.localStorage.getItem("ref") === "welcome" ||
      window.localStorage.getItem("ref") === "verify-login"
      ? null
      : window.localStorage.getItem("ref");

  useEffect(() => {
    /* MIXPANEL */

    let utmData = {
      utm_source:
        params.get("utm_source") || window.localStorage.getItem("utm_source"),
      utm_medium:
        params.get("utm_medium") || window.localStorage.getItem("utm_medium"),
      utm_campaign:
        params.get("utm_campaign") ||
        window.localStorage.getItem("utm_campaign"),
      utm_term:
        params.get("utm_term") || window.localStorage.getItem("utm_term"),
      utm_content:
        params.get("utm_content") || window.localStorage.getItem("utm_content"),
    };

    // delete nulls

    mixpanelTrack("page_view", {
      distinct_id: "not_set",
      ...utmData,
      page_name: "Welcome",
    });
    moengage.track_event("page_view", {
      distinct_id: "not_set",
      ...utmData,
      page_name: "Welcome",
    });
  }, []);

  const navigate = useNavigate();

  if (window.localStorage.getItem("token")) {
    navigate("/");
  } else {
    window.localStorage.removeItem("double");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* LOGIN LOGIC */

  let refferalCode = referralID;

  let special = window.localStorage.getItem("uid");

  if (special === undefined || special === null) special = null;
  if (refferalCode === undefined || refferalCode === null) refferalCode = null;

  const [loading, setLoading] = useState(false);

  const handleMixpanelEvent = async (success, provider, userID) => {
    mixpanelTrack("login", {
      distinct_id: userID,
      registered_with: provider,
      success: success,
    });
    moengage.track_event("login", {
      id: userID,
      registered_with: provider,
      success: success,
    });
  };

  const [emailEnabled, setEmailEnabled] = useState(false);
  const [mode, setMode] = useState("email");

  const enablePopupFlow = () => {
    setLandingLogin(true);
  };
  const ref = useRef(null);
  const scrollToDown = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.zE) {
        clearInterval(interval);
        window.zE("messenger", "hide");
      } else {
        console.error("Zendesk API not available or not initialized.");
      }
    }, 500);


    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (params.get("email") === null) {
      setMode("popup");
    } else if (params.get("email") === "1") {
      setMode("email");
      setEmailEnabled(true);
    } else {
      setMode("email");
    }
  }, [params]);

  async function scrollToAnchor(anchor) {
    if (anchor) {
      const anchorElement = document.getElementById(anchor);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
  const [clickedBtnKey, setClickedBtnKey] = useState(false);
  const handleClickedBtnKey = (e) => {
    if (params.get("email") === null) {
      enablePopupFlow();
    } else {
      setEmailModal(true);
    }
    //setClickedBtnKey(true);
    setTimeout(() => setClickedBtnKey(false), 1000);
  };
  return (
    <div className="overflow-x-hidden h-[110vh] lg:h-screen overflow-y-scroll pb-6 relative bg-black" id="homepage">

      <div className="bg-[#0A0A0A]  w-screen  bg-cover bg-center bg-no-repeat z-0 p-4 lg:p-0">
        <div className="m-auto max-w-[1300px] flex items-center justify-between pt-4">
          <img className="max-w-[490px] w-full hidden lg:block" src={getHeroImage()} alt="hero" />
          <div className="max-w-[700px] m-auto">
            <div className="max-w-[190px] lg:max-w-[unset] w-full m-auto">
              <h2 className="satoshi font-bold text-[17px] md:text-[60px] ">{languageData?.RedWelcomeHomeTitle}</h2>
              <p className="gratis text-[36px] lg:text-[130px] redGradient leading-[40px] lg:leading-[110px] ">{languageData?.RedWelcomeHomeSubTitle}</p>
            </div>
            <img className="max-w-[200px] w-full lg:hidden block m-auto mt-2" src={getHeroImage()} alt="hero" />
            <p className="satoshi font-regular text-[14px] lg:text-[40px] mt-3 lg:mt-12 leading-4 lg:leading-10 text-center lg:text-left max-w-[190px] lg:max-w-[unset] w-full m-auto">{languageData?.RedWelcomeHomeDesc}</p>
            <button onClick={handleClickedBtnKey}
              className=" flex justify-center items-center bg-[#D82323] px-[15px] py-[5px] lg:px-[30px] rounded-[8px] mt-4 lg:mt-[40px] m-auto lg:m-[unset] btn-hover">
              <svg className={`mr-1 lg:mr-2 w-[12px] lg:w-[32px] svg-hover  ${clickedBtnKey ? 'scaleKey-up' : ''}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={`svg-path ${clickedBtnKey ? 'fill-black' : ''}`} d="M24 8C24 3.582 20.418 0 16 0C11.582 0 8 3.582 8 8C8 11.192 9.87 13.948 12.574 15.23L8 32H24L19.426 15.23C22.13 13.948 24 11.192 24 8Z" fill="white" />
              </svg>
              <span className="text-hover satoshi font-bold text-[17px] lg:text-[36px] ml-4">{languageData?.RedWelcomeHomeBtn}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="rotate-3">
        <CarouselTape />
      </div>
      <Trusted languageData={languageData} />
      <div className="mt-[50px] lg:mt-[150px] p-4 lg:p-0" id="benefits">
        <div className="max-w-[300px] md:max-w-[670px] m-auto">
          <p className="satoshi font-black text-[12px] lg:text-[18px] text-[#CECECE]">{languageData?.RedWelcomeOurTitle}</p>
          <hr className="w-[40px] bg-[#848484] border-none h-[2px]" />
          <p className="satoshi font-black text-[22px] lg:text-[48px] redGradient tracking-tighter">{languageData?.RedWelcomeOurSubTitle}</p>

        </div>
        <div className="flex items-center justify-center relative">
          <img className="w-[200px] md:w-[640px] mr-[-60px] md:mr-[-200px] relative z-20" src={our} alt="our" />
          <div className="w-full max-w-[810px]">
            <p className="ourGradient satoshi font-black text-[30px] md:text-[105px] tracking-[-4px] md:tracking-[-12px] leading-[35px] md:leading-[100px]">{languageData?.RedWelcomeOurSectionTitle1}</p>
            <p className="ourGradient satoshi font-black text-[30px] md:text-[105px] tracking-[-4px] md:tracking-[-12px] leading-[35px] md:leading-[100px] pl-2 md:pl-6">{languageData?.RedWelcomeOurSectionTitle2}</p>
            <p className="ourGradient satoshi font-black text-[30px] md:text-[105px] tracking-[-4px] md:tracking-[-12px] leading-[35px] md:leading-[100px]">{languageData?.RedWelcomeOurSectionTitle3}</p>
            <p className="ourGradient satoshi font-black text-[30px] md:text-[105px] tracking-[-4px] md:tracking-[-12px] leading-[35px] md:leading-[100px] ml-[-20px] md:ml-[-40px]">{languageData?.RedWelcomeOurSectionTitle4}</p>
          </div>
          <svg className=" absolute bottom-[-70px] mr-[170px] md:bottom-[-200px] md:mr-[650px] w-[50px] md:w-[160px]" viewBox="0 0 160 297" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M159.143 0.834371C97.6967 43.6818 2.58331 148.525 66.6914 190.577C130.8 232.628 28.372 274.936 0.99999 296.5" stroke="url(#paint0_linear_1_137)" />
            <defs>
              <linearGradient id="paint0_linear_1_137" x1="201.906" y1="35.6187" x2="3.62371" y2="279.381" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" />
                <stop offset="1" stop-color="#D82323" />
              </linearGradient>
            </defs>
          </svg>
          <svg className=" absolute bottom-[-60px] ml-[70px] md:bottom-[-150px] md:ml-[50px] w-[150px] md:w-[380px]" viewBox="0 0 384 273" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M383.5 272.5C381.605 195.171 204.355 -22.6469 124 14C43.6454 50.6468 4.01696 36.7154 0.822226 1.01085" stroke="url(#paint0_linear_1_138)" />
            <defs>
              <linearGradient id="paint0_linear_1_138" x1="278.397" y1="272.392" x2="172.165" y2="-30.6876" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" />
                <stop offset="1" stop-color="#D82323" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <p className="satoshi font-medium text-[13px] md:text-[18px] max-w-[510px] text-center mt-[100px] lg:hidden">{languageData?.RedWelcomeOurDesk1} <span className="text-[16px] md:text-[24px] gratis redGradient">{languageData?.RedWelcomeOurDeskSpan}</span>{languageData?.RedWelcomeOurDesk2}
        </p>
        <div className="max-w-[1250px] m-auto flex justify-between items-center mt-6 lg:mt-[170px]" id="girls">
          <div>
            <p className="satoshi font-black text-[11px] lg:text-[18px]">{languageData?.RedWelcomeCamTitle}</p>
            <hr className="w-[40px] bg-[#848484] border-none h-[2px]" />
            <p className="satoshi font-black text-[22px] lg:text-[48px] redGradient">{languageData?.RedWelcomeCamSubTitle}</p>
          </div>
          <p className="satoshi font-medium text-[13px] md:text-[18px] max-w-[510px] hidden lg:block text-center">{languageData?.RedWelcomeOurDesk1} <span className="text-[16px] md:text-[24px] gratis redGradient">{languageData?.RedWelcomeOurDeskSpan}</span>{languageData?.RedWelcomeOurDesk2}

          </p>
          <p className="satoshi font-balck text-[18px] items-center hidden lg:flex">More Girls <svg className="ml-2" width="39" height="13" viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.6486 7.03033C38.9415 6.73744 38.9415 6.26256 38.6486 5.96967L33.8756 1.1967C33.5827 0.903806 33.1078 0.903806 32.8149 1.1967C32.522 1.48959 32.522 1.96447 32.8149 2.25736L37.0576 6.5L32.8149 10.7426C32.522 11.0355 32.522 11.5104 32.8149 11.8033C33.1078 12.0962 33.5827 12.0962 33.8756 11.8033L38.6486 7.03033ZM0 7.25L38.1182 7.25V5.75L0 5.75L0 7.25Z" fill="white" />
          </svg>
          </p>
        </div>
        {/*
        <div className="h-[100px] sm:h-[200px] lg:h-[400px] relative mt-6 lg:mt-[50px] mx-[-20px] lg:mx-0">
          <img className="w-full max-w-[1800px] absolute right-0" src={portfolio} alt="portfolio" />
        </div>
        */}
        <SliderPortfolio />
        <div className="max-w-[1450px] m-auto flex justify-between items-center mt-[70px] lg:mt-[200px] lg:px-4 relative">
          <div>
            <p className="satoshi font-black  text-[11px] lg:text-[18px]">{languageData?.RedWelcomeReviewTitle}</p>
            <hr className="w-[40px] bg-[#848484] border-none h-[2px]" />
            <p className="satoshi font-black text-[22px] lg:text-[48px] redGradient">{languageData?.RedWelcomeReviewSubTitle}</p>
          </div>

          <svg className="w-[300px] absolute top-[-180px] right-[0] hidden lg:block pr-2" width="295" height="367" viewBox="0 0 295 367" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M260.823 366.186C307.663 285.348 328.493 124.763 163.238 129.251C-2.01721 133.738 -10.3115 39.4411 7.23098 1.1508" stroke="url(#paint0_linear_42_211)" />
            <defs>
              <linearGradient id="paint0_linear_42_211" x1="141.557" y1="362.981" x2="151.177" y2="5.01935" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" />
                <stop offset="1" stop-color="#F44F4F" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="lg:flex justify-between w-full max-w-[1300px] m-auto mt-12">
          <Review img={avatarReview1} desc={languageData?.RedWelcomeReview1Desc} name={languageData?.RedWelcomeReview1Name} role={languageData?.RedWelcomeReview1Role} />
          <Review img={avatarReview2} desc={languageData?.RedWelcomeReview2Desc} name={languageData?.RedWelcomeReview2Name} role={languageData?.RedWelcomeReview2Role} />
          <Review img={avatarReview3} desc={languageData?.RedWelcomeReview3Desc} name={languageData?.RedWelcomeReview3Name} role={languageData?.RedWelcomeReview3Role} />
        </div>
        <div className="relative">
          <div className="max-w-[1800px] w-full m-auto">
            <img className=" w-full m-auto hidden lg:block" src={certificate} alt="certificate" />
            <img className=" absolute w-[250px] hidden lg:block mt-[-305px] ml-[50px]" src={getSealImage()} alt="certificate2" />
          </div>
          <div className="lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 sinnersGradient lg:bg-transparent p-4 lg:p-0 mt-12 lg:mt-0 mx-[-20px] lg:mx-0">
            <p className="satoshi font-bold text-[18px] lg:text-[38px] text-center">{languageData?.RedWelcomeCertificateTitle} <span className="satoshi font-black text-[18px] lg:text-[38px]">{languageData?.RedWelcomeCertificateTitleSpan}</span> </p>
            <div className="flex justify-center mt-8 lg:mt-[50px]">
              <button onClick={e => {
                if (params.get("email") === null) {
                  enablePopupFlow();
                } else {
                  setEmailModal(true);
                }
              }} className='satoshi font-bold text-[14px] lg:text-[38px] redGradientBtn p-2 px-[38px] lg:p-[12px] lg:px-[68px] hover:bg-[#d82323d1] duration-200 rounded-[5px] '>{languageData?.RedWelcomeCertificateBtn}</button>
            </div>
            <img className=" absolute w-[75px] lg:hidden mt-[-25px]" src={getSealImage()} alt="certificate2" />
          </div>
        </div>
        <p className="satoshi font-black text-[22px] lg:text-[48px] mt-12 lg:mt-0 redGradient text-center">{languageData?.RedWelcomeSpecialsTitle}</p>
        <div className="lg:flex justify-between max-w-[1400px] m-auto mt-[150px] mb-[100px] lg:mb-[250px]" id="contact">
          <Members img={member1} name={languageData?.RedWelcomeMember1Name} role={languageData?.RedWelcomeMember1Role} />
          <Members img={member2} name={languageData?.RedWelcomeMember2Name} role={languageData?.RedWelcomeMember2Role} />
          <Members img={member3} name={languageData?.RedWelcomeMember3Name} role={languageData?.RedWelcomeMember3Role} />
          <Members img={member4} name={languageData?.RedWelcomeMember4Name} role={languageData?.RedWelcomeMember4Role} />
        </div>
      </div>

      <FooterWelcome />
      {loading && <Loader />}

      {landingLogin && (
        <LandingLoginRed
          setOpen={setLandingLogin}
          setEmailModal={setEmailModal}
          visible={landingLogin}
          setLoading={setLoading}
          handleMixpanelEvent={handleMixpanelEvent}
          refferalCode={refferalCode}
          special={special}
          languageData={languageData}
        />
      )}
      {emailModal && (
        <EmailAuthModal
          setOpen={setEmailModal}
          visible={emailModal}
          setLoading={setLoading}
          handleMixpanelEvent={handleMixpanelEvent}
          refferalCode={refferalCode}
          special={special}
          languageData={languageData}
          fbclid={params.get("fbclid")}
        />
      )}

      {missionOpen && (
        <OurMission languageData={languageData} setOpen={setMissionOpen} />
      )}
    </div>
  );
}

export default WelcomeAuth;