import React, { useState, useEffect } from "react";
import { useDesign } from "../Helpers/Design/DesignContext";
import { Link } from "react-router-dom";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
const moment = require("moment-timezone");

function TimeCounter({ languageData, hidden, title, left, leftTitle, block, setSocialLink, setWinModal }) {
  const { design } = useDesign();
  let language = useLanguage();

  const endDate = moment.tz("2024-12-30 23:59:59", "America/Sao_Paulo");
  const [formattedEndDate, setFormattedEndDate] = useState(endDate.format("YY/MM/DD HH:mm:ss"));
  const [daysRemaining, setDaysRemaining] = useState(endDate.diff(moment(), "days"));
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = moment();
      const newFormattedEndDate = endDate.format("YY/MM/DD HH:mm:ss");
      const newDaysRemaining = endDate.diff(now, "days");
      setFormattedEndDate(newFormattedEndDate);
      setDaysRemaining(newDaysRemaining);

      const duration = moment.duration(endDate.diff(now));
      const newHoursRemaining = duration.hours();
      const newMinutesRemaining = duration.minutes();
      const newSecondsRemaining = duration.seconds();
      setHoursRemaining(newHoursRemaining);
      setMinutesRemaining(newMinutesRemaining);
      setSecondsRemaining(newSecondsRemaining);
    }, 10);
  }, []);

  return (
    <div className="w-full m-auto max-w-[480px] scaleFrom125">

      <p className="text-center font-medium text-[18px] sm:text-[16px] saira sm:tracking-[1px] leading-[21px] my-1">{languageData?.newCounterPromotion}</p>
      <div className="bg-[#EAEAEA] bg-opacity-10 backdrop-blur-lg pt-1 rounded-[37px] sm:mt-2">
        <div className={` flex items-center  justify-center gap-[18px] leading-5 lg:justify-between max-w-[300px] w-full m-auto pt-[2px]`}>
          <div className="text-center  leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{daysRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterDays}</p>
          </div>
          <p className={`text-[16px] font-bold md:text-[20px] saira`}>:</p>
          <div className="text-center leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{hoursRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterHours}</p>
          </div>
          <p className={`text-[16px] font-bold md:text-[20px] saira`}>:</p>
          <div className="text-center  leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{minutesRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterMinutes}</p>
          </div>
          <p className={`text-[16px] font-bold md:text-[20px] saira`}>:</p>
          <div className="text-center leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{secondsRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterSeconds}</p>
          </div>

        </div>
      </div>

    </div>
  );
}

export default TimeCounter;
