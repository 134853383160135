import React, { useState } from 'react';

function Step2({ selectedLanguages, setSelectedLanguages, languageData  }) {
    

    const arrayLanguage = [
        {
            option: languageData?.QuestionaireStep2Select1
        },
        {
            option: languageData?.QuestionaireStep2Select2
        },
        {
            option: languageData?.QuestionaireStep2Select3
        }
    ];

    const toggleLanguage = (language) => {
        if (selectedLanguages.includes(language)) {
            setSelectedLanguages(selectedLanguages.filter(lang => lang !== language));
        } else {
            setSelectedLanguages([...selectedLanguages, language]);
        }
    };

    return (
        <div>
            <p className='text-[28px] inter text-center font-semibold pt-[130px] 2xl:pt-[230px]'>{languageData?.QuestionaireStep1Title}</p>
            <p className='text-[16px] inter text-center font-medium pt-[30px]'>{languageData?.QuestionaireStep2SubTitle}</p>
            <div className='pt-[30px] flex justify-between max-w-[400px] w-full'>
                {arrayLanguage.map((language, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 m-2 text-[16px] inter font-semibold rounded-full text-white outline-none ${selectedLanguages.includes(language.option) ? 'bg-[#11C337]' : 'bg-[#58585899]'}`}
                        onClick={() => toggleLanguage(language.option)}
                    >
                        {language.option}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Step2;
