import React from "react";
import { getUserCountry } from "../../Requests/utills";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../Requests/auth";
import { Link } from "react-router-dom";
import checkUser from "../../Helpers/cloaker";

function EmailAuthModal({
  setOpen,
  setLoading,
  handleMixpanelEvent,
  refferalCode,
  special,
  languageData,
  fbclid
}) {
  const recaptchaRef = useRef();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === "") return alert("Please enter your email");
    if (!email.includes("@")) return alert("Please enter a valid email");
    setLoading(true);

    const result = await checkUser();
    if (result !== true) return

    let userCountry = await getUserCountry();
    const token = await recaptchaRef.current.executeAsync();

    try {
      const res = await createUser(
        token,
        email,
        refferalCode,
        userCountry.data.Data.country,
        language,
        special,
        fbclid
      );

      if (res.data.id) {
        await handleMixpanelEvent(true, "email", res.data.id);
      }
      window.localStorage.setItem("email-value", email);
      //navigate("https://cybersaysm-redirect.vercel.app?token=" + res.token);

      //setActiveModal("notification");
      navigate("/verify-login");
      setLoading(false);
    } catch (error) {
      handleMixpanelEvent(false, "email");
      setLoading(false);
      alert("Error: ", error.message);
    }
  };

  return (
    <div className="bg-[#131313] bg-opacity-60 m-auto fixed w-screen h-screen flex justify-center items-center top-0 px-2 z-50">
      <div className="bg-black border-4 bg-opacity-95 max-w-[400px] w-full p-3 rounded-[12px] border-[#FF0000]">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size="invisible"
        >
          <div className="flex justify-end  ">
            <svg
              onClick={(e) => setOpen(false)}
              className="h-[24px] w-[24px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 20L4 4M20 4L4 20"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <p className="text-center text-[30px] gratis font-semibold redGradient my-2 px-4  leading-8">
            {languageData?.EmailAuthModalTitle1}
          </p>
          <div className="relative my-4">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className=" w-full m-auto outline-none poppins py-5 pl-3 pr-[100px] relative text-black text-[14px] rounded-[12px] border-2 border-[#FF0000]"
              placeholder={languageData?.LPsingUpInput}
              type="text"
            />
            <button
              onClick={(e) => handleSubmit(e)}
              type="submit"
              className="redGradientBtn poppins font-semibold absolute top-[6px] right-[6px] h-[85%] text-[14px] px-4 duration-100 rounded-[10px]"
            >
              {languageData?.LPsingUpBtn}
            </button>
          </div>
          <p className="text-center poppins text-[10px] font-extralight opacity-[0.6] my-5">
            {languageData?.newRegisterUnderTerms}{" "}
            <Link to="https://www.cyber-says.club/terms" target="_blank">
              <span className="poppins text-[10px] font-extralight underline cursor-pointer">
                {languageData?.newRegisterUnderLink1}
              </span>
            </Link>
            ,{" "}
            <Link to="https://www.cyber-says.club/privacy" target="_blank">
              <span className="poppins text-[10px] font-extralight underline cursor-pointer">
                {languageData?.newRegisterUnderLink2}
              </span>
            </Link>
            ,{" "}
            <Link to="https://www.cyber-says.club/policy" target="_blank">
              <span className="poppins text-[10px] font-extralight underline cursor-pointer">
                {languageData?.newRegisterUnderLink3}
              </span>
            </Link>
          </p>
        </ReCAPTCHA>
      </div>
    </div>
  );
}

export default EmailAuthModal;
