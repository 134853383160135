import React from "react";
import HoverBtn from "./Buttons/HoverBtn";
import { competitionStore } from "../stores/competitionStore";

function SpinNoTimes({ setOpen, title, subtitle, btn1, btn2 }) {
  const clearWheelLimit = competitionStore((state) => state.clearWheelLimit);
  const changeMode = competitionStore((state) => state.changeMode);

  return (
    <div
      className={`relative m-auto w-full max-w-[550px] rounded-[12px] border-2 bg-[#0A1225B2] bg-opacity-70 p-2 backdrop-blur-md border-[#8B007D]`}
    >
      <div className="flex justify-end  ">
        <svg
          onClick={(e) => {
            clearWheelLimit();
            setOpen(false);
          }}
          className="h-[24px] w-[24px] cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 20L4 4M20 4L4 20"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div className="px-4">
        <p className="text-center saira text-[16px] sm:text-[24px] font-semibold">
          {title}
        </p>
        <p className=" saira text-[14px] sm:text-[16px] font-normal my-3">
          {subtitle}
        </p>
      </div>
      <div className="flex justify-center ml-2 sm:ml-4 mb-2 sm:mb-4">
        <HoverBtn
          onClick={(e) => {
            changeMode("default");
            setOpen(false);
          }}
          text={btn1}
          width={"!w-[90%]"}
        />
        <HoverBtn
          onClick={(e) => {
            clearWheelLimit();
            setOpen(false);
          }}
          text={btn2}
          width={"!w-[90%]"}
          bgColor={"!bg-[#0FF]"}
        />
      </div>
    </div>
  );
}

export default SpinNoTimes;
