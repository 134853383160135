import React from 'react'
import Slider from 'react-infinite-logo-slider'
import { getLineImage } from '../../Helpers/domainHelper'

function CarouselTape() {
    return (
        <div className='w-[500%] ml-[-20px] sm:w-full lg:ml-0'>
            <Slider
                width="100%"
                duration={20000}
                loop={true}
            >
                <Slider.Slide>
                    <img className=' w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className=' w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className=' w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className=' w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>
                <Slider.Slide>
                    <img className='w-full' src={getLineImage()} alt="line" />
                </Slider.Slide>



            </Slider>
        </div>
    )
}

export default CarouselTape

