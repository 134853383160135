import React from "react";
import classNames from "classnames";
import { useLanguage } from "../../Helpers/Languages/LanguageContext";

function HoverBtn({ text, width, bgColor, onClick, disabled, mAuto, heightBtn }) {

  const { language } = useLanguage();
  return (
    <ul
      onClick={onClick && onClick}
      className={classNames(
        `max-w-[600px] w-full btnNewHover mt-3 sm:mt-4 cursor-pointer ${mAuto}`,
        {
          "opacity-65 pointer-events-none": disabled,
        }
      )}
    >
      <li className={`h-[50px] ${heightBtn}`}>
        <span className={`${width} ${bgColor}`}></span>
        <span
          className={`fab fa-css3-alt ${language !== 'en' ? 'text-[14px]' : 'text-[16px]'}  sm:text-[20px] font-semibold saira ${width}`}
        >
          {text}
        </span>
      </li>
    </ul>
  );
}

export default HoverBtn;
