import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import images from "../../Helpers/images.js";
import { Autoplay } from "swiper/modules";
import br from "./🇧🇷.png";
import co from "./🇨🇴.png";
import ro from "./🇷🇴.png";
import { Navigation } from 'swiper/modules';

function SlidelBlur() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const getSlideClass = (currentSlide, slideIndex, totalSlides) => {
    let diff = slideIndex - currentSlide;

    if (diff > totalSlides / 2) {
      diff -= totalSlides;
    } else if (diff < -totalSlides / 2) {
      diff += totalSlides;
    }

    if (diff === 0) return 'slideTop-0';
    if (Math.abs(diff) === 1) return 'slideMiddle';
    return 'slideBottom';
  };
  const slideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };
  const slides = images.slide1.map((slideData, index) => (
    <SwiperSlide key={index}>
      
      <div className={`min-w-[64px] min-h-[64px] sm:min-w-[90px] sm:min-h-[80px] rounded-full relative duration-200 ${getSlideClass(currentSlide, index, images.slide1.length)}`}>
        <img
          className="blur-[1.5px] w-[60px] h-[60px] sm:min-w-[90px] sm:min-h-[80px] m-auto"
          src={slideData.image} alt={`slide ${index}`}
        />
        <p className="mt-[-20px] ml-[40px] sm:ml-[75px] relative z-20">
          <img className="w-[20px] sm:w-[24px]" src={slideData.flag} alt="br" />
        </p>
      </div>
    </SwiperSlide>
  ));
  return (
    <div className="max-w-[420px] iphone:max-w-[380px] sm:max-w-[600px]  iphone:m-auto pt-2 sm:pt-8  h-[135px] sm:h-[200px] overflow-x-hidden ">
      <Swiper
        className=' relative z-10 swiper_main'
        initialSlide={currentSlide}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        modules={[Navigation, Autoplay]}
        spaceBetween={0}
        centeredSlides={true}
        slidesPerView={5}
        loop={true}
        navigation={{
          prevEl: '.buttonPrev',
          nextEl: '.buttonNext',
        }}
        autoplay={{
          delay: 5000,
        }}
        onSlideChange={slideChange}
      >
        {slides}
      </Swiper>
    </div>
  );
}

export default SlidelBlur;
