import React from "react";
import header1 from "../../images/NewDesign2/homepage/header1.svg";
import header2 from "../../images/NewDesign2/homepage/header2.svg";
import header3 from "../../images/NewDesign2/homepage/header3.svg";
import { getLogotype } from "../../Helpers/domainHelper";
import cn from "classnames";

function HomePageHeader({
  setEarnedModal,
  setTicketShortModal,
  setPointsModal,
  setVisible,
  user,
  userCountry,
  languageData,
  scrollToPage,
}) {
  return (
    <div className="flex items-start sm:items-center">
      <img
        onClick={(e) => {
          scrollToPage(0);
        }}
        className={cn("w-[60px] sm:w-[110px] cursor-pointer duration-200", {
          "opacity-0": !getLogotype(),
        })}
        src={getLogotype()}
        alt="logo"
      />
      <div className="flex items-center justify-around max-w-[560px] w-full sm:ml-[110px] mr-4 sm:mr-0">
        <div
          onClick={(e) => {
            setEarnedModal(true);
          }}
          className="cursor-pointer"
        >
          <p className="saira text-[10px] sm:text-[20px] font-bold text-center">
            {languageData?.header1}
          </p>
          <p className="flex items-center justify-center saira text-[12px] sm:text-[20px] font-bold">
            <img
              className="w-[16px] sm:w-[30px] mr-1"
              src={header1}
              alt="header1"
            />
            {userCountry === "BR" || userCountry === "UA"
              ? user?.earned.toFixed(0) * 5 || 0
              : user?.earned.toFixed(0) || 0}
          </p>
        </div>
        <div
          onClick={(e) => setTicketShortModal(true)}
          className="cursor-pointer"
        >
          <p className="saira text-[10px] sm:text-[20px] font-bold text-center">
            {languageData?.header3}
          </p>
          <p className="flex items-center justify-center saira text-[12px] sm:text-[20px] font-bold">
            <img
              className="w-[16px] sm:w-[30px] mr-1"
              src={header2}
              alt="header2"
            />
            {user?.raffle_tickets || 0}
          </p>
        </div>
        <div onClick={(e) => setPointsModal(true)} className="cursor-pointer">
          <p className="saira text-[10px] sm:text-[20px] font-bold text-center">
            {languageData?.header2}
          </p>
          <p className="flex items-center justify-center saira text-[12px] sm:text-[20px] font-bold">
            <img
              className="w-[16px] sm:w-[30px] mr-1"
              src={header3}
              alt="header3"
            />
            {user && user.points
              ? user.points === "0.0"
                ? 0
                : Number(user?.points).toFixed(1)
              : 0}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePageHeader;
