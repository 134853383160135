import axios from "axios";
import getToken from "../Helpers/getToken";

async function runDoubleGame() {

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/doubleGame', {}, {
        headers: {
            token: `${getToken()}`
        }
    });
    return response;
}

async function runSpinGame() {

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/user/spinWheel', {}, {
        headers: {
            token: `${getToken()}`
        }
    });
    return response;

}



export default { runDoubleGame, runSpinGame };