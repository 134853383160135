import React, { useState } from "react";

function PIXkey({
  languageData,
  PIXKey,
  setPIXKey,
  setSelectedOptionMoney,
  setPix,
  setCPF,
  selectedOptionMoney,
  pix,
  CPF,
}) {
  return (
    <div>
      <div className="flex justify-between">
        <div
          className={` bg-white px-3 sm:px-5 py-2 lg:py-3 my-1 border rounded-[12px] border-[#A2DBF0] w-[70%] sm:w-[50%]`}
        >
          <div
            onClick={(e) => setPIXKey(!PIXKey)}
            className={`flex  items-center justify-between cursor-pointer`}
          >
            <div className="flex items-center">
              <p className="saira text-[14px] font-semibold  text-[#1E1E1E] leading-3">
                {selectedOptionMoney || languageData?.pixTypeAll}
              </p>
            </div>
            <div className="ml-2">
              {PIXKey ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M2 17L12 7L22 17"
                    stroke="#1E1E1E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M22 7.5L12 17.5L2 7.5"
                    stroke="#1E1E1E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          {PIXKey && (
            <>
              <div className="mt-2">
                <label className="checkbox-container flex items-center sm:ml-2">
                  <input
                    checked={
                      selectedOptionMoney === languageData?.pixTypeOption1
                    }
                    onChange={() => {
                      setSelectedOptionMoney(languageData?.pixTypeOption1);
                      setPIXKey(false);
                    }}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <p className="text-[#1E1E1E] saira text-[14px] font-medium leading-[14px]">
                    {languageData?.pixTypeOption1}
                  </p>
                </label>
                <label className="checkbox-container flex items-center sm:ml-2">
                  <input
                    checked={
                      selectedOptionMoney === languageData?.pixTypeOption2
                    }
                    onChange={() => {
                      setSelectedOptionMoney(languageData?.pixTypeOption2);
                      setPIXKey(false);
                    }}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <p className="text-[#1E1E1E] saira text-[14px] font-medium leading-[14px]">
                    {languageData?.pixTypeOption2}
                  </p>
                </label>
                <label className="checkbox-container flex items-center sm:ml-2">
                  <input
                    checked={
                      selectedOptionMoney === languageData?.pixTypeOption3
                    }
                    onChange={() => {
                      setSelectedOptionMoney(languageData?.pixTypeOption3);
                      setPIXKey(false);
                    }}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <p className="text-[#1E1E1E] saira text-[14px] font-medium leading-[14px]">
                    {languageData?.pixTypeOption3}
                  </p>
                </label>
                <label className="checkbox-container flex items-center sm:ml-2">
                  <input
                    checked={
                      selectedOptionMoney === languageData?.pixTypeOption4
                    }
                    onChange={() => {
                      setSelectedOptionMoney(languageData?.pixTypeOption4);
                      setPIXKey(false);
                    }}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <p className="text-[#1E1E1E] saira text-[14px] font-medium leading-[14px]">
                    {languageData?.pixTypeOption4}
                  </p>
                </label>
              </div>
            </>
          )}
        </div>
        <input
          value={pix}
          onChange={(e) => setPix(e.target.value)}
          className={`w-full bg-white border rounded-[12px] border-[#A2DBF0] text-[14px] saira px-4 py-2 lg:py-3 my-1 saira input-fix text-[#888888] ml-2 h-[50px]`}
          placeholder={selectedOptionMoney || languageData?.pixTypeAll}
        />
      </div>
      <input
        value={CPF}
        onChange={(e) => setCPF(e.target.value)}
        className={`w-full bg-white border rounded-[12px] border-[#A2DBF0] text-[14px] saira px-4 py-2 lg:py-3 mb-1 saira input-fix text-[#888888] mt-1`}
        placeholder={"CPF"}
      />
    </div>
  );
}

export default PIXkey;
