import React, { useState } from "react";

function Step1({ selectGender, setSelectGender, languageData }) {
  const [selectOpen, setSelectOpen] = useState(false);

  const arrayGender = [
    {
      option: languageData?.QuestionaireStep1Select1,
    },
    {
      option: languageData?.QuestionaireStep1Select2,
    },
    {
      option: languageData?.QuestionaireStep1Select3,
    },
  ];

  return (
    <div>
      <p className="text-[28px] inter text-center font-semibold pt-[130px] 2xl:pt-[230px]">
        {languageData?.QuestionaireStep1Title}
      </p>
      <p className="text-[16px] inter text-center font-medium pt-[30px]">
        {languageData?.QuestionaireStep1SubTitle}
      </p>
      <div className="pt-[30px] cursor-pointer">
        <div
          className="flex justify-between items-center max-w-[130px] w-full m-auto "
          onClick={(e) => setSelectOpen(!selectOpen)}
        >
          <p className="inter text-[20px] sm:text-[30px] font-semibold mr-2 underline ">
            {selectGender}
          </p>
          {selectOpen ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 17L12 7L22 17"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 7L12 17L2 7"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
      {selectOpen && (
        <div>
          {arrayGender.map((item, index) => (
            <div
              key={index}
              className="max-w-[130px] w-full m-auto cursor-pointer bg-[#4d4d4daf] border-b border-[#4D4D4D]"
              onClick={(e) => {
                setSelectGender(item.option);
                setSelectOpen(false);
              }}
            >
              <p
                className="inter text-[20px] sm:text-[30px] font-semibold cursor-pointer"
                value={item.option}
                checked={selectGender === item.option}
              >
                {item.option}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Step1;
