const { getDomain, getAmount } = require("../../domainHelper");

module.exports = {
  "siteTitle": "" + getDomain() + "",
  "metaDescription": "¡Disfruta y gana en TreatCoin a través de " + getDomain() + "!",
  "HeaderTitle": "" + getDomain() + "... <span class='text-gradient'>¡Duplique su dinero!</span>",
  "HeaderSubTitle": "¡Compre, disfrute, X2 su dinero!",
  "HeaderButton": "Recibe pago por probar ImLive",
  "LatestOffers": "Últimas Ofertas",
  "OfferTitle": "Nuestro socio - Experiencias con Webcam para Adultos",
  "OfferDescription": "¡Regístrate y deposita 5 $ en nuestro socio! nuestro socio duplicará tus créditos, dándote $10 en lugar de $5. Después de utilizar tus créditos en nuestro socio, " + getDomain() + " duplicará tu dinero, ¡devolviéndote $10 en TreatCoin!",
  "listTexts": [
    "Regístrese en el site de nuestro socio",
    "Compra 5 créditos y llévate otros 5 gratis",
    "Disfruta usando los 10 créditos completos en nuestro socio",
    "Consigue 10$ de " + getDomain() + " - ¡duplicando los 5$ que gastaste!"
  ],
  "OfferButton": "¡Gana 2X Ahora!",
  "CopyLink": "Copiar Enlace",
  "TreatCoins": "Treat Coins",
  "LinkCopied": "¡Enlace Copiado!",
  "tasks": {
    "task1": {
      "taskTitle": "Regístrese en el site de nuestro socio 🎉",
      "taskTitleOpen": "Regístrese en el site de nuestro socio 🎉",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task1.png",
          "newTaskImage": "images/newTask/bg_task1.png",
          "taskImageDesk": "images/BgTaskDesk1.png",
          "newTaskImageDesk": "images/BgNewTaskDesk1.png",
          "taskText": "Disfruta del socio, se tratado como un REY 👑, ¡y cumple tus fantasías!"
        }
      ],
      "taskButton": "1/3 Comienza ahora 🎉"
    },
    "task2": {
      "taskTitle": "Depositar",
      "taskTitleOpen": "Bienvenida real de nuestro socio: deposita $5 y siente el placer.",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task2.png",
          "newTaskImage": "images/newTask/bg_task2.png",
          "taskImageDesk": "images/BgTaskDesk2.png",
          "newTaskImageDesk": "images/BgNewTaskDesk2.png",
          "taskText": "Deposita $5 en nuestro socio, iy " + getDomain() + " duplicará el dinero!"
        }
      ],
      "taskButton": "2/3: Depósito de US$ 5, 5 créditos 💸"
    },
    "task3": {
      "taskTitle": "Gastar",
      "taskTitleOpen": "Utilice todos los créditos para obtener US$10 CashBack de " + getDomain() + "",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task3.png",
          "newTaskImage": "images/newTask/bg_task3.png",
          "taskImageDesk": "images/BgTaskDesk3.png",
          "newTaskImageDesk": "images/BgNewTaskDesk3.png",
          "taskText": "Gasta tus 5 créditos en el socio, sé tratado como un rey y gana $10 en " + getDomain() + "."
        }
      ],
      "taskButton": "3/3: ¡Gasta todos los créditos y te devuelvo 10 $!"
    },
    "task4": {
      "taskTitle": "Retira tu dinero en efectivo 🤑",
      "taskTitleOpen": "Retira tu dinero en efectivo 🤑",
      "taskBlocks": [
        {
          "taskImage": "images/bg-mobile-Task4.png",
          "newTaskImage": "images/newTask/bg_task4.png",
          "taskImageDesk": "images/BgTaskDesk1.png",
          "newTaskImageDesk": "images/BgTaskDesk1.png",
          "taskText": "nuestro socio duplica tu crédito, nosotros duplicamos tu dinero: ¡es un beneficio mutuo para ti con $10 adicionales en tu billetera!"
        }
      ],
      "taskButton": "Obtener $10 de " + getDomain() + ""
    }
  },
  "homepageTitle1": "1. Gasta 5$ para el tratamiento real de nuestro socio y obtén 10$ de devolución en " + getDomain() + "",
  "homepageTitle2": "2. ¡Consigue tantos boletos como puedas para tener la mejor oportunidad de ganar la promoción de Las Vegas!",
  "homepageTitle3": "3. ¡Termina entre los 10 primeros y gana el concurso diario de 6.9 $!",
  "homepageTitle4": "¡Duplique su dinero y tenga la oportunidad de ganar el fin de semana de Las Vegas!",
  "homepageTitle5": "Recibe el tratamiento de rey en nuestro socio y gana el fin de semana de Las Vegas",
  "homepageBtn1": "Duplique su dinero ",
  "homepageBtn2": "2/3: Deposita para obtener un reembolso del 200% 🤑",
  "homepageBtn3": "3/3: Gasta para obtener un reembolso del 200% 🤑",
  "homepageBtn4": "Gana el fin de semana en Las Vegas 💃",
  "timeCounterTitle": "Countdown to Promoción de Las Vegas:",
  "timeCounterTitle2": "Countdown para el final del día:",
  "timeCounterDays": "Días",
  "timeCounterHours": "Horas",
  "timeCounterMinutes": "Minutos",
  "timeCounterSeconds": "Segundos",
  "raffleInfoTitle1": "La elegibilidad para Las Vegas indica que tú, tus amigos referidos o el amigo que te refirió están calificados para participar en el sorteo de Las Vegas mediante uno de dos métodos:",
  "raffleInfoTitle2": "Selección para un Viaje a Las Vegas:",
  "raffleInfoTitle2Span": "Si tu amigo o tu referente han ganado, puedes ser elegido para ir a Las Vegas.",
  "raffleInfoTitle3": "Participación en el Sorteo:",
  "raffleInfoTitle3Span": "Alternativamente, puedes participar en el sorteo con tus tickets de sorteo.",
  "raffleInfoTitle4": "Para información adicional, consulta",
  "raffleInfoTitle4Span": "detalles de la competencia.",
  "raffleTitle1": "No pierdas la oportunidad de ganar la increíble promoción de Las Vegas",
  "raffleSubTitle1": "Si aprovechaste la oferta de duplicar tu dinero en nuestro socio, puedes ser elegido por tu referido o referente para ir a Las Vegas si ganan.",
  "raffleLink1": "¿Cómo puedo ganar el fin de semana en Las Vegas?",
  "raffleTitle2": "¡Gana el fin de semana en Las Vegas!",
  "raffleSubTitle2": "Elegible para ir a Las Vegas",
  "raffleLink2": "¿Cómo puedo ganar el sorteo del fin de semana en Las Vegas?",
  "raffleUnderBtn": "Reúne tantos boletos de Las Vegas como puedas y aumenta tus posibilidades de ganar:",
  "raffleUnderBtn2": "Gane más tickets de Las Vegas para aumentar tus posibilidades de ganar el sorteo:",
  "raffleLeftBtn": "Disfruta de Experiencias en Vivo 💃",
  "raffleLeftSection1": "1 crédito comprado =",
  "raffleLeftSection2": "1 billete para Las Vegas",
  "raffleRightBtn": "Refiere a tus amigos 🤑",
  "raffleRightBtn2": "Enlace copiado!",
  "raffleRightSection1": "Amigo que duplicó el dinero =",
  "raffleRightSection2": "30 tickets de sorteo + US$1",
  "raffleRulesDesc": "*No es necesario realizar una compra para participar en el sorteo.",
  "raffleRulesLink": "Reglas del sorteo",
  "doubleTitle": "Duplica tu dinero - ¡Diviértete!",
  "doubleSubtitleSpan": "Una",
  "doubleSubtitle": "aparecerá después de completar la tarea",
  "doublePaid": "Retirar",
  "refferalsTitle": "Gane 1$ + 30 entradas para Las Vegas y 10 puntos de concurso por cada amigo referido que complete la oferta.",
  "refferalsLink1": "Más información sobre las ventajas de las referencias",
  "refferalsSubtitle": "Selecciona un texto y una imagen o simplemente comparte tu enlace de recomendación con tus amigos",
  "refferalsImage": "Elige una imagen",
  "refferalsLink2": "Elige una experiencia personalizada",
  "refferalsSome": "Añade algunas fotos",
  "refferalsInfoTitle": "Sé el rey",
  "refferalsInfo1": "Serás la persona que envía a tus amigos a duplicar su dinero y divertirse 👬",
  "refferalsInfo2": "Por cada amigo que duplique el dinero, recibirás 30 tickets de sorteo y $1 🃏",
  "refferalsInfo3": "Si tus amigos duplican su dinero, puedes llevarlos a Las Vegas si ganas el sorteo, y ellos pueden llevarte si ganan y aceptaste la oferta de duplicar el dinero",
  "competitionTitleSpan": "competencia diaria",
  "competitionPoints": "Puntos",
  "competitionSubtitle": "Los primeros 2 lugares reciben $6.9 cada uno, 3-10 $6.90 cada uno.",
  "competitionLeftBtn": "Disfruta de Experiencias en Vivo 💃",
  "competitionLeftSection1": "1 crédito comprado =",
  "competitionLeftSection2": "0,5 puntos de competición",
  "competitionRightBtn": "Refiere a tus amigos 🤑",
  "competitionRightBtn2": "¡Enlace copiado!",
  "competitionRightSection1": "Amigo que duplicó el dinero =",
  "competitionRightSection2": "10 puntos de competición + US$1",
  "competitionRightLink1": "Rankings",
  "competitionRightRules": "Reglas de la competencia",
  "header1": "Ganado:",
  "header2": "Puntos de competición:",
  "header3": "Tickets para Las Vegas:",
  "modalMenuLink1": "Página de inicio",
  "modalMenuLink2": "Duplica Tu Dinero",
  "modalMenuLink3": "El fin de semana en Las Vegas",
  "modalMenuLink4": "Referir para obtener tickets",
  "modalMenuLink5": "Mis Referencias Elegibles para Las Vegas",
  "modalMenuLink6": "La",
  "modalMenuLink6Span": "$6.9 Concurso diario",
  "modalMenuLink7": "Retire su dinero",
  "modalMenuLink8": "Sala de chat",
  "modalMenuLink9": "Historial de Transacciones",
  "modalMenuLink10": "Mis Tickets y Puntos",
  "modalMenuLink11": "Preguntas Frecuentes",
  "modalMenuLink12": "Nuestra misión",
  "modalMenuLink13": "¿Por qué unirse a nosotros?",
  "modalMenuBottom1": `Acerca de ${getDomain()}`,
  "modalMenuBottom2": "Legal",
  "modalMenuBottom3": "Configuraciones",
  "modalMenuBottom4": "Contáctanos",
  "modalMenuBottom5": "Tour de Fin de Semana en Las Vegas",
  "chatOnline": "en línea",
  "chatTitle": "Sala de Chat",
  "chatNotMessages": "Aún no hay mensajes",
  "chatGif": "Elige un GIF",
  "chatNotGif": "No se encontraron resultados",
  "withdrawTitle1": "Retirar",
  "withdrawSubtitle": "Es una retirada completa o nada en absoluto.",
  "withdrawPrice": "Disponible",
  "withdrawTitle2": "Elige tu forma de cobrar:",
  "withdrawTitle3": "Recibirás un vale en efectivo en el correo electrónico que elijas:",
  "withdrawLink": "Haz clic para ver qué métodos de pago están disponibles en tu país",
  "withdrawBtn": "Retirar",
  "noTransactions": "Aún no has realizado ninguna transacción",
  "ransactionsTitle": "Historial de Transacciones",
  "transactionsSection1Left": "¡Duplica tu dinero y disfruta de nuestro socio!",
  "transactionsSection1Right": "Añadido al saldo",
  "transactionsSection2Left": "¡Tu amigo duplicó su dinero!",
  "transactionsSection2Right": "Añadido al saldo",
  "transactionsSection3Left": "Retiro de dinero",
  "transactionsSection3Left2": "a Pix",
  "transactionsSection3Right": "Añadido al saldo",
  "transactionsSection4Left": "Retirar dinero",
  "transactionsSection4Left2": "/ Pix",
  "transactionsSection4Right": "Saque",
  "ticketsTitle": "Mis Tickets y Puntos",
  "ticketsSection1Left": "¡Tu amigo duplicó su dinero!",
  "ticketsSection1Right": "Tickets para Las Vegas",
  "ticketsSection2Left": "Correo enviado",
  "ticketsSection2Right": "Tickets para Las Vegas",
  "ticketsSection3Left": "Por",
  "ticketsSection3Left2": "¡crédito adquirido en nuestro socio!",
  "ticketsSection3Right": "Tickets para Las Vegas",
  "ticketsSection4Left": "Un Amigo dobló su dinero con éxito y recibió puntos",
  "ticketsSection4Right": "Puntos",
  "ticketsSection5Left": "Correo enviado",
  "ticketsSection5Right": "Puntos",
  "ticketsSection6Left": "¡Por",
  "ticketsSection6Left2": "¡crédito adquirido en nuestro socio!",
  "ticketsSection6Right": "Puntos",
  "aboutModalTitle": "Sobre nosotros",
  "aboutModalDesc1": `Bienvenido a ${getDomain()}, tu puerta de entrada para transformar la participación en línea en recompensas tangibles y emocionantes.`,
  "aboutModalDesc2": "En un mundo en el que el valor de sus acciones digitales a menudo se siente intangible, proporcionamos una plataforma que no solo aprecia tu participación, sino que también la recompensa generosamente.",
  "aboutModalDesc3": `Desde duplicar su dinero con una simple transacción hasta ganar aventuras con todos los gastos pagados, ${getDomain()} está aquí para hacer que cada clic cuente.`,
  "aboutModalDesc4": `${getDomain()} - donde cada clic trae una recompensa, y cada recompensa trae una sonrisa (rentable)`,
  "contactModalTitle": "Contáctanos",
  "contactModalSubTitle": "Envíanos tu correo electrónico y nos pondremos en contacto contigo",
  "contactModalBtn1": "¡Enviado!",
  "contactModalBtn2": "Enviar mensaje",
  "legalModalTitle": "Legal",
  "legalModalDesc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "messageBtn": " Copiar mensaje",
  "settingsTitle": "Configuración de la cuenta",
  "settingsUserName": "Nombre de usuario",
  "settingsUserEmail": "Correo electrónico",
  "settingsCheckbox": "Recibir notificaciones y recordatorios por correo electrónico",
  "settingsDeleteStep": "Eliminar cuenta",
  "settingsDeleteSure": "Si eliminas tu cuenta, perderás todos tus logros",
  "settingsDeleteSure2": "¿Estás seguro de que quieres eliminar tu cuenta?",
  "settingsDeleteNo": "No",
  "settingsDeleteYes": "Sí",
  "settingsBtn": "Aplicar cambios",
  "settingsBtnDone": "¡Guardado!",
  "leaderboardsTitle": "Rankings",
  "leaderboardsPoints": "Puntos",
  "leaderboardsPrize": "Premio",
  "tourShortTitle": "Lorem ipsum",
  "tourLongTitle": "Lorem ipsum",
  "tourShortToggle": "Versión corta",
  "tourLongToggle": "Versión larga",
  "tourShortBtn": "Comparte con tus amigos",
  "tourLongBtn": "Comparte el tour",
  "authSlide1": "“Mi deseo es tu comando - deja que la emoción te invada.”",
  "authSlide1li1": "1. Duplica tu dinero - valoramos tu tiempo",
  "authSlide1li2": "2. Ten la oportunidad de ganar la promoción de Las Vegas",
  "authSlide1li3": "3. Gana el concurso diario de 6.9$",
  "authSlide2": "1. ¡Deposite y Doble!",
  "authSlide2li1": "Deposita 5$ en el sitio de nuestro socio - disfruta usándolos y duplícalo para obtener inmediatamente una devolución de 10$ en efectivo de " + getDomain() + "... ¡porque valoramos tu tiempo!",
  "authSlide3": "2. Las Vegas con Amigos",
  "authSlide3li1": "Consigue entradas para Las Vegas para tener la oportunidad de ganar nuestra increíble promoción de Las Vegas: ¡un fin de semana de tus sueños que nunca olvidarás!",
  "authSlide4": "3. Gana el concurso diario de US$6.9",
  "authSlide4li1": "Gana puntos participando más en " + getDomain() + ". ¡Termina primero en nuestro top 10 para ganar 6.9$, todos los días! ",
  "authEnterEmail": "Ingresa tu correo electrónico",
  "authContinue": "Continuar",
  "authDiscord": "Continuar con",
  "authGoogle": "Continuar con",
  "authFacebook": "Continuar con",
  "verifyCodeTitle": "Verifica tu cuenta",
  "verifyCodePlaceholder": "Ingresa el código de verificación enviado por correo electrónico",
  "verifyCodeError": "El código es incorrecto",
  "verifyCodeBtn": "Enviar",
  "verifyCodeLink": "¿Ya tienes una cuenta?",
  "verifyCodeLinkSpan": "Inicia sesión",
  "verifyCodeEmailTitle": "Hemos enviado un correo electrónico de verificación a",
  "openEmailApp": " POR FAVOR, ABRE TU CORREO ELECTRÓNICO",
  "logoutConfirmTitle": "¿Seguro que quieres cerrar la sesión?",
  "logoutConfirmNo": "No",
  "logoutConfirmYes": "Sí",
  "winTitle": "¿Cómo puedo conseguir tickets para ganar la promoción de Las Vegas?",
  "winSubTitle": "¿Cómo puedo conseguir entradas para ganar la promoción de Las Vegas?",
  "winPoint1Title": "Compra más créditos en nuestro socio.",
  "winPoint1li1": "Cada crédito extra que compres además de los 5 primeros que compres en la oferta del doble de dinero te dará 1 ticket de Vegas (sin créditos de bono incluidos).",
  "winPoint2Title": "Recomienda a amigos que completen la oferta Duplica Tu Dinero",
  "winPoint2li1": "Recomienda a tus amigos y consigue 30 entradas para Las Vegas por cada amigo que complete la oferta del doble de dinero",
  "winPoint3Title": "Envía un formulario postal gratuito por correo a nuestra oficina",
  "winPoint3li1": "Descargar el formulario",
  "winPoint3li2": "Rellene sus datos y envíelo a nuestras oficinas en Chipre (dirección detallada en el formulario descargable)",
  "winPoint3li3": "Cada carta que envíes puede incluir un solo formulario y te recompensará con un billete",
  "winPointEnd": "¡Buena suerte!",
  "winPointEndLink": "Ir a los términos completos de la Promoción Vegas",
  "rulesTitle": "🎉 ¡Bienvenidos al concurso de " + getDomain() + "! 🎉",
  "rulesSubTitle": "A partir del 15 de marzo de 2024 y a diario, únete a nosotros en el Concurso " + getDomain() + ".",
  "rulesPoint1Title": "Para participar:",
  "rulesPoint1li1": "Inicia sesión en nuestro socio",
  "rulesPoint1li2": "Compre 5 créditos y reciba una bonificación de 5 más",
  "rulesPoint2Title": "Formas de obtener recompensas:",
  "rulesPoint2li1": `Gasta los 10 créditos en nuestro socio y recibe 10 USD en ${getDomain()}`,
  "rulesPoint2li2": "$6.9 para el 1er lugar y $6.90 del 2do al 10mo ¡diariamente!",
  "rulesPoint2li3": "1 USD + 30 entradas a Las Vegas + 10 puntos por cada persona que complete la oferta.",
  "rulesPoint2li4": "1 Ticket Vegas + 0.5 Puntos por cada crédito comprado después de la primera compra en nuestro socio.",
  "rulesEnd": `¡Mucha suerte a todos los participantes en el Concurso ${getDomain()}`,
  "toolInfoTitle": `¿Qué puedo conseguir en ${getDomain()} y cómo?`,
  "toolInfoWhat": "¿Qué?",
  "toolInfoHow": "¿Cómo?",
  "toolInfolef1": "10$ en tu bolsillo",
  "toolInfolef2": "Gana la promoción de Las Vegas",
  "toolInfolef3": "Gana el concurso diario de 6.9$",
  "toolInfoRight1": "Completa la oferta \"Duplica Tu Dinero\"",
  "toolInfoRight2": "Cobra el billete comprando créditos en nuestro socio y recomienda a amigos que completen la oferta",
  "toolInfoRight3": "Consigue puntos comprando créditos en nuestro socio y recomendando a amigos que completen la oferta",
  "ourMissionTitle": "Nuestra misión",
  "ourMissionSubTitle1": `${getDomain()} surgió de la simple idea de que las plataformas en línea deberían ofrecer un valor más directo a sus usuarios.`,
  "ourMissionSubTitle2": "Nos dedicamos a crear un entorno en el que cada acción, ya sea un depósito, una recomendación o una compra, tenga un resultado gratificante.",
  "ourMissionSubTitle3": "Nuestra plataforma está diseñada para celebrar y recompensar a la comunidad por su compromiso y lealtad, convirtiendo las actividades cotidianas en oportunidades de alegría y emoción.",
  "whyJoinTitle": "¿Por qué unirse a nosotros?",
  "whyJoinSubTitle1": `${getDomain()} se encuentra en la intersección entre innovación, recompensa y comunidad. Somos más que una plataforma; somos un movimiento hacia la creación de un ecosistema en línea más gratificante. Nuestra dedicación a ofrecer recompensas transparentes, inmediatas y sustanciales nos diferencia, garantizando que el tiempo que pases con nosotros no solo sea agradable, sino también fructífero.`,
  "whyJoinSubTitle2": `Nuestra comunidad es el núcleo de nuestro éxito, y nos comprometemos a fomentar un espacio en el que todos los miembros puedan experimentar la alegría de ganar. Desde recompensas en metálico hasta vacaciones de ensueño, ${getDomain()} se dedica a convertir tu compromiso digital en placeres del mundo real.`,
  "FAQ_Questions": [
    {
      "question": "¿Qué hago aquí?",
      "answers": [
        "Te garantizamos que duplicarás al 100% tu depósito inicial en efectivo, competirás por la oportunidad de ganar un emocionante fin de semana en Las Vegas y participarás en nuestro concurso diario de 6.9 $."
      ]
    },
    {
      "question": "¿Cuáles son los requisitos para calificar para el reembolso instantáneo de $5?",
      "answers": [
        "Simplemente regístrate en nuestro socio, deposita $5 para recibir 5 créditos, gasta esos créditos y recibirás un reembolso del 100% al instante aquí."
      ]
    },
    {
      "question": "¿Puedo retirar mi reembolso de $5 inmediatamente después de recibirlo?",
      "answers": [
        "Sí, tu reembolso de $5 en efectivo está disponible para retiro inmediato"
      ]
    },
    {
      "question": `¿Cómo puedo invitar a mis amigos a unirse a ${getDomain()} y cuáles son nuestras ventajas?`,
      "answers": [
        "Utiliza la página \"Recomienda a tus amigos\". Por cada amigo que complete la oferta, recibirás 1 $ en efectivo, 30 entradas para Las Vegas y 10 puntos para el concurso diario. Además, si ganas la promoción de Las Vegas y ellos han completado la oferta \"Duplica Tu Dinero\", ¡podrás llevarte a dos de ellos a Las Vegas contigo!"
      ]
    },
    {
      "question": "¿Qué pasos deben seguir mis amigos para calificaar al viaje a Las Vegas?",
      "answers": [
        "Tus amigos tienen que registrarse a través de tu enlace de recomendación y completar la oferta del doble de dinero para que puedas invitar hasta a 2 de ellos como compañeros de viaje para el viaje a Las Vegas."
      ]
    },
    {
      "question": "¿Cuáles son las reglas de la promoción de Las Vegas?",
      "answers": ["Lorem ipsum"]
    },
    {
      "question": `¿Existe un límite en el número de amigos que puedo recomendar a ${getDomain()}?`,
      "answers": [
        "No, no hay límite. ¡Recomienda a tantos amigos como quieras y gana más!"
      ]
    },
    {
      "question": "¿Cómo se elige a los ganadores del fin de semana de Las Vegas?",
      "answers": [
        "Los ganadores se seleccionan mediante un sorteo en directo en presencia de un abogado y un contable, lo que garantiza la imparcialidad y la transparencia. Enlace a las normas del fin de semana en Las Vegas"
      ]
    },
    {
      "question": "¿Qué gastos están cubiertos en el premio del fin de semana de Las Vegas?",
      "answers": [
        "El premio del fin de semana en Las Vegas cubre tu viaje y entretenimiento de forma extravagante. Incluye billetes de ida y vuelta a Las Vegas para ti y dos amigos que hayan completado la oferta del doble de dinero si decides llevarlos, dos noches de hotel de 5 estrellas y 1.000 $ en créditos partner.com para el ganador. Además, recibirás 1.000 $ en Efectivo y, si procede, cada uno de tus dos amigos recibirá 500 $ para disfrutar. El paquete se corona con una cena especial y una experiencia de conducción de un Cadillac con 2 Amigas VIP, valorando el premio total en aproximadamente 5.000 $."
      ]
    },
    {
      "question": "¿Cómo puedo comprar créditos adicionales del socio?",
      "answers": [
        "Haz clic aquí para comprar más créditos y aumentar tus posibilidades de ganar la promoción de Las Vegas y los puntos del concurso diario de $6.9."
      ]
    },
    {
      "question": "¿Existe algún límite sobre la cantidad de créditos que puedo comprar?",
      "answers": [
        "No, no hay límites en el número de créditos que puedes comprar. Mientras compres más, tendrás más posibilidades de ganar la promoción de Las Vegas o de acabar entre los 10 primeros en el concurso diario de 6.9 $."
      ]
    },
    {
      "question": "¿Cómo funciona la tabla de clasificación diaria de $6.9 y cómo puedo ganar puntos?",
      "answers": [
        "Gana puntos recomendando amigos y comprando créditos de nuestro socio. Cuanto más participes, más alto subirás en la clasificación. Por cada amigo que recomiendes y que haya completado la oferta, obtendrás 10 puntos. Por cada crédito adicional de nuestro socio que compre, obtendrá 0,5 puntos. "
      ]
    },
    {
      "question": "¿Cuáles son las recompensas por las 10 primeras posiciones en la tabla de clasificación diaria?",
      "answers": [
        "Los dos primeros puestos ganan 6.9 $ cada uno, y los puestos del 3 al 10 ganan 6,90 $ cada uno, todos los días."
      ]
    },
    {
      "question": "¿Con qué frecuencia se actualizan la tabla de clasificación y las recompensas?",
      "answers": [
        "La clasificación y las recompensas se actualizan inmediatamente, en tiempo real."
      ]
    },
    {
      "question": "¿Puedo ganar premios diarios varias veces?",
      "answers": [
        "Sí, puedes ganar recompensas diarias cada vez que encabeces el top 10 de la tabla de clasificación."
      ]
    },
    {
      "question": "¿Qué ocurre si hay un empate en la clasificación diaria?",
      "answers": [
        "En caso de empate, se elegirá al usuario con más amigos recomendados, seguido del que tenga más créditos comprados y, a continuación, el que antes haya completado la oferta."
      ]
    },
    {
      "question": "¿Hay comisiones por ingresar o retirar dinero?",
      "answers": [
        "No, en nuestra plataforma no se cobran comisiones ni gastos por ninguna transacción financiera."
      ]
    },
    {
      "question": "¿Cómo puedo seguir mis recomendaciones y recompensas en tiempo real?",
      "answers": [
        " para supervisar tus recomendaciones y recompensas a medida que se producen."
      ],
      "link": "[referralsModal]",
      "linkName": "Haz clic aquí"
    },
    {
      "question": "¿Por qué no veo mi depósito en el socio incluso después de depositar?",
      "answers": [
        `Los depósitos suelen reflejarse en tu cuenta ${getDomain()} en 3 minutos, acreditándote los fondos correspondientes. Si no ves tu depósito pasados 30 minutos, te recomendamos que te pongas en contacto con nuestro servicio de atención al cliente para obtener ayuda.`
      ]
    },
    {
      "question": "¿Con quién puedo ponerme en contacto si tengo problemas con mi cuenta o mis premios?",
      "answers": [
        " para acceder a nuestro chat y recibir la ayuda que necesites."
      ],
      "link": "[contactForm]",
      "linkName": "Haz clic aquí"
    },
    {
      "question": "¿Cuáles son los términos de servicio?",
      "answers": [
        "Para todos los detalles sobre nuestras políticas:",
        "- Términos de servicio",
        "- Términos del concurso diario",
        "- Términos de la promoción",
        "- Política de Cookies",
        "- Política de privacidad",
        "- Términos de afiliación"
      ]
    }
  ],
  "dataMessageDesc1": "Imagínanos en Las Vegas si ganamos. ¿Te apuntas a una aventura?",
  "dataMessageDesc2": "Estoy repartiendo dinero gratis por referirte. ¿Te interesaría un empujón?",
  "dataMessageDesc3": "Ya están aquí los concursos diarios. ¿Crees que puedes superarme? Veamos.",
  "dataMessageDesc4": "¿Las Vegas conmigo? Gana y estaremos en el jet-set. Apostemos por mí.",
  "dataMessageDesc5": "Oye, jugando al banquero real aquí. Acompáñame, obtén dinero gratis. ¡Es un reinado de dinero!",
  "dataMessageDesc6": "Unirse a mí = diversión sin parar. En serio, es una maravilla.",
  "dataMessageDesc7": "Únete a mi séquito real y convirtamos tu membresía en el tesoro de mi reino. Ganamos todos, ¿no?",
  "dataMessageDesc8": "Sube a bordo y convirtamos tu afiliación en mis joyas de la corona. Parece un trato real, ¿eh?",
  "tourSlide1Title": "La promoción de Las Vegas te espera",
  "tourSlide1SubTitle": "Billetes de avión a Las Vegas a nuestra responsabilidad para ti y 2 amigos que hayas elegido* y que hayan completado la oferta \"Duplica Tu Dinero\". 2 noches en un hotel de 5 estrellas, 1.000 $ para tus gastos y 500 $ para los de cada uno de tus amigos 🎉.",
  "tourSlide2Title": "Glamuroso tour en uno Cadillac",
  "tourSlide2SubTitle": "Recorre Las Vegas en un Cadillac con 2 Amigas VIP. VIve el brillo y el glamour de cerca con dos de tus mejores amigos. Sé el centro de atención en las calles de Las Vegas durante unas horas 🔥",
  "tourSlide3Title": "Cena + Diversión en la discoteca con tus nuevas Amigas VIP",
  "tourSlide3SubTitle": "Cena con nosotros con tus nuevos amigos Amigas VIP, seguido de una noche de fiesta en un club de élite en Las Vegas. Haz recuerdos inolvidables en Las Vegas que permanecerán una eternidad 🏆.",
  "withdrawInputEmail": "Ingresa tu e-mail",
  "withdrawContinue": "Continuar",
  "verificationTitle": "Verificación OTP",
  "verificationSubTitle": "Te enviamos una contraseña de un solo uso a ",
  "PIXTitle": "Por favor, introduce los datos de tu Pix:",
  "PIXInpu1": "Clave Pix:",
  "PIXInpu2": "CPF",
  "withdrawConfirm": "Hemos recibido su solicitud de retirada...",
  "withdrawError": "Se produjo un error. Inténtalo de nuevo o comunícate con Atención al cliente.",
  "withdrawErrorTry": "Cerrar e intentar de nuevo",
  "withdrawLi1": "1. Envía tu correo electrónico para recibir tu cupón exclusivo.",
  "withdrawLi2": "2. Revisa tu bandeja de entrada para ver nuestro correo electrónico y haz clic en la opción 'Canjear' dentro del cupón.",
  "withdrawLi3": "3. Accederás a nuestra tienda de vales, alojada en XOXOday.",
  "withdrawLi4": "4. Una vez allí, elige una forma de canjear tus vales, como a través de PayPal o con tarjetas prepago Visa.",
  "withdrawLi5": "5. Procesarán tu solicitud y te enviarán los fondos.",
  "withdrawLi6": "6. Si necesitas ayuda, contacta a nuestro",
  "withdrawLi6Span": "atención al cliente.",
  "myReferralsTitle": "Mis referencias para Las Vegas",
  "myReferralsSubTitle": "Compruebe si sus amigos recomendados han aceptado la oferta del doble de dinero de nuestro socio: ¡sólo entonces podrá llevarlos a Las Vegas si gana!",
  "myReferralsDoneTitle": "Te ha referido:",
  "myReferralsDoneSubTitle": "¡Completaron la oferta y son elegibles para la elección de Las Vegas si ganas el sorteo!",
  "myReferralsUnDoneTitle": "Te ha referido:",
  "myReferralsUnDoneSubTitle": "Todavía no han completado la oferta y no son elegibles para la elección de Las Vegas si ganas el sorteo :(",
  "myReferralsSheet1": "Nombre",
  "myReferralsSheet2": "Aceptó la oferta",
  "myReferralsSheet3": "Enviar correo electrónico",
  "myReferralsSend": "Enviar",
  "myReferralsSubTitle2": "Todavía no has recomendado a tus amigos",
  "winTicketTitle": "👬¿Puedo ir a Las Vegas si mi ticket no ganó? 👬",
  "winTicketSubTitle": "Sí! Para que tú, quien te ha referido o tu referido puedan ser elegidos por el otro si ganan la promoción de Las Vegas, necesitarás",
  "winTicketLiTitle": `Únase a la promoción ${getDomain()} en nuestro socio:`,
  "winTicketLi1": "Crea una cuenta nueva o utiliza una existente.",
  "winTicketLi2": "Haz una compra mínima de cinco créditos para ganar cinco más.",
  "winTicketLi3": "Gasta los cinco (5) créditos recibidos en nuestro socio.",
  "winTicketBtn": "Ir a la página Duplica Tu Dinero",
  "winTicketEnd": "¡Buena suerte!",
  "winTicketLink": "Ir a los términos completos de la Promoción Vegas",
  "leaderSheetYou": "Tu lugar hoy:",
  "leaderSheetPoints": "Puntos de competencia",
  "leaderSheetFriends": "Amigos referidos",
  "raffleRulesDescSpan": "Más información.",
  "competitionOverBtn": "Consigue tantos puntos como puedas para ganar la competición diaria de 6.9$:",
  "infoOfferTitle": "¿Qué obtengo cuando recomiendo a un amigo que completa la oferta \"Duplica Tu Dinero\"?",
  "infoOfferPoint1": "Un amigo que te agradecerá que le hagas dinero gratis.",
  "infoOfferPoint2": "1$ puedes retirarlo en efectivo inmediatamente",
  "infoOfferPoint3": "30 Tickets para Las Vegas que mejoran sus posibilidades de ganar la promoción de Las Vegas",
  "infoOfferPoint4": "10 puntos de competición diarios de 6.9$ que te acercan al top 10",
  "infoOfferPoint5": "Un amigo que puedes elegir para ir a Las Vegas si ganas la promoción",
  "infoOfferPoint6": "Tu amigo podrá elegirte para ir a Las Vegas si ha ganado la promoción y has completado la oferta \"Dobla Tu Dinero\".",
  "earnedModalTitle": "Últimas ganancias en USD",
  "TicketModalTitle": "Últimas ganancias de Tickets de Las Vegas",
  "PointsModalTitle": "Ganancias en puntos del último concurso",
  "earnedModalLink": "Ver información completa",
  "toolInfoLeft1": "Acción",
  "toolInfoPoint1": "USD ganados",
  "toolInfoPoint2": "Puntos",
  "toolInfoPoint3": "Tickets",
  "contactQuestionTitle": "¿Cuál es tu problema?",
  "contactQuestion1": "No he recibido mi dinero",
  "contactQuestion2": "No recibí mis puntos de competencia",
  "contactQuestion3": "No he recibido mis tickets para Las Vegas",
  "contactQuestion4": "Tengo problemas con mi cuenta",
  "contactQuestion5": "Olvidé mi contraseña",
  "contactQuestion6": "Quejas",
  "contactQuestion7": "Otros: por favor escribe sobre ello.",
  "contactQuestionEmail": "Tu correo electrónico",
  "contactQuestionDesc": "Descripción",
  "contactQuestionAttach": "Adjuntar archivos",
  "contactConfirmTitle": "¡Tu mensaje ha sido enviado! Nos pondremos en contacto contigo en breve",
  "contactConfirmBtn": "Continuar",
  "timeCounterLink": "*Sujeto a los Términos y Condiciones del concurso diario",
  "raffleBotLink1": "👬¿Puedo ir a Las Vegas igualmente si mi boleto no ganó? 👬",
  "raffleBotLink2": "Tendrás que duplicar tu dinero",
  "doubleBtnTitle": "" + getDomain() + " te ordena que...",
  "doubleBtnAway": "minutos de distancia",
  "doubleBtnFrom": "del retiro",
  "doubleCompleteTitle": "¡Felicidades, tienes 10$ dólares esperándote, ve a buscarlo y retíralo!",
  "doubleCompleteTitleSpan": "¡Buzón de entrada!",
  "doubleCompleteSubTitle": "Sé el rey de tus amigos recomendándolos a " + getDomain() + " y te lo agradecerán por siempre... 👑",
  "doubleCompleteBtn": "Compartir",
  "doubleTaskBtn1": "1/3 Comienza ahora 🎉",
  "doubleTaskBtn2": "2/3: Deposita $5, obtén 5 créditos 💸",
  "doubleTaskBtn3": "3/3: Gasta todos los créditos, obtén $10 de regreso 🤑",
  "referralShareBtn1": "¡Comparta el enlace de referencia!",
  "referralShareBtn2": "¡Link copiado!",
  "ModalMenuToggle": "Elige tu diseño",
  "leaderSheetToday": "Hoy",
  "leaderSheetYesterday": "Ayer",
  "promoModalTitle": "Retirar con",
  "promoModalMessage": "No seas tonto toma esto consigue +30% 65 reales en lugar de 50",
  "promoModalLink": "No, gracias",
  "promoImLiveDesc": "No seas tonto toma esto consigue +30% 65 reales en lugar de 50",
  "confirmLiveDesc": "¿Seguro que quiere darse de baja con nuestro socio?",
  "earnedModalTitleBR": "Latest R$ earnings",
  "modalMenuLink14": "Leer Más",
  "raffleSubTitleLink": "¿Cómo puedo ganar entradas para Las Vegas?",
  "competitionTitle": "Gana el ",
  "competitionTitle2": " ¡Competencia diaria!",
  "leaderboardsNotUser": "No hay más usuarios",
  "NamePage1": "Página de inicio",
  "NamePage2": "Duplica tu dinero",
  "NamePage3": "Fin de Semana en Vegas",
  "NamePage4": "Competencia",
  "NamePage5": "Mis referidos",
  "withdrawGiftTitle": "Tarjeta regalo / Efectivo",
  "doubleBtnEndTask": "¡Gana la Competencia Diaria de $6.9!",
  "paxumFlowTitle": "Recibirás un vale en efectivo en el correo electrónico que elijas:",
  "paxumFlowInput": "Introduzca su ID de Paxum",
  "paxumFlowPlaceholder": "Introduzca su ID de Paxum",
  "paxumFlowBtn": "Continuar",
  "paxumFlowLink1": "¿No tiene cuenta en Paxum?",
  "paxumFlowLink2": "Créalo aquí",
  "visaFlowTitle": "Recibirás un vale en efectivo en el correo electrónico que elijas:",
  "visaFlowSubTitle": "La primera retirada tiene un mínimo de ",
  "visaFlowSelectName1": "Traslado a",
  "visaFlowSelect1": "Tarjeta de débito Virtual Prepagada",
  "visaFlowInput": "Introduzca su ID de Paxum",
  "visaFlowPlaceholder": "Introduzca su ID de Paxum",
  "visaFlowSelectName2": "Tipo de tarjeta",
  "visaFlowSelect2": "Visa US$",
  "visaFlowSelectName3": "Valor",
  "visaFlowSelect3": "Tarjeta Visa de prepago",
  "visaFlowBtn": "Continuar",
  "aboutModalDesc5": "" + getDomain() + " es una vanguardista plataforma global de recomendación social que motiva a su comunidad con recompensas como concursos, premios y devoluciones en efectivo por interactuar con socio de alta calidad en diversos sectores.",
  "aboutModalDesc6": "Inspirado en el juego infantil \"Simon Says\", " + getDomain() + " también incluye elementos de gamificación, que acaban en increíbles recompensas y diversión sin límites para sus usuarios.",
  "loginTermsTitle": "Al registrarte en " + getDomain() + " declaras que tienes 18 años o más y que estás de acuerdo con nuestro",
  "loginTermsLink": "Términos y servicios",
  "homepageMessageTitle": "Dile qué hacer y lo hará... EN VIVO",
  "SPEITitle": "Recibirás un vale en efectivo en el correo electrónico que elijas:",
  "SPEIInput1": "Nombre",
  "SPEIInput2": "Apellido",
  "SPEISelect1": "Tipo de Documento",
  "SPEIInput3": "Documento",
  "SPEIInput4": "Correo electrónico",
  "PSETitle": "Recibirás un vale en efectivo en el correo electrónico que elijas:",
  "PSEInput1": "Email",
  "PSEInput2": "Nombre",
  "PSEInput3": "Documento nacional de identidad",
  "ServiPagTitle": "Recibirás un vale en efectivo en el correo electrónico que elijas:",
  "ServiPagInput1": "Email",
  "ServiPagInput2": "Nombre",
  "ServiPagInput3": "National identity card (RUT)",
  "TransactionCompetitionWinner": "¡Terminando # ",
  "TransactionCompetitionWinner2": "en la competencia diaria de 6.9$!",
  "modalMenuLink15": "Únete como influencer",
  "modalMenuLink16": "Programa de socio",
  "InfluencerTitle": "Únete como influencer",
  "InfluencerSubTitle": "Buscamos influencers líderes a nivel mundial y local. Únete a nosotros para asociarte con marcas líderes mundiales y disfrutar de oportunidades increíbles. ",
  "InfluencerInput1": "Email",
  "InfluencerInput2": "Nombre",
  "InfluencerSelect1": "Who you are?",
  "InfluencerOptoin1": "Elige quién eres",
  "InfluencerInput3": "Tu mensaje",
  "InfluencerBtn": "Continuar",
  "collaborateTitle": "Programa de socio",
  "collaborateSubTitle": "" + getDomain() + " busca colaborar con empresas internacionales que tengan un alcance global, con el objetivo de captar nuestro elevado tráfico de usuarios.",
  "collaborateInput1": "Email",
  "collaborateInput2": "Nombre",
  "collaborateSelect1": "¿Quién eres?",
  "collaborateOptoin1": "Elige quién eres",
  "collaborateInput3": "Tu mensaje",
  "collaborateBtn": "Continuar",
  "paxumFlowCheckbox": "Sólo me pagarán con Paxum si tengo una cuenta válida.",
  "toolInfolef4": "¿No ganó su billete a Las Vegas? ¡Aún puede ir!",
  "toolInfoRight4": "Si un amigo que has recomendado o tu recomendador han ganado la promoción y has completado la oferta, ¡podrán elegirte para ir a Las Vegas!",
  "withdrawConfirmPaxum": "Recibimos tu solicitud. Nuestro equipo responderá una vez que el retiro sea exitoso",
  "withdrawBanner": "Obtén +40%",
  "withdrawBannerSpan": "de su dinero con nuestro socio.",
  "imLivePromoDesc": "nuestro socio le ofrecen el mejor valor por tu dinero: obtén 1 crédito por cada US$5 + 20% de crédito adicional",
  "imLivePromoVtn": "Aprovecha esta oferta",
  "InfluencerInputPhone": "Teléfono",
  "InfluencerWho": "¿Quién es usted?",
  "InfluencerWhoSelect": "Elige quién eres",
  "InfluencerWhoOption1": "Influencer Cripto",
  "InfluencerWhoOption2": "Modelo",
  "InfluencerWhoOption3": "Creador de contenido en Instagram",
  "InfluencerWhoOption4": "Creador de contenido en YouTube",
  "InfluencerWhoOption5": "TikToker",
  "InfluencerWhoOption6": "Otro",
  "InfluencerNickname": "Apodo de red social",
  "InfluencerFollowers": "Red social y seguidores.",
  "InfluencerThanks": "Gracias por la solicitud. Nuestro equipo se comunicará contigo pronto.",
  "collaboratePhone": "Teléfono",
  "collaborateURL": "URL del sitio web",
  "collaborateJob": "Trabajo abierto",
  "collaborateWho": "¿Quién eres?",
  "collaborateWhoSelect": "Elige quién eres",
  "collaborateWhoOption1": "Negocio local",
  "collaborateWhoOption2": "Agente",
  "collaborateWhoOption3": "Empresa mundial",
  "collaborateWhoOption4": "Otro",
  "withdrawBannerTitle": "O consiga +38% de valor como créditos de nuestro socio:",
  "withdrawBannerMessage": "¡No dejes que ",
  "withdrawBannerSpan1": "Obtener ",
  "withdrawBannerSpan2": "créditos",
  "withdrawBannerSpan3": "¡para gastar ahora!",
  "withdrawBannerBtn": "Obtener ahora",
  "withdrawBannerSubtitle": "* Los porcentajes del beneficio se calculan en base a la tarifa básica de 6,9 BRL por 1 crédito de nuestro socio. Los porcentajes bajarán al comprar paquetes de crédito más altos en nuestro socio, sin embargo esa opción siempre tiene un beneficio.",
  "modalMenuLink17": "Mis ganancias",
  "modalMenuCopyLinkTitle": "Sé el rey de tus amigos recomendándoles " + getDomain() + " y te lo agradecerán eternamente.... 👑",
  "modalMenuCopyLinkSubTitle": "Por cada amigo que haya completado la oferta:",
  "modalMenuLink18": "Atención al cliente",
  "transactionApprovedTitle": "Su solicitud de retirada a través de",
  "transactionApprovedSpan": "está aprobado",
  "transactionApprovedRight": "Retiro aprobado",
  "transactionPendingTitle": "Su solicitud de retirada a través de",
  "transactionPendingSpan": "está pendiente",
  "transactionPendingRight": "Pending",
  "transactionRejectedTitle": "Su solicitud de retirada a través de",
  "transactionRejectedSpan": "se rechazó",
  "transactionRejectedRight": "Retiro rechazado",
  "transactionID": "ID de transacción:",
  "raffleSubTitleLink2": "Haz el tour de Las Vegas",
  "CopyLinkDone": "¡Link Copiado!",
  "aboutModalDesc": "",
  "leaderSheetDayAgo": "hace 2 días",
  "verifyCodeSpam": "¿No encuentras el correo electrónico?",
  "verifyCodeSpam2": "Compruebe su carpeta de correo no deseado",
  "timeCounterLink2": "Reglas de promoción de Las Vegas",
  "pixType": "Tipo de Pix",
  "pixTypeOption1": "CPF / CNPJ",
  "pixTypeOption2": "Teléfono móvil",
  "pixTypeOption3": "Correo electrónico",
  "pixTypeOption4": "Aleatorio",
  "pixTypeSelectMain": "Seleccione el tipo de Pix",
  "pixTypeSelectName1": "Introducir CPF / CNPJ",
  "pixTypeSelectName2": "Introducir teléfono móvil",
  "pixTypeSelectName3": "Introduzca la dirección de correo electrónico del destinatario",
  "pixTypeSelectName4": "Al azar",
  "tourUnderSlide": "*Sólo los amigos que hayan sido recomendados por ti o por tu recomendador a " + getDomain() + " podrán ser elegidos por ti para ir a Las Vegas si ganas.",
  "transactionWithdraw": "Retirada",
  "transactionTransactionID": "ID de transacción:",
  "ticketsToggle1": "Entradas para Las Vegas:",
  "ticketsToggle2": "Puntos:",
  "myReferBtn": "Recomienda a tus amigos 👬",
  "authNotificationBtn": "Abrir en",
  "authNotificationBtnSpan": "Aplicación de correo",
  "newVisaFlowInput1": "Nombre y apellidos",
  "newVisaFlowInput2": "Dirección de correo electrónico",
  "newVisaFlowSelect1": "Ciudadanía",
  "newVisaFlowInput3": "Fecha de nacimiento",
  "newVisaFlowCheckbox": "Usted acepta nuestra",
  "newVisaFlowCheckboxSpan": "política de privacidad",
  "transactionStatus": "Estado:",
  "transactionPending": "Pendiente",
  "transactionApproved": "Aprobado",
  "transactionDeclined": "Rechazado",
  "transactionNoData": "Sin datos",
  "referralInvite": "Invita a tus amigos",
  "bannerBtn1": "OBTENGA LOS CRÉDITOS Y LAS ENTRADAS",
  "bannerBtn2": "segundos",
  "bannerBtn2Done": "Continue",
  "doubleCompleteTitle1": "Felicitaciones, tienes ",
  "doubleCompleteTitle2": " dólares esperándote, ¡ve a por ellos y retíralos!",
  "banner1text1": "No hagas esperar a",
  "banner1text1span": "... ¡ella está ansiosa por profundizar la conexión!",
  "banner1text2": "Ni siquiera te despediste...",
  "banner1text3": "Actúa ya",
  "banner1text4": "Obtenga 12 créditos como 38%+ de valor en sus",
  "banner1text4span2": "y regrese a",
  "banner1text4span": "ahora",
  "banner2text1": "Gana 6,90 $ en lugar de 5 $.",
  "banner2text2": "GANAR-GANAR",
  "banner2text3": "Toma los créditos, consigue 30 puntos de competición, conviértete en",
  "banner2text3span": "en la competición diaria y gana 6,90 $ ¡terminando entre los 10 primeros!",
  "banner3text1": "Promoción en Las Vegas",
  "banner3text2": "Ahora tienes 0 entradas para Las Vegas. Aumenta tus $50R un 38% para ganar 12 créditos Y 5 entradas para ganar la promoción de Las Vegas.",
  "banner3text3": "Recorre Las Vegas en un Cadillac con dos estrellas. Experimenta el brillo y el glamour con dos de tus mejores amigos. Disfruta de una cena y un club nocturno con tus nuevas amigas.",
  "banner3text4": "Puedes tener +5 entradas",
  "withdrawBannerMessage2": "espere! No puedes terminar el trabajo... ¿o sí? 🙄",
  "visaFlowSubTitle2": "en este tipo de pago",
  "noTransactionsToday": "No ha realizado ninguna transacción hoy",
  "banner1offer": "créditos para recuperar",
  "banner1offer2": "",
  "banner1offerSpan": "!",
  "banner2winner": "Al adquirir créditos, recibes de inmediato 30 puntos para el resultado de la competición de hoy, lo que puede garantizarte el primer lugar",
  "banner2winnerSpan": "con un valor de  ",
  "homepageNewTitle1": "Experimenta acción EN VIVO caliente como nunca antes:",
  "homepageNewTitle2": "1. Doblando tu dinero - valoramos tu tiempo",
  "homepageNewTitle3": "2. Ganando $6.9 en la competencia diaria ",
  "homepageNewTitle4": "3. Teniendo una oportunidad en la promoción de Vegas",
  "banner3text5Span": "Tú tienes:",

  "banner3text5": "tickets",
  "homepageFinishCTA": "¡Gana la Competencia Diaria de $6.9!",
  "newTaskDouble": "Compra 5 $, gástalos y obtén 10 $ inmediatamente como ",
  "homepageBtnNew1": "1. Recibe pago por probar ImLive 👑",
  "homepageBtnNew2": "2. Gana $6.9 en la competencia diaria",
  "homepageBtnNew3": "3. Obtén la oportunidad de ganar la promoción de Vegas",
  "homepageNewPoint1": "Compra y gasta R$25 en",
  "homepageNewPoint2": "Gana $6.9 en la competencia diaria",
  "homepageNewPoint3": "Obtén la oportunidad de ganar la promoción de Vegas",
  "homepageStep1": "Regístrate",
  "homepageStep2": "Compra ",
  "homepageStep3": "Gasta todo",
  "homepageStep4": "Obtén ",

  "newHomepageTitle": "Experiencia",
  "newHomepageTitleSpan1": "ADULTA EN VIVO",
  "newHomepageTitleSpan2": "como nunca antes &",
  "newHomepageBtn1": "1. Empieza en ImLive 👑",
  "newHomepageBtn2": "2. Deposite R$25 para una experiencia CALIENTE.",
  "newHomepageBtn3": "3. Gasta 5 créditos para obtener R$25 de reembolso",
  "newVegasTitle": "No pierdas la oportunidad de ganar",
  "newVegasSubTitle": "LA PROMOCIÓN DE LAS VEGAS",
  "newVegasBtn1": "Disfruta de ImLive 💃",
  "newVegasBtn2": "Referir Amigo 🤑",
  "newVegasBtn2Copy": "¡Enlace Copiado!",
  "newVegasUnderBtn1": "1 crédito comprado = 1 ticket a Las Vegas",
  "newVegasUnderBtn2": "Oferta completada por un amigo = 30 tickets para Las Vegas",
  "newCounterPromotion": "Cuenta atrás para la Promoción de Las Vegas",
  "newCounterDay": "Cuenta regresiva para el final del día",
  "newMenuBottom2Btn1": "Gira la Ruleta 🎰",
  "newMenuBottom2Btn2": "Doble o Nada 💵",
  "newMenuBottom3Link1": "Haz el tour de Las Vegas",
  "newMenuBottom3Link2": "¿Qué es la Promoción de Las Vegas?",
  "newMenuBottom3Btn1": "Disfruta de ImLive 💃",
  "newMenuBottom3Btn2": "Referir Amigo 🤑",
  "newMenuBottom3UnderBtn1": "1 crédito comprado = 1 ticket a Las Vegas",
  "newMenuBottom3UnderBtn2": "Oferta completada por un amigo = 10 tickets para Las Vegas",
  "newMenuLink1": "Inicio",
  "newMenuLink2": "$6.9 Diarios",
  "newMenuLink3": "Las Vegas",
  "newMenuLink4": "Referir",
  "newMenuLink5": "Menú",
  "LPmenuTitle": "Experiencia de",
  "LPmenuTitleSpan": "ACCIÓN EN VIVO",
  "LPmenuTitle2": ", en " + getDomain() + ", ten la oportunidad de ganar ",
  "LPmenuSubTitle": "LA PROMOCIÓN DE LAS VEGAS",
  "LPsingUpBtn": "Registrar",
  "LPsingUpInput": "Ingrese su correo electrónico",
  "LPsingUpLink1": "¿Ya tienes una cuenta?",
  "LPsingUpLink2": "Iniciar sesión",
  "LPfooterLink1": "Política de recompensas",
  "LPfooterLink2": "Condiciones de uso",
  "LPfooterLink3": "Política de privacidad",
  "LPfooterLink4": "Política de cookies",
  "LPfooterCopyright": "Todos los derechos reservados 2023",
  "LPmainTitle": "Regístrate en",
  "LPhowTitle": "¿Cómo",
  "LPhowTitle2": "Funciona?",
  "LPhowWork1Title": "Experimenta Acción en Vivo Caliente SIN RIESGO",
  "LPhowWork1SubTitle": "Compra y Gasta R$25 en nuestro CALIENTE sitio asociado. ¿Te gustó? ¡Obtén 10 créditos adicionales gratis! ¿No fue de tu agrado? ¡Reembolso del 100% del dinero!",
  "LPhowWork1SubTitle2": "❤️ ¿Te encanta? ¡Recibe créditos en VIVO por valor de $10 de regalo, duplica tu reembolso!",
  "LPhowWork2Title1": "Ten la oportunidad de ganar",
  "LPhowWork2Title2": "LA PROMOCIÓN DE LAS VEGAS",
  "LPhowWork2SubTitle": "¡Consigue boletos para Las Vegas para tener la oportunidad de ganar nuestra increíble promoción de Las Vegas - un fin de semana de tus sueños que nunca olvidarás!",
  "LPhowWork3Title": "Conquista la competencia diaria de $6.90",
  "LPhowWork3SubTitle": "¡Gana puntos participando más en " + getDomain() + "! ¡Termina en primer lugar en nuestro top 10 para ganar $6.9 diarios!",
  "LPjoinBtn": "Únete a " + getDomain() + "",
  "LPpromotion": "LAS VEGAS",
  "LPpromotion2": "PROMOCIÓN",
  "LPplacesTitle": "3 primeros puestos",
  "LPwinTitle": "¡GANA LOS",
  "LPwinTitle2": "$6.90 DIARIOS!",
  "LPwinDesk": "¡Acumula puntos y tienes la oportunidad de ganar $6,90 dólares todos los días en nuestra Competencia Diaria!",
  "LPwinSubTitle": "SOCIO PRINCIPAL!",
  "LPcurrenciesMain": "¿Qué puedes obtener?",
  "LPcurrencies1Title": "USD",
  "LPcurrencies1SubTitle": "Disfruta del reembolso del 100% a través de nuestro socio, gana $1 por cada amigo referido y compite diariamente por la oportunidad de ganar $6,90.",
  "LPcurrencies2Title": "TICKETS A LAS VEGAS",
  "LPcurrencies2SubTitle": "¡Consigue entradas para participar en la Promoción de Las Vegas y ten la oportunidad de ir a Las Vegas con dos amigos y muchos beneficios!",
  "LPcurrencies3Title": "PUNTOS DE COMPETENCIA",
  "LPcurrencies3SubTitle": "Gana puntos refiriendo amigos y comprando más créditos en la plataforma de nuestro socio. ¡Cuanto más puntos tengas, más oportunidades tendrás de ganar DINERO en nuestra Competición Diaria!",
  "LPcurrenciesMainSubtitle": "Éstas son algunas de las ",
  "LPcurrenciesMainSubtitle2": "marcas con las que trabajamos",
  "newDoubleTitle": "Tienes",
  "newDoubleTitleSpan": "Puntos de competencia",
  "newDoubleSubtitle": "¡Ganaste",
  "newDoubleSubtitleSpan": "puntos!",
  "newDoubleLose": "¡Perdiste! ¡Mejor suerte la próxima vez!",
  "newSpinTitle": "Puedes hacer girar la rueda",
  "newSpinTitleSpan": "Vez",
  "newSpinSubtitle": "Multiplicas tu punto por",
  "newSpinSubtitleSpan": "puntos!",
  "newSpinSubtitleSpan2": ", ahora tu tienes",
  "GameNoPointsTitle": "No puedes jugar Doble o Nada",
  "GameNoPointsTitle2": "No puedes girar la ruleta",
  "GameNoPointsSubTitle": "Puedes jugar Doble o Nada todos los días siempre que tengas puntos de competencia.",
  "GameNoPointsSubTitle2": "Puedes girar la rueda una vez al día siempre y cuando tengas puntos de competición.",
  "GameNoPointsBtn1": "Gana más puntos",
  "GameNoPointsBtn2": "Continuar",

  "leaderboardNoUser": "Sin usuarios",

  "newRegisterTitle": "Regístrese en",
  "newRegisterUnderTerms": "Al hacer clic en registrar, confirmas que tienes 18 años o más y aceptas nuestros",
  "newRegisterUnderLink1": "Términos de Uso",
  "newRegisterUnderLink2": "Política de Privacidad",
  "newRegisterUnderLink3": "Política de Cookies",
  "newRegisterContinueBtn": "Seguir con",
  "newRegisterContinueBtn2": "E-mail",
  "newRegisterJoinWith": "Unirse con",
  "newMenuToggle1": "Ganar",
  "newMenuToggle2": "Juego",
  "newCompetitionTitle": "Conquista la Competencia Diaria de $6.9!",
  "newMenuBottom4UnderBtn1": "¡Multiplica tus puntos diariamente!",
  "newMenuBottom4UnderBtn2": "¡Prueba tu suerte y duplica tus puntos!",
  "newNoTransaction": "Todavía no hay transacciones.",
  "newSpinSubTitle2": "¡La Rueda tendrá en cuenta tus puntos de competencia y te dará más!",
  "newDoubleSubTitle2": "¿Cómo has tenido suerte hoy? ¡Intenta duplicar tus puntos ahora!",

  "typeValidEmail": "Escribe un correo electrónico válido",

  "pixTypeAll": "PIX Key",
  "pixTypeOption5": "PIX Key Option",
  "pixTypeOption6": "PIX Key Data",
  "pixTypeOption7": "CPF of the user",
  "newHomepageBtnLeft": "Gana créditos! Disfruta de las lives calientes de ImLive",
  "newHomepageBtnRight": "Recupere su dinero",
  "newHomepageBtnOr": "No, gracias",
  "newHomepageBtnDone1": "¡Todas las tareas están completas!",
  "newHomepageBtnDone2": "Ahora, elige tu camino:",
  "homepageNewPointSpan": "obtenga R$50 adicionales GRATIS o un reembolso completo.",
  "messageCopy": "Copy message",
  "messageCopyDone": "Message copied!",
  "newHomepageSteps4Top": "Gana créditos y disfruta de ImLive",
  "newHomepageSteps4Bot": `Recupera tu \ dinero`,

  "homepageLiveTitle1": "",
  "homepageLiveTitleSpan": "EXPERIENCIAS DE WEBCAM PARA ADULTOS",
  "homepageLiveTitle2": "en ImLive - sin riesgos.",
  "homepageLiveUnderSlideTitle": "Compra y gasta R$25 en ImLive",
  "homepageLiveUnderSlideSubTitle": "entonces elige",
  "homepageLiveUnderOr": "o",
  "homepageLiveUnderOrLeft": "Todo el dinero de vuelta",
  "homepageLiveUnderOrRight": `${getAmount(10)} créditos extras gratuitos`,
  "homepageLiveMenuBtn1": `Compra Y Gasta ${getAmount(5)} en ImLive 👑`,
  "homepageLiveMenuTitle1": "para comprar antes de que se acabe!",
  "homepageLiveMenuBtn2": "¡Dame meus 10 créditos EXTRA gratuitos! 👑",
  "homepageLiveMenuTitle2": "Recupera el dinero",
  "LPmenuTitleSpan2": "- completamente libre de riesgos",
  "LPmenuJoinNow": "¡Únete ahora!",
  "LPUnderSlideTitle": "Compra & Gasta R$25",
  

  
  "LPTitle": `Lo que dicen nuestros usuarios sobre nosotros`,
  "LPblock1Title1": `Estamos activos en redes sociales, encuéntranos en::`,
  "LPblock1SubTitle1": `More then `,
  "LPblock1SubTitleSpan": `Members On`,
  "LPblock1SubTitle2": `Discord`,
  "LPblock1SubTitle3": `Server`,
  "LPblock2Title": `Las chicas más salvajes del mundo`,
  "LPblock2SubTitle": `¡Disfruta con chicas de todo el mundo en cualquier momento que lo desees!`,
  "LPblock3Title": `Acción en vivo caliente SIN RIESGO`,
  "LPblock3SubTitle": `Compra y gasta R$25 en nuestro sitio asociado CALIENTE. ¿Te gustó? ¡10 créditos extra gratis! ¿No te gustó? ¡Reembolso del 100%!`,
  "LPblock4Title": `Déjate llevar por el placer con los mejores BONOS si comienzas ahora mismo!`,
  "LPblock4SubTitle": `🎰✈️ ¡La aventura de tu vida! 🎰✈️`,
  "LPblock4SubTitleLi1": `100% de reembolso`,
  "LPblock4SubTitleLi2": `Ve a Las Vegas con amigos y modelos atractivas`,
  "LPblock4SubTitleLi3": `¡Increíbles recompensas en nuestra competencia diaria!`,
  "LPFooterRow1": `Sobre nosotros`,
  "LPFooterRow2": `Missión`,
  "LPFooterRow3": `Políticas`,
  "LPFooterRow4": `Follow us on our medias`,
  "LPblock2TitleOver": `Tenemos todo lo que necesitas, ÚNETE a nosotros ahora mismo.`,
  "homePageUnderTitle": `Lo que nuestros usuarios dicen sobre nosotros`,
  "homePageUnderLeftTitle": `Estamos vivos en las redes sociales, encuéntranos en:`,
  "homePageUnderLeftBtn1": `Únete a nuestro Discord`,
  "homePageUnderLeftBtn2": `Únete a nuestro Instagram`,
  "homePageUnderLeftSubTitle": `¡Ven y mira nuestras reseñas!`,
  "LPblock2TitleOverRewiew": `Ve nuestras reseñas!`,
  "Rewiew1Desc": `Fui a través del proceso de Ela Manda, ¡y fue muy fluido y muy divertido!`,
  "Rewiew2Desc": `Fui a través del proceso de Ela Manda aquí, ¡vale totalmente la pena y funciona muy bien! Además, ¡nos divertimos con cada chica encantadora! ¡Nunca pierdo la oportunidad!`,
  "Rewiew3Desc": `Pasé por el proceso de Ela Manda, ¡y todo salió muy bien! Fue muy divertido; llegamos a ImLive y nos divertimos mucho jugando con las chicas. ¡Fue increíble!`,
  "Rewiew4Desc": `¡Hola chicos, pasé por el proceso de Ela Manda y fue muy divertido, realmente bueno, ¿saben?! ¡Lo recomiendo mucho!`,
  "Rewiew5Desc": `¡Hola a todos! Pasé por el proceso de Ela Manda con el grupo y nos divertimos mucho; ¡fue una experiencia increíble!`,


  
  "QuestionaireBtnNext": `Siguiente`,
  "QuestionaireStep1Title": `Dinos lo que te gusta.`,
  "QuestionaireStep1SubTitle": `🔥 ¿A quién quieres ver?`,
  "QuestionaireStep1Select1": `mujer`,
  "QuestionaireStep1Select2": `hombre`,
  "QuestionaireStep1Select3": `trans`,
  "QuestionaireStep2SubTitle": `¿En qué idioma te obedecerá?`,
  "QuestionaireStep2Select1": `inglés`,
  "QuestionaireStep2Select2": `español`,
  "QuestionaireStep2Select3": `portugués`,
  "QuestionaireStep3SubTitle": `¿Qué color de cabello te excita:`,
  "QuestionaireStep3Select1": `pelirrojo`,
  "QuestionaireStep3Select2": `moreno`,
  "QuestionaireStep3Select3": `rubio`,
  "verifyCodeEmailBtn": `abrir gmail`,


  "EmailAuthModalTitle1": `Verifica tu correo electrónico para registrarte`,

  "EmailAuthModalTitle1": `Verify your email to sign up:`,
  "NewLPmenuTitleOther": "UNA EXPERIENCIA",
  "NewLPmenuTitle": "EN VIVO",
  "NewLPmenuTitleSpan": "QUE CASI PUEDES TOCAR",
  "NewLPmenuTitle2": "INTERACTÚA CON ESTAS CHICAS Y",
  "NewLPmenuTitle2Span": "RECÍBELO GRATIS",



  "homepageLiveMenuBtn3": "En vivo con estas chicas ahora 👑",
  "homepageLiveOverTitle": `Compra &  gasta ${getAmount(5)} en ImLive`,
  "homepageLiveUnderSlideChoose": "luego elige:",


  "LPUnderOrLeft": "TU DINERO DE",
  "LPUnderOrLeft2": "VUELTA",
  "LPUnderOrRight": `${getAmount(10)} créditos`,
  "LPUnderOrRight2": `extras gratuitos`,





  "RedWelcomeHomeTitle": `Welcome to`,
  "RedWelcomeHomeSubTitle": `Ella Commands`,
  "RedWelcomeHomeDesc": `Do you think you're badass enough to be part of our exclusive group?`,
  "RedWelcomeHomeBtn": `I Deserve to Join`,
  "RedWelcomeHeaderLink1": `Homepage`,
  "RedWelcomeHeaderLink2": `Partners`,
  "RedWelcomeHeaderLink3": `Benefits`,
  "RedWelcomeHeaderLink4": `Girls`,
  "RedWelcomeHeaderLink5": `Contact us`,
  "RedWelcomeHeaderBtn": `JOIN US!`,
  "RedWelcomeTrust": `Trusted by`,
  "RedWelcomeOurTitle": `Our benefits`,
  "RedWelcomeOurSubTitle": `Our members have access:`,
  "RedWelcomeOurSectionTitle1": `OFFERS`,
  "RedWelcomeOurSectionTitle2": `GIVEAWAY’S`,
  "RedWelcomeOurSectionTitle3": `SECRET CLUB`,
  "RedWelcomeOurSectionTitle4": `EXCLUSIVE LIVES`,
  "RedWelcomeOurDesk1": `By joining the`,
  "RedWelcomeOurDeskSpan": `Ella Commands`,
  "RedWelcomeOurDesk2": `you will have the opportunity to interact more intimately with our girls and participate in special events, exclusive to our members.`,
  "RedWelcomeCamTitle": `CamGirls`,
  "RedWelcomeCamSubTitle": `Recent Girls`,
  "RedWelcomeReviewTitle": `Testimonials`,
  "RedWelcomeReviewSubTitle": `Reviews from members`,
  "RedWelcomeReview1Desc": `It was a huge surprise to be invited to the ${getDomain()}, and since then I've had incredible experiences.`,
  "RedWelcomeReview1Name": `C. Winter`,
  "RedWelcomeReview1Role": `Owner Red Hot`,
  "RedWelcomeReview2Desc": `After joining the ${getDomain()}, I had experiences and talked to people I never thought I'd have access to.`,
  "RedWelcomeReview2Name": `T. Shake`,
  "RedWelcomeReview2Role": `Member`,
  "RedWelcomeReview3Desc": `Since joining the ${getDomain()}, I've met people and had experiences beyond my expectations.`,
  "RedWelcomeReview3Name": `C. Gray`,
  "RedWelcomeReview3Role": `Member`,
  "RedWelcomeCertificateTitle": `Do you have what it takes to be in`,
  "RedWelcomeCertificateTitleSpan": `${getDomain()}?`,
  "RedWelcomeCertificateBtn": `I do!`,
  "RedWelcomeSpecialsTitle": `Specials Members`,
  "RedWelcomeMember1Name": `Cahyra Winter`,
  "RedWelcomeMember1Role": `Owner Red Hot`,
  "RedWelcomeMember2Name": `Ruan 1001`,
  "RedWelcomeMember2Role": `Papum no Barraco`,
  "RedWelcomeMember3Name": `Raissa Precioso`,
  "RedWelcomeMember3Role": `CamGirl!`,
  "RedWelcomeMember4Name": `Alexandra Repetto `,
  "RedWelcomeMember4Role": `CamGirl`,
  "withdrawNoMoney": `No tienes dinero para retirar`,
  "settingsId": `Mi ID de usuario`,
  "ReferBtnTitle": `Gane R$5 + 30 entradas para Las Vegas y 10 puntos de competencia.`,
  "ReferBtn": `Recomienda a un amigo`,

}
