import React, { useState, useEffect } from "react";
import { useDesign } from "../Helpers/Design/DesignContext";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useLanguage } from "../Helpers/Languages/LanguageContext";
import moengage from "@moengage/web-sdk";
const moment = require("moment-timezone");

function TimeCounterDay({ languageData, hidden, title, left, leftTitle, block, setSocialLink, user }) {
  const { design } = useDesign();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);
  const { language } = useLanguage();

  function calculateTimeLeft() {
    const endOfDay = moment().utc().endOf("day");
    const duration = moment.duration(endOfDay.diff(moment()));
    return {
      hoursRemaining: duration.hours(),
      minutesRemaining: duration.minutes(),
      secondsRemaining: duration.seconds(),
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { hoursRemaining, minutesRemaining, secondsRemaining } = timeLeft;

  return (
    <div className="max-w-[480px] w-full m-auto scaleFrom125">
      <p className="text-center font-medium text-[18px] sm:text-[18px] saira  sm:tracking-[3px]">{languageData?.newCounterDay}</p>
      <div className="bg-[#EAEAEA] bg-opacity-10 backdrop-blur-lg pt-1 rounded-[37px] ">
        <div className={` flex items-center  justify-center gap-[18px] leading-5 lg:justify-between max-w-[200px] w-full m-auto`}>
          <div className="text-center leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{hoursRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterHours}</p>
          </div>
          <p className={`text-[16px] font-bold md:text-[20px] saira`}>:</p>
          <div className="text-center leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{minutesRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterMinutes}</p>
          </div>
          <p className={`text-[16px] font-bold md:text-[20px] saira`}>:</p>
          <div className="text-center leading-3 sm:leading-4">
            <h2 className={`text-[16px] font-bold md:text-[20px] saira`}>{secondsRemaining}</h2>
            <p className="saira text-[10px] text-center">{languageData?.timeCounterSeconds}</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default TimeCounterDay;
