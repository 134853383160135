import logotype_latam from '../images/mandaLogo2.png';
import logotype from '../images/commandsLogo2.png';

import hero_elamanda from '.././Pages/WelcomeRed/Image/hero_elamanda.png';
import hero_ellacommands from '.././Pages/WelcomeRed/Image/hero_ellacommands.png';

import line_em from '.././Pages/WelcomeRed/Image/line_em.png';
import line_ec from '.././Pages/WelcomeRed/Image/line_ec.png';

import em_seal from '.././Pages/WelcomeRed/Image/em_seal.png';
import ec_seal from '.././Pages/WelcomeRed/Image/ec_seal.png';

export function getLogotype() {
    let domain = window.location.host;

    if (domain.includes('elamanda')) {
        return logotype_latam;
    } else {
        return logotype;
    }
}

export function getDomain() {
    let domain = window.location.host;

    if (domain.includes('elamanda')) {
        return 'Ela Manda';
    } else {
        return 'Ella Commands';
    }
}

export function getAmount(amount) {
    let domain = window.location.host;

    if (domain.includes('elamanda') === true) {
        return `R$${Number(amount) * 5}`;
    } else {
        return `$${Number(amount)}`;
    }
}

export function getHeroImage() {

    let domain = window.location.host;

    if (domain.includes('elamanda')) {
        return hero_elamanda;
    } else {
        return hero_ellacommands;
    }

}

export function getLineImage() {
    let domain = window.location.host;

    if (domain.includes('elamanda')) {
        return line_em;
    } else {
        return line_ec;
    }
}

export function getSealImage() {
    let domain = window.location.host;

    if (domain.includes('elamanda')) {
        return em_seal;
    } else {
        return ec_seal;
    }
}