module.exports = {
  slide1: [
    {
      image: require("../images/homepage/slide1/img1.png"),
      flag: require("../Components/SliderHomepage/🇧🇷.png"),
    },
    {
      image: require("../images/homepage/slide1/img2.png"),
      flag: require("../Components/SliderHomepage/🇧🇷.png"),
    },
    {
      image: require("../images/homepage/slide1/img3.png"),
      flag: require("../Components/SliderHomepage/🇧🇷.png"),
    },
    {
      image: require("../images/homepage/slide1/img4.png"),
      flag: require("../Components/SliderHomepage/🇧🇷.png"),
    },
    {
      image: require("../images/homepage/slide2/img1.png"),
      flag: require("../Components/SliderHomepage/🇷🇴.png"),
    },
    {
      image: require("../images/homepage/slide2/img2.png"),
      flag: require("../Components/SliderHomepage/🇷🇴.png"),
    },
    {
      image: require("../images/homepage/slide2/img3.png"),
      flag: require("../Components/SliderHomepage/🇨🇴.png"),
    },
    {
      image: require("../images/homepage/slide2/img4.png"),
      flag: require("../Components/SliderHomepage/🇨🇴.png"),
    },
    {
      image: require("../images/homepage/slide3/img1.png"),
      flag: require("../Components/SliderHomepage/🇨🇴.png"),
    },
    {
      image: require("../images/homepage/slide3/img2.png"),
      flag: require("../Components/SliderHomepage/🇨🇴.png"),
    },
    {
      image: require("../images/homepage/slide3/img3.png"),
      flag: require("../Components/SliderHomepage/🇨🇴.png"),
    },
  ],
  slide2: [
    require("../Components/SliderHomepage/🇧🇷.png"),
    require("../Components/SliderHomepage/🇷🇴.png"),
    require("../Components/SliderHomepage/🇨🇴.png"),
  ],
 
};
