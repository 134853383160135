import { Navigate } from "react-router-dom";
import { useEffect } from "react";

const AuthCheck = ({ children }) => {
  const token = localStorage.getItem("token");

  const uid = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (
      uid !== null &&
      uid !== undefined &&
      uid !== "" &&
      uid !== "login" &&
      uid !== "welcome" &&
      uid !== "verify-login"
    ) {
      window.localStorage.setItem("ref", uid);
    }
  }, [uid]);

  let params = new URLSearchParams(window.location.search);
  let email = params.get("email");

  let queryParams = new URLSearchParams();

  // Get the cookies
  let fbc = document.cookie.match(/_fbc=([^;]+)/);
  let fbp = document.cookie.match(/_fbp=([^;]+)/);

  // Get the fbclid parameter from the URL
  let fbclid = params.get("fbclid");

  // If fbclid exists in the URL parameters and isn't already in queryParams, add it
  if (fbclid !== null) {
    queryParams.set("fbclid", fbclid);

    if (fbc) {
      queryParams.set("fbc", fbc[1].split(".")[0]);
    }
  }

  // Convert queryParams to a string
  let queryString = queryParams.toString();

  // If there's no token, navigate to the welcome page with the constructed query parameters
  if (!token) {
    return (
      <Navigate
        to={`/welcome${email === null ? `?${queryString}` : `?email=${email}&${queryString}`}`}
      />
    );
  } else {
    //const decoded = jwtDecode(token)
  }

  return <>{children}</>;
};
export default AuthCheck;
