import React, { useState, useEffect } from 'react';
import logo from '../images/LandingPage/questionaireLogo.svg';
import Step1 from '../Components/Questionaire/Step1';
import Step2 from '../Components/Questionaire/Step2';
import Step3 from '../Components/Questionaire/Step3';
import cn from "classnames";

function Questionaire({ languageData }) {
    const [selectGender, setSelectGender] = useState(languageData?.QuestionaireStep1Select1);
    const [selectStep, setSelectStep] = useState('gender');
    const [selectedLanguages, setSelectedLanguages] = useState([languageData?.QuestionaireStep2Select1]);
    const [selectedColor, setSelectedColor] = useState([languageData?.QuestionaireStep3Select1]);

    const handleNextStep = () => {
        if (selectStep === 'gender') {
            setSelectStep('language');
        } else if (selectStep === 'language') {
            setSelectStep('color');
        }
    };
    useEffect(() => {
        if (languageData && languageData.QuestionaireStep1Select1) {
            setSelectGender(languageData.QuestionaireStep1Select1);
        }
    }, [languageData]);

    return (
        <div className='w-screen h-screen bg-[#1A1A1A]'>
            <div className={cn(` h-screen px-6 bg-cover bg-center bg-no-repeat max-w-[560px] m-auto`,
                {
                    "bg-gender-1 ": selectGender === languageData?.QuestionaireStep1Select1 && selectStep === 'gender',
                    "bg-gender-2 ": selectGender === languageData?.QuestionaireStep1Select2 && selectStep === 'gender',
                    "bg-gender-3 ": selectGender === languageData?.QuestionaireStep1Select3 && selectStep === 'gender',
                    "bg-language-1 ": selectGender === languageData?.QuestionaireStep1Select1 && selectStep === 'language',
                    "bg-language-2 ": selectGender === languageData?.QuestionaireStep1Select2 && selectStep === 'language',
                    "bg-language-3 ": selectGender === languageData?.QuestionaireStep1Select3 && selectStep === 'language',
                    "bg-color-1 ": selectGender === languageData?.QuestionaireStep1Select1 && selectStep === 'color',
                    "bg-color-2 ": selectGender === languageData?.QuestionaireStep1Select2 && selectStep === 'color',
                    "bg-color-3 ": selectGender === languageData?.QuestionaireStep1Select3 && selectStep === 'color',
                }
            )

            }>
                <img className='w-[230px] m-auto pt-6' src={logo} alt="logo" />
                {
                    selectStep === 'gender' ? (
                        <div className='flex justify-center h-[60%] max-w-[400px] m-auto'>
                            <Step1 languageData={languageData} selectGender={selectGender} setSelectGender={setSelectGender} />
                        </div>
                    ) : null
                }
                {
                    selectStep === 'language' ? (
                        <div className='flex justify-center h-[60%] max-w-[400px] m-auto'>
                            <Step2 languageData={languageData} selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages} />
                        </div>
                    ) : null
                }
                {
                    selectStep === 'color' ? (
                        <div className='flex justify-center h-[60%] max-w-[400px] m-auto'>
                            <Step3 languageData={languageData} selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                        </div>
                    ) : null
                }

                <button onClick={handleNextStep} className='w-full max-w-[400px] m-auto py-2 sm:py-1 bg-[#11C337] text-[20px] sm:text-[30px] flex items-center justify-center inter font-semibold rounded-[52px]'>{languageData?.QuestionaireBtnNext}</button>
            </div>
        </div>
    );
}

export default Questionaire;
