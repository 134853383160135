import React, { useState } from "react";
import HomePageHeader from "../../Components/HomePage/HomePageHeader";
import HoverBtn from "../../Components/Buttons/HoverBtn";
import { Link } from "react-router-dom";

function Vegas({
  setTourModal,
  languageData,
  setWinModal,
  setWinTicketModal,
  setEarnedModal,
  setTicketShortModal,
  setPointsModal,
  user,
  userCountry,
  PartnerURL,
  scrollToPage
}) {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const shareRefferalLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Sharing",
          url: "/" + user?.referral_code,
        })
        .then(() => console.log("Successful share! 🎉"));
    } else {
      window.navigator.clipboard.writeText(
        window.location.host + "/" + user?.referral_code
      );
      setIsLinkCopied(true);

      setTimeout(() => {
        setIsLinkCopied(false);
      }, 3000);
    }
  };

  return (
    <div
      className={` h-screen w-screen bg-vegasMob sm:bg-vegasDesk  relative z-10 bg-cover bg-center bg-no-repeat`}
    >
      <div className="m-auto  px-4 max-w-[1000px] pt-2 sm:pt-[20px] 3xl:pt-[40px]">
        <HomePageHeader
          setTourModal={setTourModal}
          setEarnedModal={setEarnedModal}
          setTicketShortModal={setTicketShortModal}
          setPointsModal={setPointsModal}
          user={user}
          userCountry={userCountry}
          languageData={languageData}
          scrollToPage={scrollToPage}
        />
        <div className="2xl:flex 3xl:items-center 2xl:h-[65vh] ">
          <div className="max-w-[1000px] w-full">
            <p className="text-[24px] sm:text-[36px] font-extrabold saira text-center mt-2">
              {languageData?.newVegasTitle}{" "}
            </p>
            <p className="text-[24px] sm:text-[36px] font-semibold saira text-center tracking-[3px] sm:tracking-[6px] leading-7">
              {languageData?.newVegasSubTitle}
            </p>
            <div className=" justify-between vegasHeight vegasBtnMt sm:mt-[270px] 3xl:mt-[200px] ml-3 sm:ml-6 hidden sm:flex">
              <HoverBtn
                text={languageData?.newVegasBtn1}
                width={"!w-[90%]"}
                onClick={(e) => window.open(PartnerURL)}
              />

              <HoverBtn
                onClick={(e) => {
                  scrollToPage(3);
                }}
                text={isLinkCopied ? languageData?.newVegasBtn2Copy : languageData?.newVegasBtn2}
                width={"!w-[90%]"}
                bgColor={"!bg-[#0FF]"}
              />
            </div>
            <div className="justify-between mt-2 w-[80%] m-auto hidden sm:flex">
              <p className="text-center saira w-[40%] text-[16px] font-semibold">
                {languageData?.newVegasUnderBtn1}
              </p>
              <p className="text-center saira w-[40%] text-[16px] font-semibold">
                {languageData?.newVegasUnderBtn2}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vegas;
