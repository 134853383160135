import React from 'react'
import trust1 from './Image/trust/1.png'
import trust2 from './Image/trust/2.png'
import trust3 from './Image/trust/3.png'

function Trusted({ languageData }) {
    return (
        <div className='md:flex w-full max-w-[1300px] m-auto' id='partners'>
            <p className='satoshi font-bold text-[24px] md:hidden block text-center text-[#b6b6b6b7] mt-8 '>{languageData?.RedWelcomeTrust}</p>
            <div className='flex justify-around items-center w-full  m-auto mt-3 md:mt-[150px]'>
                <p className='satoshi font-extrabold text-[48px] hidden md:block text-[#b6b6b6b7]'>{languageData?.RedWelcomeTrust}</p>
                <hr className='h-[130px] w-[2px] bg-[#848484] hidden lg:block' />
               
                <img className='w-[75px] md:w-[170px]' src={trust1} alt="trust1" />
                <img className='w-[57px] md:w-[130px]' src={trust2} alt="trust2" />
              
                <img className='w-[110px] md:w-[270px]' src={trust3} alt="trust3" />

            </div>
        </div>
    )
}

export default Trusted
