import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import clock from "../../images//landing/clock.png";
import { getLogotype } from "../../Helpers/domainHelper";
import gmail from "../../images/gmail.png";
import mail from "../../images/mail.png";


function AuthEmailNotification({ languageData }) {

  const email = window.localStorage.getItem("email-value");

  const params = new URLSearchParams(window.location.search);

  const [isGmail, setIsGmail] = useState(false);

  const [timeLeft, setTimeLeft] = useState(moment.duration(10, "minutes"));

  useEffect(() => {

    if (email) {
      if (email.indexOf('gmail.com') === -1) return
      setIsGmail(true);
    }
  }, []);

  useEffect(() => {

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime.clone().subtract(1, "second");

        if (newTime.asSeconds() <= 0) {
          clearInterval(timer);
        }

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-screen h-screen bg-modal fixed left-0 top-0 z-[99] bg-cover flex items-center justify-center">
      <div className="p-10 rounded-[12px] bg-[#83869b] bg-opacity-25 w-[90%] sm:w-auto border-[1px] border-[#FF1CBB]">
        <img className="w-[170px] m-auto" src={getLogotype()} alt="Logotype" />
        <p className="py-6 text-[20px] font-bold max-w-[500px] w-full text-center uppercase saira">
          {languageData?.verifyCodeEmailTitle} {email}
        </p>
        {
          isGmail ? <ul
            className="max-w-[300px] w-full btnNewHover mt-2 m-auto mb-4"
            onClick={() => {
              window.open("https://mail.google.com/mail/u/0/#inbox", "_blank");
            }}
          >
            <li className={`h-[45px]`}>
              <span></span>
              <span
                className={`fab fa-css3-alt text-[18px] sm:text-[18px] font-bold saira uppercase flex items-center gap-2 text-[#2A2A2A]`}
              >
                <img className="w-[25px]" src={gmail} alt="gmail" />
                {languageData?.verifyCodeEmailBtn}
              </span>
            </li>
          </ul>
            : <p className="saira text-center w-full mt-0 mb-4 flex justify-center gap-2 items-center text-[16px]">
              <img src={mail} className="w-[25px]" alt="mail" />
              {languageData?.openEmailApp}
            </p>
        }
        <div className="w-full items-center flex justify-center gap-2 ">
          <img className="w-[20px] h-[20px]" src={clock} alt="Clock" />
          <p className="saira">
            {moment.utc(timeLeft.asMilliseconds()).format("mm:ss")}
          </p>
        </div>
        <div className="items-center">
          <p className="saira text-center w-full mt-4 text-sm">
            {languageData?.verifyCodeSpam}
            <br />
            {languageData?.verifyCodeSpam2}
          </p>
        </div>
        <p className="saira mt-5 hidden max-w-[200px]">
          {languageData?.verifyCodeLink}{" "}
          <Link className="text-[#9D3EFD] saira" to={"/welcome"}>
            {languageData?.verifyCodeLinkSpan}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default AuthEmailNotification;
