import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation } from 'swiper/modules';
import portfolio1 from "./Image/slider/1.png";
import portfolio2 from "./Image/slider/2.png";
import portfolio3 from "./Image/slider/3.png";
import portfolio4 from "./Image/slider/4.png";
import portfolio5 from "./Image/slider/5.png";
import portfolio6 from "./Image/slider/6.png";
import portfolio7 from "./Image/slider/7.png";
import portfolio8 from "./Image/slider/8.png";
import portfolio9 from "./Image/slider/9.png";

function SliderPortfolio() {
    const reviewsUser = [
        {
            image: portfolio1
        },
        {

            image: portfolio2
        },
        {

            image: portfolio3
        },
        {

            image: portfolio4
        },
        {

            image: portfolio5
        },
        {

            image: portfolio6
        },
        {

            image: portfolio7
        },
        {

            image: portfolio8
        },
        {

            image: portfolio9
        },
    ];
    const slides = reviewsUser.map((index) => (
        <SwiperSlide key={index}>

            <div className="flex justify-center">
                        <img className=" w-full h-[120px] sm:w-[350px] sm:h-[270px]" src={index.image} alt="avatar" />
            </div>
        </SwiperSlide>
    ));
    const [progress, setProgress] = useState(0);
    return (
        <div className="max-w-[1850px] m-auto sm:mr-[-250px] 3xl:mr-[-100px] sliderPortfolioMargin sm:mt-[70px]">
            <div className="w-full flex items-center">

                <Swiper
                    className=' relative z-10'
                    modules={[Navigation, Autoplay]}
                    spaceBetween={0}
                    centeredSlides={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 3,
                        },
                        640: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}

                    loop={true}
                    navigation={{
                        prevEl: '.buttonPrev',
                        nextEl: '.buttonNext',
                    }}
                    autoplay={{
                        delay: 5000,
                    }}
                    onSlideChange={(swiper) => {
                        const progressValue = (swiper.realIndex + 1) / reviewsUser.length * 100;
                        setProgress(progressValue);
                    }}
                >
                    {slides}
                </Swiper>

            </div>
            <div className="flex items-center sm:w-[80%] 3xl:w-[90%] relative right-[-20px] sm:right-[-160px] pt-6 sm:pt-12">
                <div className="w-2 sm:w-3 h-2 sm:h-3 bg-[#F44F4F] rounded-full">

                </div>
                <div className="w-full bg-[#D9D9D9] h-[1px] sm:h-[2px]">

                    <div
                        className="bg-[#F44F4F] h-full"
                        style={{
                            width: `${progress}%`,
                            transition: 'width 0.3s ease-in-out'
                        }}
                    ></div>
                </div>
            </div>

        </div>
    )
}

export default SliderPortfolio
