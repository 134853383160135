import React, { useEffect, useRef, useState } from "react";
import BottomMenu from "../Components/Landing/BottomMenu.jsx";
import Footer from "../Components/Landing/Footer.jsx";
import LandingLogin from "../Components/Landing/LandingLogin.jsx";
import { useNavigate } from "react-router-dom";
import moengage from "@moengage/web-sdk";
import { useLanguage } from "../Helpers/Languages/LanguageContext.jsx";
import Loader from "../Components/Loader.jsx";
import EmailAuthModal from "../Components/Landing/EmailAuthModal.jsx";
import { getUserCountry } from "../Requests/utills.js";
import { getLogotype } from "../Helpers/domainHelper.js";
import cn from "classnames";
import SlidelBlur from "../Components/SliderHomepage/SlidelBlur.jsx";
import ReviewsSlider from "../Components/SliderHomepage/ReviewsSlider.jsx";
import mixpanelTrack from "../Helpers/mixpanel.js";
import Block from "../Components/Landing/Auth/Block.jsx";
import OurMission from "./CyberSaysPages/Modals/OurMission.jsx";

function WelcomeAuthOLD({ languageData }) {
  const params = new URLSearchParams(window.location.search);

  const { language } = useLanguage();

  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
    const getUserCountryData = async () => {
      const userCountry = await getUserCountry();
      setUserCountry(userCountry.data.Data.country);
    };
    getUserCountryData();
  }, []);

  const countryLogo = userCountry ? getLogotype(userCountry) : null;

  const [landingLogin, setLandingLogin] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [missionOpen, setMissionOpen] = useState(false);

  let referralID =
    window.localStorage.getItem("ref") === "welcome" ||
      window.localStorage.getItem("ref") === "verify-login"
      ? null
      : window.localStorage.getItem("ref");

  useEffect(() => {
    /* MIXPANEL */

    let utmData = {
      utm_source:
        params.get("utm_source") || window.localStorage.getItem("utm_source"),
      utm_medium:
        params.get("utm_medium") || window.localStorage.getItem("utm_medium"),
      utm_campaign:
        params.get("utm_campaign") ||
        window.localStorage.getItem("utm_campaign"),
      utm_term:
        params.get("utm_term") || window.localStorage.getItem("utm_term"),
      utm_content:
        params.get("utm_content") || window.localStorage.getItem("utm_content"),
    };

    // delete nulls

    mixpanelTrack("page_view", {
      distinct_id: "not_set",
      ...utmData,
      page_name: "Welcome",
    });
    moengage.track_event("page_view", {
      distinct_id: "not_set",
      ...utmData,
      page_name: "Welcome",
    });
  }, []);

  const navigate = useNavigate();

  if (window.localStorage.getItem("token")) {
    navigate("/");
  } else {
    window.localStorage.removeItem("double");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* LOGIN LOGIC */

  let refferalCode = referralID;

  let special = window.localStorage.getItem("uid");

  if (special === undefined || special === null) special = null;
  if (refferalCode === undefined || refferalCode === null) refferalCode = null;

  const [loading, setLoading] = useState(false);

  const handleMixpanelEvent = async (success, provider, userID) => {
    mixpanelTrack("login", {
      distinct_id: userID,
      registered_with: provider,
      success: success,
    });
    moengage.track_event("login", {
      id: userID,
      registered_with: provider,
      success: success,
    });
  };

  const [emailEnabled, setEmailEnabled] = useState(false);
  const [mode, setMode] = useState("email");

  const enablePopupFlow = () => {
    setLandingLogin(true);
  };
  const ref = useRef(null);
  const scrollToDown = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.zE) {
        clearInterval(interval); // Зупиняємо інтервал, якщо умова виконана
        window.zE("messenger", "hide");
      } else {
        console.error("Zendesk API not available or not initialized.");
      }
    }, 500);

    // Повернути функцію відчищення, щоб вона виконалася при видаленні компоненту або при зміні залежностей
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-x-hidden overflow-y-scroll h-[110vh] sm:h-screen bg-[#0A0A0A]">
      <div className="bg-[#0A0A0A] sm:bg-landMainDesk2 w-screen h-[90vh] sm:h-screen bg-cover bg-center bg-no-repeat relative z-0">
        <img
          className={cn(
            "w-[130px] sm:w-[320px] 3xl:w-[330px] m-auto pt-2 3xl:pt-[100px] duration-200 sm:pt-[50px]",
            {
              "opacity-0": !getLogotype(),
            }
          )}
          src={getLogotype()}
          alt="logo"
        />
        <p className="saira font-extrabold text-[20px] sm:text-[28px] my-3 sm:my-1 3xl:mt-[20px] text-center leading-6 pr-4 ">
          {languageData?.NewLPmenuTitleOther}{" "}
          <span className="saira font-extrabold text-[22px] sm:text-[28px] text-[#A30023] ">
            {languageData?.NewLPmenuTitle}
          </span>{" "}
          {languageData?.NewLPmenuTitleSpan}{" "}
        </p>
        <SlidelBlur languageData={languageData} />
        <p className="saira text-[18px] sm:text-[28px] font-bold mt-3 justify-center items-center text-center pr-4">
          {languageData?.NewLPmenuTitle2}{" "}
          <span className="text-[#A30023] saira font-extrabold">{languageData?.NewLPmenuTitle2Span}</span>
        </p>
        {/*
        <p className="saira text-[16px] sm:text-[20px] font-bold text-center mt-4">
          {languageData?.homepageLiveUnderSlideSubTitle}
        </p>
        
        <div className="m-auto flex justify-center items-center mt-4">
          <div className="bg-[#474747] max-w-[150px] sm:max-w-[280px] w-full px-2 py-1 h-[50px] rounded-[16px]  flex items-center justify-center">
            <p className="text-center saira text-[15px] md:text-[16px] font-bold leading-5 sm:leading-[unset]">
              {languageData?.LPUnderOrLeft}
            </p>
          </div>
          <div className="w-[50px] h-[50px] rounded-full bg-[#474747] border-2 border-[#FF00E5] flex justify-center items-center saira text-[12px] font-bold mx-[-10px] relative z-10">
            {languageData?.homepageLiveUnderOr}
          </div>
          <div className="bg-[#474747] max-w-[150px] sm:max-w-[280px] w-full px-3 py-1 h-[50px] rounded-[16px] flex items-center justify-center">
            <p className="text-center saira text-[15px] md:text-[16px] font-bold leading-5 sm:leading-[unset]">
              {languageData?.LPUnderOrRight}
            </p>
          </div>
        </div>
        */}
        <div className="m-auto flex justify-center items-center sm:mt-5 3xl:mt-12">
          <div className=" max-w-[150px] sm:max-w-[280px] w-full px-2 py-1 h-[50px] rounded-[16px]  flex items-center justify-center sm:mr-[-30px]">
            <p className="text-center saira text-[12px] md:text-[18px] font-bold leading-5 sm:leading-[unset] uppercase px-4 welcomeTextLED2">
              {languageData?.LPUnderOrLeft}<br />
              {languageData?.LPUnderOrLeft2}
            </p>
          </div>
          <hr className="bg-[#AD3A92] w-[30px] h-[1px] border-none" />
          <div className="w-[50px] h-[50px] rounded-full flex justify-center items-center saira text-[12px] md:text-[18px] uppercase font-bold relative z-10 mx-2 sm:mx-4">
            {languageData?.homepageLiveUnderOr}
          </div>
          <hr className="bg-[#AD3A92] w-[30px] h-[1px] border-none" />
          <div className=" max-w-[150px] sm:max-w-[280px] w-full px-3 py-1 h-[50px] rounded-[16px] flex items-center justify-center sm:ml-[-30px]">
            <p className="text-center saira text-[12px] md:text-[18px] font-bold leading-5 sm:leading-[unset] uppercase sm:px-12">
              {languageData?.LPUnderOrRight}<br />
              {languageData?.LPUnderOrRight2}
            </p>
          </div>
        </div>
        <BottomMenu
          scrollToDown={scrollToDown}
          setLandingLogin={setLandingLogin}
          languageData={languageData}
          setLoading={setLoading}
          handleMixpanelEvent={handleMixpanelEvent}
          refferalCode={refferalCode}
          special={special}
          setEmailEnabled={setEmailEnabled}
          emailEnabled={emailEnabled}
          setMode={setMode}
          mode={mode}
          setEmailModal={setEmailModal}
        />
      </div>
      <div ref={ref} className=" w-screen z-10 relative bg-gradient-landing">
        <p className="saira text-[22px] sm:text-[36px] text-center font-bold my-10">
          {languageData?.LPTitle}
        </p>
        <ReviewsSlider languageData={languageData} />

        <Block languageData={languageData} setEmailModal={setEmailModal} enablePopupFlow={enablePopupFlow} />
        <ul
          onClick={(e) => {
            if (params.get("email") === null) {
              enablePopupFlow();
            } else {
              setEmailModal(true);
            }
          }}
          className=" sm:max-w-[440px] m-auto w-[90%] btnNewHover mt-8 sm:mt-12  cursor-pointer pb-[150px]"
        >
          <li className={`!h-[80px]`}>
            <span className="!bg-[#A30023]"></span>
            <span
              className={`fab fa-css3-alt font-bold saira ${language !== "en" ? "text-[14px] sm:text-[36px]" : "text-[18px] sm:text-[36px]"}`}
            >
              {languageData?.LPmenuJoinNow}
            </span>
          </li>
        </ul>
      </div>

      {loading && <Loader />}
      <Footer setMissionOpen={setMissionOpen} languageData={languageData} />
      {landingLogin && (
        <LandingLogin
          setOpen={setLandingLogin}
          setEmailModal={setEmailModal}
          visible={landingLogin}
          setLoading={setLoading}
          handleMixpanelEvent={handleMixpanelEvent}
          refferalCode={refferalCode}
          special={special}
          languageData={languageData}
        />
      )}

      {emailModal && (
        <EmailAuthModal
          setOpen={setEmailModal}
          visible={emailModal}
          setLoading={setLoading}
          handleMixpanelEvent={handleMixpanelEvent}
          refferalCode={refferalCode}
          special={special}
          languageData={languageData}
          fbclid={params.get("fbclid")}
        />
      )}

      {missionOpen && (
        <OurMission languageData={languageData} setOpen={setMissionOpen} />
      )}
    </div>
  );
}

export default WelcomeAuthOLD;
